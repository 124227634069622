<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">

  <!-- fil d actualite -->
  <div class="row">
    <div class="form-group col-md-12">
      <div class="col-md-12">

        <button (click)="openFormModalModal(templateCreate)" class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button">
          Ajouter </button>
          <input type="text" class="form-control custom_input" style="display: inline-block; width: 50%;"
          placeholder="Entrer clé de recherche"
          name="fullSearch" [(ngModel)]="searchKey" (ngModelChange)="this.onFullSearch.next($event)">


        <div class="col-md-12 no-padding" style="margin-top: 10px">
          <table class="table table-condensed table-striped">
            <thead>
            <tr class="custom-table-thead">
              <th style="width: 45px"></th>
              <th> Date creation </th>
              <th>Nom </th>
              <th>email </th>
              <th>contact</th>
              <!-- <th>Réference </th> -->
              <th style="text-align: center; width: 75px"> Action </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let entr of listDonateurs; let i = index" >
              <td class="vertical-align-uppercase">
                {{i + 1}}
              </td>
              <td class="vertical-align-uppercase">{{entr.createdAt}}</td>
              <td class="vertical-align-uppercase">{{entr.nom}}</td>
              <td class="vertical-align-uppercase">{{entr.email.toLowerCase()}}</td>
              <td class="vertical-align-uppercase">{{entr.contact}}</td>
              <!-- <td class="vertical-align-uppercase">{{entr.referenceDevice}}</td> -->
              <td style="text-align: center">
                <a title="Modifier" (click)="openFormModalModal(templateCreate,entr)">
                  <i class="fa fa-edit" style="color: green"> </i>
                </a>
                &nbsp;&nbsp;
                <a title="Supprimer" (click)="confirmDeleteItem(entr)">
                  <i class="fa fa-trash-o" style="color: red"> </i>
                </a>
              </td>
            </tr>

            <tr *ngIf="listDonateurs && listDonateurs.length > 0">
              <td colspan="6">
                <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
                  <pagination [boundaryLinks]="true" [totalItems]="totalItems" [maxSize]='itemPerPagePagination' [itemsPerPage]="itemsPerPage"
                              [(ngModel)]="currentPage" class="pagination-sm" previousText="&laquo;" nextText=" &raquo;"
                              firstText="&laquo;" lastText="&raquo;" name="pagination" (pageChanged)="pageChanged($event)">
                  </pagination>
                </div>
              </td>
            </tr>
            </tbody>
            <tfoot *ngIf="!listDonateurs || listDonateurs.length == 0">
            <tr>
              <td colspan="3" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
            </tr>

            </tfoot>

          </table>
        </div>

      </div>


    </div>
  </div>
  <!-- fin file d actualite -->



  <ng-template #templateCreate>
    <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">
          DONATEUR
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="form-group col-lg-12">

            <div class="row">
              <div class="col-md-12">

                <div class="form-group col-md-12">
                  <label>Nom :
                    <span class="required_champs">*</span>
                  </label>
                  <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nom" name="nom" required
                         placeholder="Nom" type="text">
                </div>


                <div class="form-group col-md-12">
                  <label>Contact :
                    <span class="required_champs">*</span>
                  </label>
                  <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.contact" name="contact" required
                         placeholder="Contact" type="text" mask="00-00-00-00-00">
                </div>
                <div class="form-group col-md-12">
                  <label>Email :
                    <span class="required_champs">*</span>
                  </label>
                  <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.email" name="email" required
                         placeholder="Email" type="text">
                </div>

                <!-- <div class="form-group col-md-12">
                  <label>Référence :
                      <span class="required_champs">*</span>
                  </label>
                  <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.referenceDevice" name="referenceDevice" required
                      placeholder="Référence" type="text">
              </div> -->


              </div>
            </div>

          </div>

        </div>


      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left">(
          <span class="required_champs">*</span>) Champs requis</b>

        <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal()">
          Annuler
        </button>

        <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItem(itemToSave) ">
          Valider
        </button>

      </div>
    </form>
  </ng-template>



</div>
