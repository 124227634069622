import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import * as _ from "lodash";
import { UserService } from "../../user/user.service";
import { Api } from "../../utils/api/api";
import { CommunicationService } from "src/app/core/services/communication.service";
import { filter } from "rxjs/operators";

@Component({
  selector: "sa-route-breadcrumbs",
  template: `
    <ol class="breadcrumb">
      <li *ngFor="let item of items">{{ item }}</li>
    </ol>

    <div class="pull-right" style="margin-top: 4px;">
      <select
        class="form-control"
        id="specificiteSearchSelected"
        (change)="selectedFiliale()"
        [disabled]="isReadOnly"
        [(ngModel)]="filialeSelected"
        name="statutSearchSelected"
      >
        <option value="">-- Choisissez --</option>
        <option *ngFor="let dispo of ListeTenants" [ngValue]="dispo">
          {{ dispo.raisonSociale }}
        </option>
      </select>
    </div>
  `,
  styles: [],
})
export class RouteBreadcrumbsComponent implements OnInit, OnDestroy {
  public items: Array<string> = [];
  private sub;
  ListeTenants: Array<string> = [];
  user: any;
  isReadOnly: boolean;
  filialeSelected: any = {};
  currentValue: any = {};

  constructor(
    private router: Router,
    private userService: UserService,
    private api: Api,
    private communicationService: CommunicationService
  ) {
    this.user = this.userService.getCurrentUser();
    console.log("current user info: ", this.user);
    console.log("current user data tenancy info: ", this.user.datasTenancy);
    if (this.user.datasTenancy && this.user.datasTenancy.length) {
      sessionStorage.setItem(
        "currentTenancyCode",
        this.user.datasTenancy[0].tenancyCode
      );
      sessionStorage.setItem(
        "currentTenancyId",
        this.user.datasTenancy[0].tenancyId
      );
    } else {
      sessionStorage.setItem("currentTenancyCode", null);
      sessionStorage.setItem("currentTenancyId", null);
    }

    this.getAllTenant();
  }

  selectedFiliale() {
    console.log("emet l evenement");
    this.currentValue = this.filialeSelected;
    this.userService.updateTenantInformation(this.currentValue);
  }

  getAllTenant() {
    var request = {
      user: this.user.id,
      data: {
        marchandId: null,
        id: null,
      },
    };
    let endPoint = "tenancy/getByCriteria";
    if (
      this.user.userTypeCode == "MARCHAND_ADMIN" ||
      this.user.userTypeCode == "MARCHAND_USER"
    ) {
      endPoint = "marchandTenancy/getByCriteria";
      request.data.marchandId =
        this.user.datasMarchand && this.user.datasMarchand.length > 0
          ? this.user.datasMarchand[0].id
          : null;
    }
    if (
      this.user.userTypeCode == "TENANCY_ADMIN" ||
      this.user.userTypeCode == "TENANCY_USER" ||
      this.user.userTypeCode == "GESTIONNAIRE_ADMIN"
    ) {
      request.data.id =
        this.user.datasTenancy && this.user.datasTenancy.length > 0
          ? this.user.datasTenancy[0].id
          : null;
    }
    this.api.post(endPoint, request).subscribe((res: any) => {
      console.log("liste des filiales", res);
      if (res && res.items) {
        res.items.forEach((value: any) => {
          value.raisonSociale = value.raisonSociale
            ? value.raisonSociale.toUpperCase()
            : value.tenancyRaisonSociale
            ? value.tenancyRaisonSociale.toUpperCase()
            : "";
        });
        this.ListeTenants = res.items;
        this.filialeSelected = this.ListeTenants[0];
        this.userService.updateTenantInformation(this.filialeSelected);
      } else {
        this.ListeTenants = [];
      }
    });
  }

  ngOnInit() {
    this.extract(this.router.routerState.root);
    this.sub = this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe((v) => {
        this.items = [];
        this.extract(this.router.routerState.root);
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  extract(route) {
    let pageTitle = route.data.value["pageTitle"];
    if (pageTitle && this.items.indexOf(pageTitle) == -1) {
      this.items.push(route.data.value["pageTitle"]);
    }
    if (route.children) {
      route.children.forEach((it) => {
        this.extract(it);
      });
    }
  }
}
