<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">


  <!-- fil d actualite -->
  <div class="row">
      <div class="form-group col-md-12">
          <div class="col-md-12">

              <button (click)="openFormModalModal(templateCreate)"
                  class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button">
                  Ajouter </button>
              <br />

              <div class="col-md-12 no-padding" style="margin-top: 10px">
                  <table class="table table-condensed table-striped">
                      <thead>
                          <tr class="custom-table-thead">
                              <th style="width: 45px">
                                  <a (click)="getData()"><i class="fa fa-refresh"></i></a>
                              </th>
                              <th> Date création </th>
                              <th>Libellé</th>
                              <!-- <th>Donateur </th> -->
                              <!-- <th>Type don</th> -->
                              <!-- <th>Quantité</th> -->
                              <th class="text-right">Valeur</th>
                              <!-- <th class="text-right">Restant</th> -->
                              <!-- <th>Réference </th> -->
                              <th style="text-align: center; width: 100px"> Action </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let entr of listDonations; let i = index">
                              <td class="vertical-align-uppercase">
                                  {{i + 1}}
                              </td>
                              <td class="vertical-align-uppercase">{{entr.createdAt}}</td>
                              <td class="vertical-align-uppercase">{{entr.libelle}}</td>
                              <!-- <td class="vertical-align-uppercase">{{entr.donateurNom}}</td> -->
                              <!-- <td class="vertical-align-uppercase">{{entr.typeDonLibelle}}</td> -->
                              <!-- <td class="vertical-align-uppercase" style="font-weight: 700">{{entr.quantiteOperation}}
                                </td> -->
                              <td class="vertical-align-uppercase text-right" style="font-weight: 700">
                                  {{entr.valeurOperation | moneyFormat}}</td>
                              <!-- <td class="vertical-align-uppercase text-right" style="font-weight: 700">
                                  {{entr.valeurOperationRestante | moneyFormat}}</td> -->
                              <!-- <td class="vertical-align-uppercase">{{entr.referenceDevice}}</td> -->

                              <td style="text-align: center">
                                  <div class="row positionRow">
                                      <div class="col-md-4 padding-icon">
                                          <a *ngIf="entr?.valeurOperationRestante == entr?.valeurOperation"
                                              title="Modifier" (click)="openFormModalModal(templateCreate,entr)">
                                              <i class="fa fa-edit" style="color: green"> </i>
                                          </a>
                                      </div>

                                      <div class="col-md-4 padding-icon">
                                          <a title="Detail"
                                              (click)="openFormModalModal(templateCreate,entr,'detail')">
                                              <i class="fa fa-eye" style="color: green"> </i>
                                          </a>
                                      </div>

                                      <div class="col-md-4 padding-icon">
                                          <a title="Supprimer" (click)="confirmDeleteItem(entr)">
                                              <i class="fa fa-trash-o" style="color: red"> </i>
                                          </a>
                                      </div>
                                  </div>
                              </td>
                          </tr>

                          <tr *ngIf="listDonations && listDonations.length > 0">
                              <td colspan="9">
                                  <div class="col-xs-12 col-12"
                                      style="text-align: left;padding: 0px;margin-top: 15px">
                                      <pagination [boundaryLinks]="true" [totalItems]="totalItems"
                                          [maxSize]='itemPerPagePagination' [itemsPerPage]="itemsPerPage"
                                          [(ngModel)]="currentPage" class="pagination-sm" previousText="&laquo;"
                                          nextText=" &raquo;" firstText="&laquo;" lastText="&raquo;" name="pagination"
                                          (pageChanged)="pageChanged($event)">
                                      </pagination>
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                      <tfoot *ngIf="!listDonations || listDonations.length == 0">
                          <tr>
                              <td colspan="9" style="text-align:center; font-weight:bold">Aucune donnée disponible
                              </td>
                          </tr>

                      </tfoot>

                  </table>
              </div>

          </div>


      </div>
  </div>
  <!-- fin file d actualite -->



  <ng-template #templateCreate>
      <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
          <div class="modal-header custom-modal-header">
              <h4 *ngIf="!detailMode" class="modal-title pull-left">
                  PRIME
              </h4>
              <h4 *ngIf="detailMode" class="modal-title pull-left">
                  DETAILS PRIME
              </h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <div class="row">
                  <div class="form-group col-lg-12">

                      <div class="row">
                          <div class="col-md-12">
                              <!-- <div class="form-group col-md-12">
                                  <label>Type prime :
                                      <span class="required_champs">*</span>
                                  </label>
                                  <select [disabled]="detailMode" class="form-control custom_input" id="nature"
                                      [(ngModel)]="itemToSave.nature" #ctrl="ngModel" name="nature">
                                      <option *ngFor="let type of listTypeDon " [ngValue]="type.id">
                                          {{ type.libelle }}
                                      </option>
                                  </select>
                              </div> -->


                              <div class="form-group col-md-12">
                                  <!-- <div class="row">
                                      <label style="margin-left: 14px;">Donateur :
                                          <span class="required_champs">*</span>
                                      </label>
                                      <br>
                                      <div class="col-md-11">

                                          <select [disabled]="detailMode" class="form-control custom_input"
                                              id="donateurId" [(ngModel)]="itemToSave.donateurId" #ctrl="ngModel"
                                              name="donateurId">
                                              <option></option>
                                              <option *ngFor="let donateur of listDonateurs " [ngValue]="donateur.id">
                                                  {{ donateur.nom }}
                                              </option>
                                          </select>
                                      </div>
                                      <div class="col-md-1">
                                          <button [disabled]="detailMode"
                                              (click)="quickAddDonator(templateCreateDonator)" style="margin-top: 5px"
                                              class="btn btn-xs btn-primary pull-right margin-top-1">
                                              <i class="fa fa-plus"></i>
                                          </button>
                                      </div>
                                  </div> -->


                              </div>
                              <div class="form-group col-md-12">
                                  <label>Libellé ({{itemToSave.libelle?.length || 0}}/50) :
                                      <span class="required_champs">*</span>
                                  </label>
                                  <input #libelleInput [disabled]="detailMode" class="form-control custom_input"
                                      maxlength="50" autocomplete="off" [(ngModel)]="itemToSave.libelle"
                                      name="libelle" required placeholder="libellé" type="text">
                              </div>
                              <div class="form-group col-md-12">
                                  <label>Description :
                                      <span class="required_champs">*</span>
                                  </label>
                                  <textarea [disabled]="detailMode" name="description" id="" style="width: 100%"
                                      [(ngModel)]="itemToSave.description" rows="3"></textarea>
                              </div>
                              <!-- <div class="form-group col-md-12" *ngIf="itemToSave.nature === 2">
                                  <label>Quantité :
                                      <span class="required_champs">*</span>
                                  </label>
                                  <input [disabled]="detailMode" class="form-control custom_input" autocomplete="off"
                                      [(ngModel)]="itemToSave.quantiteOperation" name="quantite" required
                                      placeholder="quantité" type="text">
                              </div> -->
                              <div class="form-group col-md-12" *ngIf="itemToSave.nature === 1">
                                  <label>Valeur :
                                      <span class="required_champs">*</span>
                                  </label>
                                  <input [disabled]="detailMode" class="form-control custom_input" autocomplete="off"
                                      [(ngModel)]="itemToSave.valeurOperation" name="valeur" required
                                      placeholder="valeur" type="text" mask="separator">
                              </div>
                          </div>
                      </div>

                  </div>

              </div>


          </div>
          <div class="modal-footer modal-footer-custom">
              <b class="pull-left">(
                  <span class="required_champs">*</span>) Champs requis</b>

              <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel"
                  (click)="hideModal()">
                  Annuler
              </button>

              <button *ngIf="!detailMode" type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 "
                  (click)="confirmSaveItem(itemToSave) ">
                  Valider
              </button>

          </div>
      </form>
  </ng-template>

  <ng-template #templateCreateDonator>
      <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
          <div class="modal-header custom-modal-header">
              <h4 class="modal-title pull-left">
                  PRIME
              </h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">

          </div>
          <div class="modal-footer modal-footer-custom">
              <b class="pull-left">(
                  <span class="required_champs">*</span>) Champs requis</b>

              <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel"
                  (click)="hideModal()">
                  Annuler
              </button>

              <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 "
                  (click)="confirmSaveItem(itemToSave) ">
                  Valider
              </button>

          </div>
      </form>
  </ng-template>

  <ng-template #templateCreateDonator>
      <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
          <div class="modal-header custom-modal-header">
              <h4 class="modal-title pull-left">
                  DONATEUR
              </h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal2()">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>

          <div class="modal-body">
              <div class="row">
                  <div class="form-group col-lg-12">

                      <div class="row">
                          <div class="col-md-12">

                              <div class="form-group col-md-12">
                                  <label>Nom :
                                      <span class="required_champs">*</span>
                                  </label>
                                  <input class="form-control custom_input" autocomplete="off"
                                      [(ngModel)]="itemToSave.nom" name="nom" required placeholder="Nom" type="text">
                              </div>


                              <div class="form-group col-md-12">
                                  <label>Contact :
                                      <span class="required_champs">*</span>
                                  </label>
                                  <input class="form-control custom_input" autocomplete="off"
                                      [(ngModel)]="itemToSave.contact" name="contact" required placeholder="Contact"
                                      type="text" mask="00-00-00-00">
                              </div>
                              <div class="form-group col-md-12">
                                  <label>Mail :
                                      <span class="required_champs">*</span>
                                  </label>
                                  <input class="form-control custom_input" autocomplete="off"
                                      [(ngModel)]="itemToSave.email" name="email" required placeholder="Email"
                                      type="text">
                              </div>

                              <!-- <div class="form-group col-md-12">
                                <label>Référence :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.referenceDevice" name="referenceDevice" required
                                    placeholder="Référence" type="text">
                            </div> -->


                          </div>
                      </div>

                  </div>

              </div>


          </div>
          <div class="modal-footer modal-footer-custom">
              <b class="pull-left">(
                  <span class="required_champs">*</span>) Champs requis</b>

              <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel"
                  (click)="hideModal2()">
                  Annuler
              </button>

              <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 "
                  (click)="confirmSaveItemDonator(itemToSave) ">
                  Valider
              </button>

          </div>
      </form>
  </ng-template>



</div>