<!-- MAIN CONTENT -->
<div
  id="content"
  [ngBusy]="{
    busy: busyGet,
    message: 'Veuillez patienter ...',
    backdrop: false
  }"
>
  <!-- fil d actualite -->
  <div class="row">
    <div class="form-group col-md-12">
      <div class="col-md-12">
        <div
          class="col-md-6"
          style="padding-left: 0px"
          *ngIf="user?.userTypeCode == 'SA' || user?.userTypeCode == 'ADMIN'"
        >
          <h1 class="custom-title-bold">CULTURES</h1>
          <button
            class="btn btn-md custom_border_radius btn-bg-new padding-30"
            type="button"
            (click)="openFormModalModal(templateCreate)"
          >
            Ajouter
          </button>
          <input
            type="text"
            class="form-control custom_input"
            style="display: inline-block; width: 50%"
            placeholder="Entrer clé de recherche"
            name="fullSearch"
            [(ngModel)]="searchKey"
            (ngModelChange)="this.onFullSearch.next($event)"
          />
          <br />
          <br />
          <table class="table table-condensed table-striped">
            <thead>
              <tr class="custom-table-thead">
                <th style="width: 45px">
                  <a (click)="getData()"> <i class="fa fa-refresh"></i> </a>
                </th>
                <th>Libellé</th>
                <th style="text-align: center; width: 75px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let entr of results; let i = index">
                <td class="vertical-align-uppercase">
                  {{ i + 1 }}
                </td>
                <td class="vertical-align-uppercase">{{ entr.libelle }}</td>
                <td style="text-align: center">
                  <a
                    title="Modifier"
                    (click)="openFormModalModal(templateCreate, entr)"
                  >
                    <i class="fa fa-edit" style="color: green"> </i>
                  </a>
                  &nbsp;&nbsp;
                  <a (click)="confirmDeleteItem(entr)">
                    <i class="fa fa-trash-o" style="color: red"> </i>
                  </a>
                </td>
              </tr>

              <tr *ngIf="results && results.length > 0">
                <td colspan="3">
                  <div
                    class="col-xs-12 col-12"
                    style="text-align: left; padding: 0px; margin-top: 15px"
                  >
                    <pagination
                      [boundaryLinks]="true"
                      [totalItems]="totalItems"
                      [maxSize]="itemPerPagePagination"
                      [itemsPerPage]="itemsPerPage"
                      [(ngModel)]="currentPage"
                      class="pagination-sm"
                      previousText="&laquo;"
                      nextText=" &raquo;"
                      firstText="&laquo;"
                      lastText="&raquo;"
                      name="pagination"
                      (pageChanged)="pageChanged($event)"
                    ></pagination>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot *ngIf="!results || results.length == 0">
              <tr>
                <td colspan="4" style="text-align: center; font-weight: bold">
                  Aucune donnée disponible
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div
          class="col-md-6"
          style="padding-right: 0px"
          *ngIf="
            user?.userTypeCode == 'TENANCY_ADMIN' ||
            user?.userTypeCode == 'TENANCY_USER'
          "
        >
          <!-- <h1 class="custom-title-bold">  PRIX D'ACHAT</h1> -->
          <button
            class="btn btn-md custom_border_radius btn-bg-new padding-30"
            type="button"
            (click)="openFormModalModal(templateCreateCoutCulture)"
          >
            Ajouter
          </button>
          <br />
          <br />
          <table class="table table-condensed table-striped">
            <thead>
              <tr class="custom-table-thead">
                <th style="width: 45px"></th>
                <th>Cultures</th>
                <th>Section</th>
                <th>Prix/Kg</th>
                <th>Date d'application</th>

                <th style="text-align: center; width: 75px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let entr of ListeCoutsCultures; let i = index">
                <td class="vertical-align-uppercase">
                  {{ i + 1 }}
                </td>
                <td class="vertical-align-uppercase">
                  {{ entr.cultureLibelle }}
                </td>
                <td class="vertical-align-uppercase">
                  {{ entr.cultureSectionLibelle }}
                </td>
                <td class="vertical-align-uppercase">
                  {{ entr.nombrePoint | formatmillier }}
                </td>
                <td class="vertical-align-uppercase">
                  {{ entr.dateApplication }}
                </td>
                <td style="text-align: center">
                  <!-- <a title="Modifier" (click)="openFormModalModal(templateCreateCoutCulture,entr)">
                                        <i class="fa fa-edit" style="color: green"> </i>
                                    </a> -->
                  &nbsp;&nbsp;
                  <a title="Supprimer" (click)="confirmDeleteItem(entr, true)">
                    <i class="fa fa-trash-o" style="color: red"> </i>
                  </a>
                </td>
              </tr>

              <tr *ngIf="ListeCoutsCultures && ListeCoutsCultures.length > 0">
                <td colspan="6">
                  <div
                    class="col-xs-12 col-12"
                    style="text-align: left; padding: 0px; margin-top: 15px"
                  >
                    <pagination
                      [boundaryLinks]="true"
                      [totalItems]="totalItemsCoutCulture"
                      [maxSize]="itemPerPagePagination"
                      [itemsPerPage]="itemsPerPageCoutCulture"
                      [(ngModel)]="currentPageCoutCulture"
                      class="pagination-sm"
                      previousText="&laquo;"
                      nextText=" &raquo;"
                      firstText="&laquo;"
                      lastText="&raquo;"
                      name="pagination"
                      (pageChanged)="pageChangedCoutCulture($event)"
                    ></pagination>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot
              *ngIf="!ListeCoutsCultures || ListeCoutsCultures.length == 0"
            >
              <tr>
                <td colspan="6" style="text-align: center; font-weight: bold">
                  Aucune donnée disponible
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- fin file d actualite -->

  <ng-template #templateCreate>
    <form
      name="frmSave"
      [ngBusy]="{
        busy: busySave,
        message: 'Veuillez patienter ...',
        backdrop: false
      }"
    >
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">CULTURE</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hideModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="form-group col-lg-12">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group col-md-12">
                  <label
                    >Libellé :
                    <span class="required_champs">*</span>
                  </label>
                  <input
                    class="form-control custom_input"
                    autocomplete="off"
                    [(ngModel)]="itemToSave.libelle"
                    name="code"
                    required
                    placeholder="Libellé"
                    type="text"
                    onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left"
          >( <span class="required_champs">*</span>) Champs requis</b
        >

        <button
          type="button"
          class="btn btn-md custom_border_radius btn-default padding-30 btn-bg-cancel"
          (click)="hideModal()"
        >
          Annuler
        </button>

        <button
          type="submit"
          class="btn btn-md custom_border_radius btn-bg-new padding-30"
          (click)="confirmSaveItem(itemToSave)"
        >
          Valider
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #templateCreateCoutCulture>
    <form
      name="frmSave"
      [ngBusy]="{
        busy: busySave,
        message: 'Veuillez patienter ...',
        backdrop: false
      }"
    >
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">PRIX D'ACHAT</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hideModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="form-group col-lg-12">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group col-md-12">
                  <label
                    >Culture :
                    <span class="required_champs">*</span>
                  </label>

                  <select
                    class="form-control custom_input"
                    (change)="
                      currentSection =
                        itemToSave.cultureSelected.cultureSectionLibelle;
                      currentSectionId =
                        itemToSave.cultureSelected.cultureSectionId
                    "
                    id="cultureSelected"
                    [(ngModel)]="itemToSave.cultureSelected"
                    #ctrl="ngModel"
                    name="cultureSelected"
                  >
                    <option disabled>-- Choisissez --</option>
                    <option
                      *ngFor="let vil of ListeCulturesTenant"
                      [ngValue]="vil"
                    >
                      {{ vil.libelle }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  <label>
                    Section :
                    <span class="required_champs">*</span>
                  </label>

                  <input
                    disabled
                    class="form-control custom_input"
                    autocomplete="off"
                    [value]="currentSection"
                    placeholder="Section"
                    required
                    type="text"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label
                    >Prix / Kg :
                    <span class="required_champs">*</span>
                  </label>
                  <input
                    class="form-control custom_input"
                    autocomplete="off"
                    [(ngModel)]="itemToSave.prix"
                    name="prix"
                    required
                    placeholder="Prix"
                    type="text"
                    mask="separator"
                    maxlength="10"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label
                    >Date application :
                    <span class="required_champs">*</span>
                  </label>
                  <input
                    type="text"
                    id="dateDebut"
                    class="form-control custom_input"
                    placeholder="Date d'application"
                    bsDatepicker
                    name="dateDuJour"
                    autocomplete="off"
                    [minDate]="minDate"
                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                    [(ngModel)]="itemToSave.dateApplication"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left"
          >( <span class="required_champs">*</span>) Champs requis</b
        >

        <button
          type="button"
          class="btn btn-md custom_border_radius btn-default padding-30 btn-bg-cancel"
          (click)="hideModal()"
        >
          Annuler
        </button>

        <button
          type="submit"
          class="btn btn-md custom_border_radius btn-bg-new padding-30"
          (click)="confirmSaveItemCoutCulture(itemToSave)"
        >
          Valider
        </button>
      </div>
    </form>
  </ng-template>
</div>
