import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing'
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState } from './app.service';

// Core providers
import { CoreModule } from './core/core.module';
import { SmartadminLayoutModule } from './shared/layout/layout.module';
import { SmartadminModule } from './shared/smartadmin.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoginComponent } from './+auth/+login/login.component';
import { LoggedInGuardService } from './shared/utils/guards/logged-in-guard.service';
import { CommunicationService } from './core/services/communication.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalInterceptor } from './core/intercept/global-interceptor';
import { RestService } from './core/services/rest.service';
import { NotificationService } from './core/services/notification.service';
import { MatStepperModule } from "@angular/material/stepper";
import { MatListModule } from "@angular/material/list";
// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppState,
  LoggedInGuardService
];

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SmartadminModule,
    CoreModule,
    SmartadminLayoutModule,
    MatStepperModule,
    MatListModule,
    routing
  ],
  exports: [
    LoginComponent
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    // ENV_PROVIDERS,
    {provide: HTTP_INTERCEPTORS, useClass: GlobalInterceptor, multi: true},
    APP_PROVIDERS,
    CommunicationService,
    RestService,
    NotificationService,
  ],
 // bootstrap: [FormReinitPasswordComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public appState: AppState) {}
}

