<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
    
  <div class="custom-cadre">
      <!-- Gestion stock -->
      <div class="row">
          <div class="form-group col-md-12">
              <div class="col-md-12">
                  <h1 class="custom-title-bold"> GESTION DES STOCKS </h1>
                  <br/>

                  <button class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button" (click)="openFormModalModal(templateCreate)">
                  Ajouter lot 
                </button>
                <button *ngIf="currentRow && currentRow.id" class="btn btn-md btn-bg-default custom_border_radius btn-import btn-bg-default padding-30" type="button"
                (click)="openFormModalModalLivraison(templateCreateLivraison)">
                        Ajouter livraison
                    </button>
                  <br/>
                  <br/>
                  <div class="col-md-12 no-padding" style="margin-top: 10px">
                      <table class="table table-condensed table-striped">
                          <thead>
                              <tr>
                                  <th colspan="8" style="background-color: #fff;padding: 0px !important">
                                    <div class="form-group col-md-3 no-padding" style="margin-right: 4px;">
                                            <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSearch.libelle" name="searc" required
                                                placeholder="Rechercher par libellé lot"  type="text">
                                        </div>
                                        <div class="form-group col-md-3 no-padding">
                                          <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSearch.quantite" name="searc" required
                                              placeholder="Rechercher par quantité lot"  type="text" mask="separator">
                                      </div>

                                      <div class="col-md-2">
                                        <input type="text"
                                            placeholder="Date début"
                                            class="form-control custom_input"
                                            bsDatepicker
                                            [(bsValue)]="itemToSearch.start"
                                            >
                                    </div>
                                    <div class="col-md-2">
                                        <input type="text"
                                            placeholder="Date fin"
                                            class="form-control custom_input"
                                            bsDatepicker
                                            [(bsValue)]="itemToSearch.end"
                                            >
                                    </div>
                                      <div>
                                        <button (click)="getData()" class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button">
                                          Actualiser
                                        </button>
                                      </div>
                                  </th>
                              </tr>
                              <tr class="custom-table-thead">
                                  <th style="width: 45px"></th>
                                  <th>Libellé </th>
                                  <th>Quantité(kg)</th>
                                   <th>Date création</th>
                                    <th style="text-align: center;">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr style="cursor: pointer;"  [class.isSelect]='entr.id == currentRow.id' *ngFor="let entr of results; let i = index" (click)="onSelectRow(entr)">
                                  <td class="vertical-align-uppercase" >
                                      {{i + 1}}
                                  </td>
                                  <td class="vertical-align-uppercase">{{entr.libelle}}</td>
                                  <td class="vertical-align-uppercase">{{((entr.quantite*1000) | moneyFormat) || 0}}</td>
                                   <td class="vertical-align-uppercase">{{entr.createdAt}}</td>
                                
                                  
                                  <td style="text-align: center">
                                    <a title="Modifier" (click)="
                                    openFormModalModal(templateCreate, entr);
                                    $event.stopPropagation()
                                  ">
                                                        <i class="fa fa-edit" style="color: green"> </i>
                                    </a>

                                    &nbsp;
                                        <a title="Détails" (click)="
                                        openFormModalModal(templateCreate, entr,'true');
                                        $event.stopPropagation()
                                      ">
                                            <i class="fa fa-eye" style="color: #00a984"> </i>
                                        </a>
                                        &nbsp;
                                        <a title="Supprimer" (click)="confirmDeleteItem(entr)">
                                            <i class="fa fa-trash-o" style="color: red"> </i>
                                        </a>

                                  </td>
                              </tr>

                              <tr *ngIf="results && results.length > 0">
                                  <td colspan="8">
                                      <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
                                          <pagination [boundaryLinks]="true" [totalItems]="totalItems" [maxSize]='itemPerPagePagination' [itemsPerPage]="itemsPerPage"
                                              [(ngModel)]="currentPage" class="pagination-sm" previousText="&laquo;" nextText=" &raquo;"
                                              firstText="&laquo;" lastText="&raquo;" name="pagination" (pageChanged)="pageChanged($event)"></pagination>
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                          <tfoot *ngIf="!results || results.length == 0">
                              <tr>
                                  <td colspan="8" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
                              </tr>

                          </tfoot>

                      </table>
                  </div>

                  <div class="col-md-2">
                     <div class="col-md-12">

                     </div>
                  </div>
              </div>


          </div>
      </div>

  </div>



  <ng-template #templateCreate>
      <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
          <div class="modal-header custom-modal-header">
              <h4 class="modal-title pull-left">
                  LOT
              </h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>

          <div class="modal-body">
              <div class="container-fluid">
<!--                Begin Référence de mise à jour-->
              <div class="row">

                 <div class="form-group col-md-5" *ngIf="!disableMode">
                  <label>Libellé :
                      <span class="required_champs">*</span>
                  </label>
                  <input  class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.libelle" name="libelle" placeholder="Libellé"
                         required type="text">
                </div>
                <div class="form-group col-md-5" *ngIf="!disableMode">
                  <label>Quantité(kg) :
                      <span class="required_champs">*</span>
                  </label>
                  <input  mask="separator" class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.quantite" name="quantite" placeholder="Quantité"
                         required type="text">
                </div>


                <div *ngIf="disableMode" class="form-group col-md-5 no-padding">
                    <ng-select [(ngModel)]="itemToSearch.clientId" style="margin-right: 5px;"
                        placeholder="Recherche par agriculteur" id="client"
                        [ngModelOptions]="{standalone: true}">
                        <ng-option *ngFor="let clt of ListeClients" [value]="clt.clientId">
                            {{(clt?.clientNom+' '+clt.clientPrenoms)}}</ng-option>
                    </ng-select>
                </div>
                <div *ngIf="disableMode" class="col-md-2">
                    <button (click)="getDataById()" class="btn btn-sm btn-bg-new custom_border_radius">Actualiser</button>
                </div>

                <div *ngIf="disableMode" class="col-md-2" >
                    <button class="btn btn-md pull-right custom_border_radius btn-import btn-bg-default padding-30" type="button"
                        (click)="exportData()" >
                        Exporter
                    </button>
                </div>
                {{(quantiteSelection*1000 ||0)| moneyFormat}}/{{(quantiteTotale*1000||0)| moneyFormat}}


                <div class="col-md-12"  style="max-height: 400px; overflow:auto;" *ngIf="disableMode">
                  <table class="table table-condensed table-striped">
                    <thead>
                  
                        <tr class="custom-table-thead">
                            <th style="width: 45px"></th>
                            <th>Nom </th>
                            <th>Prenom</th>
                            <th>Code plantation</th>
                            <th>Culture</th>
                            <th>Date de livraison</th>
                           <th>Quantité(kg)</th>
                            <th>Lot</th>
                        </tr>
                    </thead>
                    <tbody>
                          <tr style="cursor: pointer;"  *ngFor="let entr of listLotsLignes; let i = index">
                            <td class="vertical-align-uppercase" >
                                {{i + 1}}
                            </td>
                            <td class="vertical-align-uppercase">{{entr.nom}}</td>
                            <td class="vertical-align-uppercase">{{entr.prenom}}</td>
                              <td class="vertical-align-uppercase">{{entr.codePlantation}}</td>
                              <td class="vertical-align-uppercase">{{entr.cultureLibelle}}</td>
                              <td class="vertical-align-uppercase">{{entr.dateLivraison}}</td>
                           <td class="vertical-align-uppercase">{{((entr.quantite*1000 | moneyFormat ) || 0) }}</td>
                              <td class="vertical-align-uppercase">{{entr.libelleLot}}</td>


                          </tr>

                    </tbody>
                    <tfoot *ngIf="!listLotsLignes || listLotsLignes.length == 0">
                        <tr>
                            <td colspan="8" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
                        </tr>

                    </tfoot>

                </table>
                </div>
              </div>
              </div>
          </div>

          <div class="modal-footer modal-footer-custom">
              <b class="pull-left">(
                  <span class="required_champs">*</span>) Champs requis</b>

              <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal()">
                  Annuler
              </button>

              <button [disabled]="disableMode" type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItem(itemToSave) ">
                  Valider
              </button>

          </div>
      </form>
  </ng-template>

  <ng-template #templateCreateLivraison>
    <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
        <div class="modal-header custom-modal-header">
            <h4 class="modal-title pull-left">
                LIVRAISON
            </h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="container-fluid">
<!--                Begin Référence de mise à jour-->
            <div class="row">
              <!-- <div class="form-group col-md-4">
                <label>Lot : {{currentRow.libelle}}
                </label>
             
              </div>
              <div class="form-group col-md-4">
                <label>Quantité : {{currentRow.quantite}}
                </label>
             
              </div> -->
              <div class="form-group col-md-5 no-padding">
                <ng-select [(ngModel)]="itemToSearch.clientId" style="margin-right: 5px;"
                    placeholder="Recherche par agriculteur" id="client"
                    [ngModelOptions]="{standalone: true}">
                    <ng-option *ngFor="let clt of ListeClients" [value]="clt.clientId">
                        {{(clt?.clientNom+' '+clt.clientPrenoms)}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-2">
                <button (click)="getDataMouvement()" class="btn btn-sm btn-bg-new custom_border_radius">Actualiser</button>
            </div>
              <div class="form-group col-md-4">
                <label>Quantité selectionnée : {{((countSelectedQuantite()*1000) | moneyFormat) || 0 }}
                </label>
             
              </div>
       
              <div class="form-group col-md-12" style="max-height: 400px; overflow:auto;">
                <table class="table table-condensed table-striped">
                  <thead>
                
                      <tr class="custom-table-thead">
                          <th style="width: 45px"></th>
                          <th>Nom </th>
                          <th>Prenom</th>
                          <th>Code plantation</th>
                          <th>Culture</th>
                          <th>Date de livraison</th>
                         <th>Quantité(kg)</th>


                      </tr>
                  </thead>
                  <tbody>
                        <tr style="cursor: pointer;"  *ngFor="let entr of ListeRechargments; let i = index">
                          <td class="vertical-align-uppercase" >
                              <input type="checkbox" [(ngModel)]="entr.isSelectedClientId" name="isSelectedClientId" (change)="countSelectedQuantite()">
                          </td>
                          <td class="vertical-align-uppercase">{{entr.clientNom}}</td>
                          <td class="vertical-align-uppercase">{{entr.clientPrenoms}}</td>
                            <td class="vertical-align-uppercase">{{entr.codePlantation}}</td>
                            <td class="vertical-align-uppercase">{{entr.cultureLibelle}}</td>
                            <td class="vertical-align-uppercase">{{entr.createdAt}}</td>
                         <td class="vertical-align-uppercase">
                             {{((entr.quantite*1000) | moneyFormat) || 0}}
                         </td>


                        </tr>

                  </tbody>
                  <!-- <tfoot *ngIf="!results || results.length == 0">
                      <tr>
                          <td colspan="8" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
                      </tr>

                  </tfoot> -->

              </table>
              </div>

              <!-- <div class="col-md-12" *ngIf="disableMode && itemToSave.datasLivraisons && itemToSave.datasLivraisons.length">
                <table class="table table-condensed table-striped">
                  <thead>
                
                      <tr class="custom-table-thead">
                          <th style="width: 45px"></th>
                          <th>Nom </th>
                          <th>Prenom</th>
                         <th>Quantité</th>
                      </tr>
                  </thead>
                  <tbody>
                        <tr style="cursor: pointer;"  *ngFor="let entr of itemToSave.datasLivraisons; let i = index">
                          <td class="vertical-align-uppercase" >
                              {{i + 1}}
                          </td>
                          <td class="vertical-align-uppercase">{{entr.nom}}</td>
                          <td class="vertical-align-uppercase">{{entr.prenom}}</td>
                         <td class="vertical-align-uppercase">{{entr.quantite}}</td>
                      
                      </tr>

                  </tbody>
                  <tfoot *ngIf="!results || results.length == 0">
                      <tr>
                          <td colspan="8" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
                      </tr>

                  </tfoot>

              </table>
              </div> -->
            </div>
            </div>
        </div>

        <div class="modal-footer modal-footer-custom">
            <b class="pull-left">(
                <span class="required_champs">*</span>) Champs requis</b>

            <!-- <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal()">
                Annuler
            </button>

            <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItemLivraison(itemToSave) ">
                Valider
            </button> -->

        </div>
    </form>
</ng-template>



</div>
