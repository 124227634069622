<!-- RIBBON -->
<div id="ribbon">
  <ng-template #tipContent></ng-template>

    <span class="ribbon-button-alignment">
      <span id="refresh" class="btn btn-ribbon" 
            >
        <i class="fa fa-home"></i>
      </span>
    </span>
  <sa-route-breadcrumbs></sa-route-breadcrumbs>
</div>
