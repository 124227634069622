import { Component, OnInit } from "@angular/core";
import { Subscription } from 'rxjs';
import { Api } from "../../shared/utils/api/api";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NotificationService } from "../../shared/utils/notification.service";
import { UtilitiesService } from "../../shared/utils/utilities.service";

import { ModelService } from "../../shared/utils/model.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { FormReinitPasswordComponent } from "../../+components/form-reinit-password/form-reinit-password.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.componentnew.html",
  styleUrls: ["./login.componentnew.css"],
})
export class LoginComponent implements OnInit {
  eyePassword = false;
  itemToSave: any;
  bsModalRef: BsModalRef;
  busyConnecte: Subscription;

  config = {
    animated: true,
    keyboard: false,
    backdrop: "static",
    ignoreBackdropClick: true,
  };

  constructor(
    private api: Api,
    public router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private utilities: UtilitiesService,
    private modelService: ModelService,
    private _localeService: BsLocaleService
  ) {
    this.itemToSave = {};
  }

  ngOnInit() {}

  updatePassWord() {
    this.itemToSave.isPasswordForget = !this.itemToSave.isPasswordForget;
  }

  motDePassOublie(item) {
    console.log("le item", item);
    item.showLoader = true;
    if (!item || !item.libellePassForget) {
      item.showLoader = false;
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner login."
      );
      return;
    }

    var request = {
      //user : "1",
      data: {
        login: item.libellePassForget,
      },
    };

    this.busyConnecte = this.api.post("user/resetAccount", request).subscribe(
      (res) => {
        console.log("get user", res);
        item.showLoader = false;
        if (!res["hasError"]) {
          this.itemToSave.message = this.utilities.mainSelfFormatMsgServeur(
            res["status"]["message"]
          );
          this.notificationService.showMessageSucces(
            "Mot de passe oublié",
            this.itemToSave.message
          );
          this.updatePassWord();
        } else {
          this.itemToSave.message = this.utilities.mainSelfFormatMsgServeur(
            res["status"]["message"]
          );
          this.notificationService.showMessageError(
            "Connexion",
            res["status"].message
          );
        }
      },
      (err) => {
        console.log("Error occured G", err);
        item.showLoader = false;
      }
    );
  }

  openChangePassword(user) {
    const initialState = {
      title: "Changer votre mot de passe.",
      config: this.config,
    };
    this.bsModalRef = this.modalService.show(
      FormReinitPasswordComponent,
      {
        class: "gray modal-sm modal-custom-reinit",
      }
    );
    this.bsModalRef.content.title = "Changer votre mot de passe";
    this.bsModalRef.content.currentUser = user;
  }

  login(item) {
    // console.log("le item",item);
    item.showLoader = true;
    if (!item || !item.login || !item.password) {
      item.showLoader = false;
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les champs obligatoires."
      );
      return;
    }
    var request = {
      user: "1",
      data: {
        login: item.login,
        password: item.password,
      },
    };
    this.busyConnecte = this.api.post("user/login", request).subscribe(
      (res) => {
        console.log("get user", res);
        item.showLoader = false;
        if (!res["hasError"] && res["items"]) {
          var currentUser = res["items"][0];
          console.log("just logged in user info: ", currentUser);
          currentUser.Password = null;
          //Verifier si c'est un tenant
          if (currentUser.itemsTenancy && currentUser.itemsTenancy.length > 0) {
            let tenantInfo = currentUser.itemsTenancy[0];
            currentUser.datasTenancy = [
              {
                tenancyRaisonSociale: tenantInfo.raisonSociale,
                tenancyCode: tenantInfo.code,
                tenancyId: tenantInfo.id,
                id: tenantInfo.id,
              },
            ];
          }
          if (
            currentUser.userTypeCode == "MARCHAND_ADMIN" ||
            currentUser.userTypeCode == "MARCHAND_USER"
          ) {
            // this.notificationService.showMessageError(
            //   "Connexion",
            //   "Désolé vous n'avez pas accès à la plateforme web."
            // );
            // return;
          }

          if (currentUser.isDefaultPassword) {
            this.itemToSave = {};
            currentUser.isFromLogin = true;
            sessionStorage.setItem(
              "yz-backoffice-app-smart-carte",
              JSON.stringify(currentUser)
            );
            this.openChangePassword(currentUser);
            return;
          }
          sessionStorage.setItem(
            "yz-backoffice-app-smart-carte",
            JSON.stringify(currentUser)
          );
          if (currentUser.userTypeCode != "GESTIONNAIRE_ADMIN")
            this.router.navigate(["/tableau-de-bord"]);
          else this.router.navigate(["/valider-prefinancement"]);
          //resetPassword
        } else {
          this.notificationService.showMessageError(
            "Connexion",
            "Login ou mot de passe erroné."
          );
        }
      },
      (err) => {
        console.log("Error occured G", err);
        item.showLoader = false;
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
        // this.not
      }
    );
  }
  //gérer l'affichage du password
  afficherPassword() {
    if (this.eyePassword) {
      document.getElementById("password").setAttribute("type", "password");
      document.getElementById("eye").setAttribute("class", "fa fa-eye-slash");
      // document.getElementById("eye").style.color='gray';
      this.eyePassword = false;
    } else {
      document.getElementById("password").setAttribute("type", "text");
      document.getElementById("eye").setAttribute("class", "fa fa-eye");
      // document.getElementById("eye").style.color='gray';
      this.eyePassword = true;
    }
  }
}
