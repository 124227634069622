/**
 * Created by griga on 7/11/16.
 */

import { Routes, RouterModule } from "@angular/router";
import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { LoginComponent } from "./+auth/+login/login.component";
import { ModuleWithProviders } from "@angular/core";
import { LoggedInGuardService } from "./shared/utils/guards/logged-in-guard.service";
import { MainUtilisateurComponent } from "./+components/component-app/main-utilisateur/main-utilisateur.component";
import { MainMarchandsComponent } from "./+components/component-app/main-marchands/main-marchands.component";
import { MainCooperativeComponent } from "./+components/component-app/main-cooperative/main-cooperative.component";
import { MainClientsComponent } from "./+components/component-app/main-clients/main-clients.component";
import { FicheProductionClientComponent } from "./+components/component-app/fiche-production-client/fiche-production-client.component";
import { MainValiderPrefinancementComponent } from "./+components/component-app/main-valider-prefinancement/main-valider-prefinancement.component";
import { ManagePrefinancementComponent } from "./+components/manage-prefinancement/manage-prefinancement.component";
import { GestionStockComponent } from "./+components/component-app/gestion-stock/gestion-stock.component";
import { MainMappingComponent } from "./+components/component-app/main-mapping/main-mapping.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  {
    path: "",
    component: MainLayoutComponent,
    data: { pageTitle: "Accueil" },
    canActivate: [LoggedInGuardService],
    children: [
      { path: "", redirectTo: "tableau-de-bord", pathMatch: "full" },
      {
        path: "home",
        loadChildren: () => import("src/app/+home/home.module").then(m => m.HomeModule),
        data: { pageTitle: "" },
      },
      {
        path: "tableau-de-bord",
        loadChildren: () =>
          import("src/app/+dashboard/dashboard.module").then(
            m => m.DashboardModule
          ),
        data: { pageTitle: "" },
      },
      {
        path: "parametrages",
        loadChildren: () => import("src/app/+parametrage/parametrage.module").then(m => m.ParametrageModule),
        data: { pageTitle: "Parametrages" },
      },
      {
        path: "dons",
        loadChildren: () => import("src/app/+dons/dons.module").then(m => m.donsModule),
        data: { pageTitle: "Dons" },
      },
      {
        path: "promos",
        loadChildren: () => import("src/app/+promos/promos.module").then(m => m.PromosModule) ,
        data: { pageTitle: "Promos" },
      },
      {
        path: "livrer-encaisser",
        loadChildren: () => import("src/app/+livrer-encaisser/livrer-encaisser.module").then(m => m.LivrerEncaisserModule)
          ,
        data: { pageTitle: "Livrer-encaisser" },
      },
      {
        path: "mouvements",
        loadChildren: () => import("src/app/+mouvements/mouvements.module").then(m => m.MouvementsModule) ,
        data: { pageTitle: "Mouvements" },
      },
      {
        path: "administrations",
        loadChildren: () => import("src/app/+administration/administration.module").then(m => m.AdministrationModule)
          ,
        data: { pageTitle: "Administrations" },
      },
      {
        path: "utilisateur",
        component: MainUtilisateurComponent,
        data: { pageTitle: "Utilisateurs" },
      },
      {
        path: "marchands",
        component: MainMarchandsComponent,
        data: { pageTitle: "Marchands" },
      },
      {
        path: "cooperative",
        component: MainCooperativeComponent,
        data: { pageTitle: "Coopératives" },
      },
      {
        path: "clients",
        component: MainClientsComponent,
        data: { pageTitle: "Agriculteurs" },
      },
      {
        path: "avance",
        component: MainClientsComponent,
        data: { pageTitle: "Avance & Prêt" },
      },
      {
        path: "valider-prefinancement",
        component: MainValiderPrefinancementComponent,
        data: { pageTitle: "Valider préfinancement" },
      },
      {
        path: "manage-prefinancement",
        component: ManagePrefinancementComponent,
        data: { pageTitle: "Valider préfinancement" },
      },
      {
        path: "gestion-stock",
        component: GestionStockComponent,
        data: { pageTitle: "Gestion stock" },
      },
      {
        path: "fiche-production/:idClient",
        component: FicheProductionClientComponent,
        data: { pageTitle: "Profil" },
      },
      {
        path: "prime",
        loadChildren: () => import("src/app/+primes/primes.module").then(m => m.PrimesModule) ,
        data: { pageTitle: "Prime" },
      },
      {
        path: "supervision",
        loadChildren: () => import("src/app/+supervisions/supervisions.module").then(m => m.SupervisionsModule)
          ,
        data: { pageTitle: "Supervision" },
      },
      {
        path: "mapping-test",
        component: MainMappingComponent,
        data: { pageTitle: "Mapping" },
      },
      {
        path: "mapping",
        loadChildren: () => import("src/app/+mapping/mapping.module").then(m => m.MappingModule) ,
        data: { pageTitle: "Mapping" },
      },
    ],
  },

  {
    path: "auth",
    component: AuthLayoutComponent, // loadChildren: 'app/+auth/auth.module#AuthModule',
    children: [
      {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
      },
      {
        path: "login",
        component: LoginComponent,
        data: { pageTitle: "Connexion" },
      },
    ],
  },

  { path: "**", redirectTo: "miscellaneous/error404" },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  useHash: true,
});
