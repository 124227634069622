<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGetMouvements, message: 'Veuillez patienter ...', backdrop: false}">

    <div class="custom-cadre">
        <!-- fil d actualite -->
        <div class="row">
            <div class="form-group col-md-12">
                <div class="col-md-6 no-padding">
                    <h1 class="custom-title-bold"> MOUVEMENTS </h1>
                </div>
                    <div class="col-md-12 no-padding" style="margin-top: 10px">
                        <table class="table table-condensed table-striped">
                            <thead>
                                <tr>
                                    <th colspan="15" style="background-color: #fff;padding: 0px !important" >
                                        <div class="form-group col-md-2 no-padding">
                                            <ng-select [(ngModel)]="itemToSearch.clientId" style="margin-right: 5px;"
                                                placeholder="Recherche par agriculteur" id="client"
                                                [ngModelOptions]="{standalone: true}">
                                                <ng-option *ngFor="let clt of ListeClients" [value]="clt.clientId">
                                                    {{(clt?.clientNom+' '+clt.clientPrenoms)}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="form-group col-md-2 no-padding">
                                            <ng-select [(ngModel)]="itemToSearch.userId"
                                                placeholder="Recherche par agent" id="agent"
                                                [ngModelOptions]="{standalone: true}">
                                                <ng-option *ngFor="let clt of ListeUtilisateurs" [value]="clt.userId">
                                                    {{(clt?.userFirstName+' '+clt.userLastName)}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="form-group col-md-2 no-padding">
                                            <select  [class.opacite]="!itemToSearch.sens" style="margin-left: 6px;" (change)="onSelectOperation()" class="form-control custom_input" [(ngModel)]="itemToSearch.sens"
                                                id="sens" #ctrl="ngModel" name="sens">
                                                <option value="" selected >Rechercher par opération</option>
                                                <option *ngFor="let operation of ListOperations " [ngValue]="operation">
                                                {{ operation }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text"
                                                placeholder="Date début"
                                                class="form-control custom_input"
                                                bsDatepicker
                                                [(bsValue)]="itemToSearch.start"
                                                >
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text"
                                                placeholder="Date fin"
                                                class="form-control custom_input"
                                                bsDatepicker
                                                [(bsValue)]="itemToSearch.end"
                                                >
                                        </div>
                                        <div class="col-md-2">
                                            <button (click)="selectedClient(itemToSearch)" class="btn btn-sm btn-bg-new custom_border_radius">Actualiser</button>
                                        </div>
                                        <div class="form-group col-md-2 no-padding pull-right">
                                            <button class="btn btn-md pull-right custom_border_radius btn-import btn-bg-default padding-30" type="button"
                                                (click)="exportData()" style="margin-top: 4px;">
                                                Exporter </button>
                                                <!-- fdf -->
                                        </div>
                                    </th>
                                </tr>
                                <tr class="custom-table-thead">
                                    <th style="width: 5px;"> 
                                        <a (click)="getMouvement()"> <i class="fa fa-refresh"></i> </a>
                                    </th>
                                    <th style="width: 75px;">Opération</th>
                                    <th style="width: 100px;">Agent</th> 
                                    <th  style="width: 70px;">Moyen de paiement</th>
                                    <th style="width: 88px;">Identifiant</th>
                                    <th style="width: 70px;">Date</th>
                                    <th style="width: 68px;">Culture</th>
                                    <th style="width: 90px;">Quantité(Tonne)</th>
                                    <th style="width: 48px;">Prix</th>
                                    <th style="width: 50px;">Nom</th>
                                    <th style="width: 70px;">Prénoms</th>
                                    <th class="text-right" style="width: 120px;">Montant</th>
                                    <th class="text-right" style="width: 120px;">Ancien solde</th>
                                    <th class="text-right" style="width: 120px;">Solde</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of ListeRechargments; let i = index"
                                    style="font-size: 10px !important">
                                    <td>{{(i+1) + ((currentPage - 1) * itemsPerPage)}}</td>
                                    <td>
                                        <label
                                            [class.label-warning]="item.sens ==='AVANCE'"
                                            [class.label-primary]="item.sens ==='LIVRAISON'"
                                            [class.label-inverse]="item.sens ==='REGLEMENT'"
                                            [class.label-success]="item.sens ==='PRE-FINANCEMENT'"
                                            [class.label-danger]="item.sens ==='DON ESPECE'"
                                            [class.label-info]="item.sens ==='PRIME'"
                                            [class.label-info-bis]="item.sens ==='PROMO'"
                                            class="label">{{item.sens}}</label>
                                    </td>
                                    
                                    <td class="vertical-align-uppercase">{{item.userNameActor + ' ' + item?.userLastNameActor}}</td>
                                    <!-- <td class="vertical-align-uppercase">{{item?.tenantLibelle}}</td> -->
                                    <td class="vertical-align-uppercase">
                                        {{item?.operationLibelle}}
                                    </td>
                                    <td class="vertical-align-uppercase">{{item.dataClient ? item.dataClient.code : ''}}</td>
                                    <td class="vertical-align-uppercase">{{item.createdAt}}</td>
                                    <td class="vertical-align-uppercase" >{{item.cultureLibelle}}
                                    </td>
                                    <td class="vertical-align-uppercase text-right" >
                                        {{item.quantite | formatmillier}}</td>
                                    <td class="vertical-align-uppercase text-right" >
                                        {{item.coutTonnage | formatmillier}}</td>
                                    <td class="vertical-align-uppercase" >{{item.dataClient ? item.dataClient.nom : ''}}</td>
                                    <td class="vertical-align-uppercase" >{{item.dataClient ? item.dataClient.prenoms: ''}}</td>
                                    <!-- <td class="vertical-align-uppercase" >{{item.dataClient.telephone}}</td> -->

                                    <td class="vertical-align-uppercase text-right">{{ (item.montant | formatmillier) || 0 }}</td>
                                    <td class="vertical-align-uppercase text-right">{{ (item.ancienSolde | formatmillier) || 0}}
                                    </td>
                                    <td class="vertical-align-uppercase text-right">{{ (item.nouveauSolde | formatmillier) || 0}}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="!ListeRechargments || ListeRechargments.length == 0">
                                <tr>
                                    <td colspan="15" style="text-align:center; font-weight:bold">Aucune donnée
                                        disponible</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
                            <pagination [boundaryLinks]="true" [totalItems]="itemsSize"
                                [maxSize]='itemPerPagePagination' [itemsPerPage]="itemsPerPage"
                                [(ngModel)]="currentPage" class="pagination-sm pull-right" previousText="&laquo;"
                                nextText=" &raquo;" firstText="&laquo;" lastText="&raquo;" name="pagination"
                                (pageChanged)="pageChanged($event)"></pagination>
                        </div>
                    </div>
             
            </div>
        </div>

    </div>



</div>