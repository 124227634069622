<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">

    <div class="custom-cadre">
        <!-- fil d actualite -->
        <div class="row">
            <div class="form-group col-md-12">
                <div class="col-md-12">

                    <button (click)="openFormModalModal(templateCreate)" class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button">
                        Ajouter </button>
                        <input type="text" class="form-control custom_input" style="display: inline-block; width: 50%;"
                                    placeholder="Entrer clé de recherche"
                                    name="fullSearch" [(ngModel)]="searchKey" (ngModelChange)="this.onFullSearch.next($event)">
                    <br/>

                    <div class="col-md-12 no-padding" style="margin-top: 10px">
                        <table class="table table-condensed table-striped">
                            <thead>
                                    <!-- <tr>
                                        <th colspan="8" style="background-color: #fff;padding: 0px !important">
                                                <div class="form-group col-md-4 no-padding">
                                                        <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSearch.searchValue" name="search" required
                                                            placeholder="Rechercher par raison sociale" (keyup)="getData()" type="text">
                                                    </div>
                                        </th>
                                    </tr> -->

                                <tr class="custom-table-thead">
                                    <th style="width: 45px"></th>
                                    <th>Matricule </th>
                                    <th>Raison sociale</th>
                                    <th>Contact</th>
                                    <th>Seuil de transaction</th>
                                    <th>Domaine</th>
                                    <th>Responsable</th>
                                    <th style="text-align: center; width: 75px"> Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let entr of ListeMarchands; let i = index" (click)="selectedMarchand(entr)" [class.isSelect]='entr.id == itemToSave.id'>
                                    <td class="vertical-align-uppercase">
                                        {{i + 1}}
                                    </td>
                                    <td class="vertical-align-uppercase">{{entr.marchandCode}}</td>
                                    <td class="vertical-align-uppercase">{{entr.marchandRaisonSociale}}</td>
                                    <td class="vertical-align-uppercase">{{entr.marchandContact}}</td>
                                    <td class="vertical-align-uppercase">{{entr.seuilTransaction  | formatmillier}}</td>
                                    <td class="vertical-align-uppercase">{{entr.domaineLibelle}}</td>
                                    <td class="vertical-align-uppercase">{{entr.nomResponsable}}</td>
                                    <td style="text-align: center">                 
                                        <a title="Modifier" (click)="openFormModalModal(templateCreate,entr)">
                                            <i class="fa fa-edit" style="color: green"> </i>
                                        </a>
                                        &nbsp;&nbsp;
                                        <!-- <a title="Supprimer">
                                            <i  (click)="confirmDeleteItem(obj)" class="fa fa-trash-o" style="color: red"> </i>
                                        </a> -->
                                    </td>
                                </tr>

                                <tr *ngIf="ListeMarchands && ListeMarchands.length > 0">
                                    <td colspan="8">
                                        <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
                                            <pagination [boundaryLinks]="true" [totalItems]="totalItems" [maxSize]='itemPerPagePagination' [itemsPerPage]="itemsPerPage"
                                                [(ngModel)]="currentPage" class="pagination-sm" previousText="&laquo;" nextText=" &raquo;"
                                                firstText="&laquo;" lastText="&raquo;" name="pagination" (pageChanged)="pageChanged($event)"></pagination>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="!ListeMarchands || ListeMarchands.length == 0">
                                <tr>
                                    <td colspan="11" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
                                </tr>

                            </tfoot>

                        </table>
                    </div>

                    <div class="col-md-3">
                       <div class="col-md-12">
                          
                       </div>
                    </div>
                </div>


            </div>
        </div>
        <!-- fin file d actualite -->

    </div>



    <ng-template #templateCreate>
        <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
            <div class="modal-header custom-modal-header">
                <h4 class="modal-title pull-left">
                    MARCHAND
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-lg-12">

                        <div class="row">
                            <div class="col-md-12">

                                <!-- <div class="col-md-4">
                                    <ng-multiselect-dropdown
                                    [data]="dropdownList"
                                    [(ngModel)]="selectedItems"
                                    [settings]="dropdownSettings"
                                    (onSelect)="onItemSelect($event)"
                                    (onSelectAll)="onSelectAll($event)"
                                  >
                                  </ng-multiselect-dropdown>
                                </div> -->
                                <div class="form-group col-md-4">
                                    <label>Code :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.code" name="code" required
                                        placeholder="Code" type="text">
                                </div>

                                <div class="form-group col-md-4">
                                    <label>Contact :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.telephone" name="contact" placeholder="Contact"
                                        required type="text" maxlength="20"  mask="00-00-00-00-00">
                                </div>

                                <div class="form-group col-md-4">
                                    <label>Seuil de transaction :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.seuilTransaction" name="seuil" placeholder="Seuil de transaction"
                                        required  type="text"  mask="separator">
                                </div>

                                <div class="form-group col-md-6">
                                    <label>Raison sociale :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.raisonSociale" name="raisonSociale" placeholder="Raison sociale"
                                        required type="text">
                                </div>

                                <div class="form-group col-md-6">
                                    <label>Responsable :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nomResponsable" name="nomResponsable" placeholder="Responsable"
                                        required type="text">
                                </div>

                                <div class="form-group col-md-6">
                                    <label> Domaine :
                                        <span class="required_champs">*</span>
                                    </label>

                                    <select class="form-control custom_input"  id="domaineSelected" *ngIf='!itemToSave.id'
                                  [(ngModel)]="itemToSave.domaineSelected" #ctrl="ngModel" name="domaineSelected" >
                                      <option disabled>-- Choisissez --</option>
                                      <option *ngFor="let vild of ListeDomaines" [ngValue]="vild">
                                        {{ vild.libelle }}
                                      </option>
                                  </select> 
                                  <input disabled  *ngIf='itemToSave.id' class="form-control custom_input" autocomplete="off" [value]="itemToSave.domaineLibelle" name="nomResponsable" placeholder="Responsable"
                                  required type="text">

                                </div>

                                <div class="form-group col-md-6">
                                    <label> Moyens de paiement :
                                        <span class="required_champs">*</span>
                                    </label>

                                        <ng-multiselect-dropdown
                                        name="selectAllPaiement"
                                        placehoder="Sélectionner"
                                        [data]="ListeMoyenPaiements"
                                        [(ngModel)]="itemToSave.datasMoyenPaiement"
                                        [settings]="dropdownSettings"
                                        (onSelect)="onItemSelect($event)"
                                        (onSelectAll)="onSelectAll($event)"
                                        [placeholder]="'Moyens de paiement'" 
                                      >
                                      </ng-multiselect-dropdown>

                                    <!-- <select class="form-control custom_input"  id="moyenPaiementSelected"
                                       [(ngModel)]="itemToSave.moyenPaiementSelected" #ctrl="ngModel" name="moyenPaiementSelected">
                                           <option disabled>-- Choisissez --</option>
                                           <option *ngFor="let vil of ListeMoyenPaiements" [ngValue]="vil">
                                             {{ vil.libelle }}
                                           </option>
                                   </select>  -->
                                </div>

                                <div class="col-md-12" style="background: #eceeee">
                                   <div class="">
                                      <h1> Informations administrateur </h1>
                                   </div>
                                   
                                   <div class="col-md-12">
                                      <div class="form-group col-md-6">
                                          <label>Nom :
                                              <span class="required_champs">*</span>
                                          </label>
                                          <input class="form-control custom_input" autocomplete="off" [(ngModel)]="admin.firstName" name="firstName" placeholder="Nom"
                                              required type="text">
                                      </div>
      
                                      <div class="form-group col-md-6">
                                          <label>Prénoms :
                                              <span class="required_champs">*</span>
                                          </label>
                                          <input class="form-control custom_input"  autocomplete="off" [(ngModel)]="admin.lastName" name="lastName" placeholder="Prénoms"
                                              required type="text">
                                      </div>

                                      <div class="form-group col-md-4">
                                          <label>Téléphone :
                                              <span class="required_champs">*</span>
                                          </label>
                                          <input class="form-control custom_input" autocomplete="off" [(ngModel)]="admin.telephone" name="telephone" placeholder="Télephone"
                                              required type="text"  mask="00-00-00-00-00">
                                      </div>
      
                                      <div class="form-group col-md-4">
                                          <label>Email :
                                              <span class="required_champs">*</span>
                                          </label>
                                          <input class="form-control custom_input"  autocomplete="off" [(ngModel)]="admin.email" name="email" placeholder="Email"
                                              required type="text">
                                      </div>

                                      <div class="form-group col-md-4">
                                          <label>Login :
                                              <span class="required_champs">*</span>
                                          </label>
                                          <input class="form-control custom_input" [disabled]='itemToSave.id' autocomplete="off" [(ngModel)]="admin.login" name="login" placeholder="Login"
                                              required type="text">
                                      </div>



                                   </div>


                                </div>

                            </div>
                        </div>

                    </div>

                </div>


            </div>
            <div class="modal-footer modal-footer-custom">
                <b class="pull-left">(
                    <span class="required_champs">*</span>) Champs requis</b>

                <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal()">
                    Annuler
                </button>

                <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItem(itemToSave) ">
                    Valider
                </button>

            </div>
        </form>
    </ng-template>



</div>