import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import { HttpHeaders } from '@angular/common/http';
declare const Swal: any;

@Component({
    selector: 'app-main-parametrage-ligne-budgetaire',
    templateUrl: './main-parametrage-ligne-budgetaire.component.html',
    styleUrls: ['./main-parametrage-ligne-budgetaire.component.css']
})
export class MainParametrageLigneBudgetaireComponent implements OnInit {


    results: Array<any> = [];
    ListeDomaines: Array<any> = [];
    currentTenant: any = {};
    busyGet: Subscription;
    busySave: Subscription;
    itemToSave: any;
    totalItems = 0;
    currentPage = 1;

    user: any;
    itemsPerPage: any;
    itemToSearch: any;
    modalRef: BsModalRef;
    bsModalRef: BsModalRef;
    currentItemImage: any;
    itemPerPagePagination: number = 10;
    admin: any = {};

    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};


    config = {
        animated: true,
        keyboard: false,
        backdrop: 'static',
        ignoreBackdropClick: true
    };


    constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService) {
        this.cancelSave();
        this.user = this.userService.getCurrentUser();
        this.itemsPerPage = 10;
        this.itemToSearch = {};
        // this.statutCotisation = enumStatutCotisation;


    }

    //MULTI SELECT


    hideModal() {
        this.modalRef.hide();
    }

    openFormModalModal(template, itemToModified?: any) {

        this.cancelSave();

        if (itemToModified) {
            console.log("le tenant", itemToModified);

            //this.getDataFullMarchand(itemToModified.id) ;
            // itemToModified.domaineSelected = _.find(this.ListeDomaines, { 'domaineId': itemToModified.domaineId });
            this.itemToSave = Object.assign({}, itemToModified);
            console.log('to be modified: ',  this.itemToSave);
            
        }
        this.modalRef = this.modalService.show(template, { class: 'gray modal-md' });
    }


    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
        this.currentPage = event.page;
        this.getData();
    }


    getData() {

        if (!this.currentTenant || !this.currentTenant.code) return;

        // const  options = {
        //   headers: new HttpHeaders({
        //       'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004', 
        //       lang: "fr"
        //   })
        // }

        var request = {
            user: this.user.id,
            data: {
                tenancyCode: (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null,
            },
            index: (this.currentPage - 1),
            size: this.itemsPerPage ? this.itemsPerPage : null
        }

        console.log('lignes budgetaire', JSON.stringify(request));
        this.busyGet = this.api.post('ligneBudgetaire/getByCriteria', request)
            .subscribe(
                res => {
                    console.log('lignes budgetaire', res);
                    if (res && res['items']) {
                        this.results = res['items'];
                        this.totalItems = res['count'] ? res['count'] : 0;
                    } else {
                        this.results = [];
                    }
                },
                err => {
                    console.log("Error occured", err);
                    //this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                    // this.not
                }
            );

    }


    getDataDomaine() {


        if (!this.currentTenant || !this.currentTenant.code) return;

        const options = {
            headers: new HttpHeaders({
                'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004', 
                lang: "fr"
            })
        }


        var request = {
            user: this.user.id,
            data: {
                tenancyIdx: this.currentTenant.id
            },
        }


        this.busyGet = this.api.postOption('domaine/getByCriteria', request, options)
            .subscribe(
                res => {
                    
                    if (res && res['items']) {
                        // res['items'].forEach(element => {
                        //     element.libelle = element.domaineLibelle.toUpperCase();
                        // });
                        this.ListeDomaines = res['items'];
                        console.log('domaines data', this.ListeDomaines);
                    } else {
                        this.ListeDomaines = [];
                    }
                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                    // this.not
                }
            );

    }


    confirmSaveItem(obj) {

        if (!obj) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner les champs obligatoires.");
            return;
        }

        if (!this.itemToSave || !this.itemToSave.domaineId) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner le domaine svp.");
            return;
        }
        if (!this.itemToSave || !this.itemToSave.seuil) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner le seuil svp.");
            return;
        }

        Swal.fire({
            title: !obj.id ? 'Enregistrement ligne budgétaire' : 'Modification ligne budgétaire',
            text: 'Désirez-vous poursuivre cette action ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonColor: "#00A984",
            cancelButtonColor: "#333333",
        }).then((result) => {
            if (result.value) {
                this.saveItem(obj);
            } else {
                console.log("bnjr");
            }
        })

    }



    confirmDeleteItem(obj) {
        //this.lgModal.show();

        Swal.fire({
            title: 'Suppression',
            text: 'Désirez-vous poursuivre cette action ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonColor: '#00A984',
            cancelButtonColor: '#333333',
        }).then((result) => {
            if (result.value) {
                this.deleteItem(obj);
            } else {
                console.log("bnjr");
            }
        })

    }


    cancelSave() {
        this.itemToSave = {};
    }


    saveItem(item) {

          const  options = {
            headers: new HttpHeaders({
                'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004', 
                lang: "fr"
            })
          }
          console.log('options',options);
          
        // item.tenancyId = (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null
        var request = {
            user: this.user.id,
            datas: [
                item
            ]
        }

        console.log("create return", request);
        console.log('item to save:',JSON.stringify(request));
        
        this.busySave = this.api.postOption('ligneBudgetaire/' + (item.id ? 'update' : 'create'), request,options)
            .subscribe(
                res => {
                    console.log("resul", res);
                    if (!res['hasError'] && res['items']) {
                        this.notificationService.showMessageSucces("Succès", 'Opération effectuée avec succès.');
                        this.currentPage = 1;
                        this.hideModal();
                        this.cancelSave();
                        this.getData();
                    } else {
                        if (res['status'] && res['status']['message']) {
                            this.notificationService.showMessageError("Erreur", res['status']['message']);
                        }
                    }
                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
                }
            );
    }


    deleteItem(obj) {

        // const  options = {
        //   headers: new HttpHeaders({
        //       'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004', 
        //       lang: "fr"
        //   })
        // }

        obj.tenancyId = (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null
        var request = {
            user: this.user.id,
            datas: [
                obj
            ]
        }

        this.busyGet = this.api.post('tenancyDomaine/delete', request)
            .subscribe(
                res => {
                    console.log(res);
                    if (!res['hasError']) {
                        this.notificationService.showMessageSucces("Succès", 'Suppression effectuée avec succès');

                        this.currentPage = 1;
                        this.getData();
                    }
                    else {
                        if (res['status'] && res['status']['message']) {
                            this.notificationService.showMessageError("Erreur", res['status']['message']);
                        }
                    }

                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                    // this.not
                }
            );
    }


    getExactlyNumberRow(page, index) {
        let num = index + 1;
        if (page > 1) {
            num = ((page - 1) * 10) + (index + 1);
        }
        return num;
    }

    ngOnInit() {

        this.userService.currentData.subscribe(currentData => {
            this.currentTenant = currentData;
            this.getData();
            this.getDataDomaine();
        });



        window.scrollTo(0, 0);
    }

}
