<div class="row positRow">
  <div class="col-md-6">
    <img src="assets/img/decoupes/logo.png" id="icon" alt="User Icon" style=" width: 25%;
                      height: auto;
                      margin-top: 10px;
                      margin-bottom: 10px;" />
  </div>
  <div class="col-md-6">
    <form #f="ngForm"  novalidate id="loginForm" *ngIf='!itemToSave.isPasswordForget'>
      <div class="form-content">
        <div class="col-md-8;"  style="opacity: 1">
                          <div class="form-group col-md-12" style="padding: 0;margin-top: 40px;margin-bottom: 15px;">
                              <p style="font-size: 30px;">
                                Connectez-vous !
                              </p> 
                              <!-- <hr> -->
                        </div>
                  
                        <fieldset class="col-md-12" style="background: 0;border: 0;margin-top:10px;padding: 0;margin-bottom: 10px">
                          
                            <section style="margin-bottom: 15px">
                              <p style="opacity: 1;">Login</p>
                                  <input type="text" name="login-sodexam"  placeholder="Login" [(ngModel)]="itemToSave.login" class="form-control custom_input second" >
                            </section>
                            <br>
                            <section class="effetPassword">
                              <p style="opacity: 1;">Mot de passe</p>
                                  <input type="password" name="password" placeholder="Mot de passe" id="password" [(ngModel)]="itemToSave.password" class="form-control custom_input third">
                                  <i class="fa fa-eye-slash eye-password" id="eye" (click)="afficherPassword()"></i>
                            </section>
                
                            <br> <br>
                            <div class="col-md-12" style="text-align: center;margin-top: 15px">
                
                               <button  type="button" class="btn btn-md btn-default btn-cancel-connexion " (click)="itemToSave = {}" style="margin-right: 10px;    padding: 4px;
                                padding-left: 10px;
                                padding-right: 10px;border-radius: 30px;background-color: #333333;color: #fff">
                                    ANNULER
                               </button>
                
                               <!-- <button type="button" class="btn btn-md btn-default btn-cancel-modal" (click)="itemToSave == {}">
                                  Annuler
                             </button>
                
                               <button type="button" class="btn btn-md btn-default btn-export btn-cancel-modal" (click)="login(itemToSave)">
                                   Connexion <img src="assets/img/decoupes/ajaxloader.gif" *ngIf="itemToSave.showLoader" />
                              </button> -->
                
                               <button type="submit"  class="btn btn-md btn-default btn-connexion"  style="margin-right: 10px;padding: 4px;
                                    padding-left: 10px;
                                    padding-right: 10px;border-radius: 30px;background-color: #00A984;color: #fff" (click)="login(itemToSave)">
                                    CONNEXION <img src="assets/img/decoupes/ajaxloader.gif" *ngIf="itemToSave.showLoader" />
                               </button>
                               <br><br>
                               <p class="text-left">
                                <a style=" color: #ffffff" (click)="updatePassWord()"  *ngIf='!itemToSave.isPasswordForget'>Mot de passe oublié?</a>
                                </p>
                
                            </div>
                          
                          </fieldset>
                
                
                      </div>
      </div>
    </form>



    <form #f="ngForm"  novalidate id="loginForm" *ngIf='itemToSave.isPasswordForget'>
      <div class="form-forget">
                        
                      <div class="col-md-12" >
                          <div class="form-group col-md-12" style="padding: 0;margin-top: 40px;margin-bottom: 15px;">
                              
                              <p style="font-size: 30px;">
                                Mot de passe oublié !
                              </p> 
                        </div>
                
                        <fieldset class="col-md-12" style="background: 0;border: 0;margin-top: 10px;padding: 0;margin-bottom: 10px">
                          
                            <section style="margin-bottom: 15px">
                                <input type="text" name="login-sodexam"  placeholder=" Votre login" [(ngModel)]="itemToSave.libellePassForget" class="form-control custom_input" style="padding: 0; font-size: 13px" >
                                  <!-- <input type="text" name="login-sodexam"  placeholder="Login" [(ngModel)]="itemToSave.login" class="form-control custom_input second" > -->
                            </section>
                
                         
                
                            <div class="col-md-12" style="text-align: center;margin-top: 15px">
                
                               <button  type="button" class="btn btn-md btn-default btn-cancel-connexion " (click)="updatePassWord()" style="margin-right: 10px;    padding: 4px;
                                padding-left: 10px;
                                padding-right: 10px;border-radius: 30px;background-color: #3d3d3d;color: #fff">
                                  Retour
                               </button> 
                
                               <!-- <button type="button" class="btn btn-md btn-default btn-cancel-modal" (click)="itemToSave == {}">
                                  Annuler
                             </button>
                
                               <button type="button" class="btn btn-md btn-default btn-export btn-cancel-modal" (click)="login(itemToSave)">
                                   Connexion <img src="assets/img/decoupes/ajaxloader.gif" *ngIf="itemToSave.showLoader" />
                              </button> -->
                
                               <button type="submit"  class="btn btn-md btn-default btn-connexion"  style="margin-right: 10px;    padding: 4px;
                                    padding-left: 10px;
                                    padding-right: 10px;border-radius: 30px;background-color: #00A984;color: #fff" (click)="motDePassOublie(itemToSave)">
                                    Valider <img src="assets/img/decoupes/ajaxloader.gif" *ngIf="itemToSave.showLoader" />
                               </button>
                
                            </div>
                          
                          
                            <div class="col-md-12 form-message" *ngIf="itemToSave.message && itemToSave.isPasswordForget">
                                <span style="padding-right: 15px">&nbsp;&nbsp; {{itemToSave.message}} </span> 
                             </div>
              
                             
              
                          </fieldset>
                
                
                      </div>
      </div>
    </form>
  </div>

</div>