import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../../user/user.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormReinitPasswordComponent } from "../../../+components/form-reinit-password/form-reinit-password.component";
import { Api } from "../../utils/api/api";
import { NotificationService } from "src/app/shared/utils/notification.service";
import { UtilitiesService } from "../../../shared/utils/utilities.service";
declare const Swal: any;
import * as _ from "lodash";
import { Subscription } from "rxjs";
// import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';

declare var $: any;

@Component({
  selector: "sa-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, AfterViewInit {
  user: any;
  bsModalRef: BsModalRef;
  itemToSave: any;
  currentLogo: any;
  imageDisplay: any;
  imageDisplayUrl: any;

  config = {
    animated: true,
    keyboard: false,
    backdrop: "static",
    ignoreBackdropClick: true,
  };
  modalToShow: any;

  documents: any = [];
  currentItemImage: {
    fichierBase64: any;
    nomFichier: any;
    isPhoto: boolean;
    extension: any;
  };
  currentTenant: any;
  busyGet: Subscription;
  constructor(
    private router: Router,
    private userService: UserService,
    private modalService: BsModalService,
    private api: Api,
    private notificationService: NotificationService,
    private utilities: UtilitiesService
  ) {}

  ngOnInit() {
    this.user = this.userService.getCurrentUser();
    this.getData();
    this.userService.currentData.subscribe((currentData: any) => {
      console.log("currentUser", currentData);
      if (currentData) {
        this.currentTenant = currentData;
        console.log("current tenant id:", currentData.id);
      } else {
      }
    });
  }

  ngAfterViewInit() {
    $(document).mouseup(function (e) {
      var container = $(".dropdown-menu");
      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.css("display", "none");
      }
    });
  }

  openFormModalReinitMotDePasse(template, itemToModified) {
    if (itemToModified) {
      this.itemToSave = Object.assign({}, this.user);
    }
    this.bsModalRef = this.modalService.show(template);
  }

  truncateValue(item: any, nbre?) {
    if (!item) return;

    let regex = /(&nbsp;|<([^>]+)>)/gi;

    //RETIRER LES CARACTERES HTML
    item = item.replace(regex, "");

    if (item.length > nbre) {
      item = item.substring(0, nbre).trim() + "" + " ...";
    }
    return item;
  }

  searchMobileActive = false;

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $("body").toggleClass("search-mobile", this.searchMobileActive);
  }

  toggleDropDrown() {
    $(".dropdown-menu").css("display", "block");
  }

  onSubmit() {
    this.router.navigate(["/miscellaneous/search"]);
  }

  updateProfilInformation() {
    this.router.navigate(["/update-profil-user"]);
  }

  openChangePassword() {
    const initialState = {
      title: "Changer votre mot de passe.",
      config: this.config,
    };
    this.bsModalRef = this.modalService.show(FormReinitPasswordComponent, {
      class: "gray modal-sm modal-custom-reinit",
    });
    this.bsModalRef.content.title = "Changer votre mot de passe";
    this.bsModalRef.content.currentUser = this.user;
  }

  openChangeLogo(modRef) {
    const initialState = {
      title: "Changer votre mot de passe.",
      config: this.config,
    };
    this.bsModalRef = this.modalService.show(modRef, {
      class: "gray modal-sm modal-custom-reinit",
    });
  }

  uploadFile(event: any, item: any, bool: boolean) {
    const reader = new FileReader();
    const file = event.target.files[0];
    const fileName = file.name.split(".")[0];
    const Tabextension = file.name.split(".");
    const extension = Tabextension[Tabextension.length - 1];
    reader.onload = (readerEvent) => {
      const data = (readerEvent.target as any).result;
      this.imageDisplay = data;
      const fileBase64 = data.split(",")[1];
      this.currentItemImage = {
        fichierBase64: fileBase64,
        nomFichier: fileName,
        isPhoto: bool,
        extension: extension,
      };
      // console.log('current image: ', JSON.stringify(this.currentItemImage) );
      console.log("imageDisplay", this.imageDisplay);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  confirmSaveItem() {
    if (!this.currentItemImage) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez choisir un logo."
      );
      return;
    }
    Swal.fire({
      title: "Enregistrement de logo",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        this.saveItem();
      } else {
        console.log("bnjr");
      }
    });
  }

  saveItem(item?: any) {
    // if (!this.currentTenant || !this.currentTenant.code) {return};

    const request = {
      user: this.user.id,
      datas: [
        {
          id:
            this.currentTenant && this.currentTenant.id
              ? this.currentTenant.id
              : null,
          dataDocumentsLogo: this.currentItemImage,
        },
      ],
    };
    console.log("data sent to create logo", request);
    console.log("data sent to create logo", JSON.stringify(request));
    this.busyGet = this.api.post("tenancy/createLogoTenant", request).subscribe(
      (res: any) => {
        console.log("resul", res);
        if (!res["hasError"] && res["items"]) {
          this.notificationService.showMessageSucces(
            "Succès",
            "Opération effectuée avec succès."
          );
          this.bsModalRef.hide();
          this.getData();
        } else {
          if (res["status"] && res["status"]["message"]) {
            this.notificationService.showMessageError(
              "Erreur",
              res["status"]["message"]
            );
          }
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Erreur",
          this.utilities.messageInterpretor(err)
        );
      }
    );
  }
  getData() {
    // if (!this.currentTenant || !this.currentTenant.code) { return };
    if (!this.user && !sessionStorage.getItem("currentTenancyId")) {
      return;
    }
    const request = {
      user: this.user.id,
      datas: [
        {
          id: JSON.parse(sessionStorage.getItem("currentTenancyId")),
        },
      ],
    };
    this.busyGet = this.api
      .post("tenancy/getLogoCooperative", request)
      .subscribe((res: any) => {
        if (res && res["items"]) {
          console.log("getData logo image", res.items[0].filePathLogo);
          this.imageDisplayUrl = res.items[0].filePathLogo;
        } else {
        }
      });
  }

  logout() {
    Swal.fire({
      title: "Déconnexion",
      text: "Voulez-vous vous déconnecter de l'application ?",
      icon: "warning",
      showCancelButton: true,

      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        sessionStorage.removeItem("yz-backoffice-app-smart-carte");
        this.router.navigate(["/auth/login"]);
      }
    });
  }
}
