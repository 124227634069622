import { Component, OnInit } from "@angular/core";
import { Subscription } from 'rxjs';
import { Api } from "../../../shared/utils/api/api";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NotificationService } from "../../../shared/utils/notification.service";
import { UtilitiesService } from "../../../shared/utils/utilities.service";
import { ModelService } from "../../../shared/utils/model.service";
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
declare const Swal: any;
import { CommunicationService } from "src/app/core/services/communication.service";
import * as moment from 'moment';

@Component({
  selector: 'app-gestion-stock',
  templateUrl: './gestion-stock.component.html',
  styleUrls: ['./gestion-stock.component.css']
})
export class GestionStockComponent implements OnInit {
  selectedRow = null;
  ListeRegions: Array<any> = [];
  ListeMoyenPaiements: Array<any> = [];
  ListeDomaines: Array<any> = [];
  ListeCultures: Array<any> = [];
  ListeTailleCoperative: Array<any> = [];
  ListePartenaires: Array<any> = [];
  ListeTenants: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  currentWebApi = "societe";
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any = {};
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  imageDisplay: any;
  itemPerPagePagination = 10;
  admin: any = {};

  selectedItemsPartenaires = [];
  selectedItemsCultures = [];
  selectedItemsRegions = [];
  selectedItemsModePaiements = [];
  selectedItemsLigneBudgetaires = [];
  selectedCertificates = [];
  selectedItemsSections = [];
  selectedSection: any;
  selectedBanques = [];
  listSectionForCreate = [];

  dropdownPartenairesSettings = {};
  dropdownCulturesSettings = {};
  dropdownSectionsSettings = {};
  dropdownRegionsSettings = {};
  dropdownModePaiementSettings = {};
  dropdownLigneBudgetaireSettings = {};
  dropdownCertificateSettings = {};
  dropdownBanqueSettings = {};
  enable = true;

  config = {
    animated: true,
    keyboard: false,
    backdrop: "static",
    ignoreBackdropClick: true,
  };
  isCertificate = false;
  listCertificates = [];
  listSection = [];
  currentTenant: any = {};
  datasCultures = [];
  selectedCulture: any;
  cultureCollection = [];
  listBanques = [];
  ListeRegionsBrute = [];
  listeDelais = [];
  nbrSection: number;
  supTotaPlantCoop: number;
  cultureSuperficie: number;
  selectedSuperficie: any;
  copyListSection: any[];
  currentYear = new Date().getFullYear();
  currentYearBank = new Date().getFullYear();
  isOpen: any;
  currentRow :any
  selectedFile: any;
  results: any;
  disableMode = false
  ListeRechargments: any[];
  listLotsLignes: any[];
  ListeClients: any;
  currentLotId: any;
  quantiteTotale: any;
  quantiteSelection: any;

  constructor(
    private api: Api,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private utilities: UtilitiesService,
    private modelService: ModelService,
    private userService: UserService,
    private communicationService: CommunicationService
  ) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.itemToSearch = {};
    // this.statutCotisation = enumStatutCotisation;
  }

  //MULTI SELECT

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified?: any,disableMode?) {
    this.cancelSave();
    if(disableMode) this.disableMode = true
    console.log('itemToModified',itemToModified);
    
    if (itemToModified) {
      console.log('itemToModified',itemToModified);
      if(this.disableMode){
        this.getDataById(itemToModified.id)
      }
      else{
        this.itemToSave = {...itemToModified}
        this.itemToSave.quantite *= 1000

      }
    }
    this.modalRef = this.modalService.show(template, {
      class: "gray modal-lg",
    });
  }
  getDataClients(id?:any) {
    console.log('le this currentTenant', this.currentTenant);
    this.itemToSearch.clientSelected = null;

    if (!this.currentTenant || !this.currentTenant.code) { return }
    const request = {
      user: this.user.id,
      data: {
        tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
      }
    }
    console.log("Data sent to get clients tenancy",request);

   this.api.post('clientTenancy/getByCriteria', request)
      .subscribe(
        res => {
          console.log("Clients tenancy",res);
          if (res && res['items']) {
            this.ListeClients = res['items'];
          } else {
            this.ListeClients = [];
          }
        });
  }

  openFormModalModalLivraison(template){
    console.log('current row to add livraison',this.currentRow);
    
    this.modalRef = this.modalService.show(template, {
      class: "gray modal-lg",
    });
  }
  getDataById(id?:any) {
    if(id){
      this.currentLotId = id
    }
    const request = {
      user: this.user.id,
      data: {
        tenancyId:(this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        id:this.currentLotId,
        clientId: this.itemToSearch.clientId,
      },
    };

    console.log('data sent to lotLivraisonDetail',JSON.stringify(request));
    this.listLotsLignes = [];
    this.busyGet = this.api.post("lotLivraison/getByCriteria", request).subscribe(
      (res) => {
        console.log("return of getDataById:", res);
        if (res && res["items"] && res["items"].length) {
          console.log("resitem", res["items"]);
          this.listLotsLignes = res["items"][0].datasLivraisonDetails
          this.itemToSave.libelle = res["items"][0].libelle
          this.itemToSave.quantite = res["items"][0].quantite
          console.log('listLotsLignes',this.listLotsLignes);
          this.quantiteTotale = res["items"][0].quantite
          this.quantiteSelection=0
          if(this.listLotsLignes && this.listLotsLignes.length){
            this.listLotsLignes.forEach((element:any) => {
              this.quantiteSelection += Number(element.quantite)
            });
            console.log('quantiteSelection',this.quantiteSelection);
            
          }

          
         
        } else {
          this.listLotsLignes = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
        // this.not
      }
    );
  }

  pageChanged(event: any): void {
    console.log("Page changed to: " + event.page);
    console.log("Number items per page: " + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  getData() { 
    const request = {
      user: this.user.id,
      data: {
        libelle: this.itemToSearch.libelle || null,
        quantite: this.itemToSearch.quantite || null,
        tenancyId : (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        createdAt: this.itemToSearch.start ? moment(this.itemToSearch.start).format('DD/MM/YYYY') : null,
        createdAtParam: {
          operator: "[]",
          start: this.itemToSearch.start ? moment(this.itemToSearch.start).format('DD/MM/YYYY') : null,
          end: this.itemToSearch.end ? moment(this.itemToSearch.end).format('DD/MM/YYYY') : null
        },
      },
      index: this.currentPage - 1,
      size: this.itemsPerPage ? this.itemsPerPage : null,
    };

    console.log('data sent to server',JSON.stringify(request));
    
    this.busyGet = this.api.post("lotLivraison/getByCriteria", request).subscribe(
      (res) => {
        console.log("otLivraison/getByCriteria :", res);
        if (res && res["items"]) {
          this.results = res["items"];
          console.log('results',this.results);
          
          this.currentRow = this.results[0];

          this.totalItems = res["count"] ? res["count"] : 0;
        } else {
          this.results = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
        // this.not
      }
    );
  }

  confirmSaveItem(obj: any) {
    console.log("le item to save", obj);

    if (!this.itemToSave.libelle) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner libellé svp!"
      );
      return;
    }
    if (!this.itemToSave.quantite) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner quantité svp!"
      );
      return;
    }
    obj.quantite = obj.quantite/1000
    Swal.fire({
      title: !obj.id
        ? "Enregistrement lot"
        : "Modification lot",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        this.saveItem(obj);
      } else {
        console.log("bnjr");
      }
    });
  }
  confirmSaveItemLivraison(){
    Swal.fire({
      title: "Livraison",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        this.saveItemLivraison();
      } else {
        // console.log("bnjr");
      }
    });
  }
  countSelectedQuantite(): number{
    let totalCount:number = 0
    if(this.ListeRechargments && this.ListeRechargments.length){
      let selectedList = this.ListeRechargments.filter(
        ls=> ls.isSelectedClientId
      )
      if(selectedList && selectedList.length){
        selectedList.map(
          sl=> {
            totalCount+= sl.quantite
          }
        )
      }
    }
    return +this.round(totalCount)
  }
  round(num) {
    if (num) {
        return Number(num).toFixed(2)
    }
    return 0
  }
  saveItemLivraison(){
    // console.log('list chargement',this.ListeRechargments);
    let onlySelectedRechargement = this.ListeRechargments.filter(
      lr=>lr.isSelectedClientId
    )
    let reducedRechargements = onlySelectedRechargement.map(
      lr=>{
        return {
          tenancyId:(this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
          lotLivraisonId:this.currentRow.id,
          mouvementId: lr.id
        }
      }
    )
    const request = {
      user: this.user.id,
      datas: reducedRechargements,
    };

    console.log('data sent to lotLivraisonDetail',JSON.stringify(request));
    
    this.busySave = this.api
      .post("lotLivraisonDetail/"+ "create", request)
      .subscribe(
        (res) => {
          console.log("resul", res);
          if (!res["hasError"]) {
            this.notificationService.showMessageSucces(
              "Enregistrement",
              "Opération effectuée avec succès."
            );
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
            this.getDataMouvement()
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.notificationService.showMessageError(
                "Erreur",
                res["status"]["message"]
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Erreur",
            this.utilities.messageInterpretor(err)
          );
        }
      );


    
    
  }
  confirmDeleteItem(obj) {
    Swal.fire({
      title: "Suppression",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    })
      .then(
        (result) => {
          if (result.value) {
            this.deleteItem(obj);
          } else {
            console.log("bnjr");
          }
        },
        (dismiss) => {
          if (dismiss == "cancel") {
            //Swal.fire({..}); //un-comment this line to add another sweet alert popup on cancel
            console.log("cancelled successfully");
          }
        }
      )
  }

  checkCurrentItem(item) {
    if (item) {
      console.log("ellement coché", item);
      this.currentItemChecked = item;
    }
  }

  cancelSave() {
    this.disableMode = false
    this.selectedItemsSections = [];
    this.datasCultures = [];
    this.supTotaPlantCoop = null;
    this.itemToSave = {};
    this.selectedItemsPartenaires = [];
    this.selectedItemsCultures = [];
    this.selectedItemsRegions = [];
    this.selectedCertificates = [];
    this.selectedBanques = [];
    this.selectedItemsModePaiements = [];
    this.selectedItemsLigneBudgetaires = [];
    this.selectedItemsSections = [];
    this.admin = {};
  }

  saveItem(item: any) {
    item.tenancyId = (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null
    const request = {
      user: this.user.id,
      datas: [item],
    };
    console.log("data sent to server for lotLivraison create", request);
    this.busySave = this.api
      .post("lotLivraison/" + (item.id ? "update" : "create"), request)
      .subscribe(
        (res) => {
          console.log("resul", res);
          if (!res["hasError"]) {
            this.notificationService.showMessageSucces(
              "Enregistrement",
              "Opération effectuée avec succès."
            );
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.notificationService.showMessageError(
                "Erreur",
                res["status"]["message"]
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Erreur",
            this.utilities.messageInterpretor(err)
          );
        }
      );
  }

  deleteItem(obj) {
    const request = {
      user: this.user.id,
      datas: [obj],
    };

    this.busyGet = this.api.post("lotLivraison/delete", request).subscribe(
      (res) => {
        console.log(res);
        if (!res["hasError"]) {
          this.notificationService.showMessageSucces(
            "utilisateur",
            "Suppression effectuée avec succès"
          );

          this.currentPage = 1;
          this.getData();
        } else {
          if (res["status"] && res["status"]["message"]) {
            this.notificationService.showMessageError(
              "Erreur",
              res["status"]["message"]
            );
          }
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
        // this.not
      }
    );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = (page - 1) * 10 + (index + 1);
    }
    return num;
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  onSelectRow(entr) {
    console.log("hello");

    this.currentRow = entr;
  }

  ngOnInit() {
    this.userService.currentData.subscribe((currentData:any) => {
      console.log('currentUser', currentData);
      if (currentData) {
          this.cancelSave();
          this.currentTenant = currentData;
          this.getData();
          console.log('current tenant id:',currentData.id);
          this.getDataMouvement()
          this.getDataClients() 

      } else {
      }
  });


    window.scrollTo(0, 0);
  }



  displayLigne(lin: any) {
    console.log("ligne bud: ", lin);
    console.log("all lignes: ", this.selectedItemsLigneBudgetaires);
  }
  displayCertifs(cert: any) {
    console.log("certif: ", cert);
    console.log("all certficates: ", this.selectedCertificates);
  }
  displayCulture(cult: any) {
    console.log("cult: ", cult);
    console.log("all cultures: ", this.selectedItemsCultures);
  }
  updateCulture() {
    console.log("currentTenant.id", this.itemToSave.id);

    // if (JSON.stringify(this.selectedCulture) === JSON.stringify({})){
    //     this.notificationService.showMessageError('Erreur', 'Veuillez selectionner une culture svp!');
    //     return
    // }
    if (!this.selectedCulture) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez selectionner une culture svp!"
      );
      return;
    }
    if (!this.selectedSection) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la section de la culture svp!"
      );
      return;
    }

    const existsCulture = _.find(this.datasCultures, {
      cultureId: (this.selectedCulture as any).id,
    });
    const existsSection = _.find(this.datasCultures, {
      cultureSectionId: (this.selectedCulture as any).cultureSectionId,
    });
    console.log("selected culture: ", this.selectedCulture);
    console.log("selected sections: ", this.selectedItemsSections);
    const data = {
      tenancyId: this.itemToSave.id,
      cultureId: (this.selectedCulture as any).id,
      cultureLibelle: (this.selectedCulture as any).libelle,
      cultureSectionId: this.selectedSection.sectionId,
      cultureSectionLibelle: this.selectedSection.libelle,
    };
    console.log("single data culture: ", data);
    this.datasCultures.push(data);
    console.log("current culture: : ", this.datasCultures);
    this.selectedCulture = {};
  }
  removeCulture(cult: any) {
    console.log("removing row", cult);
    console.log("datasCultures", this.datasCultures);
    let index = _.findIndex(this.datasCultures, function (o) {
      return o.cultureId == cult.cultureId;
    });

    this.datasCultures.splice(index, 1);
    // this.supTotaPlantCoop = 0;
    // this.datasCultures.map(
    //     dc=>{
    //         this.supTotaPlantCoop += parseInt(dc.cultureSuperficie)
    //     }
    // )
  }
  getDynamicValue() {
    this.api.get("tenancy/getDynamiqueValue").subscribe((res: any) => {
      console.log("dynamic value: ", res);
      // this.itemToSave.nbrMembOffi = res.items[0].nbrMembOffi
      this.itemToSave.nbrMembCarte = res.items[0].nbrMembCarte;
      this.itemToSave.supTotalPlantCarte = res.items[0].supTotalPlantCarte;
      this.itemToSave.supTotaPlantCoop = res.items[0].supTotaPlantCoop;
      console.log("itemToSave.nbrMembOffi", this.itemToSave.nbrMembOffi);
      console.log(
        "itemToSave.supTotalPlantCarte",
        this.itemToSave.supTotalPlantCarte
      );
      console.log(
        "itemToSave.supTotaPlantCoop",
        this.itemToSave.supTotaPlantCoop
      );
    });
  }
  setNombreHommeFemme() {
    let totalMembres = 0;
    totalMembres =
      parseInt(this.itemToSave.nbrMembHom || 0) +
      parseInt(this.itemToSave.nbrMembFem || 0);

    if (totalMembres > 0) this.itemToSave.nbrMembOffi = totalMembres;
    else this.itemToSave.nbrMembOffi = "";
  }
  addCulture(p?: any) {
    console.log("list of selected cultures: ", this.selectedItemsCultures);
    console.log("last selected culture: ", this.selectedCulture);
  }
  selectCultureChange() {
    if (!this.selectedCulture.id) {
      this.listSection = [];
      return;
    }
    this.listSection = this.copyListSection;

    console.log("selected culture: ", this.selectedCulture);
  }
  onCultureDetails(cult) {
    this.selectedCulture = cult;
    console.log("cuture detail", cult);
    console.log("cuture detail", cult.cultureId);
    const index = _.findIndex(this.datasCultures, {
      cultureId: cult.cultureId,
    });
    this.selectedItemsSections = this.datasCultures[index].datasTenancySection;
    this.selectedItemsSections.map((sis) => {
      sis.libelle = sis.sectionLibelle;
    });
    console.log("selectedItemsSections", this.selectedItemsSections);
  }
  UncheckAll() {
    //    this.selectedItemsSections = [];
  }

  getAllInfoBanque() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.busySave = this.api
      .post("banque/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getAllInfoBanque", res);
        if (res && res.items) {
          this.listBanques = res.items || [];
        }
      });
  }
  getDelaiPaiement() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.busySave = this.api
      .post("delaiPaiement/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getDelaiPaiement", res);
        if (res && res.items) {
          this.listeDelais = res.items || [];
        }
      });
  }
  controleYear(item: any) {
    console.log("date changed");

    if (parseInt(item.annCertificat) > this.currentYear) {
      item.annCertificat = this.currentYear;
    }
  }

  controleYearBank(item: any) {
    console.log("date changed");

    if (parseInt(item.annOuverute) > this.currentYearBank) {
      item.annOuverute = this.currentYear;
    }
  }
  controleAnneeCreationCoop(item: any) {
    console.log("date changed");

    if (parseInt(item.annCreationCoop) > this.currentYear) {
      item.annCreationCoop = this.currentYear;
    }
  }
  controleAnneeInscriptionCarteAgricole(item: any) {
    console.log("date changed");

    if (parseInt(item.annInscCarte) > this.currentYear) {
      item.annInscCarte = this.currentYear;
    }
  }

  controleAnneeNominationPCA(item: any) {
    console.log("date changed");

    if (parseInt(item.annDirectionPca) > this.currentYear) {
      item.annDirectionPca = this.currentYear;
    }
  }
  controleAnneeNominationDirecteur(item: any) {
    console.log("date changed");

    if (parseInt(item.annDirectionDir) > this.currentYear) {
      item.annDirectionDir = this.currentYear;
    }
  }

  onChangeLock(event, item) {
    console.log("event", event);
    console.log("item", item);
    Swal.fire({
      title: "Confirmation",
      text: item.isLocked
        ? "Voulez-vous activer la coopérative ?"
        : "Voulez vous désactivation de la coopérative",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
      allowOutsideClick: false,
    })
      .then(
        (result) => {
          if (result.value) {
            this.lockCoop(item);
          } else {
            console.log("cancelled");
          }
        },
        (dismiss) => {
          if (dismiss == "cancel") {
            console.log("cancelled successfully");
            item.isValidated = !event;
          } else {
            throw dismiss;
          }
        }
      )
  }

  onChangeStatus(event, item) {
    console.log("event", event);
    console.log("item", item);
    Swal.fire({
      title: event
        ? "Validation de coopérative"
        : "Invalidation de coopérative",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
      allowOutsideClick: false,
    })
      .then(
        (result) => {
          if (result.value) {
            this.validerCoop(item);
          } else {
            console.log("cancelled");
          }
        },
        (dismiss) => {
          if (dismiss == "cancel") {
            //Swal.fire({..}); //un-comment this line to add another sweet alert popup on cancel
            console.log("cancelled successfully");
            item.isValidated = !event;
          } else {
            throw dismiss;
          }
        }
      )
  }

  lockCoop(coop: any) {
    const request = {
      user: this.user.id,
      datas: [coop],
    };
    const method = coop.isLocked === true ? "lockedTenacy" : "unLockedTenacy";
    console.log("data sent to lock coop", request);
    this.busyGet = this.api.post(`tenancy/${method}`, request).subscribe(
      (res: any) => {
        console.log("resul", res);
        if (res.hasError === false) {
          this.notificationService.showMessageSucces(
            "Enregistrement",
            "Opération effectuée avec succès."
          );
          this.currentPage = 1;
          this.cancelSave();
          // this.getData();
        } else {
          // this.getData();
          if (res.status && res.status.message) {
            this.notificationService.showMessageError(
              "Erreur",
              res.status.message
            );
          }
        }
        this.getData();
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Erreur",
          this.utilities.messageInterpretor(err)
        );
      }
    );
  }

  validerCoop(coop) {
    const request = {
      user: this.user.id,
      datas: [
        {
          id: coop.id,
        },
      ],
    };
    console.log("data sent to validate coop", request);
    this.busyGet = this.api
      .post("tenancy/validateCooperative", request)
      .subscribe(
        (res) => {
          console.log("resul", res);
          if (!res["hasError"] && res["items"]) {
            this.notificationService.showMessageSucces(
              "Enregistrement",
              "Opération effectuée avec succès."
            );
            this.currentPage = 1;
            this.cancelSave();
            this.getData();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.notificationService.showMessageError(
                "Erreur",
                res["status"]["message"]
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Erreur",
            this.utilities.messageInterpretor(err)
          );
        }
      );
  }


  getDataMouvement(){
    const request = {
      user: this.user.id,
      data: {
        tenantId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        sens: 'LIVRAISON',
        clientId: this.itemToSearch.clientId
      },
    }
    console.log("data sent to fetch mouvements",JSON.stringify(request))

    this.busySave = this.api.post('mouvementClient/getByCriteria', request).subscribe(
        (res: any) => {
          this.ListeRechargments = [];
      console.log('Liste des mouvements ', res);
      if (res.hasError === false) {
        this.ListeRechargments = res.items;
        this.ListeRechargments = this.ListeRechargments.filter(
          lr=>!lr.lotLivraisonId
        )

      } else {
        this.ListeRechargments = [];
      }
    });
  }


  exportData() {
    // if (!this.currentTenant || !this.currentTenant.code) { return };
    const request = {
      user: this.user.id,
      data: {
        tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        clientId: this.itemToSearch.clientId,
        lotLivraisonId:this.currentLotId

      },
      // index: (this.currentPage - 1),
      // size: this.itemsPerPage ? this.itemsPerPage : null
    };
    this.busyGet = this.api
      .post("lotLivraisonDetail/exportationLivraisonLot", request)
      .subscribe((res: any) => {
        console.log(res);
        if (res && res.filePathDoc) {
          console.log("res.filePathDoc: ", res.filePathDoc);

          window.location.href = res ? res.filePathDoc : window.location.href;
        } else {
          this.notificationService.showMessageError(
            "Information",
            res.status.message
          );
        }
      });
  }
  doSomethingOnToggle() {
    this.isOpen = !this.isOpen;
  }
  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  downloadTemplate() {}
}
