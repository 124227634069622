import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
declare const Swal: any;

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-main-marchands',
  templateUrl: './main-marchands.component.html',
  styleUrls: ['./main-marchands.component.css']
})
export class MainMarchandsComponent implements OnInit {

 
  ListeUtilisateurs: Array<any> = [];
  ListeMoyenPaiements: Array<any> = [];
  ListeDomaines :  Array<any> = [];
  ListeRoles: Array<any> = [];
  ListeMarchands : Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  currentWebApi = 'societe';
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  imageDisplay: any;
  itemPerPagePagination: number = 10;
  admin : any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};


  config = {
      animated: true,
      keyboard: false,
      backdrop: 'static',
      ignoreBackdropClick: true
  };
    currentTenant: any;

    searchKey:any;
    onFullSearch = new Subject<string>();
  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService) {
      this.cancelSave();
      this.user = this.userService.getCurrentUser();
      this.itemsPerPage = 10;
      this.currentItemChecked = {};
      this.itemToSearch = {};
      // this.statutCotisation = enumStatutCotisation;

      this.onFullSearch.pipe(
        debounceTime(400),
        distinctUntilChanged())
        .subscribe(value => {
          this.getData();
        
        });

      
  }

  //MULTI SELECT
 

  hideModal() {
      this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified?: any) {

      this.cancelSave();

      if (itemToModified) {
        console.log("to be modified: ",itemToModified);
          itemToModified.code = itemToModified.marchandCode
          itemToModified.telephone= itemToModified.marchandContact
          itemToModified.raisonSociale = itemToModified.marchandRaisonSociale
          itemToModified.domaineSelected = itemToModified.domaineId
          itemToModified.datasMoyenPaiement = itemToModified.datasModePaiements
          this.admin.firstName = itemToModified.dataAdmin[0].userFirstName
          this.admin.lastName = itemToModified.dataAdmin[0].userLastName
          this.admin.telephone = itemToModified.dataAdmin[0].userTelephone
          this.admin.email = itemToModified.dataAdmin[0].userEmail
          this.admin.login = itemToModified.dataAdmin[0].userLogin
          this.admin.id = itemToModified.dataAdmin[0].userId
        
        //  this.getDataFullMarchand(itemToModified.id) ;
        // itemToModified.typeUtilisateurSelected = _.find(this.ListeTypesUtilisateurs, { 'id': itemToModified.userTypeId });
          this.itemToSave = {...itemToModified};
          console.log("itemToSave: ",this.itemToSave);
          console.log("admin info: ",this.admin);
      }
      this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg' }));
  }


  pageChanged(event: any): void {
      console.log('Page changed to: ' + event.page);
      console.log('Number items per page: ' + event.itemsPerPage);
      this.currentPage = event.page;
      this.getData();
  }

  changeItermPerPage() {
      this.currentPage = 1;
      this.getData();
  }

  getData() {

      var request = {
          user: this.user.id,
          data: {
            marchandRaisonSociale : this.searchKey,
            tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,  
          },
          index: (this.currentPage - 1),
          size: this.itemsPerPage ? this.itemsPerPage : null
      }


      this.busyGet = this.api.post('marchandTenancy/getByCriteria', request)
          .subscribe(
              res => {
                  console.log('getData marchands',res);
                  if (res && res['items']) {
                      this.ListeMarchands = res['items'];
                      this.totalItems = res['count'] ? res['count'] : 0;
                  } else {
                      this.ListeMarchands = [];
                  }
              },
              err => {
                  console.log("Error occured", err);
                  this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                  // this.not
              }
          );

  }

  selectedMarchand(marchand)
  {
     this.itemToSave = { ...marchand};
  }

  getDataDomaine() {
      var request = {
          user: this.user.id,
          data: {
          }
      }

      this.api.post('domaine/getByCriteria', request)
          .subscribe(
              res => {
                  if (res && res['items']) {
                      res['items'].forEach(function(value) {
                        value.libelle = value.libelle.toUpperCase()
                      });

                      this.ListeDomaines = Object.assign([], res['items']);
                      console.log("la liste des domaines",this.ListeDomaines);
                  }
              },
              err => {
                  console.log("Error occured", err);
              }
          );
  }

  getDataFullMarchand(idMarchand) {
    var request = {
        user: this.user.id,
        data: {
          id : idMarchand
        }
    }

    this.busySave = this.api.post('marchand/getByCriteria', request)
        .subscribe(
            res => {
                
                console.log("le marchand a modifier",res);
                if (res && res['items']) {
                  
                  this.itemToSave = Object.assign({}, res['items'][0]);

                  this.itemToSave.domaineSelected =  _.find(this.ListeDomaines, (o)=> { return o.id == this.itemToSave.domaineId; });
     

                  this.selectedItems = this.itemToSave.datasMoyenPaiement;

                  if(this.itemToSave.datasUser && this.itemToSave.datasUser[0])
                  {
                    let userAdmin = this.itemToSave.datasUser[0]; 
                    this.admin = {
                      //referenceIdentite : ,
                      lastName : userAdmin.userLastName,
                      firstName : userAdmin.userFirstName,
                      email : userAdmin.userEmail,
                      telephone : userAdmin.userTelephone,
                      login : userAdmin.userLogin ,
                      id : userAdmin.userId
                    };
                  }

                }
            },
            err => {
                console.log("Error occured", err);
                //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
                // this.not
            }
        );
}

  getDataMoyenPaiement() {
      var request = {
          user: this.user.id,
          data: {
          }
      }

      this.api.post('moyenPaiement/getByCriteria', request)
          .subscribe(
              res => {
                  console.log('les moyens de paiement', res);
                  if (res && res['items']) {

                      res['items'].forEach(function(value) {
                          value.libelle = value.libelle.toUpperCase();
                          value.moyenPaiementLibelle = value.libelle
                          value.moyenPaiementId = value.id
                      });

                      this.ListeMoyenPaiements = res['items'];

                  }
              },
              err => {
                  console.log("Error occured", err);
                  //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
                  // this.not
              }
          );
  }


  confirmSaveItem(obj) {

      if (!obj) {
          this.notificationService.showMessageError("Erreur", "Veuillez renseigner les champs obligatoires.");
          return;
      }

      if(!this.itemToSave || !this.itemToSave.code   || !this.itemToSave.raisonSociale   || !this.itemToSave.nomResponsable   || !this.itemToSave.telephone 
        || !this.admin.lastName  || !this.admin.firstName  || !this.admin.telephone  || !this.admin.email  || !this.admin.login)  
        {
          this.notificationService.showMessageError("Erreur", "Veuillez renseigner les champs obligatoires.");
          return;
        }
  
      if(!this.itemToSave.domaineSelected)
      {
        this.notificationService.showMessageError("Erreur", "Veuillez sélectionner un domaine.");
        return;
      }

      if(!this.itemToSave.datasMoyenPaiement || this.itemToSave.datasMoyenPaiement.length == 0)
      {
        this.notificationService.showMessageError("Erreur", "Veuillez sélectionner les moyens de paiements.");
        return;
      }

      this.itemToSave.dataAdmin = this.admin;
      //this.itemToSave.moyenPaiementSelected.moyenPaiementId = this.itemToSave.moyenPaiementSelected.id;
    //   this.selectedItems.forEach(o=>{
    //     if(!o.moyenPaiementId)
    //     {
    //       o.moyenPaiementId = o.id
    //     }
        
    //   });

      //this.itemToSave.datasMoyenPaiement =  this.selectedItems; //[this.itemToSave.moyenPaiementSelected];
      this.itemToSave.domaineId = this.itemToSave.domaineSelected.id;
      console.log("itemToSave.datasMoyenPaiement",this.itemToSave.datasMoyenPaiement);
      

      Swal.fire({
          title: !obj.id ? 'Enregistrement marchand' : 'Modification marchand',
          text: 'Désirez-vous poursuivre cette action ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonColor: "#00A984",
          cancelButtonColor: "#333333",
      }).then((result) => {
          if (result.value) {
              this.saveItem(obj);
          } else {
              console.log("bnjr");
          }
      })

  }



  confirmDeleteItem(obj?) {
      //this.lgModal.show();

      Swal.fire({
          title: 'Suppression',
          text: 'Désirez-vous poursuivre cette action ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonColor: "#00A984",
          cancelButtonColor: "#333333",
      }).then((result) => {
          if (result.value) {
              this.deleteItem(obj);
          } else {
              console.log("bnjr");
          }
      })

  }

  checkCurrentItem(item) {
      if (item) {
          console.log("ellement coché", item);
          this.currentItemChecked = item;
      }
  }

  cancelSave() {
      this.itemToSave = {};
      this.selectedItems = [];
      this.admin = {};
  }


  modifyItem(user) {
      this.cancelSave();
      this.itemToSave = Object.assign({}, user);

      //Recuerer le profi
      this.itemToSave.roleSelected = _.find(this.ListeRoles, { 'id': this.itemToSave.idRole });

      window.scrollTo(0, 0);
  }

  saveItem(item) {
        item.tenancyId= (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null 
        item.id = item.marchandId
      var request = {
          user: this.user.id,
          datas: [
              item
          ]
      }

      console.log("create return", request);
      console.log("create return", JSON.stringify(request));

      this.busySave = this.api.post('marchand/' + (item.id ? 'update' : 'create'), request)
          .subscribe(
              res => {
                  console.log("resul", res);
                  if (!res['hasError'] && res['items']) {
                      this.notificationService.showMessageSucces("Marchand", 'Opération effectuée avec succès.');
                      this.currentPage = 1;
                      this.hideModal();
                      this.cancelSave();
                      this.getData();
                  } else {
                      if (res['status'] && res['status']['message']) {
                          this.notificationService.showMessageError("Erreur", res['status']['message']);
                      }
                  }
              },
              err => {
                  console.log("Error occured", err);
                  this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
              }
          );
  }


  deleteItem(obj) {

      var request = {
          user: this.user.id,
          datas: [
              obj
          ]
      }

      this.busyGet = this.api.post('marchand/delete', request)
          .subscribe(
              res => {
                  console.log(res);
                  if (!res['hasError']) {
                      this.notificationService.showMessageSucces("utilisateur", 'Suppression effectuée avec succès');

                      this.currentPage = 1;
                      this.getData();
                  }
                  else {
                      if (res['status'] && res['status']['message']) {
                          this.notificationService.showMessageError("Erreur", res['status']['message']);
                      }
                  }

              },
              err => {
                  console.log("Error occured", err);
                  this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                  // this.not
              }
          );
  }

  cancelSearch() {
      this.itemToSearch = {};
      this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
      let num = index + 1;
      if (page > 1) {
          num = ((page - 1) * 10) + (index + 1);
      }
      return num;
  }

  onItemSelect(item: any) {
    console.log(item);
    //this.listModePaiementToSave.push(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }


  ngOnInit() {

    this.userService.currentData.subscribe(currentData => {
        if (currentData) {
          this.currentTenant = currentData;
          this.getData();
         
        } else {
          
        }
      });





      this.getData();
      this.getDataDomaine();
      this.getDataMoyenPaiement();
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'moyenPaiementId',
        textField: 'moyenPaiementLibelle',
        selectAllText: 'Tout cocher',
        unSelectAllText: 'Tout décocher',
        //itemsShowLimit: 3,
        allowSearchFilter: true,
        searchPlaceholderText : "Sélectionner",
        placeholder : "Sélectionner",
      };

      window.scrollTo(0, 0);
  }

}
