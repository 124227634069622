import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Api} from '../../shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {NotificationService} from '../../shared/utils/notification.service';
import {UtilitiesService} from '../../shared/utils/utilities.service';
import {ModelService} from '../../shared/utils/model.service';
import {UserService} from "../../shared/user/user.service";
import * as _ from "lodash";
declare const Swal: any;

@Component({
  selector: 'app-main-profil',
  templateUrl: './main-profil.component.html',
  styleUrls: ['./main-profil.component.css']
})
export class MainProfilComponent implements OnInit {


  results : Array<any> = [];
  ListeRoles : Array<any> = [];
  ListeFonctionnalites :  Array<any> = [];
  ListeFonctionnalitesTest :  Array<any> = [];
  ListeFonctionnalitesOldRole  :  Array<any> = [];

  busyGet : Subscription;
  busySave : Subscription;
  busyFct : Subscription;
  itemToSave : any;
  totalItems = 0;
  currentPage = 1;
  currentWebApi = 'societe';
  user : any;
  itemsPerPage : any;
  currentItemChecked : any;
  statutCotisation : any;
  itemToSearch : any;
  modalRef: BsModalRef;
  currentItemImage : any;
  imageDisplay : any;


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  
  constructor(private api:Api,private modalService: BsModalService,private notificationService : NotificationService,private utilities : UtilitiesService,private modelService : ModelService,private userService: UserService) {
        this.cancelSave();
        this.user = this.userService.getCurrentUser();
        this.itemsPerPage = 10;
        this.currentItemChecked = {};
        this.itemToSearch = {};
      
   }


  hideModal()
  {
      this.modalRef.hide();
  }

  openFormModalModal(template,itemToModified?: any) {
        this.cancelSave();
        if(itemToModified)
        {
            this.itemToSave = this.modelService.societeDto(null);
            this.itemToSave = Object.assign({}, itemToModified);
            this.getDataFonctionnaliteByRole(this.itemToSave);
        }
       
        //this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg modal-custom-preview' }));
  }
    


    
  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page ;
    this.getData();
  }

  changeItermPerPage()
  {
    this.currentPage = 1;
    this.getData();
  }

  getData()
  {
      var request = {
          user : this.user.id,
          data :{
            libelle : this.itemToSearch.libelle ? this.itemToSearch.libelle : null,
            // prenom : this.itemToSearch.prenom ? this.itemToSearch.prenom : null
          },
          index:(this.currentPage-1),
          size: this.itemsPerPage ? this.itemsPerPage : null
      }

      this.busyGet = this.api.post('profil/getByCriteria', request)
          .subscribe(
              res => {
                  console.log(res); 
                  if(res && res['items'])
                  {
                      this.results = res['items'];
                      this.totalItems = res['count'] ? res['count'] : 0;
                  } else
                  {
                    this.results = [];
                  }
              },
              err => {
                  console.log("Error occured",err);
                  this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
              // this.not
              }
          );

  }

  getDataFonctionnalite()
  {
      var request = {
          user : this.user.id,
          "hierarchyFormat":true,
          data :{
          }
      }

     this.busyFct =  this.api.post('fonctionnalite/getByCriteria', request)
          .subscribe(
              res => {
                  console.log('les fonctionnalites',res); 
                  if(res && res['items'])
                  {
                      this.ListeFonctionnalites = res['items'];
                      //this.totalItems = res['count'] ? res['count'] : 0;
                  }
              },
              err => {
                  console.log("Error occured",err);
              //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
              // this.not
              }
          );
  }

  getDataFonctionnaliteByRole(item)
  {
      var request = {
          user : this.user.id,
          data :{
             profilId: item.id
          }
      }

     this.busyFct =  this.api.post('profilFonctionnalite/getByCriteria', request)
          .subscribe(
              res => {
                  console.log('les fonctionnalites by role',res); 
                  if(res && res['items'])
                  {
                      this.ListeFonctionnalitesOldRole = res['items'];
                      this.setDefaultFct();
                      //this.totalItems = res['count'] ? res['count'] : 0;
                  }
              },
              err => {
                  console.log("Error occured",err);
              //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
              // this.not
              }
          );
  }

  setDefaultFct()
  {
      console.log("iififif",this.ListeFonctionnalites);
    
      for(var i=0;i<this.ListeFonctionnalitesOldRole.length;i++)
      {
        var value = this.ListeFonctionnalitesOldRole[i];

        _.forEach(this.ListeFonctionnalites, function(valueFct, key) {
            console.log("la valeur",value);
            console.log("la clé",key);
            
            if(value.fonctionnaliteId == valueFct.id)
            {
                valueFct.checkedItem = true;
            }
           
            if(valueFct.datasFonctionnaliteChildren)
            {
                _.forEach(valueFct.datasFonctionnaliteChildren, function(valueChild, keyChild) {
                    
                    if(valueChild.id == value.fonctionnaliteId)
                    {
                        valueChild.checkedItem = true;
                    }

    
                    _.forEach(valueChild.datasFonctionnaliteChildren, function(valueChildSecond, keyChild) {
                        if(valueChildSecond.id == value.fonctionnaliteId)
                        {
                            valueChildSecond.checkedItem = true;
                        }
                      });
    
                  });
            }
          
    
      });

      }

   
  }
        
  confirmDeleteItem(obj) {
      //this.lgModal.show();

      Swal.fire({
          title: 'Suppression',
          text: 'Désirez-vous poursuivre cette action ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonColor: "#ff7b0c",
          cancelButtonColor: "rgb(154, 154, 153)",
      }).then((result) => {
          if (result.value) {
              this.deleteItem(obj);
          } else {
              console.log("bnjr");
          }
      })

  }

  checkCurrentItem(item)
  {
    if(item)
    {
        console.log("ellement coché",item);
        this.currentItemChecked = item;
    }
  }

  cancelSave()
  {
      this.itemToSave = {};
      this.setCheckedItem(false,'checkall','');
  }
      

  uploadFile(event : any,item,typeUpload)
  {
      let reader = new FileReader();

      let file = event.target.files[0];
      item.fileName = file.name.split('.')[0];
      let Tabextension = file.name.split('.');
      let extension = Tabextension[Tabextension.length-1];

        //verifier si l'extension est accepter
        const listeextensionImages = this.userService.getExtensionsImage();

        if( !_.find(listeextensionImages, { 'extension':extension.toLowerCase() }) )
        {
            this.notificationService.showMessageError("Upload","Veuillez vérifier l'extension du fichier.");
            event.target.value = null;
            return;
        }
        
      reader.onload = (readerEvent) => {

          let data = (readerEvent.target as any).result;
          this.imageDisplay = data;
          console.log(data);
        //  item.photoProfil = data;
          item.fileBase64 = data.split(',')[1];

          this.currentItemImage = {
              fichierBase64 : item.fileBase64,
              nomFichier : item.fileName,
              extension : extension
          }

          console.log("le item",this.currentItemImage);
      };

      reader.readAsDataURL(event.target.files[0]);
  }


    saveItem(item)
    {
        if(!item || !item.libelle)
        {
            this.notificationService.showMessageInfo("Information","Veuillez renseigner les champs obligatoires.")
            return;
        }

        var listeFonctionnaliteToSave = [];

        /* LISTE DES FONCTIONNALITES */
        _.forEach(this.ListeFonctionnalites, function(value, key) {
            console.log("la valeur",value);
            console.log("la clé",key);
            if(value.checkedItem)
            {
                listeFonctionnaliteToSave.push({id:value.id});
            }
           
            if(value.datasFonctionnaliteChildren)
            {
                _.forEach(value.datasFonctionnaliteChildren, function(valueChild, keyChild) {

                    if(valueChild.checkedItem)
                    {
                        listeFonctionnaliteToSave.push({id:valueChild.id});
                    }

                    _.forEach(valueChild.datasFonctionnaliteChildren, function(valueChildSecond, keyChild) {
                        if(valueChildSecond.checkedItem)
                        {
                            listeFonctionnaliteToSave.push({id:valueChildSecond.id});
                        }
                      });

                  });
            }
          

        });
    
        if(listeFonctionnaliteToSave.length == 0)
        {
            this.notificationService.showMessageInfo("Information","Veuillez sélectionner les fonctionnalités.")
            return;
        }

        item.datasFonctionnalite = listeFonctionnaliteToSave;

        var request = {
            user : this.user.id,
            datas : [
                item 
            ]
        }
        
        console.log("create return",request); 
        //return; 

        this.busySave = this.api.post('profil/'+(item.id ? 'update':'create'), request)
            .subscribe(
                res => {
                console.log("resul",res);
                    if(!res['hasError'] && res['items'])
                    {
                        this.notificationService.showMessageSucces("Rôle",'Opération effectuée avec succès');
                        this.currentPage = 1;
                        //this.hideModal();
                        this.cancelSave();
                        this.getData();
                    }else
                    {
                    if(res['status'] && res['status']['message'])
                    {
                        this.notificationService.showMessageError("Erreur",res['status']['message']); 
                    }
                    }
                },
                err => {
                    console.log("Error occured",err);
                    this.notificationService.showMessageError("Erreur",this.utilities.messageInterpretor(err));
                }
        );
    }


    deleteItem(obj)
    {

        var request = {
            user : this.user.id,
            datas : [
                obj
            ]
        }

        this.busyGet = this.api.post('profil/delete', request)
            .subscribe(
                res => {
                    console.log(res); 
                    if(!res['hasError'])
                    {
                        this.notificationService.showMessageSucces("Suppression",'Suppression effectuée avec succès');
                    
                        this.currentPage = 1;
                        this.getData();
                    }
                    else
                    {
                    if(res['status'] && res['status']['message'])
                    {
                        this.notificationService.showMessageError("Erreur",res['status']['message']); 
                    }
                    }
                
                },
                err => {
                    console.log("Error occured",err);
                    this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
                // this.not
                }
        );
    }


    getExactlyNumberRow(page,index)
    {
        let num = index +1;
        if(page>1)
        {
            num = ((page - 1) * 10) + (index+1);
        }
        return num;
    }

    displayDetails(item)
    {
        item.isOpen = !item.isOpen;
    }
    
    setCheckedItem(valeur,type,listFct?: any)
    {
        console.log(valeur);
        if(type == "checkall")
        {
            _.forEach(this.ListeFonctionnalites, function(value, key) {
                    console.log("la valeur",value);
                    console.log("la clé",key);
        
                    value.checkedItem = valeur;

                    if(value.datasFonctionnaliteChildren)
                    {
                        _.forEach(value.datasFonctionnaliteChildren, function(valueChild, keyChild) {
                            valueChild.checkedItem = valeur;

                            _.forEach(valueChild.datasFonctionnaliteChildren, function(valueChildSecond, keyChild) {
                                valueChildSecond.checkedItem = valeur;
                              });

                          });
                    }
                  

              });
        }

        if(type == "parent")
        {
            _.forEach(listFct, function(value, key) {
                    console.log("la valeur",value);
                    console.log("la clé",key);
        
                    value.checkedItem = valeur;

                    if(value.datasFonctionnaliteChildren)
                    {
                        _.forEach(value.datasFonctionnaliteChildren, function(valueChild, keyChild) {
                            valueChild.checkedItem = valeur;

                            _.forEach(valueChild.datasFonctionnaliteChildren, function(valueChildSecond, keyChild) {
                                valueChildSecond.checkedItem = valeur;
                              });

                          });
                    }
                  

              });
        }
       
    }

    cancelSearch()
    {
      this.itemToSearch = {};
      this.changeItermPerPage();
    }

    ngOnInit() {
        this.getData();
        this.getDataFonctionnalite();
        window.scrollTo(0, 0);
    }

}
