import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Api } from '../../../shared/utils/api/api';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UserService } from '../../../shared/user/user.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CommunicationService } from 'src/app/core/services/communication.service';

@Component({
  selector: 'app-main-mouvement',
  templateUrl: './main-mouvement.component.html',
  styleUrls: ['./main-mouvement.component.css']
})
export class MainMouvementComponent implements OnInit {
  @Input() id: any;
  adminUser: any;
  user: any;
  currentClient: any;
  ListeCooperatives: Array<any> = [];
  ListeMarchands: Array<any> = [];
  ListeRechargments: Array<any> = [];
  ListeClients: Array<any> = [];
  ListeMarchandsFilters: Array<any> = [];
  busyGetTenant: Subscription;
  busyGetSearchByPeriode: Subscription;
  busyGetMouvements: Subscription;
  itemStatistique: any;
  itemToSearch: any = {};
  currentMarchand: any = {};
  bsModalRef: BsModalRef;
  itemPerPagePagination = 5;
  totalItems = 0;
  itemsPerPage = 10;
  currentPage = 1;
  isMarchandView: boolean;
  isViewSa: boolean;
  tenancyStat: any = [];
  debitCreditStat: any = [];
  currentTenant: any = {
    code: 'CA'
  };

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  maxDate = new Date();
  established= false;
  itemsSize: number=0;
  busyGet: Subscription;
  ListeUtilisateurs: any;
  ListOperations = ['LIVRAISON','AVANCE','REGLEMENT','DON','PRE-FINANCEMENT','PROMO','PRIME']
  constructor(private userService: UserService, private api: Api, private notificationService: NotificationService, 
    private modalService: BsModalService, private communicationService: CommunicationService) {
    this.itemsPerPage = 10;
    this.currentPage = 1;
  }
  onSelectOperation(){
    console.log('operation selected',this.itemToSearch.sens);
    
  }
  changeValueItem(event: any) { }

  getDataClients(id?:any) {
    console.log('le this currentTenant', this.currentTenant);
    this.itemToSearch.clientSelected = null;

    if (!this.currentTenant || !this.currentTenant.code) { return }
    const request = {
      user: this.user.id,
      data: {
        tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
      }
    }
    console.log("Data sent to get clients tenancy",request);

    this.busyGetMouvements = this.api.post('clientTenancy/getByCriteria', request)
      .subscribe(
        res => {
          console.log("Clients tenancy",res);
          if (res && res['items']) {
            this.ListeClients = res['items'];
          } else {
            this.ListeClients = [];
          }
        });
  }

  getDataMarchand() {
    this.itemToSearch.marchandSelected = null;
    const request = {
      user: this.user.id,
      data: {
        tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
      },
    }
    this.busyGetMouvements = this.api.post('marchandTenancy/getByCriteria', request)
      .subscribe((res: any) => {
        console.log(res);
        if (res && res['items']) {
          this.ListeMarchandsFilters = res['items'];
        } else {
          this.ListeMarchandsFilters = [];
        }
      });
  }

  selectedClient(event) {
    console.log('event ', event);
    if (event && event.clientId) {
      this.currentClient = event.clientId;
    } else {
      this.currentClient = null;
    }
    this.getMouvement()
  }

  selectedMarchand(event) {
    console.log('event ', event);
    if (event && event.id) {
      this.currentMarchand = event;
    } else {
      this.currentMarchand = null;
    }
    this.getMouvement();
  }

  getMouvement() {
    const request = {
      user: this.user.id,
      data: {
        tenantId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        clientId: this.itemToSearch.clientId,
        sens:this.itemToSearch.sens ==''?'':this.itemToSearch.sens,
        createdAt: this.itemToSearch.start ? moment(this.itemToSearch.start).format('DD/MM/YYYY') : null,
        createdAtParam: {
          operator: "[]",
          start: this.itemToSearch.start ? moment(this.itemToSearch.start).format('DD/MM/YYYY') : null,
          end: this.itemToSearch.end ? moment(this.itemToSearch.end).format('DD/MM/YYYY') : null
        },
        createdBy: this.itemToSearch.userId
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }
    console.log("data sent to fetch mouvements",JSON.stringify(request))

    this.busyGetMouvements = this.api.post('mouvementClient/getByCriteria', request).subscribe(
        (res: any) => {
          this.ListeRechargments = [];
      console.log('Liste des mouvements ', res);
      if (res.hasError === false) {
        this.ListeRechargments = res.items;
        // if(this.id){
        //   this.ListeRechargments = this.ListeRechargments.filter(
        //     lr=> lr.clientId == this.id
        //   )
        // }
        this.itemsSize = res.count || 0;
        console.log("itemsSize",this.itemsSize);
        

      } else {
        this.ListeRechargments = [];
        this.itemsSize = 0;
      }
    });
  }

  exportData() {
    console.log('this.id',this.id);
    
    this.currentPage = 1;
    if (!this.currentTenant || (!this.currentTenant.code && !this.currentTenant.tenancyCode)) { return };
    const request = {
      user: this.user.id,
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null,
      data: {
        marchandId: null,
        tenantCode: (this.currentTenant && (this.currentTenant.code || this.currentTenant.tenancyCode)) ? (this.currentTenant.code || this.currentTenant.tenancyCode) : null,
        clientId: this.itemToSearch.clientId,
        tenantId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        sens:this.itemToSearch.sens,
        createdAt: this.itemToSearch.start ? moment(this.itemToSearch.start).format('DD/MM/YYYY') : null,
        createdAtParam: {
          operator: "[]",
          start: this.itemToSearch.start ? moment(this.itemToSearch.start).format('DD/MM/YYYY') : null,
          end: this.itemToSearch.end ? moment(this.itemToSearch.end).format('DD/MM/YYYY') : null
        },
        createdBy: this.itemToSearch.userId
      },
    }
    if (this.user.userTypeCode == 'MARCHAND_ADMIN' || this.user.userTypeCode == 'MARCHAND_USER') {
      request.data.marchandId = (this.user.datasMarchand && this.user.datasMarchand.length > 0) ? this.user.datasMarchand[0].id : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.createdAt = moment(this.itemToSearch.dateDebut).format('DD/MM/YYYY').toLocaleString().split(' ')[0] + ' 00:00:00';
      request.data.createdAtParam = {
        'operator': '[]',
        'start': moment(this.itemToSearch.dateDebut).format('DD/MM/YYYY').toLocaleString().split(' ')[0] + ' 00:00:00',
        'end': moment(this.itemToSearch.dateFin).format('DD/MM/YYYY').toLocaleString().split(' ')[0] + ' 23:59:59',
      }
    }
    console.log('data sent to server',request);
    
    this.busyGetMouvements = this.api.post('mouvementClient/exportData', request).subscribe((res: any) => {
      if (res.hasError === false) {
        if (res && res['filePathDoc']) {
          document.location.href = res ? res['filePathDoc'] : document.location.href;
          
          setTimeout(function () { 
            document.location.href = res ? res['filePathDoc'] : document.location.href;
           }, 1000);
        } else {
        }
      }
    });
  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getMouvement();
  }

  ngOnInit() {
    this.itemToSearch.sens = ''
    console.log("current client remote select", this.id);
    window.scrollTo(0, 0);
    this.user = this.userService.getCurrentUser();
    if (this.user.userTypeCode == 'MARCHAND_ADMIN' || this.user.userTypeCode == 'MARCHAND_USER') {
      this.isMarchandView = true;
    } else {
      this.isMarchandView = false;
    }
    if (this.user.userTypeCode == 'SA' || this.user.userTypeCode == 'ADMIN') {
      this.isViewSa = true;
    } else {
      this.isViewSa = false;
    }
    this.userService.currentData.subscribe(currentData => {
      if (currentData) {
        this.currentTenant = currentData;
        this.getMouvement()
        this.getDataClients();
        this.getDataMarchand();
        this.getTenancyStat();
        this.getUsers();
      } else {
        this.ListeRechargments = [];
        this.ListeClients = [];
      }
    });
  }

  getTenancyStat() {
    const request = {
      user: this.user.id,
      datas: [{
        code: this.currentTenant.code
      }]
    };
    this.api.post('tenancy/getMarchantClientByCooperative', request)
      .subscribe((res: any) => {
        console.log('getTenancyStat', res);
        if (res && res.items) {
          let [debit, credit] = [0, 0];
          let [debits, credits] = [[], []];
          if (res.items[0].datasMouvements && res.items[0].datasMouvements.legend){
            debits = res.items[0].datasMouvements.filter((p: any) => p.typeOperation === 'REGLEMENT');
            credits = res.items[0].datasMouvements.filter((p: any) => p.typeOperation !== 'REGLEMENT');
          }
          if (debits && debits.length){
            debits = debits.map((p: any) => {
              debit = debit + p.montantOperation;
              p.date = moment(p.dateOperation, 'DD/MM/YYYY HH:mm:ss').valueOf()
              return [p.date, p.montantOperation];
            });
          }
          if (credits && credits.length){
            credits = credits.map((p: any) => {
              credit = credit + p.montantOperation;
              p.date = moment(p.dateOperation, 'DD/MM/YYYY HH:mm:ss').valueOf()
              return [p.date, p.montantOperation];
            });
          }


          // this.drawTime(debits, credits);
          // this.drawPie(debit, credit);
        }
      });
  }

  getUsers() {
    var request = {
        user: this.user.id,
        data: {
            firstName: this.itemToSearch.searchValue || null,
            userFirstName:  null,
            marchandId: null,
            tenancyId: null

        },
        index: (this.currentPage - 1),
        size: this.itemsPerPage ? this.itemsPerPage : null
    }

    let endPoint = 'tenancyUser/getByCriteria';
    // let endPoint = 'user/getByCriteria';
    if (this.user.userTypeCode == 'TENANCY_ADMIN' || this.user.userTypeCode == 'TENANCY_USER') {
        endPoint = 'tenancyUser/getByCriteria';
        request.data.tenancyId = ((this.user.datasTenancy && this.user.datasTenancy.length > 0)) ? (this.user.datasTenancy[0].id) : null
        this.itemToSearch.marchandSelected = null;
        request.data.userFirstName = this.itemToSearch.searchValue || null;
    }
    this.busyGet = this.api.post(endPoint, request)
        .subscribe(
            (res: any) => {
                console.log('ListeUtilisateurs', res);
                if (res && res.items) {
                    this.ListeUtilisateurs = res['items'];
                } else {
                    this.ListeUtilisateurs = [];
                }
            },
            err => {
                console.log("Error occured", err);
                this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                // this.not
            }
        );

}

}
