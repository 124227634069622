<div class="col-md-2" style="max-height:100vh; overflow:auto" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
  <strong>Liste des agriculteurs</strong>
  <ul>
    <li class="client" *ngFor="let item of ListeClients" [class.isActive]="currentClient.clientId == item.clientId" (click)="onSelectClient(item)">
      {{item.clientNom}} {{item.clientPrenoms}}
    </li>
 
  </ul>
</div>
<div class="col-md-10" [ngBusy]="{busy: busyGetPointContour, message: 'Veuillez patienter ...', backdrop: false}"> 

  <div id="map" style="height: 600px; width: 100%;"> </div>
  
</div>

