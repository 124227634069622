<!-- MAIN CONTENT -->
<div id="content">

    <div class="row" [ngBusy]="busyGet">

          <div class="col-md-12" style="padding: 10px">
              <div class="col-md-12 custom-cadre">

                  <!--img src="assets/img/decoupes/icone-table.png" style="float: right"-->
                  <div class="form-group col-md-12 cadre-title-page">
                    <b class="title-page">Informations personnelles</b>

                  </div>

                  <div class="form-group col-md-12" style="padding-right: 0">

                      <form name="frmSave">
       
                           <div class="col-md-12">
                             <div class="row">
                                  <div class="form-group col-lg-12">
                                     <div class="col-md-12">
                                       
                                       <div class="col-md-8" style="padding:0">
                                           <div class="form-group col-md-6">
                                               <label>Nom : <span class="required_champs">*</span></label>
                                               <input class="form-control custom_input" [(ngModel)]="itemToSave.nom" name="nom" required placeholder="Nom" type="text">
                                         </div>
       
                                         <div class="form-group col-md-6">
                                             <label>Prénoms : <span class="required_champs">*</span></label>
                                             <input class="form-control custom_input" [(ngModel)]="itemToSave.prenom" name="prenom" placeholder="Prénoms" required type="text">
                                         </div>
       
                                         <div class="form-group col-md-6">
                                             <label>Login : <span class="required_champs">*</span></label>
                                             <input class="form-control custom_input" [(ngModel)]="itemToSave.login" name="login" readonly placeholder="Login" type="text">
                                         </div>
       
                                         <div class="form-group col-md-6" *ngIf="!itemToSave.id">
                                             <label>Mot de passe : <span class="required_champs">*</span></label>
                                             <input class="form-control custom_input" [(ngModel)]="itemToSave.password" name="password" required placeholder="Mot de passe" type="password">
                                         </div>
       
                                         <div class="form-group col-md-6">
                                             <label>Email : <span class="required_champs">*</span></label>
                                             <input class="form-control custom_input" [(ngModel)]="itemToSave.email" name="email" required placeholder="Email" type="email">
                                         </div>
       
                                         <div class="form-group col-md-6">
                                             <label>Contact : <span class="required_champs">*</span></label>
                                             <input class="form-control custom_input" [(ngModel)]="itemToSave.contact" name="contact" mask="00-00-00-00" placeholder="Contact" required type="tel">
                                         </div>
       
                                       </div>
       
                                       <div class="col-md-4" style="padding:0">
                                           <div class="form-group col-md-12" style="text-align: center">
                                             <img src="{{imageDisplay || 'assets/img/decoupes/user_default.png'}}" style="width: 100px;height: 110px;border:1px solid #eceeee" />
                                           </div>
       
                                           <div class="form-group col-md-12" style="text-align: center">
                                               <input type="file" name="file" (change)="uploadFile($event,itemToSave,'fileNameAlerte')"> 
                                            </div>
                                       </div>
       
                                     </div>
                                  </div>
       
                             </div>
                                    
                                  
                           </div>
                              <div class="col-md-12" [ngBusy]="busySave">
                                      <b class="pull-left">(<span class="required_champs">*</span>) Champs requis &nbsp;&nbsp;&nbsp;&nbsp;</b>
                                             <button  type="submit" class="btn btn-md btn-primary btn-bg-new " (click)="saveItem(itemToSave)">
                                                      Sauvegarder
                                             </button>
                                              <button  type="button" class="btn btn-md btn-default " (click)="cancelSave()">
                                                      Annuler
                                             </button>
                                          
                                  </div>
                             </form>
                  </div>
           </div>

    </div>
</div>
