import {Component, OnInit, TemplateRef} from '@angular/core';
import {Subscription} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";
import * as _ from 'lodash';
import { CommunicationService } from 'src/app/core/services/communication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Api } from 'src/app/shared/utils/api/api';
import { NotificationService } from 'src/app/shared/utils/notification.service';
import { UserService } from 'src/app/shared/user';
import { UtilitiesService } from 'src/app/shared/utils/utilities.service';
import { HttpHeaders } from '@angular/common/http';
declare const Swal: any;
@Component({
  selector: 'app-manage-prefinancement',
  templateUrl: './manage-prefinancement.component.html',
  styleUrls: ['./manage-prefinancement.component.css']
})
export class ManagePrefinancementComponent implements OnInit {

  resultDelaiPaiements: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  itemToSave2: any;
  totalItems = 0;
  currentPage = 1;

  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  currentTenant: any = {};
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination = 10;
  admin: any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  listDonations= [];
  listTypeDon = [];
  ListeTenants= [];
  listDonateurs= [];
  private modalRefDonator: BsModalRef;


  constructor(private api: Api, private modalService: BsModalService, 
    private notificationService: NotificationService, private utilities: UtilitiesService, 
    private userService: UserService,private communicationService:CommunicationService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.itemToSearch = {};
  }

  // MULTI SELECT


  hideModal() {
    this.modalRef.hide();
  }
  hideModal2() {
    this.modalRefDonator.hide();
  }

  openFormModalModal(template, itemToModified?: any) {
    this.cancelSave();
    if (itemToModified) {
      this.itemToSave = { ...itemToModified };
    }
    this.modalRef = this.modalService.show(template, { class: 'gray modal-md' });
  }


  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }


  getData() {
    // if (!this.currentTenant || !this.currentTenant.code) {return};
    let data: any;
    data = {
      typeOperationCode : "CREDIT"
    };

    const request = {
      user: this.user.id,
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null,
      data
    }
    console.log('data sent to get donations list', request)
    this.busyGet = this.api.post('operation/getByCriteria', request)
        .subscribe((res: any) => {
              console.log('listUnfiltered operations', res);
              if (res && res['items']) {
                this.listDonations = res['items'];
                this.totalItems = this.listDonations.length;

              } else {
                this.listDonations = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }

  confirmSaveItem(obj: any) {
    if (!obj) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner les champs obligatoires.');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.donateurId) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner la micro-finance svp!.');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.libelle) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner le libellé svp!.');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.description) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner la description svp!.');
      return;
    }
    if (!this.itemToSave.valeurOperation) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner la valeur svp!.');
      return;
    }
    obj.modeleDevice = 'WEB';
    obj.typeCode = 'MIXTE';
    Swal.fire({
      title: !obj.id ? 'Enregistrement de crédit' : 'Modification crédit',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.saveItem(obj);
      } else {
        console.log('bnjr');
      }
    })
  }



  confirmDeleteItem(obj: any) {
    Swal.fire({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.deleteItem(obj);
      } else {
        console.log('bnjr');
      }
    })

  }


  cancelSave() {
    this.itemToSave = {};
  }
  cancelSave2() {
    this.itemToSave.nom = '';
    this.itemToSave.contact = '';
    this.itemToSave.email = '';
  }


  saveItem(item: any) {
    // if (!this.currentTenant || !this.currentTenant.code) {return};
    item.tenancyId =  (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null;
    item.nature = 1
    const request = {
      user: this.user.id,
      isForDon: false,
      isForPromo: false,
      isForCredited: true,
      datas: [
        {
          ...item
        }]
    }
    console.log('data sent to create donation', request)
    this.busySave = this.api.post('operation/' + (item.id ? 'update' : 'create'), request)
        .subscribe((res: any) => {
              console.log('resul', res);
              if (!res['hasError'] && res['items']) {
                this.notificationService.showMessageSucces('Succès', 'Opération effectuée avec succès.');
                this.currentPage = 1;
                this.hideModal();
                this.cancelSave();
                this.getData();
                this.communicationService.sendData("Refresh list")

              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Erreur', this.utilities.messageInterpretor(err));
            });
  }


  deleteItem(obj: any) {
    const request = {
      user: this.user.id,
      datas: [obj]
    }
    this.busyGet = this.api.post('operation/delete', request)
        .subscribe(
            res => {
              console.log(res);
              if (!res['hasError']) {
                this.notificationService.showMessageSucces('Succès', 'Suppression effectuée avec succès');
                this.currentPage = 1;
                this.getData();
                this.communicationService.sendData("Refresh list")
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            }
        );
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }
  getTypeDon() {
    const request = {
      user: this.user.id,
      data: {},
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }
    this.busyGet = this.api.post('typeDon/getByCriteria', request)
        .subscribe((res: any) => {
              console.log('listTypeDon', res);
              // debugger
              if (res && res['items']) {
                this.listTypeDon = res['items'];
                console.log('this.listTypeDon', this.listTypeDon)

              } else {
                this.listTypeDon = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }
  getTenancy() {
    const request = {
      user: this.user.id,
      data: {
        raisonSociale: this.itemToSearch.searchValue || null
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }


    this.busyGet = this.api.post('tenancy/getByCriteria', request)
        .subscribe(
            res => {
              console.log('liste coopératives: ', res);
              if (res && res['items']) {
                this.ListeTenants = res['items'];
                console.log('ListeTenants', this.ListeTenants)
              } else {
                this.ListeTenants = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
              // this.not
            }
        );

  }
  getDonator() {

    const request = {
      user: this.user.id,
      data: {},
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }
    this.busyGet = this.api.post('donateur/getByCriteria', request)
        .subscribe((res: any) => {
              console.log('listDonateurs', res);
              if (res && res['items']) {
                this.listDonateurs = res['items'];
              } else {
                this.listDonateurs = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }

  quickAddDonator(templateCreateDonator: TemplateRef<any>) {
    this.modalRefDonator = this.modalService.show(templateCreateDonator, { class: 'gray modal-md' });


  }

  confirmSaveItemDonator(itemToSave: any) {
    console.log('itemtosave',itemToSave)
    if (!itemToSave) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner les champs obligatoires.');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.nom) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner le nom svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.contact) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner le contact svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.email) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner l\'adresse email svp!');
      return;
    }
    Swal.fire({
      title: !itemToSave.id ? 'Enregistrement de micro-finance' : 'Modification micro-finance',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.saveItemDonator(itemToSave);
      } else {
        console.log('bnjr');
      }
    })
  }

  private saveItemDonator(item: any) {
    if (!this.currentTenant || !this.currentTenant.code) {return};
    const options = {
      headers: new HttpHeaders({
        'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004',
        lang: 'fr'
      })
    }
    const request = {
      user: this.user.id,
      datas: [{ ...item, tenancyId: this.currentTenant.id }]
    }
    console.log('create return', request)
    this.busySave = this.api.postOption('donateur/' + (item.id ? 'create' : 'create'), request, options)
        .subscribe((res: any) => {
              console.log('resul', res);
              if (!res['hasError'] && res['items']) {
                this.notificationService.showMessageSucces('Tenan', 'Opération effectuée avec succès.');
                this.currentPage = 1;
                this.hideModal2();
                this.cancelSave2();
                this.getDonator();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Erreur', this.utilities.messageInterpretor(err));
            });
  }

  selectType() {
    console.log('itemtosave',this.itemToSave)
    console.log('nature',this.itemToSave.nature)

    let type = this.listTypeDon.filter(
        ftd => ftd.id= this.itemToSave.nature
    )[0].id
    console.log('selected type',type)

  }
  
onChangeStatus(event,item){
  console.log('event', event);
  console.log('item', item);
  Swal.fire({
      title: event ? 'Validation de préfinancement' : 'Invalidation de préfinancement',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
      allowOutsideClick:false
  }).then((result) => {
      if (result.value) {
          this.validerOperation(item);
      } else {
          
          console.log('cancelled');
      }
  }
  , (dismiss)=>{
      if(dismiss == 'cancel'){
         //Swal.fire({..}); //un-comment this line to add another sweet alert popup on cancel
         console.log('cancelled successfully');
         item.isValidated = !event
      }
   }
  )
  
}
validerOperation(oper){
  const request = {
      user: this.user.id,
      datas: [
           {
              id:oper.id
           }

      ]
  }

  console.log('data sent to validate op', request);
  console.log('data sent to validate op', JSON.stringify(request));

  this.busyGet = this.api.post('operation/validateOperation', request)
      .subscribe(
          res => {
              console.log('resul', res);
              if (!res['hasError'] && res['items']) {
                  this.notificationService.showMessageSucces('Enregistrement', 'Opération effectuée avec succès.');
                  this.currentPage = 1;
                  this.cancelSave();
                  this.getData();
                  this.communicationService.sendData("Refresh list")

              } else {
                  if (res['status'] && res['status']['message']) {
                      this.notificationService.showMessageError('Erreur', res['status']['message']);
                  }
              }
          },
          err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Erreur', this.utilities.messageInterpretor(err));
          }
      );

  
}
  ngOnInit() {
    this.getTypeDon();
    this.getTenancy();
    this.getDonator();
    this.userService.currentData.subscribe(currentData => {
      this.currentTenant = currentData;
      console.log('current tenant: ', this.currentTenant)
      setTimeout(()=>{
        this.getData();
      },2000)
    });
    window.scrollTo(0, 0);
  }
}
