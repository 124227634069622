import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import * as _ from 'lodash';
@Injectable()
export class CommunicationService {
    tabs: ITab[] = [];
    tabOptions: ITab[] = [];
    public subject = new Subject<any>();
    public data = new Subject<any>();
    public sharedValue: any;

    sendData(req: any){
        console.log('data', req);
    }

    getRequest(): Observable<any> {
        return this.subject.asObservable();
    }

    getData(): Observable<any> {
        return this.data.asObservable();
    }

    deSouscrire() {
        this.subject.unsubscribe();
    }

}

export interface ITab {
    // id: any;
    title: string;
    url: string;
}
