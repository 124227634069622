import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { Api } from '../../../shared/utils/api/api';
import { NotificationService } from '../../../shared/utils/notification.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from '../../../shared/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CommunicationService } from 'src/app/core/services/communication.service';


@Component({
  selector: 'app-fiche-production-client',
  templateUrl: './fiche-production-client.component.html',
  styleUrls: ['./fiche-production-client.component.css']
})
export class FicheProductionClientComponent implements OnInit {
  id = 0;
  currentClient: any = {};
  user: any = {};
  busyGet: Subscription;
  itemToSearch: any = {};
  ListeRechargments: Array<any> = [];
  totalItems: number;
  currentTenant: any = {};
  currentPage = 1;
  itemsPerPage = 10;
  itemPerPagePagination = 10;
  busyGetMouvements: Subscription;
  itemsSize: any;
  objectifs: any[] = [];

  constructor(public router: Router, private route: ActivatedRoute, private api: Api, private notificationService: NotificationService, private utilities: UtilitiesService,
     private modelService: ModelService, private userService: UserService, private communicationService:CommunicationService) {
    this.user = this.userService.getCurrentUser();
    this.route.params.subscribe(params => {
      console.log(params);
      if (params['idClient']) {
        console.log('le client ', params['idClient']);
        this.id = params['idClient'].split('_')[1]
        this.getDataClient(params['idClient'].split('_')[1]);
        this.getMouvement(params['idClient'].split('_')[1]);
        this.getClientObjectif(this.id);
      } else {
        this.currentClient = {}
      }
    });

  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getMouvement(this.id);
  }

  getDataClient(idClient) {
    const request = {
      user: this.user.id,
      data: {
        id: idClient
      }
    }
    this.busyGet = this.api.post('client/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.currentClient = res['items'][0];
            console.log('current client: ',this.currentClient);
            this.communicationService.sendData(this.currentClient)
          } else {
            this.currentClient = {};
          }
        },
        err => {
        }
      );
  }

  getClientObjectif(idClient) {
    const request = {
      user: this.user.id,
      data: {
        clientId: idClient
      }
    }
    this.busyGet = this.api.post('clientObjectif/getByCriteria', request)
      .subscribe(
        (res: any) => {
          console.log("getClientObjectif",  res);
          if (res && res['items']) {
            this.objectifs = (res.items || []).map((data: any) => {
              data.cumulQuantite = data.cumulQuantite ? data.cumulQuantite : 0;
              // if (data.estimation < data.cumulQuantite) {
              //   data.taux = 100;
              //   return data;
              // }
              data.taux = data.estimation ? Math.round((data.cumulQuantite * 100) / data.estimation) : null; 
              return data;
            });
            this.objectifs = _.orderBy(this.objectifs, ['annee'], ['asc']);
          } 
        }
      );
  }


  getMouvementTenant(idClient) {
    console.log('tenant');
    if (!idClient) {return};
    let request = {
      user: this.user.id,
      data: {
        tenantCode: this.currentTenant.code || null,
        createdAt: null,
        createdAtParam: null,
        clientId: idClient || null,
        sens: 'RECHARGEMENT'
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }


    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.createdAt = moment(this.itemToSearch.dateDebut).format('DD/MM/YYYY').toLocaleString().split(' ')[0] + ' 00:00:00';
      request.data.createdAtParam =
      {
        'operator': '[]',
        'start': moment(this.itemToSearch.dateDebut).format('DD/MM/YYYY').toLocaleString().split(' ')[0] + ' 00:00:00',
        'end': moment(this.itemToSearch.dateFin).format('DD/MM/YYYY').toLocaleString().split(' ')[0] + ' 23:59:59',
      }
    }
    this.busyGet = this.api.post('mouvementClient/getByCriteria', request).subscribe((res: any) => {
      console.log('le retour', res);
      if (res.hasError === false) {
        this.ListeRechargments = res.items;
        this.totalItems = res['count'] || 0;
      } else {
        this.ListeRechargments = [];
        this.totalItems = 0;
      }
    });
  }


  exportData() {
    const request = {
      user: this.user.id,
      data: {
        clientId: this.currentClient.id
      }
    }
    this.busyGet = this.api.post('mouvementClient/exportMouvementClient', request)
      .subscribe((res: any) => {
        console.log('exportData', res);
        if (res && res['filePathDoc']) {
          window.location.href = res ? res['filePathDoc'] : window.location.href;
        }
        else{
          this.notificationService.showMessageError('Erreur', res.status.message);
        }
      });
  }


  getMouvement(clientId?: any) {
    const request = {
      user: this.user.id,
      data: {
        clientId: clientId,
        tenantId: JSON.parse(sessionStorage.getItem("currentTenancyId"))
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }
    console.log("data sent to fetch mouvements",JSON.stringify(request))
    
    this.busyGetMouvements = this.api.post('mouvementClient/getByCriteria', request).subscribe(
        (res: any) => {
          this.ListeRechargments = [];
      console.log('Liste des mouvements ', res);
      if (res.hasError === false) {
        this.ListeRechargments = res.items;
        // if(this.id){
        //   this.ListeRechargments = this.ListeRechargments.filter(
        //     lr=> lr.clientId == this.id
        //   )
        // }
        this.itemsSize = res.count || 0;
        console.log("itemsSize",this.itemsSize);
        

      } else {
        this.ListeRechargments = [];
        this.itemsSize = 0;
      }
    });
  }
  round(nbr){
    if(nbr)
      return Number(nbr).toFixed(2)
  }
  phoneNumberSeparator(str){
    if(str)
        return [str.slice(0, 2), " ", str.slice(2,4), " ", str.slice(4,6), " ", str.slice(6,8)].join('');
}
  ngOnInit() {
    this.userService.currentData.subscribe(currentData => {
      if (currentData) {
        this.currentTenant = currentData;
        // this.getMouvementTenant(this.currentClient.id);
      } else {
        this.ListeRechargments = [];
      }
    });
  }

}
