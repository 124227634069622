<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">

    <div class="custom-cadre"> 
        <!-- fil d actualite -->
        <div class="row">
            <div class="form-group col-md-12">
                <div class="col-md-12">
                    <h1 class="custom-title-bold">UTILISATEURS </h1>
                    <br/>

                    <button class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button" (click)="openFormModalModal(templateCreate)">
                        Ajouter </button>

                    <!-- <button class="btn btn-md custom_border_radius btn-import btn-bg-default padding-30" type="button" (click)="exportData()">
                        Exporter </button> -->

                    <br/>
                    <br/>

                    <div class="col-md-12 no-padding" style="margin-top: 10px">
                        <table class="table table-condensed table-striped">
                            <thead>

                                <tr>
                                    <th colspan="8" style="background-color: #fff;padding: 0px !important">
                                        <div class="form-group col-md-4 no-padding"> 
                                            <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSearch.searchValue" name="search" required
                                                placeholder="Rechercher par nom" (keyup)="getData(itemToSearch.marchandSelected ? true : false,itemToSearch.cooperativeSelected ? true : false, (itemToSearch.cooperativeSelected ? itemToSearch.cooperativeSelected : (itemToSearch.marchandSelected ? itemToSearch.marchandSelected : null))  )" type="text">
                                        </div>


                                        <div class="form-group col-md-4" *ngIf="user?.userTypeCode == 'SA' || user?.userTypeCode == 'ADMIN' ">

                                            <ng-select name="cooperativeselected" [(ngModel)]="itemToSearch.cooperativeSelected" (change)='getData(false,true,itemToSearch.cooperativeSelected)' placeholder="Rechercher par coopérative"
                                                id="cooperativeSelected" [ngModelOptions]="{standalone: true}">
                                                <ng-option *ngFor="let clt of ListeCooperativeFilters" [value]="clt">{{(clt?.raisonSociale)}}</ng-option>
                                            </ng-select>

                                        </div>
<!-- 
                                        <div class="form-group col-md-4" *ngIf="user?.userTypeCode == 'SA' || user?.userTypeCode == 'ADMIN' ">

                                            <ng-select [(ngModel)]="itemToSearch.marchandSelected" (change)='getData(true,false,itemToSearch.marchandSelected)' placeholder="Rechercher par un marchand"
                                                id="marchand" [ngModelOptions]="{standalone: true}">
                                                <ng-option *ngFor="let clt of ListeMarchandsFilters" [value]="clt">{{(clt?.marchandRaisonSociale)}}</ng-option>
                                            </ng-select>

                                        </div> -->
                                    </th>
                                </tr>

                                <tr class="custom-table-thead">
                                    <th style="width: 45px">
                                        <a (click)="getData()"> <i
                                          class="fa fa-refresh"></i> </a>
                                      </th>
                                    <th>Login </th>
                                    <!-- <th>Type utilisateur</th> -->
                                    <th>Nom et prénoms</th>
                                    <th>Email</th>
                                    <th>Coopérative</th>
                                    <th>Télephone</th>
                                    <th>Statut</th>
                                    <th>Rôle</th>
                                    <th style="text-align: center; width: 75px"> Action </th>
                                    <th>
                                        <span class="fa fa-lock"></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of ListeUtilisateurs; let i = index">
                                    <td class="vertical-align-uppercase">
                                        {{i + 1}}
                                    </td>
                                    <td class="vertical-align">{{item.login || item.userLogin}}</td>
                                    <!-- <td class="vertical-align-uppercase">{{ item.userTypeLibelle || item.typeUserLibelle }}</td>  -->
                                    <td class="vertical-align-uppercase">{{item.firstName || item.userFirstName}} {{item.lastName || item.userLastName}}</td>
                                    <td class="vertical-align">{{item.email || item.userEmail}}</td>
                                    <td class="vertical-align">{{item.tenancyRaisonSociale}}</td>
                                    <td class="vertical-align-uppercase">{{phoneNumberSeparator(item.telephone) || phoneNumberSeparator(item.userTelephone)}}</td>
                                    <td class="vertical-align-uppercase">{{item.statutLibelle || item.userstatutLibelle}}</td>
                                    <td class="vertical-align-uppercase">{{item.roleLibelle}}</td>
                                    <td style="text-align: center">
                                        <a title="Modifier" (click)="openFormModalModal(templateCreate,item)">
                                            <i class="fa fa-edit" style="color: green"> </i>
                                        </a>
                                        &nbsp;&nbsp;
                                        <!-- <a>
                                            <i class="fa fa-trash-o" style="color: red"> </i>
                                        </a> -->
                                    </td>
                                    <td style="text-align: center">
                                        <ui-switch ngDefaultControl color="red" tooltip="Activé / Désactivée" size="small" [(ngModel)]="item.isLocked" (change)="onChangeLock($event,item)"></ui-switch>
                                    </td>
                                </tr>

                                <tr *ngIf="ListeUtilisateurs && ListeUtilisateurs.length > 0">
                                    <td colspan="10">
                                        <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
                                            <pagination [boundaryLinks]="true" [totalItems]="totalItems" [maxSize]='itemPerPagePagination' [itemsPerPage]="itemsPerPage"
                                                [(ngModel)]="currentPage" class="pagination-sm" previousText="&laquo;" nextText=" &raquo;"
                                                firstText="&laquo;" lastText="&raquo;" name="pagination" (pageChanged)="pageChanged($event)"></pagination>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="!ListeUtilisateurs || ListeUtilisateurs.length == 0">
                                <tr>
                                    <td colspan="9" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
                                </tr>

                            </tfoot>

                        </table>
                    </div>
                </div>


            </div>
        </div>
        <!-- fin file d actualite -->

    </div>



    <ng-template #templateCreate>
        <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
            <div class="modal-header custom-modal-header">
                <h4 class="modal-title pull-left">
                    UTILISATEUR
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-lg-12">

                        <div class="row">
                            <div class="col-md-12">

                                <div class="form-group col-md-6">
                                    <label>Nom :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.firstName" name="firstName" required
                                        placeholder="Nom" type="text">
                                </div>

                                <div class="form-group col-md-6">
                                    <label>Prénoms :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.lastName" name="lastName" placeholder="Prénoms"
                                        required type="text">
                                </div>

                                <div class="form-group col-md-6">
                                    <label>Email :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.email" name="email" placeholder="Email"
                                        required type="email">
                                </div>

                                <div class="form-group col-md-6">
                                    <label>Téléphone :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" maxlength="20" autocomplete="off" [(ngModel)]="itemToSave.telephone" name="telephone"
                                        placeholder="Télephone" mask="00-00-00-00-00" required type="text">
                                </div>

                                <div class="form-group col-md-6">
                                    <label>Login :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" [disabled]='itemToSave.id' autocomplete="off" [(ngModel)]="itemToSave.login" name="login"
                                        placeholder="Login" required type="text">
                                </div>

                                <div class="form-group col-md-6">
                                    <label> Type :
                                        <span class="required_champs">*</span>
                                    </label>

                                    <select class="form-control custom_input" id="roleSelected" [(ngModel)]="itemToSave.roleSelected" #ctrl="ngModel" name="roleSelected">
                                        <option value="">-- Choisissez --</option>
                                        <option *ngFor="let niv of ListeRoles " [ngValue]="niv">
                                            {{ niv.libelle === 'ADMIN TENANCY' ? 'ADMINISTRATEUR' : (niv.libelle === 'COOPERATIVE' ? 'OPERATEUR': niv.libelle) }}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>


            </div>
            <div class="modal-footer modal-footer-custom">
                <b class="pull-left">(
                    <span class="required_champs">*</span>) Champs requis</b>

                <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal()">
                    Annuler
                </button>

                <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItem(itemToSave) ">
                    Valider
                </button>

            </div>
        </form>
    </ng-template>



</div>