import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgModule} from "@angular/core";
import {CollapseMenuComponent} from "./collapse-menu/collapse-menu.component";
import {RecentProjectsComponent} from "./recent-projects/recent-projects.component";
import {FullScreenComponent} from "./full-screen/full-screen.component";
import {HeaderComponent} from "./header.component";

import {UtilsModule} from "../../utils/utils.module";
import {UserModule} from "../../user/user.module";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {NgBusyModule} from 'ng-busy';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule,
    UtilsModule, UserModule,
    NgBusyModule
  ],
  declarations: [
    RecentProjectsComponent,
    FullScreenComponent,
    CollapseMenuComponent,
    HeaderComponent,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule{}
