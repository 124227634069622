import {Component, OnInit} from '@angular/core';
import {UserService} from "../user.service";
import {LayoutService} from "../../layout/layout.service";
import {ActivatedRoute, Route, Router} from '@angular/router';

declare const Swal: any;

@Component({

  selector: 'sa-login-info',
  templateUrl: './login-info.component.html',
})
export class LoginInfoComponent implements OnInit {

  user:any;

  constructor(
    private userService: UserService,
              private layoutService: LayoutService,public router:Router) {
  }

  logout()
  {
    Swal.fire({
      title: 'Déconnection',
        text: 'Voulez-vous déconnecter de l\'application ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#73B973",
        cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
        if (result.value) {
          sessionStorage.removeItem("yz-backoffice-app-smart-carte");
          this.router.navigate(['/auth/login']);
        }
    })

  }

  ngOnInit() {

    this.user = this.userService.getCurrentUser();
  }

  toggleShortcut() {
    this.layoutService.onShortcutToggle()
  }

}
