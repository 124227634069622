import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import { enumCodeTypeUtilisateur, enumLibelleRole } from '../../../shared/utils/enumerator';
declare const Swal: any;
 

@Component({
    selector: 'app-main-utilisateur',
    templateUrl: './main-utilisateur.component.html',
    styleUrls: ['./main-utilisateur.component.css']
})
export class MainUtilisateurComponent implements OnInit {


    ListeUtilisateurs: Array<any> = [];
    ListeTypesUtilisateurs: Array<any> = [];
    ListeMarchandsFilters: Array<any> = [];
    ListeCooperativeFilters: Array<any> = [];
    ListeRoles: Array<any> = [];
    busyGet: Subscription;
    busySave: Subscription;
    itemToSave: any;
    totalItems = 0;
    currentPage = 1;
    currentWebApi = 'societe';
    user: any;
    itemsPerPage: any;
    currentItemChecked: any;
    statutCotisation: any;
    itemToSearch: any;
    modalRef: BsModalRef;
    bsModalRef: BsModalRef;
    currentItemImage: any;
    imageDisplay: any;
    typeUtilisateurSelected: any = {};
    itemPerPagePagination: number = 10;


    config = {
        animated: true,
        keyboard: false,
        backdrop: 'static',
        ignoreBackdropClick: true
    };


    constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService) {
        this.cancelSave();
        this.user = this.userService.getCurrentUser();
        this.itemsPerPage = 10;
        this.currentItemChecked = {};
        this.itemToSearch = {};
        // this.statutCotisation = enumStatutCotisation;

    }


    hideModal() {
        this.modalRef.hide();
    }


    openFormModalModal(template, itemToModified?: any) {
 
        this.cancelSave();

        if (itemToModified) {
            console.log("le item to save ", itemToModified);
            if (this.user.userTypeCode == 'TENANCY_ADMIN' || this.user.userTypeCode == 'TENANCY_USER' || this.user.userTypeCode == 'MARCHAND_ADMIN' || this.user.userTypeCode == 'MARCHAND_USER') {

                itemToModified.userTypeId = itemToModified.typeUserId;
                itemToModified.firstName = itemToModified.userFirstName;
                itemToModified.email = itemToModified.userEmail;
                itemToModified.telephone = itemToModified.userTelephone;
                itemToModified.login = itemToModified.userLogin;
                itemToModified.id = itemToModified.userId;
                itemToModified.lastName = itemToModified.userLastName;
            }

            itemToModified.roleSelected = _.find(this.ListeRoles, { 'id': itemToModified.roleId });
            itemToModified.typeUtilisateurSelected = _.find(this.ListeTypesUtilisateurs, { 'id': itemToModified.userTypeId });

            if (!itemToModified.roleSelected) {
                itemToModified.isHideRole = true;
            }
            this.itemToSave = { ...itemToModified };
        }
        this.modalRef = this.modalService.show(template, { class: 'gray modal-lg' });
    }


    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
        this.currentPage = event.page;
        this.getData();
    }

    changeItermPerPage() {
        this.currentPage = 1;
        this.getData();
    }


    onChangeLock(event,item){
        console.log('event', event);
        console.log('item', item);
        Swal.fire({
            title: 'Confirmation',
            text: item.isLocked ? 'Voulez-vous activer cet utilisateur ?' : 'Voulez vous désactivation cet utilisateur ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonColor: '#00A984',
            cancelButtonColor: '#333333',
            allowOutsideClick:false
        }).then((result) => {
            if (result.value) {
                this.lockUser(item);
            } else {
                
                console.log('cancelled');
            }
        }
        , (dismiss)=>{
            if(dismiss == 'cancel'){
               console.log('cancelled successfully');
               item.isValidated = !event
            }
            else {
                throw dismiss;
              }
         }
        )
    }

    lockUser(u: any){
        const request = {
            user: this.user.id,
            datas: [{id:u.userId}]
        }
        const method = u.isLocked === true ? 'lock' : 'unlock';
        console.log('data sent to lock user', request);
        this.busyGet = this.api.post(`user/${method}`, request)
            .subscribe(
                (res: any) => {
                    console.log('resul', res);
                    if (res.hasError === false) {
                        this.notificationService.showMessageSucces('Enregistrement', 'Opération effectuée avec succès.');
                        this.currentPage = 1;
                        this.cancelSave();
                        // this.getData();
                    } else {
                        // this.getData();
                        if (res.status && res.status.message) {
                            this.notificationService.showMessageError('Erreur', res.status.message);
                        }
                    }
                    this.getData();
                },
                err => {
                    console.log('Error occured', err);
                    this.notificationService.showMessageError('Erreur', this.utilities.messageInterpretor(err));
                }
            );
    }

    getData(isSearchMarchand?: boolean, isSearchCooperative?: boolean, itemFiltre?: any) {
        var request = {
            user: this.user.id,
            data: {
                firstName: this.itemToSearch.searchValue || null,
                userFirstName:  null,
                marchandId: null,
                tenancyId: null

            },
            index: (this.currentPage - 1),
            size: this.itemsPerPage ? this.itemsPerPage : null
        }

        let endPoint = 'user/getByCriteria';
 
        if (this.user.userTypeCode == 'MARCHAND_ADMIN' || this.user.userTypeCode == 'MARCHAND_USER' || isSearchMarchand) {
            endPoint = 'marchandUser/getByCriteria';
            // request.data.marchandId = (itemFiltre && itemFiltre.marchandId) ? itemFiltre.marchandId : null;
            if((itemFiltre && itemFiltre.marchandId))
            {
                request.data.marchandId = (itemFiltre && itemFiltre.marchandId) ? itemFiltre.marchandId : null;
            }
            else
            {
                request.data.marchandId = ((this.user.datasMarchand && this.user.datasMarchand.length > 0)) ? (this.user.datasMarchand[0].id) : null
           
            }
            request.data.userFirstName = this.itemToSearch.searchValue || null;

            this.itemToSearch.cooperativeSelected = null; 
        }

        if (this.user.userTypeCode == 'TENANCY_ADMIN' || this.user.userTypeCode == 'TENANCY_USER' || isSearchCooperative) {
            endPoint = 'tenancyUser/getByCriteria';
            console.log("le item du filtre ",itemFiltre);
            if((itemFiltre && itemFiltre.id))
            {
                request.data.tenancyId = (itemFiltre && itemFiltre.id) ? itemFiltre.id : null
            }
            else
            {
                request.data.tenancyId = ((this.user.datasTenancy && this.user.datasTenancy.length > 0)) ? (this.user.datasTenancy[0].id) : null
            }
            
            this.itemToSearch.marchandSelected = null;
            request.data.userFirstName = this.itemToSearch.searchValue || null;
        }

        this.busyGet = this.api.post(endPoint, request)
            .subscribe(
                res => {
                    console.log(res);
                    if (res && res['items']) {
                        this.ListeUtilisateurs = res['items'];
                        this.totalItems = res['count'] ? res['count'] : 0;
                    } else {
                        this.ListeUtilisateurs = [];
                    }
                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                    // this.not
                }
            );

    }

    exportData() {
        var request = {
            user: this.user.id,
            data: {
                firstName: this.itemToSearch.searchValue || null,
                marchandId: null,
                tenancyId: null

            },
            // index: (this.currentPage - 1),
            // size: this.itemsPerPage ? this.itemsPerPage : null
        }

        let endPoint = 'user/exportUser';

        if (this.user.userTypeCode == 'MARCHAND_ADMIN' || this.user.userTypeCode == 'MARCHAND_USER') {
            endPoint = 'marchandUser/exportUser';
            request.data.marchandId = (this.user.datasMarchand && this.user.datasMarchand.length > 0) ? this.user.datasMarchand[0].id : null
        }

        if (this.user.userTypeCode == 'TENANCY_ADMIN' || this.user.userTypeCode == 'TENANCY_USER') {
            endPoint = 'tenancyUser/exportUser';
            request.data.tenancyId = (this.user.datasTenancy && this.user.datasTenancy.length > 0) ? this.user.datasTenancy[0].id : null
        }



        this.busyGet = this.api.post(endPoint, request)
            .subscribe(
                res => {
                    console.log(res);
                    if (res && res['filePathDoc']) {
                        window.location.href = res ? res['filePathDoc'] : window.location.href;
                    } else {
                    }
                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                    // this.not
                }
            );

    }

    getDataRole() {
        var request = {
            user: this.user.id,
            data: {
            }
        }

        this.api.post('role/getByCriteria', request)
            .subscribe(
                res => {
                    console.log('les roles', res);
                    if (res && res['items']) {
                        this.ListeRoles = res['items'];

                        //AU CAS OU C'EST UN USER SUPER ADMIN QUI EST CONNECTE IL PEUT SELECTIONNER 
                        if (this.user.userTypeCode == enumCodeTypeUtilisateur.SA || this.user.userTypeCode == enumCodeTypeUtilisateur.ADMIN) {
                            this.ListeRoles = _.filter(res['items'], function (o: any) { return o.libelle == enumLibelleRole.SUPER_ADMIN; });
                        }

                        if (this.user.userTypeCode == enumCodeTypeUtilisateur.MARCHAND_ADMIN || this.user.userTypeCode == enumCodeTypeUtilisateur.MARCHAND_USER) {
                            this.ListeRoles = _.filter(res['items'], function (o: any) { return (o.libelle == enumLibelleRole.MARCHAND || o.libelle == enumLibelleRole.ADMIN_MARCHAND); });
                        }

                        if (this.user.userTypeCode == enumCodeTypeUtilisateur.TENANCY_USER || this.user.userTypeCode == enumCodeTypeUtilisateur.TENANCY_ADMIN) {
                            this.ListeRoles = _.filter(res['items'], function (o: any) { return (o.libelle == enumLibelleRole.ADMIN_TENANCY || o.libelle == enumLibelleRole.COOP || o.libelle == enumLibelleRole.DELEGUE); });
                        }

                    }
                },
                err => {
                    console.log("Error occured", err);
                    //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
                    // this.not
                }
            );
    }



    getDataTypeUtilisateur() {
        var request = {
            user: this.user.id,
            data: {
            }
        }

        this.api.post('userType/getByCriteria', request)
            .subscribe(
                res => {
                    console.log('les types utilisateurs', res);
                    if (res && res['items']) {

                        res['items'].forEach(function (value) {
                            value.libelle = value.libelle.toUpperCase()
                        });

                        this.ListeTypesUtilisateurs = res['items'];

                        //AU CAS OU C'EST UN USER SUPER ADMIN QUI EST CONNECTE IL PEUT SELECTIONNER 
                        if (this.user.userTypeCode == enumCodeTypeUtilisateur.SA || this.user.userTypeCode == enumCodeTypeUtilisateur.ADMIN) {
                            this.ListeTypesUtilisateurs = _.filter(res['items'], function (o) { return o.code == enumCodeTypeUtilisateur.ADMIN; });
                        }

                        if (this.user.userTypeCode == enumCodeTypeUtilisateur.MARCHAND_ADMIN || this.user.userTypeCode == enumCodeTypeUtilisateur.MARCHAND_USER) {
                            this.ListeTypesUtilisateurs = _.filter(res['items'], function (o) { return (o.code == enumCodeTypeUtilisateur.MARCHAND_USER || o.code == enumCodeTypeUtilisateur.MARCHAND_ADMIN); });
                        }

                        if (this.user.userTypeCode == enumCodeTypeUtilisateur.TENANCY_USER || this.user.userTypeCode == enumCodeTypeUtilisateur.TENANCY_ADMIN) {
                            this.ListeTypesUtilisateurs = _.filter(res['items'], function (o) { return (o.code == enumCodeTypeUtilisateur.TENANCY_USER || o.code == enumCodeTypeUtilisateur.TENANCY_ADMIN); });
                        }

                        this.typeUtilisateurSelected = this.ListeTypesUtilisateurs[0];
                    }
                },
                err => {
                    console.log("Error occured", err);
                    //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
                    // this.not
                }
            );
    }


    confirmSaveItem(obj) {

        console.log("le obj ", obj);
        console.log("le obj de itemToSave", this.itemToSave);

        if (!obj) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner les champs obligatoires.");
            return;
        }

        if (!obj.firstName) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner nom.");
            return;
        }
        if (!obj.lastName) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner prenoms.");
            return;
        }
        if (!obj.email) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner email.");
            return;
        }
        if (!obj.telephone) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner telephone.");
            return;
        }
        if (!obj.login) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner login.");
            return;
        }
        if (!this.typeUtilisateurSelected) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner utilisateur.");
            return;
        }
        if (!obj.roleSelected) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner type.");
            return;
        }

        obj.roleId = obj.roleSelected.id;
        obj.userTypeCode = this.typeUtilisateurSelected.code;
        obj.parentId = this.user.id;

        if (this.user.userTypeCode == enumCodeTypeUtilisateur.MARCHAND_ADMIN || this.user.userTypeCode == enumCodeTypeUtilisateur.MARCHAND) {
            obj.datasMarchand = [
                {
                    marchandId: this.user.datasMarchand[0].id,
                    roleId: obj.roleSelected.id
                }
            ]
        }

        if (this.user.userTypeCode == enumCodeTypeUtilisateur.TENANCY_USER || this.user.userTypeCode == enumCodeTypeUtilisateur.TENANCY_ADMIN) {
            obj.datasTenancy = [
                {
                    tenancyId: this.user.datasTenancy[0].id,
                    roleId: obj.roleSelected.id
                }
            ]
        }


        Swal.fire({
            title: !obj.id ? 'Enregistrement utilisateur' : 'Modification utilisateur',
            text: 'Désirez-vous poursuivre cette action ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonColor: "#00A984",
            cancelButtonColor: "#333333",
        }).then((result) => {
            if (result.value) {
                this.saveItem(obj);
            } else {
                console.log("bnjr");
            }
        })

    }



    confirmDeleteItem(obj) {
        //this.lgModal.show();

        Swal.fire({
            title: 'Suppression',
            text: 'Désirez-vous poursuivre cette action ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonColor: "#ff7b0c",
            cancelButtonColor: "rgb(154, 154, 153)",
        }).then((result) => {
            if (result.value) {
                this.deleteItem(obj);
            } else {
                console.log("bnjr");
            }
        })

    }

    checkCurrentItem(item) {
        if (item) {
            console.log("ellement coché", item);
            this.currentItemChecked = item;
        }
    }

    cancelSave() {
        this.itemToSave = {};
    }


    modifyItem(user) {
        this.cancelSave();
        this.itemToSave = Object.assign({}, user);

        //Recuerer le profi
        this.itemToSave.roleSelected = _.find(this.ListeRoles, { 'id': this.itemToSave.idRole });

        window.scrollTo(0, 0);
    }

    saveItem(item) {

        var request = {
            user: this.user.id,
            datas: [
                item
            ]
        }

        console.log("data sent to saveItem", JSON.stringify(request));

        this.busySave = this.api.post('user/' + (item.id ? 'update' : 'create'), request)
            .subscribe(
                res => {
                    console.log("resul", res);
                    if (!res['hasError'] && res['items']) {
                        this.notificationService.showMessageSucces("Utilisateur", 'Opération effectuée avec succès.');
                        this.currentPage = 1;
                        this.hideModal();
                        this.cancelSave();
                        this.getData();
                    } else {
                        if (res['status'] && res['status']['message']) {
                            this.notificationService.showMessageError("Erreur", res['status']['message']);
                        }
                    }
                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
                }
            );
    }


    deleteItem(obj) {

        var request = {
            user: this.user.id,
            datas: [
                obj
            ]
        }

        this.busyGet = this.api.post('users/delete', request)
            .subscribe(
                res => {
                    console.log(res);
                    if (!res['hasError']) {
                        this.notificationService.showMessageSucces("utilisateur", 'Suppression effectuée avec succès');

                        this.currentPage = 1;
                        this.getData();
                    }
                    else {
                        if (res['status'] && res['status']['message']) {
                            this.notificationService.showMessageError("Erreur", res['status']['message']);
                        }
                    }

                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                    // this.not
                }
            );
    }

    cancelSearch() {
        this.itemToSearch = {};
        this.changeItermPerPage();
    }

    getExactlyNumberRow(page, index) {
        let num = index + 1;
        if (page > 1) {
            num = ((page - 1) * 10) + (index + 1);
        }
        return num;
    }


    //RECUPERER LA LISTE DES MARCHANDS

    getDataMarchand() {

        var request = {
            user: this.user.id,
            data: {
                //tenancyId : (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
            },
        }


        this.api.post('marchandTenancy/getByCriteria', request)
            .subscribe(
                res => {
                    console.log(res);
                    if (res && res['items']) {
                        this.ListeMarchandsFilters = res['items'];
                    } else {
                        this.ListeMarchandsFilters = [];
                    }
                },
                err => {
                }
            );

    }
    phoneNumberSeparator(str){
        if(str)
        return [str.slice(0, 2), " ", str.slice(2,4), " ", str.slice(4,6), " ", str.slice(6,8), " ", str.slice(8,10)].join('');

    }

     //RECUPERER LA LISTE DES COOP

     getDataCooperative() {

        var request = {
            user: this.user.id,
            data: {
                //tenancyId : (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
            },
        }


        this.api.post('tenancy/getByCriteria', request)
            .subscribe(
                res => {
                    console.log(res);
                    if (res && res['items']) {
                        this.ListeCooperativeFilters = res['items'];
                    } else {
                        this.ListeCooperativeFilters = [];
                    }
                },
                err => {
                }
            );

    }



    ngOnInit() {
        this.getData();
        this.getDataTypeUtilisateur();
        this.getDataRole();

        if (this.user.userTypeCode == "SA" || this.user.userTypeCode == "ADMIN") {
            this.getDataMarchand();
            this.getDataCooperative();
        }
        window.scrollTo(0, 0);
    }
}
