<!-- MAIN CONTENT -->
<div
  id="content"
  [ngBusy]="{
    busy: busyGet,
    message: 'Veuillez patienter ...',
    backdrop: false
  }"
>
  <div class="custom-cadre">
    <!-- fil d actualite -->
    <div class="row">
      <div class="form-group col-md-12">
        <div class="col-md-12">
          <h1
            *ngIf="!isAvance"
            style="display: inline-block"
            class="custom-title-bold"
          >
            GESTION DES AGRICULTEURS
          </h1>
          <h1
            *ngIf="isAvance"
            style="display: inline-block"
            class="custom-title-bold"
          >
            GESTION DES AVANCES ET PRETS
          </h1>
          <br />
          <div class="row">
            <div class="form-group col-md-3 no-padding">
              <input
                class="form-control custom_input"
                autocomplete="off"
                [(ngModel)]="itemToSearch.nom"
                name="nom"
                required
                placeholder="Recherche par nom"
                (keyup)="getDataSearch()"
                type="text"
              />
            </div>

            <div class="form-group col-md-3 no-padding">
              <input
                class="form-control custom_input"
                autocomplete="off"
                [(ngModel)]="itemToSearch.prenoms"
                name="prenoms"
                required
                placeholder="Recherche par prénom"
                (keyup)="getDataSearch()"
                type="text"
              />
            </div>
            <div class="form-group col-md-3 no-padding">
              <input
                class="form-control custom_input"
                autocomplete="off"
                [(ngModel)]="itemToSearch.code"
                name="code"
                required
                placeholder="Recherche par identifiant"
                (keyup)="getDataSearch()"
                type="text"
              />
            </div>
            <div class="col-md-3">
              <!-- <div class="col-md-3" *ngIf="isAvance"> -->
              <div class="btn-group" dropdown>
                <button
                  id="button-basic"
                  dropdownToggle
                  type="button"
                  class="btn btn-sm custom_border_radius btn-bg-new padding-30 dropdown-toggle"
                  aria-controls="dropdown-basic"
                >
                  Ajouter <span class="caret"></span>
                </button>
                <ul
                  id="dropdown-basic"
                  *dropdownMenu
                  class="dropdown-menu"
                  role="menu"
                  aria-labelledby="button-basic"
                >
                  <li
                    (click)="openFormModalModal(templateCreate)"
                    role="menuitem"
                  >
                    <a class="dropdown-item">Nouveau</a>
                  </li>
                  <li
                    (click)="openModalIdentifiant(templateIdentifiant)"
                    role="menuitem"
                  >
                    <a class="dropdown-item">Avec Identifiant</a>
                  </li>
                  <li (click)="openModalImport(templateImport)" role="menuitem">
                    <a class="dropdown-item">Importer un fichier</a>
                  </li>
                </ul>
              </div>
              <button
                (click)="openModalAction(templateAction)"
                class="btn btn-success custom_border_radius btn-bg-new"
              >
                + Carte
              </button>
            </div>
          </div>
          <div class="tableFixHead col-md-12 no-padding">
            <table class="table table-condensed table-striped">
              <thead>
                <tr class="custom-table-thead">
                  <th style="width: 45px">
                    <a (click)="getData()"> <i class="fa fa-refresh"></i> </a>
                  </th>
                  <th>Nom</th>
                  <th>Prénoms</th>
                  <th>Sexe</th>
                  <th>Contact</th>
                  <th>Solde</th>
                  <th class="text-center">Date soucription</th>
                  <th>Identifiant</th>
                  <th>Statut</th>
                  <th style="text-align: center; width: 75px">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of ListeClients; let i = index"
                  (click)="selectedClient(item)"
                  [class.isSelect]="item.id == currentItemSeleced.id"
                >
                  <td class="vertical-align-uppercase">
                    {{ i + 1 + (currentPage - 1) * itemsPerPage }}
                  </td>
                  <td class="vertical-align-uppercase">
                    {{ item.dataClient.nom }}
                  </td>
                  <td class="vertical-align-uppercase">
                    {{ item.dataClient.prenoms }}
                  </td>
                  <td class="vertical-align-uppercase">
                    {{ item.dataClient.sexe }}
                  </td>
                  <td class="vertical-align-uppercase">
                    {{ phoneNumberSeparator(item.dataClient.telephone) }}
                  </td>
                  <td class="vertical-align-uppercase text-right">
                    {{ (item.soldeClientTenancy | formatmillier) || 0 }}
                  </td>
                  <td class="vertical-align-uppercase text-center">
                    {{ item.createdAt }}
                  </td>
                  <td class="vertical-align">{{ item.dataClient.code }}</td>
                  <td class="vertical-align">{{ item.dataClient.statu }}</td>
                  <td style="text-align: center">
                    <a
                      title="Modifier"
                      (click)="openFormModalModal(templateCreate, item)"
                    >
                      <i class="fa fa-edit" style="color: green"> </i>
                    </a>
                    &nbsp;
                    <a
                      title="Détails"
                      (click)="gotoFicheProduction(item.dataClient)"
                    >
                      <i class="fa fa-eye" style="color: #00a984"> </i>
                    </a>
                    &nbsp;
                    <a title="Supprimer" (click)="confirmDeleteItem(item)">
                      <i class="fa fa-trash-o" style="color: red"> </i>
                    </a>
                  </td>
                </tr>

                <tr *ngIf="ListeClients && ListeClients.length > 0">
                  <td colspan="10">
                    <div
                      class="col-xs-12 col-12"
                      style="text-align: left; padding: 0px; margin-top: 15px"
                    >
                      <pagination
                        [boundaryLinks]="true"
                        [totalItems]="totalItems"
                        [maxSize]="itemPerPagePagination"
                        [itemsPerPage]="itemsPerPage"
                        [(ngModel)]="currentPage"
                        class="pagination-sm"
                        previousText="&laquo;"
                        nextText=" &raquo;"
                        firstText="&laquo;"
                        lastText="&raquo;"
                        name="pagination"
                        (pageChanged)="pageChanged($event)"
                      ></pagination>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot *ngIf="!ListeClients || ListeClients.length == 0">
                <tr>
                  <td
                    colspan="10"
                    style="text-align: center; font-weight: bold"
                  >
                    Aucune donnée disponible
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- fin file d actualite -->
  </div>

  <ng-template #templateCreate>
    <form
      name="frmSave"
      [ngBusy]="{
        busy: busySave,
        message: 'Veuillez patienter ...',
        backdrop: false
      }"
    >
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">AGRICULTEUR</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hideModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-lg-12">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group col-md-3">
                      <label> Identifiant:</label>
                      <input
                        [(ngModel)]="itemToSave.code"
                        name="code"
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>
                        Souscription coopérative:
                        <!-- {{ itemToSave.annSouscriptionCoop }} -->
                        <span class="required_champs">*</span>
                      </label>
                      <input
                        autocomplete="off"
                        (onHidden)="onSetDate(bsInlineValueAnnSouscriptionCoop, 'annSouscriptionCoop')"
                        bsDatepicker
                        [bsValue]="bsInlineValueAnnSouscriptionCoop"
                        name="annSouscriptionCoop"
                        type="text"
                        class="form-control form-control-sm"
                        [maxDate]="maxDate"
                        [outsideClick]="false"
                        [ngClass]="{ 'white-date': !renderAnnSouscriptionCoop }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>
                        Souscription carte:
                        <span class="required_champs">*</span>
                      </label>
                      <input
                        autocomplete="off"
                        (onHidden)="onSetDate(bsInlineValueAnnSouscriptionCarte, 'annSouscriptionCarte')"
                        bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                        [bsValue]="bsInlineValueAnnSouscriptionCarte"
                        name="annSouscriptionCarte"
                        type="text"
                        class="form-control form-control-sm"
                        [maxDate]="maxDate"
                        [outsideClick]="false"
                        [ngClass]="{
                          'white-date': !renderAnnSouscriptionCarte
                        }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>
                        Code carte:
                        <!--                                                    <span class="required_champs">*</span>-->
                      </label>
                      <!--                                                <input [(ngModel)]="itemToSave.carteCode" name="carteCode" type="text" class="form-control form-control-sm">-->
                      <select
                        *ngIf="
                          !itemToSave.id ||
                          (itemToSave.id && !itemToSave.dataClient.carteCode)
                        "
                        class="form-control custom_input"
                        [(ngModel)]="itemToSave.carteId"
                        name="carteCodeId"
                      >
                        <option disabled>-- Choisissez --</option>
                        <option
                          *ngFor="let d of ListeCartes"
                          value="{{ d.id }}"
                        >
                          {{ d.code }}
                        </option>
                      </select>
                      <input
                        *ngIf="itemToSave.id && itemToSave.dataClient.carteCode"
                        [value]="itemToSave.dataClient.carteCode"
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group col-md-1">
                      <label>
                        Sexe :
                        <span class="required_champs">*</span>
                      </label>
                      <select
                        class="form-control custom_input"
                        id="sexeSelected"
                        [(ngModel)]="itemToSave.sexe"
                        #ctrl="ngModel"
                        name="sexeSelected"
                      >
                        <option disabled>-- Choisissez --</option>
                        <option value="M">M</option>
                        <option value="F">F</option>
                      </select>
                    </div>
                    <div class="form-group col-md-2">
                      <label
                        >Nom :
                        <span class="required_champs">*</span>
                      </label>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.nom"
                        name="nom"
                        required
                        placeholder="Nom"
                        type="text"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label
                        >Prénoms :
                        <span class="required_champs">*</span>
                      </label>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.prenoms"
                        name="prenoms"
                        placeholder="Prénoms"
                        required
                        type="text"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label
                        >Date de naissance :
                        <span class="required_champs">*</span>
                      </label>
                      <input
                        type="text"
                        id="dateDeNaissance"
                        class="form-control custom_input"
                        placeholder="Date de naissance"
                        bsDatepicker
                        name="dateDeNaissance"
                        [bsConfig]="{
                          dateInputFormat: 'DD/MM/YYYY',
                          containerClass: 'theme-default'
                        }"
                        autocomplete="off"
                        [bsValue]="bsInlineValueDateNais"
                        (onHidden)="onSetDate(bsInlineValueDateNais, 'dateNais')"
                        [maxDate]="maxDate"
                        [outsideClick]="false"
                        [ngClass]="{ 'white-date': !renderDateNais }"
                      />
                    </div>

                    <div class="form-group col-md-2">
                      <label>Part sociale : </label>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.partSociale"
                        name="partSociale"
                        placeholder="Part sociale"
                        required
                        type="text"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Code planteur : </label>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.codePlanteur"
                        name="codePlanteur"
                        placeholder="Code planteur"
                        required
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group col-md-3">
                      <label>Email :</label>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.email"
                        name="email"
                        placeholder="Email"
                        required
                        type="email"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label
                        >Contact 1:
                        <span class="required_champs">*</span>
                      </label>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.telephone"
                        name="telephone"
                        placeholder="Contact"
                        required
                        type="text"
                        mask="00-00-00-00-00"
                        maxlength="20"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Contact 2:</label>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.telephone1"
                        name="telephone1"
                        placeholder="Contact"
                        required
                        type="text"
                        mask="00-00-00-00-00"
                        maxlength="20"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Contact 3:</label>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.telephone2"
                        name="telephone2"
                        placeholder="Contact"
                        required
                        type="text"
                        mask="00-00-00-00-00"
                        maxlength="20"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group col-md-3">
                      <label
                        >Situation matrimoniale:
                        <span class="required_champs">*</span>
                      </label>
                      <select
                        class="form-control custom_input"
                        id="situationMatrimonialeSelected"
                        [(ngModel)]="itemToSave.statutMatrimoniale"
                        name="situationMatrimonialeSelected"
                      >
                        <option disabled>-- Choisissez --</option>
                        <option value="Marié(e)">Marié(e)</option>
                        <option value="Célibataire">Célibataire</option>
                        <option value="Divorcé(e)">Divorcé(e)</option>
                        <option value="Veuf(ve)">Veuf(ve)</option>
                      </select>
                    </div>
                    <div class="form-group col-md-3">
                      <label
                        >Lettré:
                        <span class="required_champs">*</span>
                      </label>
                      <select
                        class="form-control custom_input"
                        [(ngModel)]="itemToSave.dialecteId"
                        name="dialecte"
                      >
                        <option disabled>-- Choisissez --</option>
                        <option *ngFor="let d of dialectes" value="{{ d.id }}">
                          {{ d.libelle }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-2">
                      <label> Nombre d'enfant :</label>
                      <span class="required_champs">*</span>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.nbreEnfant"
                        name="nbreEnfant"
                        placeholder="Nombre d'enfant"
                        required
                        type="text"
                        mask="separator"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Enfants -15 ans</label>
                      <input
                        [(ngModel)]="itemToSave.nbrEnfantMoin15"
                        name="nbrEnfantMoin15"
                        id="nbrEnfantMoin15"
                        type="text"
                        class="form-control custom_input form-control-sm"
                        autocomplete="off"
                        mask="separator"
                        required
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Enfants scolarisés</label>
                      <input
                        [(ngModel)]="itemToSave.nbrEnfantScola"
                        name="nbrEnfantScola"
                        id="nbrEnfantScola"
                        mask="separator"
                        type="text"
                        class="form-control custom_input form-control-sm"
                        autocomplete="off"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group col-md-2">
                      <label>Mobile money : </label>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.numeroMm"
                        name="numeroMm"
                        mask="00-00-00-00-00"
                        placeholder=""
                        type="text"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Moyen paiement:</label>
                      <select
                        class="form-control custom_input"
                        [(ngModel)]="itemToSave.moyenPaiementId"
                        name="moyenPaiementId"
                      >
                        <option disabled>-- Choisissez --</option>
                        <option
                          *ngFor="let d of ListeMoyenPaiements"
                          value="{{ d.id }}"
                        >
                          {{ d.libelle }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-2">
                      <label
                        >Delai paiement :
                        <span class="required_champs">*</span>
                      </label>
                      <select
                        class="form-control custom_input"
                        [(ngModel)]="itemToSave.delaiId"
                        name="delaiId"
                      >
                        <option disabled>-- Choisissez --</option>
                        <option
                          *ngFor="let t of listeDelais"
                          value="{{ t.id }}"
                        >
                          {{ t.libelle }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-md-2">
                      <label
                        >Type de pièce :
                        <span class="required_champs">*</span>
                      </label>

                      <select
                        class="form-control custom_input"
                        id="typePieceId"
                        [(ngModel)]="itemToSave.typePieceId"
                        name="typePieceId"
                      >
                        <option disabled>-- Choisissez --</option>
                        <option *ngFor="let c of typePieces" value="{{ c.id }}">
                          {{ c.libelle }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-2">
                      <label
                        >Numéro de pièce :
                        <span class="required_champs">*</span>
                      </label>
                      <input
                        class="form-control custom_input"
                        autocomplete="off"
                        [(ngModel)]="itemToSave.numPiece"
                        name="numPiece"
                        placeholder=" N° Pièce"
                        required
                        type="text"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label
                        >Statut :
                        <span class="required_champs">*</span>
                      </label>
                      <select
                        class="form-control custom_input"
                        [(ngModel)]="itemToSave.statu"
                        name="statu"
                        placeholder=" N° Pièce"
                        required
                      >
                        <option value=""></option>
                        <option value="Membre">Membre</option>
                        <option value="Usager">Usager</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row" style="background: #dff0d8;">
                  <div class="col col-md-4">
                    <label>
                      Cultures
                      <span class="required_champs">*</span>
                    </label>
                    <select
                      [(ngModel)]="SelectedCultureInfo"
                      (change)="onSelectedCulture()"
                      name="newCulture-{{ '#' }}"
                      type="text"
                      class="form-control form-control-sm"
                    >
                      <option value=""></option>
                      <option *ngFor="let c of ListeCulturesCopy" [ngValue]="c">
                        {{
                          c.cultureLibelle + " - " + c.cultureSectionLibelle
                            | uppercase
                        }}
                      </option>
                    </select>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Culture</th>
                          <th>Section</th>
                          <th>Superficie (ha)</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let c of datasCultures; let i = index"
                          style="cursor: pointer;"
                          (click)="autoFillFields(c)"
                          [ngClass]="{ 'selected-row': rowSelected == c.id }"
                        >
                          <td>
                            {{ c.cultureLibelle | uppercase }}
                          </td>
                          <td>
                            {{ c.cultureSectionLibelle }}
                          </td>
                          <td>
                            {{ c.surfaceCultivee || 0 }}
                          </td>
                          <td>
                            <button
                              (click)="datasCultures.splice(i, 1)"
                              class="btn btn-xs btn-danger pull-right"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                        <tr *ngIf="!(datasCultures?.length > 0)">
                          <td colspan="4" class="text-center">
                            Aucune culture
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col col-md-8">
                    <div class="row">
                      <div class="row">
                        <div class="col-md-4">
                          <label>
                            Section
                            <span class="required_champs">*</span>
                          </label>

                          <input
                            disabled
                            class="form-control custom_input"
                            autocomplete="off"
                            [value]="currentSection"
                            name="sectionId"
                            placeholder="Section"
                            required
                            type="text"
                          />
                        </div>
                        <div class="col-md-4">
                          <label
                            >Année création :
                            <span class="required_champs">*</span>
                          </label>
                          <input
                            [(ngModel)]="SelectedCultureInfo.annCreationCulture"
                            class="form-control custom_input"
                            autocomplete="off"
                            name="annCreationCulture"
                            placeholder="Année création"
                            required
                            type="text"
                            mask="0000"
                            maxlength="12"
                            (input)="controleYearCreate()"
                          />
                        </div>

                        <div class="col-md-4">
                          <label>Certification</label>
                          <span class="required_champs">*</span>
                          <select
                            [(ngModel)]="SelectedCultureInfo.certificatId"
                            name="certificatId"
                            class="form-control custom_input form-control-sm"
                          >
                            <option value=""></option>
                            <option
                              *ngFor="let c of listCertificates"
                              value="{{ c.id }}"
                            >
                              {{ c.libelle }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Latitude</label>
                          <input
                            class="form-control custom_input"
                            autocomplete="off"
                            [(ngModel)]="SelectedCultureInfo.latitude"
                            name="latitude"
                            placeholder="Latitude"
                            required
                            type="number"
                            step="any"
                          />
                        </div>

                        <div class="col-md-4">
                          <label>Longitude</label>

                          <input
                            class="form-control custom_input"
                            autocomplete="off"
                            [(ngModel)]="SelectedCultureInfo.longitude"
                            name="longitude"
                            placeholder="Longitude"
                            required
                            type="number"
                            step="any"
                          />
                        </div>

                        <div class="col-md-4">
                          <label>Pesticide</label>
                          <select
                            class="form-control custom_input"
                            [(ngModel)]="SelectedCultureInfo.hasFertilisant"
                            id="typePieceId"
                            name="typePieceId"
                          >
                            <option disabled value="null">
                              -- Choisissez --
                            </option>
                            <option value="oui">Oui</option>
                            <option value="non">Non</option>
                          </select>
                        </div>

                        <div
                          class="col-md-5"
                          *ngIf="SelectedCultureInfo.hasFertilisant == 'oui'"
                        >
                          <label>Description</label>
                          <textarea
                            [(ngModel)]="SelectedCultureInfo.fertilisant"
                            rows="1"
                            name="fertilisant"
                            class="form-control form-control-sm"
                          ></textarea>
                        </div>

                        <div class="col-md-4">
                          <label>Code plantation</label>
                          <input
                            placeholder="Code plantation"
                            [(ngModel)]="SelectedCultureInfo.codePlantation"
                            rows="1"
                            name="codePlantation"
                            class="form-control form-control-sm custom_input"
                          />
                        </div>

                        <div class="col-md-4">
                          <label
                            >Superficie (ha) :
                            <span class="required_champs">*</span>
                          </label>
                          <input
                            [(ngModel)]="SelectedCultureInfo.surfaceCultivee"
                            style="display: inline-block; width: 80%;"
                            class="form-control custom_input"
                            autocomplete="off"
                            name="superficie"
                            placeholder="Superficie culture"
                            required
                            type="number"
                            step="any"
                            min="”0″"
                            maxlength="12"
                          />
                          <button
                            (click)="updateCulture()"
                            class="btn btn-sm btn-primary pull-right"
                            style="border-radius: 100%;background-color: #00a984;">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-4">
                    <strong>Données bancaires</strong>
                    <!-- <span class="required_champs">*</span> -->
                    <table class="table table-bordered bank-info">
                      <thead>
                        <tr>
                          <td width="30%" style="padding: 0">
                            <select
                              [(ngModel)]="newBanque.banque"
                              name="banque"
                              class="form-control"
                            >
                              <option value=""></option>
                              <option *ngFor="let b of banques" [ngValue]="b">
                                {{ b.code }}
                              </option>
                            </select>
                          </td>
                          <td width="30%" style="padding: 0">
                            <input
                              (input)="controleYearCreate()"
                              [(ngModel)]="newBanque.annOuverture"
                              name="annOuverture"
                              type="text"
                              placeholder="Année"
                              class="form-control"
                              mask="0000"
                            />
                          </td>

                          <td width="30%" style="padding: 0">
                            <input
                              [(ngModel)]="newBanque.rib"
                              type="text"
                              name="rib"
                              placeholder="RIB"
                              class="form-control"
                            />
                          </td>
                          <td width="10%" style="padding: 4px">
                            <button
                              class="btn btn-xs btn-primary pull-right"
                              style="border-radius: 100%;background-color: #00a984;"
                              (click)="addBanque(newBanque)">
                              <i class="fa fa-plus"></i>
                            </button>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let c of clientBanques; let i = index">
                          <td *ngIf="c.banqueId" (click)="onSelectBanque(c)">
                            {{ c.banqueCode }}
                          </td>
                          <td *ngIf="c.banqueId" (click)="onSelectBanque(c)">
                            {{ c.annOuverture }}
                          </td>
                          <td *ngIf="c.banqueId" (click)="onSelectBanque(c)">
                            {{ c.rib }}
                          </td>
                          <td *ngIf="c.banqueId" (click)="onSelectBanque(c)">
                            <button
                              (click)="clientBanques.splice(i, 1)"
                              class="btn btn-xs btn-danger pull-right"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                        <tr *ngIf="!(clientBanques.length > 0)">
                          <td colspan="4" class="text-center">
                            <b>Aucune donnée</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col-md-8">
                    <div class="col-md-12 no-padding">
                      <div
                        *ngIf="cultureSelected.isNew"
                        class="form-group col-md-6 bg-success"
                      ></div>
                      <div class="col-md-12">
                        <div class="row">
                          <ngui-map
                            zoom="5"
                            center="Yamoussoukro"
                            draggable="true"
                            (mapReady$)="onMapReady($event)"
                            (mapClick)="onMapClick($event)"
                            (idle)="onIdle($event)"
                          >
                            <marker
                              (drag)="dragChamp($event)"
                              draggable="true"
                              *ngIf="
                                SelectedCultureInfo.latitude &&
                                SelectedCultureInfo.longitude
                              "
                              [icon]="{ url: './assets/markers/champ.png' }"
                              position="{{ SelectedCultureInfo.latitude }},{{
                                SelectedCultureInfo.longitude
                              }}"
                            >
                            </marker>
                            <marker
                              (drag)="dragHome($event)"
                              draggable="true"
                              [icon]="{ url: './assets/markers/home.png' }"
                              *ngIf="
                                itemToSave.longitudeDomicile &&
                                itemToSave.latitudeDomicile &&
                                !itemToSave.isClickMapDomicile
                              "
                              position="{{ itemToSave.latitudeDomicile }},{{
                                itemToSave.longitudeDomicile
                              }}"
                            >
                            </marker>
                          </ngui-map>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <div class="col-md-12 pj">
                    <div class="">
                      <h4 class="text-center">Photo</h4>
                      <div
                        class="form-group col-md-12"
                        style="text-align: center"
                      >
                        <img
                          src="{{
                            imageDisplay ||
                              'assets/img/decoupes/user_default.png'
                          }}"
                          style="height: 110px; border: 1px solid #eceeee"
                        />
                      </div>
                      <div
                        class="form-group col-md-12"
                        style="text-align: center"
                      >
                        <input
                          type="file"
                          name="file"
                          (change)="uploadFile($event, 'photo', true)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 pj">
                    <div class="">
                      <h4 class="text-center">Recto</h4>
                      <div
                        class="form-group col-md-12"
                        style="text-align: center"
                      >
                        <img
                          *ngIf="piece1"
                          src="{{ piece1 }}"
                          style="height: 110px; border: 1px solid #eceeee"
                        />
                      </div>
                      <div
                        class="form-group col-md-12"
                        style="text-align: center"
                      >
                        <input
                          type="file"
                          name="file"
                          (change)="uploadFile($event, 'piece1', false)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 pj">
                    <div class="">
                      <h4 class="text-center">Verso</h4>
                      <div
                        class="form-group col-md-12"
                        style="text-align: center"
                      >
                        <img
                          src="{{ piece2 }}"
                          style="height: 110px; border: 1px solid #eceeee"
                        />
                      </div>
                      <div
                        class="form-group col-md-12"
                        style="text-align: center"
                      >
                        <input
                          type="file"
                          name="file"
                          (change)="uploadFile($event, 'piece2', false)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left"
          >( <span class="required_champs">*</span>) Champs requis</b
        >

        <button
          type="button"
          class="btn btn-md custom_border_radius btn-default padding-30 btn-bg-cancel"
          (click)="hideModal()"
        >
          Annuler
        </button>

        <button
          type="submit"
          class="btn btn-md custom_border_radius btn-bg-new padding-30"
          (click)="confirmSaveItem(itemToSave)"
        >
          Valider
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #templateImport>
    <form
      name="frmSave"
      [ngBusy]="{
        busy: busySave,
        message: 'Veuillez patienter ...',
        backdrop: false
      }"
    >
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">Importer des agriculteurs</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hideModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <label for="" class="btn btn-block btn-secondary">
              <a href="assets/file-template/template_import_agriculteur.xlsx"
                >Telecharger le template ici</a
              >
            </label>
          </div>
          <div class="col-md-12" style="margin-top: 20px">
            <label class="btn btn-block btn-secondary btn-sm">
              <input
                #loadPkcs12
                id="loadPkcs12"
                type="file"
                name="loadPkcs12"
                hidden
                (change)="handleImportFile($event.target.files)"
                multiple="false"
              />
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left"
          >( <span class="required_champs">*</span>) Champs requis</b
        >

        <button
          type="button"
          class="btn btn-md custom_border_radius btn-default padding-30 btn-bg-cancel"
          (click)="hideModal()"
        >
          Annuler
        </button>

        <button
          type="submit"
          class="btn btn-md custom_border_radius btn-bg-new padding-30"
          (click)="saveItemByImport(itemToSave)"
        >
          Valider
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #templateIdentifiant>
    <form
      name="frmSave"
      [ngBusy]="{
        busy: busySave,
        message: 'Veuillez patienter ...',
        backdrop: false
      }"
    >
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">AGRICULTEUR</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hideModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label> Identifiant:</label>
              <input
                [(ngModel)]="itemToSave.code"
                name="code"
                type="text"
                class="form-control form-control-sm"
              />
            </div>
          </div>
        </div>
        <div class="row" style="background: #dff0d8">
          <div class="col-md-12">
            <label>
              Cultures
              <span class="required_champs">*</span>
            </label>
            <select
              [(ngModel)]="SelectedCultureInfo"
              (change)="onSelectedCulture()"
              name="newCulture-{{ '#' }}"
              type="text"
              class="form-control form-control-sm"
            >
              <option value=""></option>
              <option *ngFor="let c of ListeCulturesCopy" [ngValue]="c">
                {{
                  c.cultureLibelle + " - " + c.cultureSectionLibelle | uppercase
                }}
              </option>
            </select>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Culture</th>
                  <th>Section</th>
                  <th>Superficie (ha)</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let c of datasCultures; let i = index"
                  style="cursor: pointer"
                  (click)="autoFillFields(c)"
                  [ngClass]="{ 'selected-row': rowSelected == c.id }"
                >
                  <td>
                    {{ c.cultureLibelle | uppercase }}
                  </td>
                  <td>
                    {{ c.cultureSectionLibelle }}
                  </td>
                  <td>
                    {{ c.surfaceCultivee || 0 }}
                  </td>
                  <td>
                    <button
                      (click)="datasCultures.splice(i, 1)"
                      class="btn btn-xs btn-danger pull-right"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="!(datasCultures?.length > 0)">
                  <td colspan="4" class="text-center">Aucune culture</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="row">
                <div class="col-md-4">
                  <label>
                    Section
                    <span class="required_champs">*</span>
                  </label>

                  <input
                    disabled
                    class="form-control custom_input"
                    autocomplete="off"
                    [value]="currentSection"
                    name="sectionId"
                    placeholder="Section"
                    required
                    type="text"
                  />
                </div>
                <div class="col-md-4">
                  <label
                    >Année création :
                    <span class="required_champs">*</span>
                  </label>
                  <input
                    [(ngModel)]="SelectedCultureInfo.annCreationCulture"
                    class="form-control custom_input"
                    autocomplete="off"
                    name="annCreationCulture"
                    placeholder="Année création"
                    required
                    type="text"
                    mask="0000"
                    maxlength="12"
                    (input)="controleYearCreate()"
                  />
                </div>

                <div class="col-md-4">
                  <label>Certification</label>
                  <span class="required_champs">*</span>
                  <select
                    [(ngModel)]="SelectedCultureInfo.certificatId"
                    name="certificatId"
                    class="form-control custom_input form-control-sm"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let c of listCertificates"
                      value="{{ c.id }}"
                    >
                      {{ c.libelle }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Latitude</label>
                  <input
                    class="form-control custom_input"
                    autocomplete="off"
                    [(ngModel)]="SelectedCultureInfo.latitude"
                    name="latitude"
                    placeholder="Latitude"
                    required
                    type="number"
                    step="any"
                  />
                </div>

                <div class="col-md-4">
                  <label>Longitude</label>

                  <input
                    class="form-control custom_input"
                    autocomplete="off"
                    [(ngModel)]="SelectedCultureInfo.longitude"
                    name="longitude"
                    placeholder="Longitude"
                    required
                    type="number"
                    step="any"
                  />
                </div>

                <div class="col-md-4">
                  <label>Pesticide</label>
                  <select
                    class="form-control custom_input"
                    [(ngModel)]="SelectedCultureInfo.hasFertilisant"
                    id="typePieceId"
                    name="typePieceId"
                  >
                    <option disabled value="null">-- Choisissez --</option>
                    <option value="oui">Oui</option>
                    <option value="non">Non</option>
                  </select>
                </div>

                <div
                  class="col-md-5"
                  *ngIf="SelectedCultureInfo.hasFertilisant == 'oui'"
                >
                  <label>Description</label>
                  <textarea
                    [(ngModel)]="SelectedCultureInfo.fertilisant"
                    rows="1"
                    name="fertilisant"
                    class="form-control form-control-sm"
                  ></textarea>
                </div>

                <div class="col-md-4">
                  <label>Code plantation</label>
                  <input
                    placeholder="Code plantation"
                    [(ngModel)]="SelectedCultureInfo.codePlantation"
                    rows="1"
                    name="codePlantation"
                    class="form-control form-control-sm custom_input"
                  />
                </div>

                <div class="col-md-4">
                  <label
                    >Superficie (ha) :
                    <span class="required_champs">*</span>
                  </label>
                  <input
                    [(ngModel)]="SelectedCultureInfo.surfaceCultivee"
                    style="display: inline-block; width: 80%"
                    class="form-control custom_input"
                    autocomplete="off"
                    name="superficie"
                    placeholder="Superficie culture"
                    required
                    type="number"
                    step="any"
                    min="”0″"
                    maxlength="12"
                  />
                  <button
                    (click)="updateCulture()"
                    class="btn btn-sm btn-primary pull-right"
                    style="border-radius: 100%; background-color: #00a984">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left"
          >( <span class="required_champs">*</span>) Champs requis</b
        >

        <button
          type="button"
          class="btn btn-md custom_border_radius btn-default padding-30 btn-bg-cancel"
          (click)="hideModal()"
        >
          Annuler
        </button>

        <button
          type="submit"
          class="btn btn-md custom_border_radius btn-bg-new padding-30"
          (click)="saveItemByIdentifiant(itemToSave)"
        >
          Valider
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #templateAction>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Opérations sur l'agriculteur</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="hideModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row" style="padding: 20px">
        <div class="col-md-5">
          <p class="vertical-align-uppercase">
            <strong>Nom:</strong> {{ currentItemSeleced.dataClient.nom }}
          </p>
          <p class="vertical-align-uppercase">
            <strong>Prénom(s):</strong>
            {{ currentItemSeleced.dataClient.prenoms }}
          </p>
          <p class="vertical-align-uppercase">
            <strong>Sexe:</strong> {{ currentItemSeleced.dataClient.sexe }}
          </p>
          <p class="vertical-align-uppercase">
            <strong>Tel:</strong>
            {{ phoneNumberSeparator(currentItemSeleced.dataClient.telephone) }}
          </p>
          <p class="vertical-align-uppercase">
            <strong>Solde:</strong>
            {{ (currentItemSeleced.soldeClientTenancy | formatmillier) || 0 }}
          </p>
          <p class="vertical-align-uppercase">
            <strong>Création:</strong> {{ currentItemSeleced.createdAt }}
          </p>
          <p class="vertical-align-uppercase">
            <strong>Carte:</strong> {{ currentItemSeleced.dataClient.code }}
          </p>
          <p class="vertical-align-uppercase">
            <strong>Statut:</strong> {{ currentItemSeleced.dataClient.statu }}
          </p>
        </div>
        <div
          class="col-md-7"
          [hidden]="!currentItemSeleced || !currentItemSeleced.id"
        >
          <div class="tabs">
            <div class="tab">
              <input class="check-tab" type="checkbox" id="chck1" />
              <label class="tab-label" for="chck1">Créditer carte</label>
              <div class="tab-content">
                <!-- CREDITER CLIENT -->
                <div
                  class="col-md-12 info-supplementaire"
                  [ngBusy]="{
                    busy: busyGetMontant,
                    message: 'Veuillez patienter ...',
                    backdrop: false
                  }"
                  *ngIf="user.userTypeCode != 'SA' && isAvance == false"
                >
                  <div class="col-md-12 no-padding">
                    <div class="row">
                      <div class="form-group col-md-12">
                        <label>
                          Culture :
                          <span class="required_champs">*</span>
                        </label>
                        <select
                          class="form-control custom_input"
                          [(ngModel)]="SelectedCultureInfo"
                          (change)="onSelectCreditCulture()"
                          #ctrl="ngModel"
                          name="SelectedCultureInfo"
                        >
                          <option disabled value="">-- Choisissez --</option>
                          <option
                            *ngFor="let cult of ListeCultures"
                            [ngValue]="cult"
                          >
                            {{
                              cult.cultureLibelle +
                                " - " +
                                cult.cultureSectionLibelle
                            }}
                            ({{ cult.surfaceCultivee }}ha)
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-12">
                        <label>
                          N°Bordereau :
                          <span class="required_champs">*</span>
                        </label>

                        <input
                          [(ngModel)]="SelectedCultureInfo.numeroBorderaux"
                          class="form-control custom_input"
                          autocomplete="off"
                          placeholder=""
                          required
                          type="text"
                        />
                      </div>

                      <div class="form-group col-md-12">
                        <label>
                          Prix Unitaire :
                          <span class="required_champs">*</span>
                        </label>

                        <input
                          [(ngModel)]="SelectedCultureInfo.prix"
                          class="form-control custom_input"
                          autocomplete="off"
                          placeholder=""
                          required
                          type="text"
                        />
                      </div>

                      <div class="form-group col-md-12">
                        <label>
                          Coopérative :
                          <span class="required_champs">*</span>
                        </label>

                        <select
                          class="form-control custom_input"
                          [(ngModel)]="SelectedCultureInfo.autreCooperativeId"
                          #ctrl="ngModel"
                          name="autreCooperativeId"
                        >
                          <option disabled value="">-- Choisissez --</option>
                          <option
                            *ngFor="let c of autreCoops"
                            [value]="c.id">
                            {{ c.raisonSocial }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group col-md-12">
                        <label>
                          Section :
                          <span class="required_champs">*</span>
                        </label>

                        <input
                          disabled
                          class="form-control custom_input"
                          autocomplete="off"
                          [value]="currentSection"
                          placeholder="Section"
                          required
                          type="text"
                        />
                      </div>

                      <div class="col-md-12">
                        <label
                          >Quantité produit (Kg) :
                          <span class="required_champs">*</span>
                        </label>
                      </div>

                      <div class="row">
                        <div
                          class="form-group col-md-12"
                          style="padding-left: 26px"
                        >
                          <input
                            class="form-control custom_input"
                            autocomplete="off"
                            [(ngModel)]="SelectedCultureInfo.quantite"
                            name="quantite"
                            placeholder="Quantité"
                            required
                            type="number"
                          />
                        </div>
                        <!-- <div
                          class="form-group col-md-4"
                          style="padding-left: 0; padding-right: 0"
                        >
                          <button
                            class="btn btn-xs btn-round"
                            type="button"
                            (click)="calculerMontant()"
                          >
                            Calculer
                          </button>
                        </div> -->
                      </div>

                      <div class="form-group col-md-12">
                        <label
                          >Points à créditer :
                          <span class="required_champs">*</span>
                        </label>
                        <!-- <input
                          class="form-control custom_input"
                          autocomplete="off"
                          [(ngModel)]="SelectedCultureInfo.montant"
                          name="montant"
                          placeholder="Montant à créditer"
                          required
                          type="text"
                          mask="separator"
                          [disabled]="true"
                        /> -->
                        <input 
                        class="form-control custom_input" 
                        [value]="(SelectedCultureInfo.quantite || 0) * (SelectedCultureInfo.prix || 0)" 
                        mask="separator"
                        [disabled]="true" type="text">
                      </div>

                  <ng-container>
                      <hr>
                      <div class="form-group col-md-12">
                          <input type="file" id="fileBordereau" class="file-input" (change)="uploadFileBordereau($event)" multiple="false" />
                          <label for="fileBordereau" class="input-file btn btn-md btn-bg-default custom_border_radius btn-import pull-right">
                          Image Bordereau
                          </label>
                      </div>
                    
                    <!-- <div class="form-group col-md-12">
                      <button (click)="saveBordereauImage()" 
                        class="btn btn-md custom_border_radius btn-bg-new padding-30 pull-right">
                        Enregistrer Image
                      </button>
                    </div> -->

                    <div class="form-group col-md-12">
                      <button
                        type="button"
                        class="btn btn-md custom_border_radius btn-bg-new padding-30 pull-right"
                        (click)="confirmSaveCrediterCarteItem(SelectedCultureInfo)">
                        Valider
                      </button>
                    </div>

                  </ng-container>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab">
              <input class="check-tab" type="checkbox" id="chck2" />
              <label class="tab-label" for="chck2">
                <span *ngIf="!isAvance">Débiter carte</span>
                <span *ngIf="isAvance">Avance & Prêt</span>
              </label>
              <div class="tab-content">
                <!-- DEBITER CARTE -->
                <div
                  class="col-md-12 info-supplementaire"
                  [ngBusy]="{
                    busy: busyGetMontant,
                    message: 'Veuillez patienter ...',
                    backdrop: false
                  }"
                  *ngIf="user.userTypeCode != 'SA'"
                >
                  <div class="col-md-12 no-padding">
                    <div class="row">
                      <div class="form-group col-md-12">
                        <label
                          >Moyen de paiement :
                          <span class="required_champs">*</span>
                        </label>
                        <select
                          [(ngModel)]="itemToSave.moyenPaiementId"
                          class="form-control custom_input"
                          name="moyenPaiementId"
                        >
                          <option value=""></option>
                          <option
                            *ngFor="let p of ListeMoyenPaiements"
                            value="{{ p.id }}"
                          >
                            {{ p.libelle }}
                          </option>
                        </select>
                      </div>
                      <!-- fdfd -->
                      <div class="form-group col-md-12">
                        <label
                          >Points à débiter :
                          <span class="required_champs">*</span>
                        </label>
                        <input
                          class="form-control custom_input"
                          autocomplete="off"
                          [(ngModel)]="itemToSave.montantDebit"
                          name="montantDebit"
                          placeholder="Montant à debiter"
                          required
                          type="text"
                          mask="separator"
                        />
                      </div>

                      <div class="form-group col-md-12" *ngIf="isAvance">
                        <label>Type de prêt : </label>
                        <select
                          [(ngModel)]="itemToSave.typePret"
                          class="form-control custom_input"
                          name="typePret"
                        >
                          <option value="pret intrant">Prêt intrant</option>
                          <option value="pret particulier">
                            Prêt particulier
                          </option>
                          <option value="pret sur livraison">
                            Avance sur livraison
                          </option>
                        </select>
                      </div>

                      <div class="form-group col-md-12">
                        <label>Commentaire : </label>
                        <textarea
                          [(ngModel)]="itemToSave.commentaireDebit"
                          name="commentaireDebit"
                          id="commentaireDebit"
                          style="width: 100%"
                          rows="3"
                          placeholder="Entrer commentaire"
                        ></textarea>
                      </div>
                      <div class="form-group col-md-12">
                        <button
                          type="button"
                          class="btn btn-md custom_border_radius btn-bg-new padding-30 pull-right"
                          (click)="confirmSaveDebiterCarteItem(itemToSave)"
                        >
                          Valider
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab">
              <input class="check-tab" type="checkbox" id="chck3" />
              <label class="tab-label" for="chck3">Définir Objectifs</label>
              <div class="tab-content">
                <!-- OBJECTIFS CLIENT -->
                <div
                  class="col-md-12 info-supplementaire"
                  [ngBusy]="{
                    busy: busyGetMontant,
                    message: 'Veuillez patienter ...',
                    backdrop: false
                  }"
                  *ngIf="user.userTypeCode != 'SA'"
                >
                  <div class="col-md-12 no-padding">
                    <div class="row">
                      <div class="form-group col-md-12">
                        <label>
                          Culture :
                          <span class="required_champs">*</span>
                        </label>
                        {{ itemToSave.cultureId | json }}
                        <select
                          class="form-control custom_input"
                          [(ngModel)]="itemToSave.cultureId"
                          #ctrl="ngModel"
                          name="cultureId"
                        >
                          <option value=""></option>
                          <option
                            *ngFor="let cult of ListeCultures"
                            [value]="cult.cultureId"
                          >
                            {{
                              cult.cultureLibelle +
                                " - " +
                                cult.cultureSectionLibelle
                            }}
                            ({{ cult.surfaceCultivee }}ha)
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-12">
                        <label>
                          Période :<span class="required_champs">*</span>
                        </label>
                        <select
                          [(ngModel)]="itemToSave.annee"
                          name="annee"
                          class="form-control custom_input"
                          required
                        >
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                        </select>
                      </div>

                      <div class="row">
                        <div
                          class="form-group col-md-11"
                          style="padding-left: 26px"
                        >
                          <label
                            >Objectif (T) :
                            <span class="required_champs">*</span>
                          </label>
                          <input
                            [(ngModel)]="itemToSave.estimation"
                            class="form-control custom_input"
                            autocomplete="off"
                            [(ngModel)]="SelectedCultureInfo.quantite"
                            name="quantite"
                            required
                            type="number"
                          />
                        </div>
                      </div>

                      <div class="form-group col-md-12">
                        <button
                          type="button"
                          class="btn btn-md custom_border_radius btn-bg-new padding-30 pull-right"
                          (click)="confirmDefinirObjectif(itemToSave)"
                        >
                          Valider
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
