import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {UserService} from "../../user/user.service";


@Injectable()
export class Api {
    url: any;
    user :any;
    constructor(public http: HttpClient,private userService: UserService) {
         this.user = this.userService.getCurrentUser();
         this.url = "https://smartagro-stg.smart-intl.net/api/smart-carte-coop";
    }


    get(endpoint: string, params?: any, options?: HttpParams) {

        return this.http.get(this.url + '/' + endpoint);
    }

    getOption(endpoint: string, params?: any, options?: any) {

        return this.http.get(this.url + '/' + endpoint,options);
    }

    postOption(endpoint: string, body: any, options: any) {
        return this.http.post(this.url + '/' + endpoint, body,options);
    }

    post(endpoint: string, body: any, options?: any) {
        console.log(this.url + '/' + endpoint);
        return this.http.post(this.url + '/' + endpoint, body);
    }

    getTonnageAnnuelCooperative(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);
    }
    getTonnageMoyen(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);

    }
    getLastLigneRechargement(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);

    }
    getTonnageYesterday(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);

    }
    getTonnageLastWeek(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);

    }
    getTonnageLastMonth(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);

    }
    getTonnageDateRange(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);

    }
    getTonnageSectionYesterday(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);

    }
    getTonnageSectionLastWeek(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);

    }
    getTonnageSectionLastMonth(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);

    }
    getTonnageSectionDateRange(endpoint: string, body: any, options?: any){
        return this.http.post(this.url + '/' + endpoint, body);

    }
    getLivraisonAttente(endpoint: string, body: any, options: any) {
        return this.http.post(this.url + '/' + endpoint, body,options);
    }
    getClientsTop10(endpoint: string, body: any) {
        return this.http.post(this.url + '/' + endpoint, body);
    }
    postTest(endpoint: string, body: any, options?: any) {
        console.log(this.url + '/' + endpoint);
      
        return this.http.post('http://e-tiers-api.swanpro.net/ispc-backend' + '/' + endpoint, body);
    }

    put(endpoint: string, body: any, options?: any) {
        return this.http.put(this.url + '/' + endpoint, body);
    }

    delete(endpoint: string, options?: any) {
        return this.http.delete(this.url + '/' + endpoint);
    }

    patch(endpoint: string, body: any, options?: any) {
        return this.http.put(this.url + '/' + endpoint, body);
    }
}
