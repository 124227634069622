<div class="row" *ngIf="user?.userTypeCode == 'SA' || user?.userTypeCode == 'ADMIN'">
  <div style="display: flex; padding-right: 20px;">

    <div class="col border-style" style="flex: 5; position: relative; margin-top: 5px;">

      <div class="row">
        <span class="fa">
          <img  src="assets/icons/icon31.png">
        </span>
        <span class="chart-title">Nombre total de coopératives inscrites</span>
      </div>
      <br>
      <div class="row">
        <div class="col text-center" style="font-size: 50px;">
          {{nombreTotalCooperativeInscripte}}
        </div>
        
      </div>
    </div>

    <div class="col border-style" style="flex: 5; position: relative; margin-top: 5px;">

      <div class="row">
        <span class="fa">
          <img  src="assets/icons/icon31.png">
        </span>
        <span class="chart-title">Nombre de membres hommes</span>
      </div>
      <br>
      <div class="row">
        <div class="col text-center" style="font-size: 50px;">
          {{nombreMembreHomme}}
        </div>
        
      </div>
    </div>

    <div class="col border-style" style="flex: 5; position: relative; margin-top: 5px;">

      <div class="row">
        <span class="fa">
          <img  src="assets/icons/icon31.png">
        </span>
        <span class="chart-title">Nombre de membres femmes</span>
      </div>
      <br>
      <div class="row">
        <div class="col text-center" style="font-size: 50px;">
          {{nombreMembreFemme}}
        </div>
        
      </div>
    </div>


    
    </div>
</div>


<div class="row" *ngIf="user?.userTypeCode == 'SA' || user?.userTypeCode == 'ADMIN'">
  <div style="display: flex; padding-right: 20px;">

    <div class="col border-style" style="flex: 5; position: relative; margin-top: 5px;">

      <div class="row">
        <span class="fa">
          <img  src="assets/icons/icon31.png">
        </span>
        <span class="chart-title">Nombre de membres total</span>
      </div>
      <br>
      <div class="row">
        <div class="col text-center" style="font-size: 50px;">
          {{nombreTotalMembre}}
        </div>
        
      </div>
    </div>

    <div class="col border-style" style="flex: 5; position: relative; margin-top: 5px;">

      <div class="row">
        <span class="fa">
          <img  src="assets/icons/icon31.png">
        </span>
        <span class="chart-title">Superficie homme CA (ha)</span>
      </div>
      <br>
      <div class="row">
        <div class="col text-center" style="font-size: 50px;">
          {{superfHomme}}
        </div>
        
      </div>
    </div>

    <div class="col border-style" style="flex: 5; position: relative; margin-top: 5px;">

      <div class="row">
        <span class="fa">
          <img  src="assets/icons/icon31.png">
        </span>
        <span class="chart-title">Superficie femme CA (ha)</span>
      </div>
      <br>
      <div class="row">
        <div class="col text-center" style="font-size: 50px;">
          {{superfFemme}}
        </div>
        
      </div>
    </div>



    </div>
</div>



<div class="row" *ngIf="user?.userTypeCode == 'SA' || user?.userTypeCode == 'ADMIN'">
  <div style="display: flex; padding-right: 20px;">

    <div class="col border-style" style="flex: 5; position: relative; margin-top: 5px;height: 197px;">

      <div class="row">
        <span class="fa">
          <img  src="assets/icons/icon31.png">
        </span>
        <span class="chart-title">Superficie totale CA (ha)</span>
      </div>
      <br>
      <div class="row">
        <div class="col text-center" style="font-size: 50px;">
          {{superfTotal}}
        </div>
        
      </div>
    </div>

    <div class="col border-style" style="flex: 10; position: relative; margin-top: 5px;">

      <div class="row">
        <span class="fa">
          <img  src="assets/icons/icon31.png">
        </span>
        <span class="chart-title">TOP 10 des coopératives ayant le plus
          grand nombre de membres inscrits sur la CA</span>


      </div>
      <br>
      <div class="row">
        <table class="table table-condensed table-striped" style="padding: 20px">
                      <thead>
                        <tr class="custom-table-thead">
                          <th> </th>
                          <th>Coopérative</th>
                          <th>Nombre inscrits CA</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of listDataTopTenMbrCA; let i = index"
                          style="font-size: 10px !important">
                          <td>{{i+1}}</td>
                          <td class="vertical-align-uppercase">{{item.raisonSociale}}</td>
                          <td class="vertical-align-uppercase">{{item.nombreMembreCoop}}</td>
                        </tr>
                      </tbody>
                    </table>
        
      </div>
    </div>


    </div>
</div>

<div class="row" *ngIf="user?.userTypeCode == 'SA' || user?.userTypeCode == 'ADMIN'">
  <div style="display: flex; padding-right: 20px;">

   

    <div class="col border-style" style="flex: 10; position: relative; margin-top: 5px;">

      <div class="row">
        <span class="fa">
          <img  src="assets/icons/icon31.png">
        </span>
        <span class="chart-title">Statistiques cultures</span>


      </div>
      <br>
      <div class="row">
        <table class="table table-condensed table-striped" style="padding: 20px">
                      <thead>
                        <tr class="custom-table-thead">
                          <th> </th>
                          <th>Culture</th>
                          <th>Nombre agriculteurs</th>
                          <th>Superficie CA (ha)</th>
                          <th>Rendement (Tonne)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of listDataStatByCulture; let i = index"
                          style="font-size: 10px !important">
                          <td>{{i+1}}</td>
                          <td class="vertical-align-uppercase">{{item.libelle}}</td>
                          <td class="vertical-align-uppercase">{{item.nbrClientByCulture}}</td>
                          <td class="vertical-align-uppercase">{{item.superficieCA}}</td>
                          <td class="vertical-align-uppercase">{{item.rendement}}</td>
                        </tr>
                      </tbody>
                    </table>
        
      </div>
    </div>



    </div>
</div>














<div class="row" *ngIf="user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER'">

  <div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}" class="main-page">
    <div class="row" style="margin-bottom: 10px;">
      <span style="font-weight: 700; font-size: 20px; padding: 14px;">DASHBOARD</span>
    </div>
    <div class="row">
      <div class="col-md-3 text-left">
          <label>Date debut :
              <span class="required_champs">*</span>
            </label>
            <input type="text"
                   placeholder="Datepicker"
                   class="form-control custom_input"
                   bsDatepicker
                   [(bsValue)]="dateDebut"
                   >
  
      </div>
      <div class="col-md-3 text-left">
          <label>Date fin :
              <span class="required_champs">*</span>
            </label>
            <input type="text"
                   placeholder="Datepicker"
                   class="form-control custom_input"
                   bsDatepicker
                   [(bsValue)]="dateFin"
                   >
  
      </div>
      <div class="col-md-1">
          <br>
          <button (click)="searchData()" class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button">
              Rechercher
            </button>
      </div>
      <div class="col-md-9">
        
      </div>
    </div>
  <br>
        <div class="row" >
          <div style="display: flex; padding-right: 20px;">
            <div class="col border-style" style="flex: 10; position: relative; margin-top: 5px;">
  
              <span class="fa">
                <img  src="assets/icons/icon31.png">
              </span>
              <span class="chart-title">Tonnage</span><br>
              <span class="chart-sub-title">Dernières 24h</span>
              <div id="pie-tonnage-day"></div>
            </div>
  
            <div class="col border-style" style="flex: 10; position: relative; margin-top: 5px;">
              <span class="fa">
                <img  src="assets/icons/icon41.png">
              </span>
              <span class="chart-title">Tonnage</span><br>
              <span class="chart-sub-title">Semaine dernière</span>
              <div id="pie-tonnage-week"></div>
            </div>
           
         
          </div>
  
          <div style="display: flex; padding-right: 20px;">
        
            <div class="col border-style" style="flex: 5; position: relative;margin-top: 5px;" >
              <span class="fa">
                <img  src="assets/icons/icon-user1.png">
              </span>
              <span class="chart-title">Tonnage</span><br>
              <span class="chart-sub-title">Du {{tonnageRangeStart}} au {{tonnageRangeEnd}}</span>
  
              <div id="pie-tonnage-date"></div>
            </div>
          </div>
        
        </div>
        <br>
        <div class="row">
          <div style="display: flex; padding-right: 20px; position: relative;">
            <div class=" border-style" style="margin-top: 5px; flex: 5;">
            <img class="fa" src="assets/icons/icon11.png" style="background:#159739; left: 6px;">
              <strong>Tonnages moyens</strong>
              <table class="table table-condensed table-striped table-bordered">
                <thead>
                  <tr class="custom-table-thead">
                    <th>Culture</th>
                    <th>Délégué(Tonne)</th>
                    <th>Hectare(Tonne)</th>
                    <th>Agriculteurs(Tonne)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of tonnageMoyenParClient">
                    <td class="vertical-align-uppercase">{{item.cultureLibelle}}</td>
                    <td class="vertical-align-uppercase">{{item.listInfo[0].moyennrTonnageByDelegue | moneyFormat}}</td>
                    <td class="vertical-align-uppercase">{{item.listInfo[0].moyennrTonnageByHectare | moneyFormat}}</td>
                    <td class="vertical-align-uppercase">{{item.listInfo[0].moyennrTonnageByClient | moneyFormat }}</td>
                  </tr>
                </tbody>
    
              </table>
        </div>
  
        <div class="border-style" style="margin-top: 5px; flex: 5; position: relative;">
          <strong>Tonnages annuel</strong>
          <span class="fa">
            <img  src="assets/icons/icon31.png">
          </span>
  
          <div id="bar-chart-year-tonnage"></div>
        </div>
          </div>
          
            
            
        </div>
  <div class="custom-cadre">
      <div class="row">
            <div style="display: flex; padding-right: 20px; position: relative;" class="benef-table">
            <span class="fa" style="left: 5px;">
              <img   src="assets/icons/icon41.png">
  
            </span>
  
  
              <div style="flex: 5; height: 100%;" class=" border-style">
                <span class="chart-title">Tonnage</span><br>
              <span class="chart-sub-title">Dernières 24h</span>
                <div class="col-md-12 no-padding" style="margin-top: 10px">
                    <table class="table table-condensed table-striped div-24h">
                      <thead>
                      <tr class="custom-table-thead">
                        <th style="width: 15px"></th>
                        <th  style="text-align: left;">Section</th>
                        <th style="text-align: left;">Tonnage(Tonne)</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let entr of listTonnageSectionYesterday; let i = index" >
                        <td class="vertical-align-uppercase" style="width: 1px;">
                          {{i + 1}}
                        </td>
                        <td style="text-align: left;" class="vertical-align-uppercase">{{entr.section}}</td>
                        <td style="text-align: left;" class="vertical-align-uppercase">{{round(entr.sommeTonneByDayBySection) | moneyFormat}}</td>
                      </tr>
                      </tbody>
          
                    </table>
                  </div>
              </div>
  
              <div style="flex: 5; height: 100%; position: relative;" class=" border-style">
                <span class="fa">
            <img  src="assets/icons/icon31.png">
  
                </span>
            <span class="chart-title">Tonnage</span><br>
            <span class="chart-sub-title">Semaine dernière</span>
                <div class="col-md-12 no-padding" style="margin-top: 10px">
                    <table class="table table-condensed table-striped">
                      <thead>
                      <tr class="custom-table-thead">
                        <th style="width: 16px"></th>
                        <th>Section</th>
                        <th>Tonnage(Tonne)</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let entr of listTonnageSectionLastWeek; let i = index" >
                        <td class="vertical-align-uppercase" style="width: 1px;">
                          {{i + 1}}
                        </td>
                        <td class="vertical-align-uppercase">{{entr.section}}</td>
                        <td class="vertical-align-uppercase">{{round(entr.sommeTonneByWeekBySection) | moneyFormat}}</td>
                      </tr>
                      </tbody>
          
                    </table>
                  </div>
            </div>
  
            <div style="flex: 5; height: 100%; position: relative;" class=" border-style">
              <span class="fa">
            <img src="assets/icons/icon31.png">
  
              </span>
              <span class="chart-title">Tonnage</span><br>
            <span class="chart-sub-title">Mois dernier</span>
              <div class="col-md-12 no-padding" style="margin-top: 10px">
                  <table class="table table-condensed table-striped">
                    <thead>
                    <tr class="custom-table-thead">
                      <th style="width: 16px"></th>
                      <th>Section</th>
                      <th>Tonnage(Tonne)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let entr of listTonnageSectionLastMonth; let i = index" >
                      <td class="vertical-align-uppercase" style="width: 1px;">
                        {{i + 1}}
                      </td>
                      <td class="vertical-align-uppercase">{{entr.section}}</td>
                      <td class="vertical-align-uppercase">{{round(entr.sommeTonneByMonthBySection) | moneyFormat}}</td>
                    </tr>
                    </tbody>
        
                  </table>
                </div>
          </div>
          <div style="flex: 5; height: 100%; position: relative;" class=" border-style">
                <span class="fa">
                  <img  src="assets/icons/icon31.png">
                </span>
                <span class="chart-title">Tonnage</span><br>
                <span class="chart-sub-title">Du {{formateDate(dateDebut)}} au {{formateDate(dateFin)}}</span>
                <div class="col-md-12 no-padding" style="margin-top: 10px">
                    <table class="table table-condensed table-striped">
                      <thead>
                      <tr class="custom-table-thead">
                        <th style="width: 16px"></th>
                        <th>Section</th>
                        <th>Tonnage(Tonne)</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let entr of listSectionTonnageRange; let i = index" >
                        <td class="vertical-align-uppercase" style="width: 1px;">
                          {{i + 1}}
                        </td>
                        <td class="vertical-align-uppercase">{{entr.section}}</td>
                        <td class="vertical-align-uppercase">{{round(entr.sommeTonneByDateBySection) | moneyFormat}}</td>
                      </tr>
                      </tbody>
  
                    </table>
                  </div>
            </div>
            </div>
          
      </div>
      <br>
          <div class="row" style="padding-right: 35px;">
             <strong style="margin-left: 16px;">Les 10 dernières livraisons en attente</strong>
              <!-- <a class="pull-right" style="margin-right: 9px; position: relative;"><strong style="font-size: 16px;color: #159739;">En attente de livraison: {{totalAttente | moneyFormat}}/{{MontantAttente | moneyFormat}}</strong></a> -->
                <div style=" position: relative" class="col-md-12 border-style" style="margin-top: 12px;">
                  <span class="fa">
                  <img  src="assets/icons/icon31.png">
                  </span>
  
                    <table class="table table-condensed table-striped" style="padding: 20px">
                      <thead>
                        <tr class="custom-table-thead">
                          <th> </th>
                          <th>Date</th>
                          <th>Quantité(Tonne)</th>
                          <th>Nom</th>
                          <th>Prenom</th>
                          <th>Section</th>
                          <th class="text-right">Montant</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of lastLigneRechargement | slice: (currentPage-1) * 10 : currentPage * 10; let i = index" (click)="client = item"
                          style="font-size: 10px !important">
                          <td>{{i+1}}</td>
                          <td class="vertical-align-uppercase">{{item.createdAt}}</td>
                          <td class="vertical-align-uppercase">{{item.quantite}}</td>
                          <td class="vertical-align-uppercase">{{item.nom}}</td>
                          <td class="vertical-align-uppercase">{{item.prenom}}</td>
                          <td class="vertical-align-uppercase">{{item.sectionLibelle}}</td>
                          <td class="vertical-align-uppercase text-right">{{ (item.montant) | moneyFormat }}</td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- <pagination [boundaryLinks]="true" [totalItems]="lastLigneRechargement.length"
                                [maxSize]="maxSize" [itemsPerPage]="'10'"
                                [(ngModel)]="currentPage" class="pagination-sm pull-right" previousText="&laquo;"
                                nextText=" &raquo;" firstText="&laquo;" lastText="&raquo;" name="pagination"
                                (pageChanged)="pageChanged($event)"></pagination> -->
              </div>
          </div>
          
          <br>
        
          <strong style="margin-left: 16px;">Top 10 des meilleurs planteurs</strong>
          <div class="row" style="padding-right: 11px; margin-top: 15px;">
            <div class="col-md-4" *ngFor="let itemCulture of listClientTop10; let i = index">
  
              <div style="min-height: 364px; position: relative;" class=" border-style">
                  <span class="fa">
                <img src="assets/icons/icon31.png">
      
                  </span>
                <span class="chart-sub-title">{{itemCulture.cultureLibelle}}</span>
                  <div class="col-md-12 no-padding" style="margin-top: 2px">
                    <table class="table table-condensed table-striped" style="padding: 20px">
                      <thead>
                        <tr class="custom-table-thead">
                          <th> </th>
                          <th>Nom</th>
                          <th>Prenom</th>
                          <th>Rendement (Tonne)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of itemCulture.listInfo; let j = index"
                          style="font-size: 10px !important">
                          <td>{{j+1}}</td>
                          <td class="vertical-align-uppercase">{{item.nom}}</td>
                          <td class="vertical-align-uppercase">{{item.prenom}}</td>
                          <td class="vertical-align-uppercase">{{(item.rendement) | moneyFormat}}</td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
              </div>
  
            </div>
              
          </div>

    <strong style="margin-left: 16px;">Top 10 des meilleurs délégués</strong>
    <div class="row" style="padding-right: 11px; margin-top: 15px;">
      <div class="col-md-4" *ngFor="let itemCulture of listDelegueTop10; let i = index">

        <div style="min-height: 364px; position: relative;" class=" border-style">
                  <span class="fa">
                <img src="assets/icons/icon31.png">

                  </span>
          <span class="chart-sub-title">{{itemCulture.cultureLibelle}}</span>
          <div class="col-md-12 no-padding" style="margin-top: 2px">
            <table class="table table-condensed table-striped" style="padding: 20px">
              <thead>
              <tr class="custom-table-thead">
                <th> </th>
                <th>Nom</th>
                <th>Prenom</th>
                <th>Rendement (Tonne)</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of itemCulture.listInfo; let j = index"
                  style="font-size: 10px !important">
                <td>{{j+1}}</td>
                <td class="vertical-align-uppercase">{{item.nom}}</td>
                <td class="vertical-align-uppercase">{{item.prenom}}</td>
                <td class="vertical-align-uppercase">{{(item.rendement) | moneyFormat}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>

    </div>
  
  </div> 
  </div>
</div>
