import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {NotificationService} from '../../../shared/utils/notification.service';
import {UtilitiesService} from '../../../shared/utils/utilities.service';

import {Router} from '@angular/router';
import {Api} from '../../../shared/utils/api/api';
import {DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-form-preview-print',
  templateUrl: './form-preview-print.component.html',
  styleUrls: ['./form-preview-print.component.css']
})
export class FormPreviewPrintComponent implements OnInit {

  itemToSave : any;
  endPointReport : any;
  currentLink : any;

  constructor(public sanitizer: DomSanitizer,private api:Api,public bsModalRefPreview: BsModalRef,public router:Router,private notificationService : NotificationService,private utilities : UtilitiesService) {
    this.itemToSave = {
      isReady : false,
      //filUri : "https://angular.io/api/platform-browser/DomSanitizer",
      filUri : "http://localhost:82/PointagePro/wepApi/Report/ReportsPPM/PaiementElectronicPeriodeOuReference.php?date_debut=2018-03-01&date_fin=2018-03-04&libellereport=BORDEREAU&libellesociete=ANSUT#toolbar=1&amp;zoom=80,0,0&amp;page=1&amp;view=FitH"
    };

    setTimeout(() => {
      let link  = this.currentLink;
      this.cleanURL(link);
    }, 1000);

  }

  cleanURL(oldURL ) {
      console.log(oldURL);

      if(!this.itemToSave.isReady && oldURL)
      {
        this.itemToSave.isReady = true;
        this.itemToSave.fileUrlFinale = this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
      }
    
    }

    hideModale()
    {
      this.bsModalRefPreview.content.isSave = false;
      this.bsModalRefPreview.hide();

    }

   

  ngOnInit() {
  }

  // ngAfterViewInit() {
  //   let fileUriReport ="";
  //   let endPointReportParam = this.utilities.getReportParametre();
  //   fileUriReport = config.API_REPORT_BASE+ endPointReportParam +"#toolbar=1&amp;zoom=100,0,0&amp;page=1&amp;view=FitH";
  //   this.cleanURL(fileUriReport);
  // }

}
