import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {UserService} from "../../user/user.service";


@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  user:any;

  bsModalRef: BsModalRef;
  itemToSave : any;

  isSaAdmin : boolean;
  isTenant : boolean;
  isMarchand : boolean;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(public router:Router,private modalService: BsModalService,private userService: UserService) {
  }
  

  getPermission(code1,code2)
  { 
    this.isMarchand = false;
    this.isSaAdmin = false;
    this.isTenant = false;

    if(code1 == 'SA' || code2=='ADMIN')
    {
      return true;
    }
    
    if(code1 == 'MARCCHAND_ADMIN' || code2=='MARCCHAND_USER')
    {
      return true
    }
    
    if(code1 == 'TENANT_ADMIN' || code2=='TENANT_USER')
    {
      return true;
    }

    return false;

  }

  
  ngOnInit() {
    this.user = this.userService.getCurrentUser();
  }

}
