<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
  <!--
  <button (click)="sortTest()">Sort test</button>
   fil d actualite -->
  <div class="row">
    <div class="form-group col-md-12">
      <div class="row">
        <div class="col-md-7">
          <label style="font-weight: 700">
            Sélectionner crédit :
          </label>
          <select style="width: 75%; display: inline-block" class="form-control custom_input" [(ngModel)]="selectedDonationId"
            id="selectedDonationId" (change)="selectDonation(templateCreate)" #ctrl="ngModel" name="selectedDonationId">
            <option></option>
            <option *ngFor="let donation of listDonations " [ngValue]="donation.id">
              {{ donation.libelle }}
            </option>
          </select>
        </div>
        <!-- <ng-select [(ngModel)]="selectedDonationId"
                  (change)='selectDonation(templateCreate)'
                  style="width: 68%; display: inline-block"
                  placeholder="Rechercher par crédit" id="selectedDonationId"
                  [ngModelOptions]="{standalone: true}">
                  <ng-option *ngFor="let donation of listDonations" [value]="donation.id">
                      {{(donation.libelle)}}
                  </ng-option>
              </ng-select> -->
        <!-- <i class="fa fa-refresh fa-2x" style="font-size: 20px; cursor: pointer" (click)="getOperations()"></i> -->
      
        <div class="col-md-2">
          <button (click)="openFormModalModal(templateCreate)" class="btn btn-md custom_border_radius btn-bg-new padding-30"
            type="button">
            Ajouter
          </button>
        </div>
        <div class="col-md-3 text-right">
          <button *ngIf="size && size>1" (click)="onValidateOperation()" class="btn btn-md custom_border_radius padding-30"
            style="background: red !important;" type="button">
            Supprimer
          </button>
        </div>
      </div>

      <div class="col-md-12 no-padding" style="margin-top: 10px">

        <table class="table table-condensed table-striped">
          <thead>
          <tr class="custom-table-thead">
            <th style="width: 45px">
              <a (click)="getData()"> <i
                class="fa fa-refresh"></i> </a>
            </th>
            <th> </th>
            <th> Statut </th>
            <th> Date creation </th>
            <th>Nom bénéficiaire </th>
            <th>Contact bénéficiaire</th>
            <th class="text-right">Montant</th>
            <th style="text-align: center; width: 75px"> Action </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let entr of listBeneficiairesTable; let i = index" >
            <td class="vertical-align-uppercase">
              {{i + 1}}
            </td>
            <td class="vertical-align-uppercase" >
              <input *ngIf="entr.isActived && !entr.isWithdrawal" type="checkbox" name="isCheked" 
              [(ngModel)]="entr.isChecked" (change)="onCheckItem()">
            </td>
            <td class="vertical-align-uppercase" >
              <i *ngIf="entr.isActived && !entr.isWithdrawal" class="fa fa-check-circle fa-2x" aria-hidden="true"
                 style="color: orange" 
                 tooltip="Montant déposé sur la carte"
                   placement="right"
              ></i>

                  <!-- <i *ngIf="entr.isActived && !entr.isWithdrawal" class="fa fa-check-circle fa-2x" aria-hidden="true"
                  style="color: #e8091b" 
                  tooltip="Montant non déposé sur la carte"
                   placement="right"
                  ></i> -->

                  <i *ngIf="entr.isWithdrawal && !entr.isActived"  class="fa fa-check-circle fa-2x" aria-hidden="true"
                  style="color: #0aa66e" 
                  tooltip="Montant rétiré"
                   placement="right"
                  ></i>

            </td>
          
            <td class="vertical-align-uppercase">{{entr.createdAt}}</td>
            <!--              <td class="vertical-align-uppercase">{{entr.clientNom}} {{entr.clientPrenoms}}</td>-->
            <td class="vertical-align-uppercase">{{entr.clientNom}} {{entr.clientPrenoms}}</td>
            <td class="vertical-align-uppercase">{{entr.clientTelephone}}</td>
            <td class="vertical-align-uppercase text-right"  style="font-weight: 700">{{entr.valeurRecut | moneyFormat}}</td>
            <td style="text-align: center">
              <a *ngIf="entr.isActived && !entr.isWithdrawal" title="Modifier" (click)="onValidateOperation(entr)">
                <i class="fa fa-trash-o" style="color: red"> </i>
              </a>
            </td>
          </tr>

          <tr *ngIf="listBeneficiairesTable && listBeneficiairesTable.length > 0">
            <td colspan="9">
              <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
                <pagination [boundaryLinks]="true" [totalItems]="totalItems" [maxSize]='itemPerPagePagination' [itemsPerPage]="itemsPerPage"
                            [(ngModel)]="currentPage" class="pagination-sm" previousText="&laquo;" nextText=" &raquo;"
                            firstText="&laquo;" lastText="&raquo;" name="pagination" (pageChanged)="pageChanged($event)">
                </pagination>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot *ngIf="!listBeneficiairesTable || listBeneficiairesTable.length == 0">
          <tr>
            <td colspan="9" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
          </tr>

          </tfoot>

        </table>
      </div>

    </div>
  </div>
  <!-- fin file d actualite -->



  <ng-template #templateCreate>
    <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">
          {{selectectedDonation.libelle | truncate:['60']}}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="form-group col-lg-12">

            <div class="row">
              <div class="col-md-12">
                <div class="form-group col-md-12" style="padding: 0">
                  <label>Type de repartition :
                    <span class="required_champs">*</span>
                  </label>
                  <select class="form-control custom_input" id="isForSeveral" [(ngModel)]="isForSeveral" (change)="resetFilterredClients()" #ctrl="ngModel"
                          name="isForSeveral" >
                    <option [ngValue]="true">
                      Repartition identique
                    </option>
                    <option [ngValue]="false">
                      Repartition non identique
                    </option>
                  </select>
                </div>
                
                <div class="row" style="padding-left: 10px;padding-right: 10px">

                  <div class="form-group col-md-6">
                    <label>Critères:</label>
                    <div *ngFor="let option of options; let i = index">
                      <input type="checkbox" (change)="onSelectOption(option,'criteria',$event)" [(ngModel)]="option.selected" id="{{i}}" name="{{i}}" value="{{option.key}}">
                      <label for="{{i}}">{{option.value}}</label><br>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Ordre:</label>
                    <div *ngFor="let order of orders; let j = index">
                      <input id="{{10 + j}}" type="radio" [(ngModel)]="order.selected" (change)="onSelectOption(order,'ordering',$event)" name="order" [value]="order.key">
                      <label for="{{10 + j}}">{{order.value}}</label><br>
                    </div>
                  </div>

                </div>

                <div class="form-group col-md-12" *ngIf="isForSeveral">
                  <label>Valeur :
                    <span class="required_champs">*</span>
                  </label>
                  <div class="row">
                    <div class="col-md-8" style="padding: 0">
                      <input class="form-control custom_input" autocomplete="off" [(ngModel)]="valeurToApply" name="valeurToApply" required
                             placeholder="Valeur" type="text" mask="separator">
                    </div>
                    <div class="col-md-4">
                      <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="applyValeur() ">
                        Appliquer
                      </button>
                    </div>
                  </div>
                </div>
                
                <div class="form-group col-md-12 benef-table" style="max-height: 300PX;overflow: auto" >
                  <div class="row">
      
                      <label>Répartition <a (click)="resetFilterredClients()"><i class="fa fa-refresh"></i></a>:
                      </label>
                      <input type="text" class="form-control custom_input" style="display: inline-block; width: 50%;"
                      placeholder="Entrer nom agriculteur svp"
                      name="fullSearch" [(ngModel)]="benefKey" (ngModelChange)="this.onFullSearch.next($event)">
                    
                   
                  </div>
                  
                  

                  <table class="table table-condensed">
                    <thead>
                    <tr>
                      <th> Agriculteur  </th>
                      <th > Valeur </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr *ngFor="let bnef of listClientsFiltered ">
                      <td>
                        <a *ngIf="bnef.valeurRecut"  (click)="deleteRow(bnef)" style="margin-right: 5px">
                          <i class="fa fa-times" aria-hidden="true" style="color: red;"></i>
                         </a>
                        {{bnef.libelle}} </td>
                      <td>
                        <input [disabled]="isForSeveral" class="form-control custom_input"  autocomplete="off" [(ngModel)]="bnef.valeurRecut" name="valeurRecut" placeholder="Valeur"
                               required type="text" mask='separator' [ngModelOptions]="{standalone: true}" (keyup)="displayClientQuantiteValeur(bnef)" maxlength="12">
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="form-group col-md-12">
          <div class="row">
            <div class="col-md-6 text-left">
              Total : <strong>{{selectectedDonation.valeurOperation | moneyFormat}}</strong>
            </div>
            <!-- <div *ngIf="totalRepartie ==0" class="col-md-4 text-left">
              Repatie : <strong>{{totalRepartie}}</strong>
            </div>
            <div *ngIf="totalRepartie>0" class="col-md-4 text-left">
              Repatie : <strong>{{totalRepartie | moneyFormat}}</strong>
            </div> -->
            <div class="col-md-6 text-right">
              Reste : <strong>{{resteArepartir | moneyFormat}}</strong>

            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left">(<span class="required_champs">*</span>) Champs requis</b>

        <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal()">
          Annuler
        </button>

        <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItem(itemToSave) ">
          Valider
        </button>

      </div>
    </form>
  </ng-template>
</div>
