<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
  <div class="custom-cadre">
    <!-- Fiche de production-->
    <div class="row">
      <div class="form-group col-md-12">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <a class="btn-back pull-left" routerLink="/clients">
                <i class="fa fa-backward fa-2x" title="Retour" aria-hidden="true"></i> 
              </a>
              <h1 class="custom-title-bold"> PROFIL </h1>
            </div>
            <div class="col-md-6">
            </div>
          </div>
          <div class="col-md-12 no-padding" style="margin-top: 10px">
            <div class="form-group col-md-12 no-padding">
              <table class="table table-condensed table-striped">
                <tbody>
                  <tr>
                    <td rowspan="8" style="width: 12%;vertical-align: middle;text-align: center">
                      <img src="{{currentClient.photoPath || 'assets/img/decoupes/user_default.png'}}"
                        style="width: 110px;height: 110px;" />
                    </td>
                  </tr>
                  <tr>
                    <td class="label-fiche"> Nom & Prénoms </td>
                    <td> {{currentClient.nom +' '+currentClient.prenoms}} </td>
                    <td class="label-fiche" style="border-left: 1px solid #eceeee"> N° Pièce </td>
                    <td> {{currentClient.numPiece}} </td>
                  </tr>
                
                  <tr>
                    <td class="label-fiche"> Télephone </td>
                    <td> {{phoneNumberSeparator(currentClient.telephone)}} </td>
                    <td class="label-fiche" style="border-left: 1px solid #eceeee"> Situation matrimoniale </td>
                    <td> {{currentClient.statutMatrimoniale}} </td>
                  </tr>
                  <tr>
                    <td class="label-fiche"> Sexe </td>
                    <td> {{ currentClient.sexe}}</td>
                    <td class="label-fiche" style="border-left: 1px solid #eceeee"> Surface cultivée </td>
                    <td> {{round(currentClient.sommeSurfaceCultive)}} </td>
                  </tr>
                  <tr>
                    <td class="label-fiche"> Date souscription carte agricole  </td>
                    <td> {{ currentClient.annSouscriptionCarte}} </td>
                    <td class="label-fiche" style="border-left: 1px solid #eceeee"> Nombre d'enfant </td>
                    <td> {{ currentClient.nbreEnfant}} </td>
                  </tr>
                  <tr>
                    <td class="label-fiche"> Date souscription coopérative </td>
                    <td> {{ currentClient.annSouscriptionCoop}} </td>
                    <td class="label-fiche" style="border-left: 1px solid #eceeee"> Date de naissance </td>
                    <td> {{ currentClient.dateNais}} </td>
                  </tr>
                  
                  <tr style="padding-bottom: 0px">
                    <td colspan="4"
                      style="background: #4e4945; color: #fff; font-weight: bold;padding-bottom: 0px;font-size: 15px">
                      Solde : {{( currentClient.soldeClientTenancy | formatmillier ) || 0 }}
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>
    </div> 
    <!-- fin file d actualite -->
  </div>
</div>

<div id="content" [ngBusy]="{busy: busyGetMouvements, message: 'Veuillez patienter ...', backdrop: false}">

  <div class="custom-cadre">
    <!-- fil d actualite -->
    <div class="row">
      <div *ngIf="objectifs.length" class="col-md-12" style="padding: 0 24px">
        <h6>Objectifs de production (Tonne)</h6>
        <!-- <span class="estimation-item" *ngFor="let item of objectifs">
          <span>Année: <b>{{item.annee}}</b> </span>
          <span>Culture: <b>{{item.cultureLibelle}}</b> </span>
          <span>Estimation: <b>{{item.estimation}} <b *ngIf="item.cumulQuantite">sur {{item.cumulQuantite}}</b> </b> </span>
        </span> -->
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Année</th>
              <th>Culture</th>
              <th>Objectif</th>
              <th>Réalisé</th>
              <th>Taux</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of objectifs">
              <td>{{item.annee}}</td>
              <td>{{item.cultureLibelle}}</td>
              <td>{{item.estimation}}</td>
              <td>{{item.cumulQuantite}}</td>
              <td> <b>{{item.taux}}%</b> </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="form-group col-md-12">
        <div class="col-md-6">
          <h1 class="custom-title-bold"> HISTORIQUE DES TRANSACTIONS </h1>
        </div>
        <div class="col-md-12">
          <div class="col-md-12 no-padding" style="margin-top: 10px">
          <table class="table table-condensed table-striped">
              <thead> 
                <!-- <tr> 
                  <th colspan="15" style="background-color: #fff;padding: 0px !important">
                    <div class="form-group col-md-3 no-padding">
                    </div>
                    <div class="form-group col-md-3 no-padding pull-right">
                        <button class="btn btn-md pull-right custom_border_radius btn-import btn-bg-default padding-30" type="button"
                          (click)="exportData()" style="margin-top: 5px;">
                          Exporter </button>
                      </div>
                  </th>
                </tr> -->
                <tr class="custom-table-thead">
                  <th style="width: 5px;">
                    <a (click)="getMouvement()"> <i class="fa fa-refresh"></i> </a>
                  </th>
                  <th>Opération</th>
                  <!-- <th>Coopérative</th> -->
                  <!-- <th  style="width: 74px;">Moyen de paiement</th> -->
                  <th>Identifiant</th>
                  <th>N°Bordereau</th>
                  <th>Date</th>
                  <th >Culture</th>
                  <th style="width: 110px;">Quantité(Tonne)</th>
                  <th>Prix</th>
                  <!-- <th >Nom</th>
                  <th >Prénoms</th> -->
                  <th class="text-left">Montant</th>
                  <th class="text-left">Ancien solde</th>
                  <th class="text-left">Solde</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ListeRechargments; let i = index"
                  style="font-size: 10px !important">
                  <td>{{(i+1) + ((currentPage - 1) * itemsPerPage)}}</td>
                  <td>
                    <label 
                      [class.label-warning]="item.sens ==='AVANCE'"
                      [class.label-primary]="item.sens ==='LIVRAISON'"
                      [class.label-inverse]="item.sens ==='REGLEMENT'"
                      [class.label-success]="item.sens ==='PRE-FINANCEMENT'"
                      [class.label-danger]="item.sens ==='DON ESPECE'" class="label">{{item.sens}}</label>
                  </td>

                  <!-- <td class="vertical-align-uppercase">
                                  {{item?.tenantLibelle}}
                              </td> -->
                  <!-- <td class="vertical-align-uppercase">
                                  {{item?.operationLibelle}}
                              </td> -->
                  <td class="vertical-align-uppercase">{{item.dataClient.code}}</td>
                  <td class="vertical-align-uppercase">
                    <a [href]="item.pathFile" target="_blank"> {{item.numeroBorderaux}} </a>
                  </td>
                  <td class="vertical-align-uppercase">{{item.createdAt}}</td>
                  <td class="vertical-align-uppercase">{{item.cultureLibelle}}
                  </td>
                  <td class="vertical-align-uppercase text-right">
                    {{item.quantite | formatmillier}}</td>
                  <td class="vertical-align-uppercase text-right">
                    {{item.coutTonnage | formatmillier}}</td>
                  <!-- <td class="vertical-align-uppercase" >{{item.dataClient.nom}}</td>
                              <td class="vertical-align-uppercase" >{{item.dataClient.prenoms}}</td> -->

                  <td class="vertical-align-uppercase text-right">{{ (item.montant | formatmillier) || 0 }}</td>
                  <td class="vertical-align-uppercase text-right">{{ (item.ancienSolde | formatmillier) || 0}}
                  </td>
                  <td class="vertical-align-uppercase text-right">{{ (item.nouveauSolde | formatmillier) || 0}}
                  </td>
                </tr>
              </tbody>
              <tfoot *ngIf="!ListeRechargments || ListeRechargments.length == 0">
                <tr>
                  <td colspan="15" style="text-align:center; font-weight:bold">Aucune donnée
                    disponible</td>
                </tr>
              </tfoot>
            </table>
            <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
              <pagination [boundaryLinks]="true" [totalItems]="itemsSize" [maxSize]='itemPerPagePagination'
                [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage" class="pagination-sm pull-right"
                previousText="&laquo;" nextText=" &raquo;" firstText="&laquo;" lastText="&raquo;" name="pagination"
                (pageChanged)="pageChanged($event)"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
          <div class="col-md-4">
              <div id="pie-mouvement"></div>
          </div>
          <div class="col-md-8">
              <div id="line-mouvement"></div>
          </div>
      </div> -->
    <!-- fin file d actualite -->

  </div>



</div>