import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from 'src/app/shared/utils/notification.service';
import { UtilitiesService } from 'src/app/shared/utils/utilities.service';
import { UserService } from 'src/app/shared/user';
import { HttpHeaders } from '@angular/common/http';
import { Api } from 'src/app/shared/utils/api/api';
declare const Swal: any;
@Component({
  selector: 'app-main-gestionnaires',
  templateUrl: './main-gestionnaires.component.html',
  styleUrls: ['./main-gestionnaires.component.css']
})
export class MainGestionnairesComponent implements OnInit {
  resultDelaiPaiements: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;

  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  currentTenant: any = {};
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination = 10;
  admin: any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  listDonateurs=[];
  listBanques=[];
  ListeRoles=[];
  idToModify: any;
  isEditLogin: boolean;


  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.itemToSearch = {};
  }

  // MULTI SELECT


  hideModal() {
    this.modalRef.hide();
  }
  getDataRole() {
    var request = {
        user: this.user.id,
        data: {
        }
    }

    this.api.post('role/getByCriteria', request)
        .subscribe(
            res => {
                console.log('les roles', res);
                if (res && res['items']) {
                    this.ListeRoles = res['items'];
                }
            },
            err => {
                console.log("Error occured", err);
            }
        );
}

  getTenancyBanque() {
    const request = {
        user: this.user.id,
        data: {
          tenancyId: this.currentTenant.id
        }
    }
    this.busySave = this.api.post('banqueTenancy/getByCriteria', request)
        .subscribe((res: any) => {
            console.log('getTenancyBanque', res);
            if (res && res.items) {
                this.listBanques = res.items || [];
            }
        });
}

  openFormModalModal(template, itemToModified?: any) {
    this.cancelSave();
    if (itemToModified) {
      itemToModified.firstNameAdmin = itemToModified.datasAdmin[0].userFirstName
      itemToModified.lastNameAdmin= itemToModified.datasAdmin[0].userLastName
      itemToModified.emailAdmin = itemToModified.datasAdmin[0].email
      itemToModified.telephoneAdmin = itemToModified.datasAdmin[0].telephone
      itemToModified.loginAdmin = itemToModified.datasAdmin[0].userLogin
      this.idToModify = itemToModified.datasAdmin[0].userId
      this.itemToSave = { ...itemToModified };
      console.log("data to save:",this.itemToSave);
      
    }
    this.modalRef = this.modalService.show(template, { class: 'gray modal-md' });
  }


  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }


  getData() {
    console.log("...");
    
    if (!this.currentTenant || !this.currentTenant.code) {return};
    const options = {
    
      headers: new HttpHeaders({
        'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004',
        lang: 'fr'
      })
    }
    const request = {
      user: this.user.id,
      data: {
        tenancyId: this.currentTenant.id
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }
    this.busyGet = this.api.post('gestionnaire/getByCriteria', request)
        .subscribe((res: any) => {
              console.log('listDonateurs', res);
              if (res && res['items']) {
                this.listDonateurs = res['items'];
                this.totalItems = res['count'] ? res['count'] : 0;
              } else {
                this.listDonateurs = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }

  confirmSaveItem(obj: any) {
    if (!obj) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner les champs obligatoires.');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.nomResponsable) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner nom svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.telephone) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner contact svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.banqueId) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner banque svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.firstNameAdmin) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner nom admin svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.lastNameAdmin) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner prenoms admin svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.emailAdmin) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner email admin svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.telephoneAdmin) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner contact admin svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.loginAdmin) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner login admin svp!');
      return;
    }
    // if (!this.itemToSave || !this.itemToSave.roleIdAdmin) {
    //   this.notificationService.showMessageError('Erreur', 'Veuillez renseigner role admin svp!');
    //   return;
    // }
    let itemToSaveModif = {
      id:this.itemToSave.id,
      code:this.itemToSave.code,
      nomResponsable:this.itemToSave.nomResponsable,
      banqueId:this.itemToSave.banqueId,
      tenancyId:this.currentTenant.id,
      telephone:this.itemToSave.telephone,
      dataAdmin:{
        id:this.idToModify,
        email:this.itemToSave.emailAdmin,
        firstName:this.itemToSave.firstNameAdmin,
        lastName:this.itemToSave.lastNameAdmin,
        login:this.itemToSave.loginAdmin,
        telephone:this.itemToSave.telephoneAdmin,
        roleId:this.itemToSave.roleIdAdmin,

      }


    }
    Swal.fire({
      title: !obj.id ? 'Enregistrement gestionnaire' : 'Modification gestionnaire',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.saveItem(itemToSaveModif);
      } else {
        console.log('bnjr');
      }
    })
  }



  confirmDeleteItem(obj: any) {
    Swal.fire({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.deleteItem(obj);
      } else {
        console.log('bnjr');
      }
    })

  }


  cancelSave() {
    this.idToModify= null
    this.itemToSave = {};
  }


  saveItem(item: any) {
    if (!this.currentTenant || !this.currentTenant.code) {return};

    const request = {
      user: this.user.id,
      datas: [item]
    }
    console.log('data sent to create gestionnaire', request)
    console.log('data sent to create gestionnaire', JSON.stringify(request))
    this.busySave = this.api.post('gestionnaire/' + (item.id ? 'update' : 'create'), request)
        .subscribe((res: any) => {
              console.log('resul', res);
              if (!res['hasError'] && res['items']) {
                this.notificationService.showMessageSucces('Tenan', 'Opération effectuée avec succès.');
                this.currentPage = 1;
                this.hideModal();
                this.cancelSave();
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Erreur', this.utilities.messageInterpretor(err));
            });
  }


  deleteItem(obj: any) {
    const request = {
      user: this.user.id,
      datas: [obj]
    }
    this.busyGet = this.api.post('gestionnaire/delete', request)
        .subscribe(
            res => {
              console.log(res);
              if (!res['hasError']) {
                this.notificationService.showMessageSucces('SuccèsÒ', 'Suppression effectuée avec succès');
                this.currentPage = 1;
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            }
        );
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    this.getDataRole()
    this.userService.currentData.subscribe(currentData => {
      this.currentTenant = currentData;
      this.getData();
      this.getTenancyBanque();
      
    });
    window.scrollTo(0, 0);
  }
}
