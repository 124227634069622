<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">

  <!-- fil d actualite -->
  <div class="row">
    <div class="form-group col-md-12">
      <div class="col-md-12">

        <button (click)="openFormModalModal(templateCreate)"
          class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button">
          Ajouter </button>
        <br />

        <div class="col-md-12 no-padding" style="margin-top: 10px">
          <table class="table table-condensed table-striped">
            <thead>
              <tr class="custom-table-thead">
                <th style="width: 45px">
                  <a (click)="getData()"> <i class="fa fa-refresh"></i>
                  </a>
                </th>
                <th> Date creation </th>
                <th>Libellé</th>
                <th>Coopérative</th>
                <th>Marchand </th>
                <th>Nature </th>
                <th>Debut </th>
                <th>Fin </th>
                <th>Quantité</th>
                <th style="text-align: center; width: 75px"> Action </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let entr of listPromos; let i = index">
                <td class="vertical-align-uppercase">
                  {{i + 1}}
                </td>
                <td class="vertical-align-uppercase">{{entr.createdAt}}</td>
                <td class="vertical-align-uppercase">{{entr.libelle}}</td>
                <td class="vertical-align-uppercase">{{entr.tenancyRaisonSociale}}</td>
                <td class="vertical-align-uppercase">{{entr.marchantNom}}</td>
                <td class="vertical-align-uppercase">{{entr.typeDonCode}}</td>
                <td class="vertical-align-uppercase">{{entr.dateDebut}}</td>
                <td class="vertical-align-uppercase">{{entr.dateFin}}</td>
                <td class="vertical-align-uppercase" style="font-weight: 700">{{entr.quantiteOperation}}</td>

                <td style="text-align: center">
                  <a title="Modifier" (click)="openFormModalModal(templateCreate,entr)">
                    <i class="fa fa-edit" style="color: green"> </i>
                  </a>
                  &nbsp;&nbsp;
                  <a (click)="confirmDeleteItem(entr)">
                    <i class="fa fa-trash-o" style="color: red"> </i>
                  </a>
                </td>
              </tr>

              <tr *ngIf="listPromos && listPromos.length > 0">
                <td colspan="10">
                  <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
                    <pagination [boundaryLinks]="true" [totalItems]="totalItems" [maxSize]='itemPerPagePagination'
                      [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage" class="pagination-sm"
                      previousText="&laquo;" nextText=" &raquo;" firstText="&laquo;" lastText="&raquo;"
                      name="pagination" (pageChanged)="pageChanged($event)">
                    </pagination>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot *ngIf="!listPromos || listPromos.length == 0">
              <tr>
                <td colspan="10" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
              </tr>

            </tfoot>

          </table>
        </div>

      </div>


    </div>
  </div>
  <!-- fin file d actualite -->



  <ng-template #templateCreate>
    <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">
          PROMO
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-lg-12">

            <div class="row">
              <div class="col-md-12">
                <div class="form-group col-md-6">
                  <label>Date debut :
                    <span class="required_champs">*</span>
                  </label>
                  <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(bsValue)]="dateDebut">
                </div>
                <div class="form-group col-md-6">
                  <label>Date fin :
                    <span class="required_champs">*</span>
                  </label>
                  <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(bsValue)]="dateFin">
                </div>
                <div class="row" style="padding: 0 15px;">
                  <div class="form-group col-md-6">
                    <label>Marchand :
                      <span class="required_champs">*</span>
                    </label>
                    <select class="form-control custom_input" id="donateurId" [(ngModel)]="itemToSave.marchandId"
                      #ctrl="ngModel" name="donateurId">
                      <option *ngFor="let marchand of listMarchands " [ngValue]="marchand.marchandId">
                        {{ marchand.marchandRaisonSociale }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Libellé({{itemToSave.libelle?.length || 0}}/50) :
                      <span class="required_champs">*</span>
                    </label>
                    <input maxlength="50" class="form-control custom_input" autocomplete="off"
                      [(ngModel)]="itemToSave.libelle" name="libelle" required placeholder="libelle" type="text">
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label for="">Coopératives</label>
                  <ng-select name="regions" [items]="ListeTenants" bindLabel="raisonSociale" bindValue="id"
                    [multiple]="true" [(ngModel)]="selectedTenants">
                  </ng-select>
                </div>
                <div class="form-group col-md-12">
                  <label for="">Régions</label>
                  <ng-select name="regions" [items]="regions" bindLabel="libelle" bindValue="id" [multiple]="true"
                    [(ngModel)]="selectedRegions">
                  </ng-select>
                </div>
                <div class="form-group col-md-12">
                  <label for="">Cultures</label>
                  <ng-select name="regions" [items]="cultures" bindLabel="libelle" bindValue="id" [multiple]="true"
                    [(ngModel)]="selectedCultures">
                  </ng-select>
                </div>
                <div class="form-group col-md-12">
                  <span (click)="isMoreOptionCollapsed = !isMoreOptionCollapsed" class="more-option">Plus d'options
                    <span [hidden]="isMoreOptionCollapsed" class="plus">
                      <i class="fa fa-plus"></i>
                    </span>
                    <span [hidden]="!isMoreOptionCollapsed" class="plus">
                      <i class="fa fa-minus"></i>
                    </span>
                  </span>
                </div>
                <div *ngIf="isMoreOptionCollapsed" class="ng-container">
                  <div class="form-group col-md-12">
                    <span class="option-item">Sexe</span>
                    <select [(ngModel)]="itemToSave.sexe" name="sexe">
                      <option value=""></option>
                      <option value="F">Feminin</option>
                      <option value="M">Masculin</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12">
                    <span class="option-item">Superficie de</span>
                    <input [(ngModel)]="itemToSave.superficiMin" type="number" name="supFrom">
                    <span class="to">à</span>
                    <input [(ngModel)]="itemToSave.superficiMax" type="number" name="supTo">
                  </div>
                  <div class="form-group col-md-12">
                    <span class="option-item">Nombre d'années de</span>
                    <input [(ngModel)]="itemToSave.nombreMin" type="number" name="nbrAnnFrom">
                    <span class="to">à</span>
                    <input [(ngModel)]="itemToSave.nombreMax" type="number" name="nbrAnnTo">
                  </div>
                  <div class="form-group col-md-12">
                    <span class="option-item">Revenus Annuels de</span>
                    <input [(ngModel)]="itemToSave.revenueMin" type="number" name="revAnnFrom">
                    <span class="to">à</span>
                    <input [(ngModel)]="itemToSave.revenueMax" type="number" name="revAnnTo">
                  </div>
                  <div class="form-group col-md-12">
                    <span class="option-item">Tonnage de</span>
                    <input [(ngModel)]="itemToSave.tonnageMin" type="number" name="tonnFrom">
                    <span class="to">à</span>
                    <input [(ngModel)]="itemToSave.tonnageMax" type="number" name="tonnTo">
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label>Description :
                    <span class="required_champs">*</span>
                  </label>
                  <textarea name="description" id="" style="width: 100%" [(ngModel)]="itemToSave.description"
                    rows="1"></textarea>
                </div>
                <div class="form-group col-md-12">
                  <label>Quantité :
                    <span class="required_champs">*</span>
                  </label>
                  <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.quantiteOperation"
                    name="quantite" required placeholder="quantite" type="text">
                </div>
              </div>
            </div>

          </div>

        </div>


      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left">(
          <span class="required_champs">*</span>) Champs requis</b>

        <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel"
          (click)="hideModal()">
          Annuler
        </button>

        <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 "
          (click)="confirmSaveItem(itemToSave) ">
          Valider
        </button>

      </div>
    </form>
  </ng-template>



</div>