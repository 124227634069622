<header id="header" >
  <div id="logo-group" style="background:#fff">

    <!-- PLACE YOUR LOGO HERE -->
    <span id="logo" style="color:#fff;margin-left: -3px"> 
      <img src="{{'assets/img/decoupes/logo.png'}}" alt="LOGO APP" style="margin-top: 20px;
      margin-left: 15px;
      width: auto;
      height: 50px;"> 
    </span>
    <!-- END LOGO PLACEHOLDER -->

    <!-- Note: The activity badge color changes when clicked and resets the number to 0
    Suggestion: You may want to set a flag when this happens to tick off all checked messages / notifications -->
  </div>
  
  <div class="link-header" style="margin-left: 43px; padding: 7px;">
      <a style="margin-right: 20px" class="active" style="color: #00A984 !important;" 
        *ngIf="user.userTypeCode =='SA' || user.userTypeCode =='ADMIN'"> ESPACE ADMINISTRATION </a>
    
      <a style="margin-right: 20px" class="active" style="color: #00A984 !important;margin-right: 20px"
        *ngIf="(user.userTypeCode !='SA') && (user.userTypeCode !='ADMIN') ">
        <span *ngIf="user?.datasMarchand && (user.userTypeCode == 'MARCHAND_ADMIN' || user.userTypeCode == 'MARCHAND_USER' ) ">{{ user?.datasMarchand[0]?.marchandRaisonSociale | uppercase}}</span>
        <span *ngIf="user?.datasTenancy && (user.userTypeCode == 'TENANCY_ADMIN' || user.userTypeCode == 'TENANCY_USER' ) "> {{ user?.datasTenancy[0]?.tenancyRaisonSociale | uppercase}} </span>
         
        
        </a>
    

  </div>

  <!-- pulled right: nav area -->
  <div class="pull-right">

    <sa-collapse-menu *ngIf="false"></sa-collapse-menu>
   
    <div class="col-md-12" style="height: 64px; margin-top: 22px;font-weight: bold">
      <ul id="mobile-profile-img" class="header-dropdown-list hidden-xs padding-5">
          <li class="">
            <div (click)="toggleDropDrown()" class="dropdown-toggle no-margin userdropdown" data-toggle="dropdown">
              <div class="col-md-3">
                  <img src="{{imageDisplayUrl || 'assets/img/decoupes/user_default.png'}}"  alt="User" class="online" style="border:0;background: #fff;
                  border-radius: 50%;
                  padding: 2px; width: 50px; height: 50px; margin-right: 40px;" 
                  />
              </div>
              <div class="col-md-9" *ngIf="user && user.id" style="padding-top: 25px;padding-right: 0px">
                  <span style="color: #000; text-transform: uppercase">{{ truncateValue(user?.firstName)}} ({{ truncateValue(user.userTypeLibelle,7)}}) <i class="fa fa-caret-down"></i> </span>
              </div>
            </div>
            
            <ul class="dropdown-menu pull-right" style="width: 200px;">
              <li>
                <a (click)="openChangePassword()" class="padding-10 padding-top-0 padding-bottom-0"
                   data-action="toggleShortcut">
                   <i class="fa fa-lock"></i>&nbsp;
                   <strong><u>M</u>ot de passe</strong></a>
              </li>
              <li class="divider"></li>
              <li *ngIf="user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER'">
                <a (click)="openChangeLogo(template)" class="padding-10 padding-top-5 padding-bottom-5">
                  <i class="fa fa-image"></i>&nbsp;
                  <strong><u>L</u>ogo</strong></a>
              </li>
              <li class="divider"></li>
              <li>
                <a (click)="logout()" class="padding-10 padding-top-5 padding-bottom-5"><i
                  class="fa fa-sign-out fa-lg"></i> <strong><u>D</u>éconnecter</strong></a>
              </li>
            </ul>
          </li>
        </ul>
  </div>

  </div>

</header>



<ng-template #template>
    <form name="frmSave">
 <div class="modal-header custom-modal-header">
       <h4 class="modal-title pull-left">Modifier logo</h4>
       <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
       </button>
 </div>

  <div class="modal-body" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
    <div class="row">
         <div class="form-group col-lg-12">
            <div class="col-md-12">


                <div class="form-group">
                      <label>Logo : <span class="required_champs">*</span></label>
                      <div class="form-group col-md-12" style="text-align: center">
                        <img src="{{imageDisplay || 'assets/img/decoupes/user_default.png'}}"
                            style="height: 110px;border:1px solid #eceeee" />
                    </div>
                      <input class="form-control custom_input"  name="libelle" required type="file" (change)="uploadFile($event, 'photo', true)">
                </div>

            </div>


         </div>

    </div>
           
         
  </div>
     <div class="modal-footer modal-footer-custom">
             <b class="pull-left">(<span class="required_champs">*</span>) Champs requis</b>
                    <button  type="submit" class="btn btn-md btn-primary btn-bg-new " (click)="confirmSaveItem() ">
                             Sauvegarder
                    </button>
                     <button  type="button" class="btn btn-md btn-default " (click)="bsModalRef.hide()">
                             Annuler
                    </button>
                 
         </div>
    </form>
</ng-template>
