<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
    
    <div class="custom-cadre">
        <!-- fil d actualite -->
        <div class="row">
            <div class="form-group col-md-12">
                <div class="col-md-12">
                    <h1 class="custom-title-bold"> GESTION DES COOPERATIVES </h1>
                    <br/>

                    <button class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button" (click)="openFormModalModal(templateCreate)">
                    Ajouter </button>

                    <button *ngIf="currentItemSeleced.id" class="btn btn-md btn-bg-default custom_border_radius btn-import btn-bg-default padding-30" type="button"
                        (click)="downloadTemplate()">
                        Télécharger modèle 
                    </button>

                     <input *ngIf="currentItemSeleced.id" type="file" style="display: none;" id="file" (change)="onSelectFile($event)"  multiple="false" />
                        <label for="file" class="input-file btn btn-md btn-bg-default custom_border_radius btn-import btn-bg-default">
                        Importer
                        </label>

                        <button class="btn btn-md custom_border_radius btn-import btn-bg-default padding-30" type="button"
                        (click)="exportData()">
                        Exporter agriculteurs 
                        </button>
                    <br/>
                    <br/>
                    <div class="col-md-12 no-padding" style="margin-top: 10px">
                        <table class="table table-condensed table-striped">
                            <thead>
                                <tr>
                                    <th colspan="9" style="background-color: #fff;padding: 0px !important">
                                            <div class="form-group col-md-3 no-padding">
                                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSearch.searchValue" name="searc" required
                                                        placeholder="Rechercher par raison sociale" (keyup)="getData()" type="text">
                                                </div>
                                    </th>
                                </tr>
                                <tr class="custom-table-thead">
                                    <th style="width: 45px"></th>
                                    <th>Matricule </th>
                                    <th>Raison sociale</th>
                                   <th>Nbr. clients C.A</th>
                                   <th>Surface cultivée (ha)</th>
                                     <th>Mise à jour le</th>
                                    <th style="text-align: center; width: 15px" colspan="2"> Statut </th>
                                    <th class="text-center">
                                        <span class="fa fa-lock"></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="cursor: pointer;"  [class.isSelect]='entr.id == currentItemSeleced.id' *ngFor="let entr of ListeTenants; let i = index" (click)="onSelectTenancy(entr)">
                                    <td class="vertical-align-uppercase" >
                                        {{i + 1}}
                                    </td>
                                    <td class="vertical-align-uppercase">{{entr.code}}</td>
                                    <td class="vertical-align-uppercase">{{entr.raisonSociale}}</td>
                                   <td class="vertical-align-uppercase">{{(entr.nbrClientCarteAgricole | moneyFormat)|| 0 }}</td>
                                   <td class="vertical-align-uppercase">{{(entr.totalSurfaceCultivee | moneyFormat ) || 0 }}</td>
                                   
<!--                                    <td class="vertical-align-uppercase">{{entr.solde  | formatmillier}}</td>-->
                                     <td class="vertical-align-uppercase">{{entr.updatedAt || entr.createdAt}}</td>
<!--                                     <td class="vertical-align-uppercase">{{entr.updatedBy || entr.createdBy}}</td>-->
                                    <td style="text-align: center">
                                        <a title="Modifier" (click)="openFormModalModal(templateCreate,entr)">
                                            <i class="fa fa-edit" style="color: green"> </i>
                                        </a>
                                       
                                    </td>
                                    <td style="text-align: center">
                                        <ui-switch ngDefaultControl tooltip="Statut de la coopérative" size="small" [(ngModel)]="entr.isValidated" (change)="onChangeStatus($event,entr)"></ui-switch>
                                    </td>
                                    <td style="text-align: center">
                                        <ui-switch ngDefaultControl color="red" tooltip="Activé / Désactivée" size="small" [(ngModel)]="entr.isLocked" (change)="onChangeLock($event,entr)"></ui-switch>
                                    </td>
                                </tr>

                                <tr *ngIf="ListeTenants && ListeTenants.length > 0">
                                    <td colspan="9">
                                        <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
                                            <pagination [boundaryLinks]="true" [totalItems]="totalItems" [maxSize]='itemPerPagePagination' [itemsPerPage]="itemsPerPage"
                                                [(ngModel)]="currentPage" class="pagination-sm" previousText="&laquo;" nextText=" &raquo;"
                                                firstText="&laquo;" lastText="&raquo;" name="pagination" (pageChanged)="pageChanged($event)"></pagination>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="!ListeTenants || ListeTenants.length == 0">
                                <tr>
                                    <td colspan="8" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
                                </tr>

                            </tfoot>

                        </table>
                    </div>

                    <div class="col-md-2">
                       <div class="col-md-12">

                       </div>
                    </div>
                </div>


            </div>
        </div>
        <!-- fin file d actualite -->

    </div>



    <ng-template #templateCreate>
        <form ngDefaultControl name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
            <div class="modal-header custom-modal-header">
                <h4 class="modal-title pull-left">
                    COOPERATIVE
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="container-fluid">
<!--                Begin Référence de mise à jour-->
                <div class="row" *ngIf="itemToSave.id">

                    <accordion [showArrows]="true">
                        <accordion-group heading="Référence de mise à jour">
                            <!-- <div class="col col-md-12">
                                <h1>Référence de mise à jour </h1>
                            </div> -->
                            <div class="col-md-3">
                                <label>Date de dernière mise à jour :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [value]="itemToSave.updatedAt || itemToSave.createdAt" name="code" required
                                       placeholder="Code" type="text" [disabled]='true'>
                            </div>
                            <div class="col-md-3">
                                <label>Auteur de la mise à jour :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off"  name="code" required
                                       placeholder="Code" type="text" [disabled]='true' [value]="itemToSave.updatedByNom || ''">
                            </div>
                            <div class="col-md-3"></div>
                        </accordion-group>
                        
                    </accordion>
                    
                    
                </div>
<!--                Fin Référence de mise à jour-->

<!--                Debut contacts-->
                <div class="row">
                    <accordion [showArrows]="true" [expandAll]="false">
                        <accordion-group heading="Contacts" (onToggle)="doSomethingOnToggle()" #myGroup>
                            <!-- <accordion-heading>
                                    {{ isOpen ? 'Opened' : 'Closed' }}
                                    <i class="fas" [ngClass]="{'fa-caret-down': myGroup?.isOpen, 'fa-caret-up': !myGroup?.isOpen}"></i>
   
                            </accordion-heading> -->
                            <div class="form-group col-md-3">
                                <label>Nom & Prenom PCA :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nomPrenomPca" name="nomPrenomPca" placeholder="Nom & prenoms PCA"
                                       required type="text">
                            </div>
        
                            <div class="form-group col-md-3">
                                <label>Contact PCA :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.contactPca" name="contactPca" placeholder="Contact PCA"
                                       required  mask="00-00-00-00-00" type="text">
                            </div>
                            <!--Fin contact PCA-->
                            <!--Debut contact directeur-->
                            <div class="form-group col-md-3">
                                <label>Nom & Prenom Directeur :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nomPrenomDir" name="nomPrenomDir" placeholder="Nom & prenoms Directeur"
                                       required type="text">
                            </div>
                            <div class="form-group col-md-3">
                                <label>Contact Directeur :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.contactDir" name="contactDir" placeholder="Contact Directeur"
                                       required  mask="00-00-00-00-00" type="text">
                            </div>
        <!--                    Fin contact directeur-->
        <!--                    Debut contact représentant agricole-->
                            <div class="form-group col-md-3">
                                <label>Nom représentant C.A :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nomRepresentantCarte" name="nomRepresentantCarte" placeholder="Nom représentant Carte Agricole"
                                       required type="text">
                            </div>
                            <div class="form-group col-md-3">
                                <label>Contact représentant C.A :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.contactRepresentantCarte" name="contactRepresentantCarte" placeholder="Contact représentant Carte Agricole"
                                       required type="text" mask="00-00-00-00-00">
                            </div>
        <!--                    Fin contact représentant agricole-->
                        </accordion-group>
                        
                    </accordion>
                    
                    
                </div>
<!--                Fin contacts-->
<!--Debut Informations générales-->
                <div class="row">
                    <accordion [showArrows]="true">
                        <accordion-group heading="Informations générales">
                            <div class="form-group col-md-1">
                                <label>Identifiant :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.code" name="identifiant" required
                                       placeholder="Code" type="text" [disabled]='itemToSave.id'>
            
                            </div>
                                <div class="form-group col-md-3">
                                    <label>Raison sociale :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.raisonSociale" name="raisonSociale" placeholder="Raison sociale"
                                           required type="text">
                                </div>
                            <div class="form-group col-md-2">
                                <label>Localisation siège social :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.localisationSiege" name="localisationSiege" placeholder="Localisation siège social"
                                       required type="text">
                            </div>
                            <div class="form-group col-md-2">
                                <label>Numéro d’agrément:
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.numeroAgrement" name="numeroAgrement" placeholder="Numero agrément"
                                       required type="text" >
                            </div>
                            <div class="form-group col-md-2">
                                <label>Localisation region:
                                    <span class="required_champs">*</span>
                                </label>
                                <select style="width: 100%; display: inline-block;" name="localisationMembre"
                                        [(ngModel)]="itemToSave.localisationMembre" class="form-control form-control-sm">
                                    <option value=""></option>
                                    <option [value]="reg.id" *ngFor="let reg of ListeRegionsBrute">{{reg.libelle}}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-2">
                                <label>Verification(doc) :
                                    <!--                                          <span class="required_champs">*</span>-->
                                </label>
                                <select style="width: 100%; display: inline-block;" name="verificationDocument"
                                        [(ngModel)]="itemToSave.verificationDocument" class="form-control form-control-sm">
                                    <option value=""></option>
                                    <option value="true">OUI</option>
                                    <option value="false">NON</option>
                                </select>
            
                            </div>
                            <div class="form-group col-md-2">
                                <label>Rayon d'action (km) :
                                    <!--                                          <span class="required_champs">*</span>-->
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.rayonAction" name="rayonAction" placeholder="Rayon d'action"
                                       required type="text"  mask="0000">
                            </div>
                         
                            <div class="form-group col-md-2">
                                <label>Année création coop:
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.annCreationCoop" name="annCreationCoop" placeholder="Année création"
                                       required type="text"  mask="0000" maxlength="12" (input)="controleAnneeCreationCoop(itemToSave)">
                            </div>
                            <div class="form-group col-md-2">
                                <label>Année inscription C.A :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.annInscCarte" name="annInscCarte" placeholder="Année inscription C.A"
                                       required type="text"  mask="0000" maxlength="12" (input)="controleAnneeInscriptionCarteAgricole(itemToSave)">
                            </div>
                            <div class="form-group col-md-2">
                                <label>Nbr membres hommes :
                                    <span class="required_champs">*</span>
                                </label>
                                <input (input)="setNombreHommeFemme()" class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nbrMembHom" name="nbrMembHom" placeholder="Nbr. membres hommes"
                                       required type="text"  mask="separator" maxlength="12">
                            </div>
                            <div class="form-group col-md-2">
                                <label>Nbr membres femmes :
                                    <span class="required_champs">*</span>
                                </label>
                                <input (input)="setNombreHommeFemme()" class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nbrMembFem" name="nbrMembFem" placeholder="Nbr. membres femmes"
                                       required type="text"  mask="separator" maxlength="12">
                            </div>
                            <div class="form-group col-md-2">
                                <label>Nbr membres total :
                                    <span class="required_champs">*</span>
                                </label>
                                <input class="form-control custom_input" disabled autocomplete="off" [(ngModel)]="itemToSave.nbrMembOffi" name="nbrMembOffi" placeholder="Nbr. membres officiels"
                                       required type="text"  maxlength="12" mask="separator">
                            </div>

                        </accordion-group>
                    </accordion>
                </div>
                
<!--Fin informations générales-->

<!--                    DEBUT DYNAMISME-->
                    <div class="row">
                        <accordion [showArrows]="true">
                            <accordion-group heading="Dynamisme">
                                <div class="form-group col-md-2">
                                    <label>Nbr de délégués:
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nbrDelegue" name="nbrDelegue" placeholder="Nbr de délégués"
                                           required type="text"  mask="000000" maxlength="12">
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Certifications :
                                        <!-- <span class="required_champs">*</span> -->
                                    </label>
                                    <select style="width: 100%; display: inline-block;" name="isCertificat"
                                            [(ngModel)]="itemToSave.isCertificat" class="form-control form-control-sm">
                                        <option value="null"></option>
                                        <option value="true">OUI</option>
                                        <option value="false">NON</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-2" *ngIf="itemToSave.isCertificat == !!'true' || itemToSave.isCertificat == 'true'" >
                                    <label> Certifications :
                                        <span class="required_champs">*</span>
                                    </label>
        
                                    <ng-multiselect-dropdown
                                            name="selectAllPaiement"
                                            [data]="listCertificates"
                                            [(ngModel)]="selectedCertificates"
                                            [settings]="dropdownCertificateSettings"
                                            (onSelect)="onItemSelect($event)"
                                            (onSelectAll)="onSelectAll($event)"
                                            [placeholder]="'Certifications'"
                                    >
                                    </ng-multiselect-dropdown>
                                </div>
        <!--                        <div class="form-group col-md-3" style="border: 2px solid #eceeee; margin-top: 20px;" *ngIf="itemToSave.isCertificat == 'true'">-->
                                <div class="form-group col-md-3" style="margin-top: 0px;" *ngIf="itemToSave.isCertificat == !!'true' || itemToSave.isCertificat == 'true'" >
        
                                    <label>Certifications années :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <div class="col-md-12">
                                        <table class="table table-condensed">
                                            <thead>
                                            <tr>
                                                <th> Certification </th>
                                                <th> Année </th>
                                            </tr>
                                            </thead>
        
                                            <tbody>
                                            <tr *ngFor="let cert of selectedCertificates">
                                                <td> {{cert.libelle}} </td>
                                                <td>
                                                    <input class="form-control custom_input"  autocomplete="off" [(ngModel)]="cert.annCertificat" name="annCertificat" placeholder="Année"
                                                           required type="text" mask='0000' [ngModelOptions]="{standalone: true}" (keyup)="displayCertifs(cert)" maxlength="12"
                                                           (input)="controleYear(cert)">
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Dons d’intrants/12 mois:
                                        <span class="required_champs">*</span>
                                    </label>
                                    <select style="width: 100%; display: inline-block;" name="donIntraAgricole"
                                            [(ngModel)]="itemToSave.donIntraAgricole" class="form-control form-control-sm">
                                        <option value="null"></option>
                                        <option value="true">OUI</option>
                                        <option value="false">NON</option>
                                    </select>
                                </div>
        
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="form-group col-md-2">
                                            <label>Nbr distinctions/3 ans:
                                                <!--                                          <span class="required_champs">*</span>-->
                                            </label>
                                            <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nbrDistinstion" name="rendAnnMoyen" placeholder="Nbr distinctions/3 ans"
                                                   required type="text"  mask="000000" maxlength="12">
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label>Remise dons/12 mois:
                                                <span class="required_champs">*</span>
                                            </label>
                                            <select style="width: 100%; display: inline-block;"
                                                    [(ngModel)]="itemToSave.remisDonSociaux" name="remisDonSociaux" class="form-control form-control-sm">
                                                <option value="null"></option>
                                                <option value="true">OUI</option>
                                                <option value="false">NON</option>
                                            </select>
                                        </div>
                                    </div>
        
                                </div>
                            </accordion-group>
                        </accordion>
    
                        

                    </div>
<!--FIN DYNAMISME-->

<!--                    DEBUT CULTURES-->
<div class="row">
    <accordion [showArrows]="true">
        <accordion-group heading="Cultures">
            <div class="form-group col-md-6">
                <label>Cultures:
                    <span class="required_champs">*</span>
                </label>
                <ng-multiselect-dropdown
                name="selectAllCultures"
                [settings]="dropdownCultureSettings"
                [data]="ListeCultures"
                [(ngModel)]="selectedCulture"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                [placeholder]="'Cultures '"
        >
        </ng-multiselect-dropdown>
            </div>
            <div class="col-md-12">
                <table>
                    <thead>
                        <th>Culture</th>
                        <th>Section</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of selectedCulture; let i = index">
                            <td>{{ c.libelle }}</td>
                            <td>
                                <select name="cultureSectionId{{i}}" [(ngModel)]="c.cultureSectionId">
                                    <option *ngFor="let s of listSection" [value]="s.id">{{ s.libelle }}</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </accordion-group>
    </accordion>

    

</div>
<!--FIN CULTURES-->

<!--                    Debut Equipe dirigeante-->
                    <div class="row">
                        <accordion [showArrows]="true">
                            <accordion-group heading="Equipe dirigeante">
                                <div class="col-md-12">
                                    <h1>Equipe dirigeante</h1>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Expérience PCA :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nbrAnnExpePca" name="nbrAnnExpePca" placeholder="Nbr années d'expérience PCA"
                                           required  mask="00" type="text">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Année de nomination PCA :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.annDirectionPca" name="annDirectionPca" placeholder="Année de nomination PCA"
                                           required  mask="0000" type="text" (input)="controleAnneeNominationPCA(itemToSave)">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Plantation PCA (ha) :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.supPlantPca" name="supPlantPca" placeholder="Superficie plantation PCA"
                                           required  mask="0000" type="text">
        
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Modifications PCA/5 ans :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nbrModifPca" name="nbrModifPca" placeholder="Nbr de modifications PCA"
                                           required  mask="0000" type="text">
        
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Expérience Directeur :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nbrAnnExpeDir" name="nbrAnnExpeDir" placeholder="Nbr années d'expérience Directeur"
                                           required  mask="00" type="text">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Année de nomination Directeur :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.annDirectionDir" name="annDirectionDir" placeholder="Année de nomination Directeur "
                                           required  mask="0000" type="text" (input)="controleAnneeNominationDirecteur(itemToSave)">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Plantation Directeur (ha) :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.supPlantDir" name="supPlantDir" placeholder="Superficie plantation Directeur"
                                           required  mask="0000" type="text">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Modifications Directeur/5 ans :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nbrModifDir" name="nbrModifDir" placeholder="Nbr de modifications Directeur"
                                           required  mask="0000" type="text">
                                </div>
                            </accordion-group>
                        </accordion>
                        
                    </div>
                    <!--                    Fin Equipe dirigeante-->
<!--                    Debut Finance-->
                    <div class="row">
                        <accordion [showArrows]="true">
                            <accordion-group heading="Finances">
                                <div class="form-group col-md-3">
                                    <label> Banques :
                                        <!--                                                    <span class="required_champs">*</span>-->
                                    </label>
        
                                    <ng-multiselect-dropdown
                                            name="selectAllBanques"
                                            [settings]="dropdownBanqueSettings"
                                            [data]="listBanques"
                                            [(ngModel)]="selectedBanques"
                                            (onSelect)="onItemSelect($event)"
                                            (onSelectAll)="onSelectAll($event)"
                                            [placeholder]="'Banques '"
                                    >
                                    </ng-multiselect-dropdown>
        
                                </div>
                                <div class="form-group col-md-3" style="border: 2px solid #eceeee;" *ngIf="selectedBanques && selectedBanques.length">
                                    <label>Banque/année ouverture :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <div class="col-md-12">
                                        <table class="table table-condensed">
                                            <thead>
                                            <tr>
                                                <th> Banque </th>
                                                <th> Année ouverture</th>
                                            </tr>
                                            </thead>
        
                                            <tbody>
                                            <tr *ngFor="let bank of selectedBanques">
                                                <td> {{bank.libelle}} </td>
                                                <td>
                                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="bank.annOuverute" name="annOuverute" placeholder="Année ouverture"
                                                           required type="text" mask="0000" [ngModelOptions]="{standalone: true}" (input)="controleYearBank(bank)">
        
                                                           <!-- <input class="form-control custom_input"  autocomplete="off" [(ngModel)]="cert.annCertificat" name="annCertificat" placeholder="Année"
                                                           required type="text" mask='0000' [ngModelOptions]="{standalone: true}" (keyup)="displayCertifs(cert)" maxlength="12"
                                                           (input)="controleYear(cert)"> -->
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Crédit rég. aux membres :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <select style="width: 100%; display: inline-block;" name="creditRegul"
                                            [(ngModel)]="itemToSave.creditRegul" class="form-control form-control-sm">
                                        <option value="null"></option>
                                        <option value="true">OUI</option>
                                        <option value="false">NON</option>
                                    </select>
        
                                </div>
                                <div class="form-group col-md-2">
                                    <label>% prod. pré-vendu :
                                        <!--                                                    <span class="required_champs">*</span>-->
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.prcProducPrevu" name="prcProducPrevu" placeholder="% prod. pré-vendu"
                                           required  mask="000" type="text">
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Verification(justificatif) :
                                        <!--                                                    <span class="required_champs">*</span>-->
                                    </label>
                                    <select style="width: 100%; display: inline-block;" name="verificationPrefinancement"
                                            [(ngModel)]="itemToSave.verificationPrefinancement" class="form-control form-control-sm">
                                        <option value=""></option>
                                        <option value="true">OUI</option>
                                        <option value="false">NON</option>
                                    </select>
        
                                </div>
                            </accordion-group>
                        </accordion>
                        
                    </div>
<!--Fin finance-->
<!--                    Debut mode de reglement-->
                    <div class="row">
                        <accordion [showArrows]="true">
                            <accordion-group heading="Mode de règlement">
                                <div class="form-group col-md-2">
                                    <label>% reglem. cash :
                                        <!--                                                    <span class="required_champs">*</span>-->
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.prcRegleCash" name="prcRegleCash" placeholder="% reglem. cash"
                                           required  mask="000" type="text">
                                </div>
                                <div class="form-group col-md-2">
                                    <label>% reglem. m. money :
                                        <!--                                                    <span class="required_champs">*</span>-->
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.prcRegleMobile" name="prcRegleMobile" placeholder="% reglem. m. money"
                                           required  mask="000" type="text">
                                </div>
                                <div class="form-group col-md-2">
                                    <label>% reglem. chèque :
                                        <!--                                                    <span class="required_champs">*</span>-->
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.prcRegleCheque" name="prcRegleCheque" placeholder="% reglem. chèque"
                                           required  mask="000" type="text">
                                </div>
                                <div class="form-group col-md-2">
                                    <label>% reglem. virement :
                                        <!--                                                    <span class="required_champs">*</span>-->
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.prcRegleVirement" name="prcRegleVirement" placeholder="% reglem. virement"
                                           required  mask="000" type="text">
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Delai m. reglement :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <select style="width: 100%; display: inline-block;" name="delaiPaiementId"
                                            [(ngModel)]="itemToSave.delaiPaiementId" class="form-control form-control-sm">
                                        <option value=""></option>
                                        <option [value]="del.id" *ngFor="let del of listeDelais">{{del.libelle}}</option>
        
                                    </select>
        
                                </div>
                            </accordion-group>
                        </accordion>
                        
                    </div>
                    <div class="row">
                        <accordion [showArrows]="true">
                            <accordion-group heading="Informatique">
                                <div class="col-md-12">
                                    <h1>Informatique</h1>
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Logiciel comptable :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <select style="width: 100%; display: inline-block;" name="isComptaSoftware"
                                            [(ngModel)]="itemToSave.isComptaSoftware" class="form-control form-control-sm">
                                        <option value="null"></option>
                                        <option value="true">OUI</option>
                                        <option value="false">NON</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-2" *ngIf="itemToSave.isComptaSoftware == !!'true' || itemToSave.isComptaSoftware == 'true'">
                                    <label>Nom logiciel cpt. :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nomLogicielCompta" name="nomLogicielCompta" placeholder="Nom logiciel compt."
                                           required  type="text">
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Logiciel traçabilité :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <select style="width: 100%; display: inline-block;" name="isTracabiltySoftware"
                                            [(ngModel)]="itemToSave.isTracabiltySoftware" class="form-control form-control-sm">
                                        <option value="null"></option>
                                        <option value="true">OUI</option>
                                        <option value="false">NON</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-2" *ngIf="itemToSave.isTracabiltySoftware == !!'true' || itemToSave.isTracabiltySoftware == 'true'">
                                    <label>Nom logiciel traçab. :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nomLogicielTracabilite" name="nomLogicielTracabilite" placeholder="Nom logiciel traçab. :"
                                           required  type="text">
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Autre logiciel :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <select style="width: 100%; display: inline-block;" name="isOtherSoftware"
                                            [(ngModel)]="itemToSave.isOtherSoftware" class="form-control form-control-sm">
                                        <option value="null"></option>
                                        <option value="true">OUI</option>
                                        <option value="false">NON</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-2" *ngIf="itemToSave.isOtherSoftware == !!'true' || itemToSave.isOtherSoftware == 'true'">
                                    <label>Nom autre logiciel :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nomAutreLogiciel" name="nomAutreLogiciel" placeholder="Nom autre logiciel :"
                                           required   type="text">
                                </div>
                            </accordion-group>
                        </accordion>
                        
                    </div>


                    <!--                    Fin mode de reglement-->
<!--                    Debut Informations administrateur-->
                    <div class="row">
                        <accordion [showArrows]="true">
                            <accordion-group heading="Information administrateur">
                                <div class="form-group col-md-2">
                                    <label>Nom :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <!-- <input class="form-control custom_input" autocomplete="off" [(ngModel)]="admin.firstName" name="firstName" placeholder="Nom"
                                           required type="text" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'> -->
                                           <input class="form-control custom_input" autocomplete="off" [(ngModel)]="admin.firstName" name="firstName" placeholder="Nom"
                                           required type="text" >
                                </div>
        
                                <div class="form-group col-md-2">
                                    <label>Prénoms :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input"  autocomplete="off" [(ngModel)]="admin.lastName" name="lastName" placeholder="Prénoms"
                                           required type="text" >
                                </div>
        
                                <div class="form-group col-md-2">
                                    <label>Contact :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" autocomplete="off" [(ngModel)]="admin.telephone" name="telephoneAd" placeholder="Télephone"
                                           required  mask="00-00-00-00-00" type="text">
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Email :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input [ngClass]="{'valid-email':admin.email && emailIsValid(admin.email), 'invalid-email': admin.email && !emailIsValid(admin.email)}" class="form-control custom_input"  autocomplete="off" [(ngModel)]="admin.email" name="emailA" placeholder="Email"
                                           required type="text" (input)="emailIsValid(admin.email)">
                                           <span *ngIf="admin.email && !emailIsValid(admin.email)" style="color:red">Email non valide</span>
                                           <span *ngIf="admin.email && emailIsValid(admin.email)" style="color:#00A984">Email valide</span>

                                </div>
        
                                <div class="form-group col-md-2">
                                    <label>Login :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom_input" [disabled]='itemToSave.id' autocomplete="off" [(ngModel)]="admin.login" name="login" placeholder="Login"
                                           required type="text" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'>
                                </div>
                            </accordion-group>
                        </accordion>
                        
                        
                    </div>
<!--                    Fin Informations administrateur-->

                </div>
            </div>

            <div class="modal-footer modal-footer-custom">
                <b class="pull-left">(
                    <span class="required_champs">*</span>) Champs requis</b>

                <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal()">
                    Annuler
                </button>

                <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItem(itemToSave) ">
                    Valider
                </button>

            </div>
        </form>
    </ng-template>



</div>
