<aside id="left-panel" style="background:#00A984">

  <nav>

    <ul saSmartMenu>

        <li routerLinkActive="active" *ngIf="user?.userTypeCode != 'GESTIONNAIRE_ADMIN' ">
            <a title="Tableau de bord" routerLink="/tableau-de-bord" >
                <i class="fa fa-lg fa-fw fa-home icone-nav"></i>
                <span class="menu-item-parent">{{'Tableau de bord'}}
                </span>
            </a> 
        </li>
 
        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER' ">
            <a title="mouvements" routerLink="/mouvements" >
                <i class="fa fa-lg fa-fw fa-tasks icone-nav"></i>
                <span class="menu-item-parent">{{'MOUVEMENTS'}}
                </span>
            </a>
        </li>

        <li routerLinkActive="active" *ngIf="user?.userTypeCode != 'GESTIONNAIRE_ADMIN' ">
            <a title="Utilisateurs" routerLink="/utilisateur" >
                <i class="fa fa-lg fa-fw fa-user icone-nav"></i>
                <span class="menu-item-parent">{{'UTILISATEURS'}}
                </span>
            </a>
        </li>

        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'SA' || user?.userTypeCode == 'ADMIN' || user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER' ">
            <a title="Paramètres" routerLink="/parametrages" >
                <i class="fa fa-lg fa-fw fa-cog icone-nav"></i>
                <span class="menu-item-parent">{{'PARAMETRAGES'}}
                </span>
            </a>
        </li>

        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'SA' || user?.userTypeCode == 'ADMIN' ">
            <a title="Coopératives" routerLink="/cooperative" >
                <i class="fa fa-lg fa-fw fa-university icone-nav"></i>
                <span class="menu-item-parent">{{'COOPERATIVES'}}
                </span>
            </a>
        </li>

        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER' ">
            <a title="AGRICULTEURS" [routerLink]="['/clients']" >
                <i class="fa fa-lg fa-fw fa-user-secret icone-nav"></i>
                <span class="menu-item-parent">{{'AGRICULTEURS'}}
                </span>
            </a>
        </li>
        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER'">
            <a title="Prime" routerLink="/prime" >
                <i class="fa fa-lg fa-fw fa-credit-card icone-nav"></i>
                <span class="menu-item-parent">{{'Prime'}}
                </span>
            </a>
        </li>
        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER' ">
            <a title="AVANCE & PRET" [routerLink]="['/avance', {avance: true}]" >
                <i class="fa fa-lg fa-fw fa-credit-card icone-nav"></i>
                <span class="menu-item-parent">{{'AVANCE & PRET'}}
                </span>
            </a>
        </li>
        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER' ">
            <a title="GESTION DES STOCKS" [routerLink]="['/gestion-stock']" >
                <i class="fa fa-lg fa-fw fa-cubes icone-nav"></i>
                <span class="menu-item-parent">{{'GESTION DES STOCKS'}}
                </span>
            </a>
        </li>
        <li routerLinkActive="active" *ngIf="user?.userTypeCode != 'GESTIONNAIRE_ADMIN' ">
            <a title="Dons" routerLink="/dons" >
                <i class="fa fa-lg fa-fw fa-gift icone-nav"></i>
                <span class="menu-item-parent">{{'DONS'}}
                </span>
            </a>
        </li>
        <li routerLinkActive="active" *ngIf="user?.userTypeCode != 'GESTIONNAIRE_ADMIN' ">
            <a title="Promos" routerLink="/promos" >
                <i class="fa fa-lg fa-fw fa-gift icone-nav"></i>
                <span class="menu-item-parent">{{'Promos'}}
                </span>
            </a>
        </li>

        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER'">
            <a title="Livrer-encaisser" routerLink="/livrer-encaisser" >
                <i class="fa fa-lg fa-fw fa-shopping-cart icone-nav"></i>
                <span class="menu-item-parent">{{'Livrer-encaisser'}}
                </span>
            </a>
        </li>
    
        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER'">
            <a title="Mapping" routerLink="/mapping" >
                <i class="fa fa-lg fa-fw fa-map-marker icone-nav"></i>
                <span class="menu-item-parent">{{'Mapping'}}
                </span>
            </a>
        </li>
        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'TENANCY_ADMIN' || user?.userTypeCode == 'TENANCY_USER'">
            <a title="Supervision" routerLink="/supervision" >
                <i class="fa fa-lg fa-fw fa-file-text icone-nav"></i>
                <span class="menu-item-parent">{{'Supervision'}}
                </span>
            </a>
        </li>
        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'GESTIONNAIRE_ADMIN' ">
            <a title="Valider-préfinancement" routerLink="/valider-prefinancement" >
                <i class="fa fa-lg fa-fw fa-building icone-nav"></i>
                <span class="menu-item-parent">{{'Valider préfinancement'}}
                </span>
            </a>
        </li>
        <li routerLinkActive="active" *ngIf="user?.userTypeCode == 'SA' || user?.userTypeCode == 'ADMIN'">
            <a title="Valider préfinancement" routerLink="/manage-prefinancement" >
                <i class="fa fa-lg fa-fw fa-cog icone-nav"></i>
                <span class="menu-item-parent">{{'Valider préfinancement'}}
                </span>
            </a>
        </li>
        
        
</ul>

  </nav>

  <sa-minify-menu></sa-minify-menu>

</aside>
