<!-- MAIN CONTENT -->
<div id="content">

    <div class="row" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">

          <div class="col-md-12" style="padding: 10px">
              <div class="col-md-12 custom-cadre">

                  <!--img src="assets/img/decoupes/icone-table.png" style="float: right"-->
                  <div class="form-group col-md-12 cadre-title-page">
                    <b class="title-page">Profil</b>

                  </div>

                  <div class="form-group col-md-6" style="padding-right: 0">

                      <div class="form-group col-md-8">
                          <label>Libellé : </label>
                          <input type='text' id="libelleSearch"  class="form-control custom_input"  placeholder="Libellé"  name="libelle"  [(ngModel)]="itemToSearch.libelle"> 
                      </div>
                
                      <div class="col-md-4 pull-right" style="margin-top: 23px">
                          <button class="btn btn-md btn-default  btn-bg-cancel pull-right" (click)="cancelSearch()" > <i class="fa fa-times"></i> </button>
                          <button class="btn btn-md  btn-bg-new pull-right" (click)="getData()"> <i class="fa fa-search"></i> Rechercher</button>
                      </div>


                      <div class="form-group col-md-12 table-responsive ">

                            <div class="col-md-12" style="padding-left: 0;padding-right: 0">
                                    <div class="form-group col-md-6 pull-right no-padding">
                                            <label style="padding-left: 35px"> Affichage par page :  </label>
                                            <select class="custom_input custom_enter_per_page"  id="itemsPerPage"
                                            [(ngModel)]="itemsPerPage" #ctrl="ngModel" name="itemsPerPage" (change)="changeItermPerPage()">
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="150">150</option>
                                                <option value="">Tout</option>
                                            </select> 
                                  </div>
                                </div>


                          <table class="table table-bordered table-striped table-condensed table-hover smart-form">
                            <thead>
                              <tr class="custom-table-thead">
                               
                                <td class="minus-td">#</td>
                                <td>Libellé</td>
                                <td>Date de creation</td>
                                <td class="td-action"></td>
                              </tr>
                            </thead>
                            <tbody>
                                    <tr *ngFor="let res of results;let indice = index">
                                      
                                            <td class="minus-td"> {{res.id}} </td>
                                            <td> {{res.libelle}} </td>
                                            <td> {{res.createdAt}} </td>
                                            <td>
                                              <a  (click)="openFormModalModal(template,res)">
                                                <i class="fa fa-edit" style="color: #54be63"></i>
                                              </a>
            
                                              <a (click)="confirmDeleteItem(res)">
                                                  <i class="fa fa-trash-o" style="color: red"></i>
                                              </a>
                                            </td>
                                    </tr>
                                    <tr class="custom-tfoot-for-pagination">
                                            <td colspan="4" *ngIf="results && results.length>0">
                                                     <div class="col-xs-12 col-12" style="text-align: right">
            <pagination [boundaryLinks]="true" [totalItems]="totalItems"  [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage" class="pagination-sm"
                        previousText="&laquo;" nextText=" &raquo;" firstText="Précédent" lastText="Suivant" (pageChanged)="pageChanged($event)"></pagination>
            </div>
                                            </td>
            
                                              <td colspan="4" *ngIf="!results || results.length==0">
                                                     <div class="col-xs-12 col-12" style="text-align: center">
                                                             <span>Aucune donnée disponible</span>
                                                     </div>
                                            </td>
                                    </tr>
            
                            </tbody>
                          </table>
                        </div>

                  </div>

                  <div class="col-md-5 col-md-offset-1" [ngBusy]="busyFct">
                    <h1>Création de profil</h1>
                    <div class="form-group col-md-12">
                            <label>Code : <span class="required_champs">*</span></label>
                            <input class="form-control custom_input" [(ngModel)]="itemToSave.code" name="cod" required placeholder="Code" type="text">
                       </div>

                      <div class="form-group col-md-12">
                          <label>Libellé : <span class="required_champs">*</span></label>
                          <input class="form-control custom_input" [(ngModel)]="itemToSave.libelle" name="libpr" required placeholder="Libellé" type="text">
                     </div>

                     <div class="form-group col-md-12">
                            <label>Fonctionnalités : <span class="required_champs">*</span></label>

                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td class="minus-td">
                                            <input type="checkbox" name="ttcoch"  [(ngModel)]="itemToSave.isAllFct" name="checkAll" (change)="setCheckedItem(itemToSave.isAllFct,'checkall')" /> 
                                        </td>
                                        <td colspan="6">
                                            Tout cocher
                                        </td>
                                    </tr>
                                    <tr *ngFor="let res of ListeFonctionnalites;let indice = index" [class.itemCheckCss]=" (res && res.icheckedItem )">
                                        <td class="minus-td">
                                                <a (click)="displayDetails(res)"  *ngIf="res.datasFonctionnaliteChildren && res.datasFonctionnaliteChildren.length>0"> 
                                                        <i class="fa fa-caret-right fa-ico-fct" *ngIf="!res.isOpen" ></i> 
                                                        <i class="fa fa-caret-down fa-ico-fct" *ngIf="res.isOpen" ></i> 
                                                </a>
                                        </td>
                                        <td class="minus-td"> <input type="checkbox" [ngModelOptions]="{standalone: true}" name="ligneChecked" [(ngModel)]="res.checkedItem"  (change)="setCheckedItem(res.checkedItem,(res.datasFonctionnaliteChildren && res.datasFonctionnaliteChildren.length>0 ? 'parent':'simple'),res.datasFonctionnaliteChildren ? res.datasFonctionnaliteChildren : '')" />  </td>
                                        <td colspan="5">
                                                {{res.libelle}}
                                                <div class="col-md-12" style="padding: 0px" *ngIf="res.isOpen" >
                                                    <table class="table">
                                                        <tbody>
                                                            <tr *ngFor="let chil of res.datasFonctionnaliteChildren;" >
                                                                   
                                                                    <td class="minus-td" *ngIf='chil.datasFonctionnaliteChildren && chil.datasFonctionnaliteChildren.length>0 '> 
                                                                        <a (click)="displayDetails(chil)" > 
                                                                            <i class="fa fa-caret-right fa-ico-fct" *ngIf="!chil.isOpen" ></i> 
                                                                            <i class="fa fa-caret-down fa-ico-fct" *ngIf="chil.isOpen" ></i> 
                                                                        </a>
                                                                    </td> 
                                                                    
                                                                    <td class="minus-td"> <input type="checkbox" [ngModelOptions]="{standalone: true}" name="ligneChecked" [(ngModel)]="chil.checkedItem"  />  </td>
                                                                    <td colspan="5">  {{chil.libelle}} 

                                                                            <div class="col-md-12" style="padding: 0px" *ngIf='chil.datasFonctionnaliteChildren && chil.datasFonctionnaliteChildren.length>0 && chil.isOpen'>
                                                                                    <table class="table">
                                                                                        <tbody>
                                                                                            <tr *ngFor="let chil2 of chil.datasFonctionnaliteChildren;" >
                                                                                                    <td class="minus-td"> <input type="checkbox" [ngModelOptions]="{standalone: true}" name="ligneChecked" [(ngModel)]="chil2.checkedItem"  />  </td>
                                                                                                    <td>  {{chil2.libelle}} </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>

                                                                    </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                         </td>
                                    </tr>
                                </tbody>
                            </table>
                     </div>



                     <div class="form-group col-md-12"  [ngBusy]="busySave">
                             
                               <button  type="button" class="btn btn-md btn-default pull-right" (click)="cancelSave()" style="margin-left: 15px">
                                        Annuler
                               </button>

                               <button  type="button" class="btn btn-md btn-primary btn-bg-new pull-right" (click)="saveItem(itemToSave) ">
                                  Sauvegarder
                               </button>

                            
                     </div>


                  </div>
      
                </div>
      
          </div>


          

            <ng-template #template>
                      <form name="frmSave">
                   <div class="modal-header custom-modal-header">
                         <h4 class="modal-title pull-left">Utilisateur</h4>
                         <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                          <span aria-hidden="true">&times;</span>
                         </button>
                   </div>

                    <div class="modal-body">
                      <div class="row">
                           <div class="form-group col-lg-12">
                              <div class="col-md-12">
                                
                                <div class="col-md-8" style="padding:0">
                                    <div class="form-group col-md-6">
                                        <label>Nom : <span class="required_champs">*</span></label>
                                        <input class="form-control custom_input" [(ngModel)]="itemToSave.nom" name="nom" required placeholder="Nom" type="text">
                                  </div>

                                  <div class="form-group col-md-6">
                                      <label>Prénoms : <span class="required_champs">*</span></label>
                                      <input class="form-control custom_input" [(ngModel)]="itemToSave.prenom" name="prenom" placeholder="Prénoms" required type="text">
                                  </div>

                                  <div class="form-group col-md-6">
                                      <label>Login : <span class="required_champs">*</span></label>
                                      <input class="form-control custom_input" [(ngModel)]="itemToSave.login" name="login" required placeholder="Login" type="text">
                                  </div>

                                  <div class="form-group col-md-6">
                                      <label>Mot de passe : <span class="required_champs">*</span></label>
                                      <input class="form-control custom_input" [(ngModel)]="itemToSave.password" name="password" required placeholder="Mot de passe" type="password">
                                  </div>

                                  <div class="form-group col-md-6">
                                      <label>Email : <span class="required_champs">*</span></label>
                                      <input class="form-control custom_input" [(ngModel)]="itemToSave.email" name="email" required placeholder="Email" type="email">
                                  </div>

                                  <div class="form-group col-md-6">
                                      <label>Contact : <span class="required_champs">*</span></label>
                                      <input class="form-control custom_input" [(ngModel)]="itemToSave.contact" name="contact" placeholder="Contact" required type="tel">
                                  </div>

                                  <div class="form-group col-md-6">
                                      <label>Rôle : <span class="required_champs">*</span></label>

                                      <select class="form-control custom_input"  id="roleSelected"
                                      [(ngModel)]="itemToSave.roleSelected" #ctrl="ngModel" name="roleSelected">
                                          <option value="">-- Choisissez --</option>
                                          <option *ngFor="let niv of ListeRoles " [ngValue]="niv">
                                            {{ niv.libelle }}
                                          </option>
                                       </select> 

                                  </div>


                                </div>

                                <div class="col-md-4" style="padding:0">
                                    <div class="form-group col-md-12" style="text-align: center">
                                      <img src="{{imageDisplay || 'assets/img/decoupes/user_default.png'}}" style="width: 100px;height: 110px;border:1px solid #eceeee" />
                                    </div>

                                    <div class="form-group col-md-12" style="text-align: center">
                                        <input type="file" name="file" (change)="uploadFile($event,itemToSave,'fileNameAlerte')">
                                         
                                       </div>
                                </div>

                              </div>
                           </div>

                      </div>
                             
                           
                    </div>
                       <div class="modal-footer modal-footer-custom"  [ngBusy]="busySave">
                               <b class="pull-left">(<span class="required_champs">*</span>) Champs requis</b>
                                      <button  type="submit" class="btn btn-md btn-primary btn-bg-new " (click)="saveItem(itemToSave) ">
                                               Sauvegarder
                                      </button>
                                       <button  type="button" class="btn btn-md btn-default " (click)="modalRef.hide()">
                                               Annuler
                                      </button>
                                   
                        </div>
                      </form>
              </ng-template>

    </div>
</div>
