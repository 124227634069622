import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Api } from 'src/app/shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from 'src/app/shared/utils/notification.service';
import { UtilitiesService } from 'src/app/shared/utils/utilities.service';
import { UserService } from 'src/app/shared/user';
import { HttpHeaders } from '@angular/common/http';
declare const Swal: any;

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-delegue',
  templateUrl: './delegue.component.html',
  styleUrls: ['./delegue.component.css']
})
export class DelegueComponent implements OnInit {
  resultDelaiPaiements: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;

  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  currentTenant: any = {};
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination = 10;
  admin: any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  listDonateurs=[];
  listBanques=[];
  ListeRoles=[];
  listDelegues: any;
  dropdownSectionsSettings: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; searchPlaceholderText: string; placeholder: string; };
  listItemsSections: any;
  selectedItemsSections:any

  searchKey:any;
  onFullSearch = new Subject<string>();
  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.itemToSearch = {};

    this.onFullSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.getData();
      
      });
  }

  // MULTI SELECT


  hideModal() {
    this.modalRef.hide();
  }
  getDataRole() {
    var request = {
        user: this.user.id,
        data: {
        }
    }

    this.api.post('role/getByCriteria', request)
        .subscribe(
            res => {
                console.log('les roles', res);
                if (res && res['items']) {
                    this.ListeRoles = res['items'];
                }
            },
            err => {
                console.log("Error occured", err);
            }
        );
}

  getTenancyBanque() {
    const request = {
        user: this.user.id,
        data: {
          tenancyId: this.currentTenant.id
        }
    }
    this.busySave = this.api.post('banqueTenancy/getByCriteria', request)
        .subscribe((res: any) => {
            console.log('getTenancyBanque', res);
            if (res && res.items) {
                this.listBanques = res.items || [];
            }
        });
}

  openFormModalModal(template, itemToModified?: any) {
    this.cancelSave();
    if (itemToModified) {
      console.log('To be modified: ',itemToModified);
      
      this.itemToSave = { ...itemToModified };
      console.log("data to save:",this.itemToSave);
      console.log('itemToSave.datasDelegueSectionDto',this.itemToSave.datasDelegueSectionDto);
      
      
    }
    this.modalRef = this.modalService.show(template, { class: 'gray modal-md' });
  }


  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }


  getData() {
    console.log("...");
    
    if (!this.currentTenant || !this.currentTenant.code) {return};
    const options = {
    
      headers: new HttpHeaders({
        'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004',
        lang: 'fr'
      })
    }
    const request = {
      user: this.user.id,
      data: {
        tenancyId: this.currentTenant.id,
        nom:this.searchKey
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }
    this.busyGet = this.api.post('delegue/getByCriteria', request)
        .subscribe((res: any) => {
              console.log('get data listDelegues', res);
              if (res && res['items']) {
                this.listDelegues = res['items'];
                this.totalItems = res['count'] ? res['count'] : 0;
                this.listDelegues.map(
                  ld=>{
                    if(ld.datasDelegueSectionDto && ld.datasDelegueSectionDto.length){
                      ld.datasDelegueSectionDto.map(
                        ldc=> ldc.libelle = ldc.sectionLibelle
                      )
                    }
                   
                  }
                )
              } else {
                this.listDelegues = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }

  confirmSaveItem(obj: any) {
    if (!obj) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner les champs obligatoires.');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.nom) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner nom svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.prenom) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner prenom svp!');
      return;
    }

    if (!this.itemToSave || !this.itemToSave.email) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner email svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.telephone) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner contact svp!');
      return;
    }
    console.log("item to save: ",this.itemToSave);
    
    if (!this.itemToSave.datasDelegueSectionDto || !this.itemToSave.datasDelegueSectionDto.length) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner au moins une section svp!');
      return;
    }
    
    Swal.fire({
      title: !obj.id ? 'Enregistrement délégué' : 'Modification délégué',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.saveItem(this.itemToSave);
      } else {
        console.log('bnjr');
      }
    })
  }



  confirmDeleteItem(obj: any) {
    Swal.fire({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.deleteItem(obj);
      } else {
        console.log('bnjr');
      }
    })

  }


  cancelSave() {
    this.itemToSave = {};
  }


  saveItem(item: any) {
    if (!this.currentTenant || !this.currentTenant.code) {return};
    item.tenancyId = this.currentTenant.id
    const request = {
      user: this.user.id,
      datas: [item]
    }
    console.log('data sent to create délégué', request)
    console.log('data sent to create délégué', JSON.stringify(request))
    this.busySave = this.api.post('delegue/' + (item.id ? 'update' : 'create'), request)
        .subscribe((res: any) => {
              console.log('resul', res);
              if (!res['hasError'] && res['items']) {
                this.notificationService.showMessageSucces('Succès', 'Opération effectuée avec succès.');
                this.currentPage = 1;
                this.hideModal();
                this.cancelSave();
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Erreur', this.utilities.messageInterpretor(err));
            });
  }


  deleteItem(obj: any) {
    const request = {
      user: this.user.id,
      datas: [{
        id:obj.id
      }]
    }
    console.log('to delete delegue: ',request);
    
    this.busyGet = this.api.post('delegue/delete', request)
        .subscribe(
            res => {
              console.log(res);
              if (!res['hasError']) {
                this.notificationService.showMessageSucces('Succès', 'Suppression effectuée avec succès');
                this.currentPage = 1;
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            }
        );
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  getSections() {

    const request = {
        user: this.user.id,
        data: {
            tenancyId: this.currentTenant.id,
        }
    }
    this.api.post('tenancyCulture/getSectionByTenancy', request)
        .subscribe(
            res => {
              // debugger
                console.log('getSections delegue', res)
                if (!res['hasError']) {
                    if (res && res['items']) {
                        this.listItemsSections = res['items'];
                        this.listItemsSections.map(
                          lis=>{
                            lis.sectionId = lis.id
                          }
                        )
                        console.log('getSections in delegue',this.listItemsSections);
                    }
                }
            },
            err => {
            }
        );
}

onItemSelect(event){
  console.log('select one: ',event);
  console.log('itemToSave.datasDelegueSectionDto',this.itemToSave.datasDelegueSectionDto);
  
}
onSelectAll(event){
  console.log('select all: ',event);
  console.log('itemToSave.datasDelegueSectionDto',this.itemToSave.datasDelegueSectionDto);

  
}

  ngOnInit() {
    this.getDataRole()
    this.userService.currentData.subscribe(currentData => {
      this.currentTenant = currentData;
      this.getData();
      this.getTenancyBanque();
      this.getSections();
      
    });
    this.dropdownSectionsSettings = {
      singleSelection: false,
      idField: 'sectionId',
      textField: 'libelle',
      selectAllText: 'Tout cocher',
      unSelectAllText: 'Tout décocher',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: 'Sélectionner',
      placeholder: 'Sélectionner',
  };
    window.scrollTo(0, 0);
  }
}
