<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
  <!--
  <button (click)="sortTest()">Sort test</button>
   fil d actualite -->
  <div class="row">
    <div class="form-group col-md-12">
      <div class="col-md-5">
        <label style="font-weight: 700">
          Numéro carte :
        </label>
        <input style="width: 77%; display: inline-block" [(ngModel)]="codeClient"  type="text" class="form-control custom_input" placeholder="Entrer le numéro de la carte svp!">
        
      </div>
      <div class="col-md-2">
        <button (click)="getData()" class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button">
          Rechercher </button>
      </div>
      <div class="col-md-5 text-right">
        <button *ngIf="size && size>1" (click)="onValidateOperation()" class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button">
          Valider
        </button> 
      </div>

      <br/>

      <div class="col-md-12 no-padding" style="margin-top: 10px">

        <table class="table table-condensed table-striped">
          <thead>
          <tr class="custom-table-thead">
            <th style="width: 45px"></th>
            <th style="width: 45px"></th>
            <th> Statut </th>
            <th> Date creation </th>
            <th>Nom bénéficiaire </th>
            <th>Contact bénéficiaire</th>
            <th class="text-right">Valeur</th>
            <th style="text-align: center; width: 75px"> Action </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let entr of listBeneficiairesTable; let i = index" >
            <td class="vertical-align-uppercase">
              <input type="checkbox" name="isCheked"  *ngIf="!entr.isWithdrawal" [(ngModel)]="entr.isChecked" (change)="onCheckItem()">
            </td>
            <td class="vertical-align-uppercase">
              {{i + 1}}
            </td>
            <td class="vertical-align-uppercase" *ngIf="!entr.isWithdrawal">
              <i class="fa fa-check-circle fa-2x" aria-hidden="true"
                  tooltip="Montant non rétiré"
                   placement="right"
                 style="color:#e8091b " ></i>
            </td>
            <td class="vertical-align-uppercase" *ngIf="entr.isWithdrawal" >
              <i  class="fa fa-check-circle  fa-2x" aria-hidden="true"
                  tooltip="Montant rétiré"
                   placement="right"
                  style="color: #0aa66e"></i>
            </td>
            <td class="vertical-align-uppercase">{{entr.createdAt}}</td>
            <!--              <td class="vertical-align-uppercase">{{entr.clientNom}} {{entr.clientPrenoms}}</td>-->
            <td class="vertical-align-uppercase">{{entr.clientNom}} {{entr.clientPrenoms}}</td>
            <td class="vertical-align-uppercase">{{entr.clientTelephone}}</td>
            <td class="vertical-align-uppercase text-right"  style="font-weight: 700">{{entr.valeurRecut | moneyFormat}}</td>
            <td style="text-align: center">
              <a title="Modifier" (click)="onValidateOperation(entr)" *ngIf="!entr.isWithdrawal">
                <i class="fa fa-edit" style="color: green"> </i>
              </a>


              &nbsp;&nbsp;
            </td>
          </tr>

          <tr *ngIf="listBeneficiairesTable && listBeneficiairesTable.length > 0">
            <td colspan="9">
              <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
                <pagination [boundaryLinks]="true" [totalItems]="totalItems" [maxSize]='itemPerPagePagination' [itemsPerPage]="itemsPerPage"
                            [(ngModel)]="currentPage" class="pagination-sm" previousText="&laquo;" nextText=" &raquo;"
                            firstText="&laquo;" lastText="&raquo;" name="pagination" (pageChanged)="pageChanged($event)">
                </pagination>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot *ngIf="!listBeneficiairesTable || listBeneficiairesTable.length == 0">
          <tr>
            <td colspan="9" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
          </tr>

          </tfoot>

        </table>
      </div>

    </div>
  </div>
  <!-- fin file d actualite -->



  <ng-template #templateCreate>
    <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">
          {{selectectedDonation.libelle | truncate:['60']}}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="form-group col-lg-12">

            <div class="row">
              <div class="col-md-12">
                <div class="form-group col-md-12" style="padding: 0">
                  <label>Type de repartition :
                    <span class="required_champs">*</span>
                  </label>
                  <select class="form-control custom_input" id="isForSeveral" [(ngModel)]="isForSeveral" #ctrl="ngModel"
                          name="isForSeveral" >
                    <option [ngValue]="true">
                      Repartition identique
                    </option>
                    <option [ngValue]="false">
                      Repartition non identique
                    </option>
                  </select>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px">

                  <div class="form-group col-md-6">
                    <label>Critères:</label>
                    <!-- <div *ngFor="let option of options; let i = index">
                      <input type="checkbox" [(ngModel)]="option.selected" id="{{i}}" name="{{i}}" value="{{option.key}}">
                      <label for="{{i}}">{{option.value}}</label><br>
                    </div> -->
                  </div>
                  <div class="form-group col-md-6">
                    <label>Ordre:</label>
                    <!-- <div *ngFor="let order of orders; let j = index">
                      <input id="{{10 + j}}" type="radio" [(ngModel)]="order.selected" name="order" [value]="order.key">
                      <label for="{{10 + j}}">{{order.value}}</label><br>
                    </div> -->
                  </div>

                </div>

                <div class="form-group col-md-12" *ngIf="isForSeveral">
                  <label>Valeur :
                    <span class="required_champs">*</span>
                  </label>
                  <div class="row">
                    <div class="col-md-8" style="padding: 0">
                      <input class="form-control custom_input" autocomplete="off" [(ngModel)]="valeurToApply" name="valeurToApply" required
                             placeholder="Valeur" type="text" mask="separator">
                    </div>
                    <div class="col-md-4">
                      <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30">
                        Appliquer
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-12" style="max-height: 300PX;overflow: auto" >
                  <label>Répartition <a><i class="fa fa-refresh"></i></a>:
                    <span class="required_champs">*</span>
                  </label>

                  <table class="table table-condensed">
                    <thead>
                    <tr>
                      <th> Client </th>
                      <th > Valeur </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr *ngFor="let bnef of listClientsFiltered ">
                      <td>
                        <!-- <a *ngIf="bnef.valeurRecut"  (click)="deleteRow(bnef)" style="margin-right: 5px">
                          <i class="fa fa-times" aria-hidden="true" style="color: red;"></i>
                         </a> -->
                        {{bnef.libelle}} </td>
                      <td>
                        <!-- <input [disabled]="isForSeveral" class="form-control custom_input"  autocomplete="off" [(ngModel)]="bnef.valeurRecut" name="valeurRecut" placeholder="Valeur"
                               required type="text" mask='separator' [ngModelOptions]="{standalone: true}" (keyup)="displayClientQuantiteValeur(bnef)" maxlength="12"> -->
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="form-group col-md-12">
          <div class="row">
            <div class="col-md-6 text-left">
              Total : <strong>{{selectectedDonation.valeurOperation | moneyFormat}}</strong>
            </div>
            <!-- <div *ngIf="totalRepartie ==0" class="col-md-4 text-left">
              Repatie : <strong>{{totalRepartie}}</strong>
            </div>
            <div *ngIf="totalRepartie>0" class="col-md-4 text-left">
              Repatie : <strong>{{totalRepartie | moneyFormat}}</strong>
            </div> -->
            <div class="col-md-6 text-right">
              Reste : <strong>{{resteArepartir | moneyFormat}}</strong>

            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left">(<span class="required_champs">*</span>) Champs requis</b>

        <!-- <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal()">
          Annuler
        </button> -->

        <!-- <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItem(itemToSave) ">
          Valider
        </button> -->

      </div>
    </form>
  </ng-template>
</div>
