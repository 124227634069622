import { Component, OnInit } from '@angular/core';
import {FadeZoomInTop} from "../../animations/fade-zoom-in-top.decorator";
import {config} from "../../smartadmin.config";
import {LayoutService} from "../layout.service";
import {UserService} from "../../user/user.service";
import {ActivatedRoute, Route, Router} from '@angular/router';

@FadeZoomInTop()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styles: []
})
export class MainLayoutComponent implements OnInit {

  private config:any = {};
  user : any;

  constructor(public layoutService:LayoutService,private userService: UserService,public router:Router) {
    this.config = config;
  }

  ngOnInit() {
    this.layoutService.onSmartSkin(this.config.skins[2]);
  }

}
