import { Injectable } from '@angular/core';


@Injectable()
export class DataResolver {
  constructor() {}
}

// an array of services to resolve routes with data
export const APP_RESOLVER_PROVIDERS = [
  DataResolver
];
