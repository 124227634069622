import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";
import {Api} from "../../../shared/utils/api/api";
import {BsModalService} from "ngx-bootstrap/modal";
import {NotificationService} from "../../../shared/utils/notification.service";
import {UtilitiesService} from "../../../shared/utils/utilities.service";
import {UserService} from "../../../shared/user";
import {HttpHeaders} from "@angular/common/http";
import * as _ from 'lodash';
declare const Swal: any;


@Component({
  selector: 'app-main-type-don',
  templateUrl: './main-type-don.component.html',
  styleUrls: ['./main-type-don.component.css']
})
export class MainTypeDonComponent implements OnInit {
  resultDelaiPaiements: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;

  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  currentTenant: any = {};
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination = 10;
  admin: any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  listTypeDon=[];


  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.itemToSearch = {};
  }

  // MULTI SELECT


  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified?: any) {
    this.cancelSave();
    if (itemToModified) {
      this.itemToSave = { ...itemToModified };
    }
    this.modalRef = this.modalService.show(template, { class: 'gray modal-md' });
  }


  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }


  getData() {
    if (!this.currentTenant || !this.currentTenant.code) {return};
    const options = {
      headers: new HttpHeaders({
        'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004',
        lang: 'fr'
      })
    }
    const request = {
      user: this.user.id,
      data: {},
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }
    this.busyGet = this.api.post('typeDon/getByCriteria', request)
        .subscribe((res: any) => {
              console.log('listTypeDon', res);
              if (res && res['items']) {
                this.listTypeDon = res['items'];
                this.totalItems = res['count'] ? res['count'] : 0;
              } else {
                this.listTypeDon = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }

  confirmSaveItem(obj: any) {
    if (!obj) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner les champs obligatoires.');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.code) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner le code svp!');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.libelle) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner le libellé svp!');
      return;
    }
    obj.modeleDevice = 'WEB';
    obj.typeCode = 'MIXTE';
    Swal.fire({
      title: !obj.id ? 'Enregistrement de type don' : 'Modification de type don',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.saveItem(obj);
      } else {
        console.log('bnjr');
      }
    })
  }



  confirmDeleteItem(obj: any) {
    Swal.fire({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#ff7b0c',
      cancelButtonColor: 'rgb(154, 154, 153)',
    }).then((result) => {
      if (result.value) {
        this.deleteItem(obj);
      } else {
        console.log('bnjr');
      }
    })

  }


  cancelSave() {
    this.itemToSave = {};
  }


  saveItem(item: any) {
    if (!this.currentTenant || !this.currentTenant.code) {return};
    const options = {
      headers: new HttpHeaders({
        'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004',
        lang: 'fr'
      })
    }
    const request = {
      user: this.user.id,
      datas: [{ ...item, tenancyId: this.currentTenant.id }]
    }
    console.log('create return', request)
    this.busySave = this.api.post('typeDon/' + (item.id ? 'update' : 'create'), request)
        .subscribe((res: any) => {
              console.log('resul', res);
              if (!res['hasError'] && res['items']) {
                this.notificationService.showMessageSucces('Succès', 'Opération effectuée avec succès.');
                this.currentPage = 1;
                this.hideModal();
                this.cancelSave();
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Erreur', this.utilities.messageInterpretor(err));
            });
  }


  deleteItem(obj: any) {
    const request = {
      user: this.user.id,
      datas: [obj]
    }
    this.busyGet = this.api.post('typeDon/delete', request)
        .subscribe(
            res => {
              console.log(res);
              if (!res['hasError']) {
                this.notificationService.showMessageSucces('Site', 'Suppression effectuée avec succès');
                this.currentPage = 1;
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            }
        );
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    this.userService.currentData.subscribe(currentData => {
      this.currentTenant = currentData;
      this.getData();
    });
    window.scrollTo(0, 0);
  }
}
