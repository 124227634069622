import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs";

import * as _ from "lodash";

@Injectable()
export class UserService {
  public user: Subject<any>;
  private data = new BehaviorSubject("");
  currentData = this.data.asObservable();

  public userInfo = {
    username: "Guest",
  };

  constructor() {
    this.user = new Subject();
  }

  updateTenantInformation(item: any) {
    console.log("userservice calle");
    this.data.next(item);
  }

  isLoggedIn(): boolean {
    return !!sessionStorage.getItem("yz-backoffice-app-smart-carte");
  }

  getExtensionsImage() {
    return [{ extension: "png" }, { extension: "jpeg" }, { extension: "jpg" }];
  }

  getExtensionsXlsx() {
    return [{ extension: "xlsx" }, { extension: "xls" }];
  }

  getPermission(code) {
    var returnValue = null;
    var ListeFonctionnalites =
      this.getCurrentUser() && this.getCurrentUser().fonctionnalites
        ? this.getCurrentUser().fonctionnalites
        : [];

    if (ListeFonctionnalites.length > 0) {
      _.forEach(ListeFonctionnalites, function (valueChildSecond, keyChild) {
        if (valueChildSecond == code) {
          returnValue = true;
        }
      });
    }

    return null;
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem("yz-backoffice-app-smart-carte"));
  }
}
