import {Injectable} from '@angular/core';
declare const Swal: any;

declare var $: any;

@Injectable()
export class NotificationService {

  constructor() {
  }

 showMessageInfo(title,message)
  {
      this.smallBox({
          title: title,
          content: message,
          color: "#159",
          iconSmall: "fa fa-info fa-2x fadeInRight animated",
          timeout: 4000
      })
  }

    showMessageError(title,message){
      Swal.fire({
        title: title,
        text: message,
        icon: 'error',
        timer: 3000
      })
    }

    showMessageSucces(title,message) {
      Swal.fire({
        title: title,
        text: message,
        icon: 'success',
        timer: 3000
      })
    }

    defaultMessage()
    {
      let text_default = "Connexion momentanément interrompue";
      return text_default;
    }

    defaultMessageError()
    {
      let text_default = "Une erreur c'est produite lors de l'enregistrement";
      return text_default;
    }

  smallBox(data, cb?) {
    // $.smallBox(data, cb)
  }

  bigBox(data, cb?) {
    $.bigBox(data, cb)
  }

  smartMessageBox(data, cb?) {
    $.SmartMessageBox(data, cb)
  }

}
