import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Api } from "../../../shared/utils/api/api";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NotificationService } from "../../../shared/utils/notification.service";
import { UtilitiesService } from "../../../shared/utils/utilities.service";
import { ModelService } from "../../../shared/utils/model.service";
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
declare const Swal: any;
import { HttpEventType, HttpHeaders } from "@angular/common/http";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
// defineLocale('fr', deLocale);
import { frLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import * as XLSX from "xlsx";
type AOA = any[][];

@Component({
  selector: "app-main-clients",
  templateUrl: "./main-clients.component.html",
  styleUrls: ["./main-clients.component.css"],
})
export class MainClientsComponent implements OnInit {
  msg: any;
  rowSelected: any;
  SelectedCultureInfo: any = {};
  currentSection = "";
  ListeUtilisateurs: Array<any> = [];
  ListeMoyenPaiements: Array<any> = [];
  ListeCultures: Array<any> = [];
  ListeRoles: Array<any> = [];
  ListeClients: Array<any> = [];
  ListeAllCultures: Array<any> = [];
  ListeAllTenants: Array<any> = [];
  positions: Array<any> = [];
  positionsDomicile: Array<any> = [];
  ListeCartes: Array<any> = [];
  dataCarte: any = {};
  busyGet: Subscription;
  busySave: Subscription;
  busyGetMontant: Subscription;
  itemToSave: any;
  typePieceId: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemSeleced: any = {};
  itemToSearch: any;
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  //   imageDisplay: any;
  itemPerPagePagination = 10;
  admin: any = {};
  currentTenant: any = {};
  imageDisplay: any;
  piece1: any;
  piece2: any;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  selectedItemsCultures = [];
  selectedItemsCooperatives = [];

  dropdownCultureSettings = {};
  dropdownCooperativesSettings = {};
  dialectes: any = [];
  documents: any = [];
  config = {
    animated: true,
    keyboard: false,
    backdrop: "static",
    ignoreBackdropClick: true,
  };
  cultureSelected: any = {};
  clientBanques: any = [];
  banqueSelected: any = {};
  typePieces: any;
  newCulture: any = {};
  newBanque: any = {};
  certificats: any = [];
  banques: any = [];
  tenancySections: any = [];
  tenancyDelaiPaiements: any = [];
  dropdownSectionsSettings = {};
  maxDate = new Date();
  bsInlineValueAnnSouscriptionCoop = new Date();
  bsInlineValueAnnSouscriptionCarte = new Date();
  bsInlineValueDateNais = new Date();
  private itemId: any;
  selectedItemsSections: any;
  selectedCulture: any;
  listItemsSections: any;
  cultureSuperficie: any;
  listCertificates: any;
  datasCultures = []; //for saving purpose
  listeDelais: any;
  fullTenancyInfo: any;
  datasSectionToEdit: any;
  currentYear = new Date().getFullYear();
  sectionSelected: any;
  listSection: any;
  listTenancyCuluturesSections: any;
  ListeCulturesCopy: any[];
  renderAnnSouscriptionCoop = false;
  renderAnnSouscriptionCarte = false;
  renderDateNais = false;
  selectedFile: any;
  defaultSecurityCode = 3706;
  importFile: any = {};
  isAvance = false;
  autreCoops: any = [];
  fileBordereau: any;
  crediterIdForBordereau: string;
  constructor(
    private api: Api,
    public router: Router,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private utilities: UtilitiesService,
    private modelService: ModelService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef
  ) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 50;
    this.itemToSearch = {};
    defineLocale(frLocale.abbr, frLocale);
    // this.localeService.use('fr');
    this.activatedRoute.params.subscribe((params) => {
      console.log("params", params);
      this.isAvance = params["avance"] == "true";
      if (!this.isAvance) {
        this.isAvance = this.activatedRoute.snapshot.url[0].path == "avance";
      }
    });
  }

  // Importation de fichier excel
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      console.log("ws", ws);

      /* save data */
      var dataSheet1 = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      console.log("read import data 1", dataSheet1);
      this.formatData(dataSheet1);
    };

    reader.readAsBinaryString(target.files[0]);
  }

  formatData(liste) {
    var listeValeursFormate = [];

    //On commence a partir de la ligne 3 du fichier excel
    for (var i = 3; i < liste.length; i++) {
      var currentI = liste[i];

      //On format un objet suivant l'ordre qu'on veut

      var objImport = {
        maColonne1: currentI[0] ? currentI[0] : null,
        maColonne2: currentI[1] ? currentI[1] : null,
        maColonne3: currentI[2] ? currentI[2] : null,
        maColonne4: currentI[3] ? currentI[3] : null,
      };

      listeValeursFormate.push(objImport);
    }

    console.log("listeValeursFormate ", listeValeursFormate);
  }

  onSelectedCulture() {
    this.currentSection = this.SelectedCultureInfo
      ? this.SelectedCultureInfo.cultureSectionLibelle
      : null;
    this.SelectedCultureInfo.annCreationCulture = null;
    this.SelectedCultureInfo.certificatId = null;
    this.SelectedCultureInfo.latitude = null;
    this.SelectedCultureInfo.longitude = null;
    this.SelectedCultureInfo.hasFertilisant = "";
    this.SelectedCultureInfo.fertilisant = null;
    this.SelectedCultureInfo.surfaceCultivee = null;
    this.SelectedCultureInfo.codePlantation = null;
  }

  // MULTI SELECT
  hideModal() {
    this.cancelSave();
    this.modalRef.hide();
  }

  dragHome(evt: any) {
    console.log("dragHome", evt.latLng.lat(), evt.latLng.lng());
    this.itemToSave.latitudeDomicile = evt.latLng.lat().toFixed(4);
    this.itemToSave.longitudeDomicile = evt.latLng.lng().toFixed(4);
  }

  dragChamp(evt: any) {
    console.log("dragChamp", evt.latLng.lat(), evt.latLng.lng());
    this.cultureSelected.latitude = evt.latLng.lat().toFixed(4);
    this.cultureSelected.longitude = evt.latLng.lng().toFixed(4);
  }

  addCulture(p: any) {
    console.log("already selected cultures: ", this.selectedItemsCultures);
    console.log("selected culture: ", this.cultureSelected);
    // let selectedCultureValid = !this.selectedItemsCultures.length
    if (!(JSON.stringify(p) === JSON.stringify({}))) {
      if (!(JSON.stringify(this.cultureSelected) === JSON.stringify({}))) {
        if (!this.cultureSelected.annCreationCulture) {
          this.notificationService.showMessageError(
            "Information",
            "Veuillez renseigner l'année de création de la culture svp!"
          );
          return;
        }
        if (!this.cultureSelected.surfaceCultivee) {
          this.notificationService.showMessageError(
            "Information",
            "Veuillez renseigner la surface de la culture svp!"
          );
          return;
        }
      }
      p.id = null;
      this.selectedItemsCultures.forEach((e: any) => {
        e.isNew = false;
      });
      const fd =
        this.selectedItemsCultures.find(
          (e: any) => e.cultureId === p.cultureId
        ) || {};
      if (fd.cultureId) {
        this.notificationService.showMessageError(
          "Information",
          "Une culture du même nom existe deja"
        );
        return;
      }
      this.selectedItemsCultures.unshift({ ...p });
      this.cultureSelected = this.selectedItemsCultures[0];
      this.cultureSelected.isNew = true;
      this.newCulture = {};
    } else {
      this.notificationService.showMessageError(
        "Information",
        "Veuillez selectionner une culture svp!"
      );
    }
  }

  addBanque(p: any) {
    console.log("selected bank: ", p);
    if (!(JSON.stringify(p) === JSON.stringify({}))) {
      if (!p.banque) {
        this.notificationService.showMessageError(
          "Information",
          "Veuillez selectionner une banque svp!"
        );
        return;
      }
      if (!p.annOuverture) {
        this.notificationService.showMessageError(
          "Information",
          "Veuillez selectionner l'année d'ouverture svp!"
        );
        return;
      }
      if (!p.rib) {
        this.notificationService.showMessageError(
          "Information",
          "Veuillez renseigner le rib svp!"
        );
        return;
      }

      p.id = null;
      p.banqueId = p.banque ? p.banque.id : null;
      p.banqueCode = p.banque ? p.banque.code : null;
      // this.clientBanques.splice(0, 1);
      this.clientBanques.unshift(p);
      this.newBanque = {};
    } else {
      this.notificationService.showMessageError(
        "Information",
        "Veuillez renseigner les données bancaires svp!"
      );
    }
  }

  openModalAction(template: any) {
    this.SelectedCultureInfo = {};
    this.modalRef = this.modalService.show(template, {
      class: "modal-actions",
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  openModalIdentifiant(template: any) {
    this.SelectedCultureInfo = {};
    this.modalRef = this.modalService.show(template, {
      class: "modal-identifiant",
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  openModalImport(template: any) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-import",
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  openFormModalModal(template: any, p?: any) {
    if (p) {
      this.itemToSave = p;
      this.itemToSave.nom = p.clientNom;
      this.itemToSave.prenoms = p.clientPrenoms;
    }
    console.log("p", this.itemToSave);
    console.log("fullTenancyInfo", this.fullTenancyInfo);
    if (this.itemToSave.nbreEnfant < this.itemToSave.nbrEnfantMoin15) {
      this.notificationService.showMessageError(
        "Erreur",
        "le nombre d'enfants de -15 ne peut être supérieur au nombre d'enfants!"
      );
      return;
    }
    if (this.itemToSave.nbreEnfant < this.itemToSave.nbrEnfantScola) {
      this.notificationService.showMessageError(
        "Erreur",
        " le nombre d'enfants scolarisés ne peut être supérieur au nombre d'enfants!"
      );
      return;
    }
    this.SelectedCultureInfo = {};
    this.currentSection = null;
    this.cancelSave();

    this.clientBanques = [];
    this.cultureSelected = {};
    this.itemToSave.typePieceId = null;
    this.imageDisplay = null;
    this.piece1 = null;
    this.piece2 = null;
    this.itemId = p && p.id ? p.id : null;
    const item = p && p.id ? { ...p, ...p.dataClient } : {};
    console.log("to be modified: ", p);
    // console.log('to be modified: ', JSON.stringify(p))
    this.bsInlineValueAnnSouscriptionCarte = new Date();
    this.bsInlineValueAnnSouscriptionCoop = new Date();
    this.bsInlineValueDateNais = new Date();
    this.datasSectionToEdit = [];
    if (p && p.id) {
      this.renderAnnSouscriptionCoop = true;
      this.renderAnnSouscriptionCarte = true;
      this.renderDateNais = true;
      // this.itemToSave.typePieceId = p.dataClient.typePieceId
      if (p.dataClient.datasCulture) {
        this.datasCultures = p.dataClient.datasCulture;
        if (!p.dataClient.datasCulture[0].cultureId)
          p.dataClient.datasCulture.shift();
      }

      if (p.dataClient.datasBanque) {
        this.clientBanques = p.dataClient.datasBanque;
      }

      console.log("datasCultures", this.datasCultures);

      // this.datasSectionToEdit = p.dataClient.datasCulture.datasClientSection
      this.bsInlineValueAnnSouscriptionCarte = item.annSouscriptionCarte
        ? moment(item.annSouscriptionCarte, "DD/MM/YYYY").toDate()
        : null;
      this.bsInlineValueAnnSouscriptionCoop = item.annSouscriptionCoop
        ? moment(item.annSouscriptionCoop, "DD/MM/YYYY").toDate()
        : null;
      this.bsInlineValueDateNais = item.dateNais
        ? moment(item.dateNais, "DD/MM/YYYY").toDate()
        : null;
    }
    if (item) {
      this.itemToSave.isClickMap = false;
      // Ne sert à rien à priori
      // this.getAllInfoDataClient(item);
      this.itemToSave = Object.assign({}, item);
      //this.itemToSave.typePieceId = this.itemToSave.typePieceId+"";
      setTimeout(() => {
        this.itemToSave.typePieceId = this.itemToSave.typePieceId + "";
      }, 400);

      setTimeout(() => {
        this.itemToSave.delaiId = this.itemToSave.delaiId + "";
      }, 1000);

      if (this.itemToSave.filePath) {
        this.imageDisplay = this.itemToSave.photoPath;
        this.piece1 = this.itemToSave.filePath;
        this.piece2 = this.itemToSave.filePath1;
      }
      console.log("itemToSave.typePieceId", this.itemToSave.typePieceId);
      console.log(
        "itemToSave.moyenPaiementId",
        this.itemToSave.moyenPaiementId
      );
      console.log("itemToSave.delaiId", this.itemToSave.delaiId);
    } else {
      this.getDataCarte();
    }
    this.modalRef = this.modalService.show(template, {
      class: "gray modal-lg custom-modal-client",
      backdrop: true,
      ignoreBackdropClick: true,
    });
    this.itemToSave = { ...this.itemToSave };
    this.ref.detectChanges();
  }

  pageChanged(event: any): void {
    console.log("Page changed to: " + event.page);
    console.log("Number items per page: " + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  getDelaiPaiement() {
    const request = {
      user: this.user.id,
      data: {
        tenancyId:
          this.currentTenant && this.currentTenant.id
            ? this.currentTenant.id
            : null,
      },
    };
    this.busySave = this.api
      .post("delaiPaiement/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getDelaiPaiement", res);
        if (res && res.items) {
          this.listeDelais = res.items || [];
        }
      });
  }

  getAllInfoDataClient(item: any) {
    const request = {
      user: this.user.id,
      data: {
        clientId: item.id,
      },
    };
    this.busySave = this.api
      .post("clientTenancy/getByCriteria", request)
      .subscribe((res: any) => {
        console.log(res);
        if (res && res.items) {
          this.selectedItemsCooperatives = res["items"];
          // this.itemToSave.ListeCooperativesExistants = Object.assign([], this.selectedItemsCooperatives);
        } else {
        }
      });
  }

  getAllInfoTypePiece() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.busySave = this.api
      .post("typePiece/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getAllInfoTypePiece", res);
        if (res && res.items) {
          this.typePieces = res.items || [];
          this.typePieces.map((tp) => {
            tp.typePieceId = tp.id;
          });
        }
      });
  }

  getDialecte() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.busySave = this.api
      .post("dialecte/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getDialecte", res);
        this.dialectes = res.items || [];
        this.dialectes = _.sortBy(this.dialectes, [
          function (lb) {
            return lb.libelle;
          },
        ]);
      });
  }

  getData() {
    if (!this.currentTenant || !this.currentTenant.id) {
      return;
    }
    const request = {
      user: this.user.id,
      data: {
        tenancyId:
          this.currentTenant && this.currentTenant.id
            ? this.currentTenant.id
            : null,
        clientNom: this.itemToSearch.nom || null,
        clientPrenoms: this.itemToSearch.prenoms || null,
        clientCode: this.itemToSearch.code || null,
      },
      index: this.currentPage - 1,
      size: this.itemsPerPage ? this.itemsPerPage : null,
    };
    this.busyGet = this.api
      .post("clientTenancy/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getData", res.items);
        if (res && res.items) {
          this.ListeClients = res.items;
          this.currentItemSeleced = this.ListeClients[0];
          this.ListeCultures =
            this.currentItemSeleced.dataClient.datasCulture || [];
          this.addSectionLibelle();
          this.SelectedCultureInfo = this.ListeCultures[0];
          if (this.SelectedCultureInfo && this.SelectedCultureInfo.cultureId) {
            this.currentSection =
              this.SelectedCultureInfo.cultureSectionLibelle;
          }
          this.totalItems = res.count ? res.count : 0;
        } else {
          this.ListeClients = [];
        }
      });
  }

  removeEmptyObject() {
    if (!this.ListeCultures[0].cultureId) this.ListeCultures.shift();
  }

  selectedClient(client: any) {
    this.SelectedCultureInfo = "";
    this.currentItemSeleced = Object.assign({}, client);
    this.itemToSave = Object.assign({}, client);
    this.documents = [];
    this.ListeCultures = (
      this.currentItemSeleced.dataClient || {}
    ).datasCulture;
    this.addSectionLibelle();
    this.currentSection = "";
  }

  onSelectCreditCulture() {
    this.SelectedCultureInfo.montant = 0;
    this.SelectedCultureInfo.quantite = 0;
    console.log("SelectedCultureInfo", this.SelectedCultureInfo);
    this.currentSection = "";

    if (this.SelectedCultureInfo && this.SelectedCultureInfo.cultureId) {
      this.currentSection = this.SelectedCultureInfo.cultureSectionLibelle;
      if (!this.ListeCultures[0].cultureId) this.ListeCultures.shift();
    }
  }

  addSectionLibelle() {
    if (this.ListeCultures && this.ListeCultures.length) {
      this.ListeCultures.forEach((element) => {
        if (this.ListeCulturesCopy && this.ListeCulturesCopy.length) {
          this.ListeCulturesCopy.forEach((subElement) => {
            if (element.cultureSectionId == subElement.cultureSectionId)
              element.cultureSectionLibelle = subElement.cultureSectionLibelle;
          });
        }
      });
    }
  }

  calculerMontant() {
    console.log("le montant ");
    if (!this.SelectedCultureInfo) {
      this.notificationService.showMessageError(
        "Information",
        "Veuillez sélectionner une culture."
      );
      return;
    }
    if (this.SelectedCultureInfo.quantite < 0) {
      this.notificationService.showMessageError(
        "Information",
        "Veuillez renseigner une quantité positive."
      );
      return;
    }
    if (!this.SelectedCultureInfo.quantite) {
      this.notificationService.showMessageError(
        "Information",
        "Veuillez sélectionner la quantité et la culture."
      );
      return;
    }

    if (!this.currentTenant || !this.currentTenant.code) {
      return;
    }

    const options = {
      headers: new HttpHeaders({
        // tslint:disable-next-line:comment-format
        tenantID:
          this.currentTenant && this.currentTenant.code
            ? this.currentTenant.code
            : null, //'1907122100004',
        lang: "fr",
      }),
    };

    const request = {
      user: this.user.id,
      tenantId:
        this.currentTenant && this.currentTenant.code
          ? this.currentTenant.code
          : null,
      datas: [
        {
          clientId: this.SelectedCultureInfo.cultureId,
          siteId: 1,
          quantite: this.SelectedCultureInfo.quantite,
          cultureId: this.SelectedCultureInfo.cultureId,
          cultureSectionId: this.SelectedCultureInfo.cultureId,
          sectionId: this.SelectedCultureInfo.sectionId,
        },
      ],
    };
    console.log("data sent to calculate montant: ", request);
    console.log("data sent to calculate montant: ", JSON.stringify(request));
    this.busyGetMontant = this.api
      .postOption("carte/calculerMontant", request, options)
      .subscribe(
        (res) => {
          console.log("les roles", res);
          if (res && res["items"]) {
            this.SelectedCultureInfo.montant = res["items"][0].montant;
          } else {
            this.SelectedCultureInfo.montant = 0;
            this.notificationService.showMessageError(
              "Erreur",
              this.utilities.mainSelfFormatMsgServeur(res["status"]["message"])
            );
          }
        },
        (err) => {
          console.log("Error occured", err);
        }
      );
  }

  getDataFullMarchand(idMarchand) {
    const request = {
      user: this.user.id,
      data: {
        id: idMarchand,
      },
    };
    this.busySave = this.api.post("marchand/getByCriteria", request).subscribe(
      (res: any) => {
        if (res && res["items"]) {
          this.itemToSave = { ...res["items"][0] };
          this.itemToSave.domaineSelected = _.find(this.ListeCultures, (o) => {
            return (o.id = this.itemToSave.domaineId);
          });
          this.selectedItems = this.itemToSave.datasMoyenPaiement;
          if (this.itemToSave.datasUser && this.itemToSave.datasUser[0]) {
            const userAdmin = this.itemToSave.datasUser[0];
            this.admin = {
              // tslint:disable-next-line:comment-format
              //referenceIdentite : ,
              lastName: userAdmin.userLastName,
              firstName: userAdmin.userFirstName,
              email: userAdmin.userEmail,
              telephone: userAdmin.userTelephone,
              login: userAdmin.userLogin,
              id: userAdmin.id,
            };
          }
        }
      },
      (err) => {
        console.log("Error occured", err);
        //   this.notificationService.showMessageError('Connexion',this.notificationService.defaultMessage());
        // this.not
      }
    );
  }

  getDataCarte() {
    const request = {
      user: this.user.id,
      data: {
        isAttribute: false,
      },
    };
    this.api.post("carte/getByCriteria", request).subscribe(
      (res: any) => {
        console.log("getDataCarte call", res);
        if (res && res["items"]) {
          this.ListeCartes = res["items"];
        } else {
          this.ListeCartes = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
      }
    );
  }

  getDataMoyenPaiement() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.api
      .post("moyenPaiement/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("les moyens de paiement", res);
        if (res && res.items) {
          res.items.forEach((value: any) => {
            value.libelle = value.libelle.toUpperCase();
            value.moyenPaiementLibelle = value.libelle;
          });
          this.ListeMoyenPaiements = res.items;
        }
      });
  }

  uploadFile(event: any, item: any, bool: boolean) {
    const reader = new FileReader();
    const file = event.target.files[0];
    const fileName = file.name.split(".")[0];
    const Tabextension = file.name.split(".");
    const extension = Tabextension[Tabextension.length - 1];
    // verifier si l'extension est accepter
    const listeextensionImages = this.userService.getExtensionsImage();
    if (!_.find(listeextensionImages, { extension: extension.toLowerCase() })) {
      this.notificationService.showMessageError(
        "Upload",
        "Veuillez vérifier l'extension du fichier."
      );
      event.target.value = null;
      return;
    }
    reader.onload = (readerEvent) => {
      const data = (readerEvent.target as any).result;
      if (item === "photo") {
        this.imageDisplay = data;
      } else if (item === "piece1") {
        this.piece1 = data;
      } else if (item === "piece2") {
        this.piece2 = data;
      }
      const fileBase64 = data.split(",")[1];
      const currentItemImage = {
        fichierBase64: fileBase64,
        nomFichier: fileName,
        isPhoto: bool,
        extension: extension,
      };
      this.documents.push(currentItemImage);
      console.log("le item", this.currentItemImage);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  confirmDefinirObjectif(obj: any) {
    // if (!this.SelectedCultureInfo) {
    //     this.notificationService.showMessageError('Erreur', 'Veuillez renseigner les champs obligatoires.');
    //     return;
    // }
    // if (!this.SelectedCultureInfo.quantite || !this.SelectedCultureInfo.montant) {
    //     this.notificationService.showMessageError('Erreur', 'Veuillez renseigner la culture et la quantité.');
    //     return;
    // }
    // obj.cultureId = this.SelectedCultureInfo.cultureId;
    // obj.clientId = this.currentItemSeleced.dataClient.id;
    // obj.montant = this.SelectedCultureInfo.montant;
    // obj.quantite = this.SelectedCultureInfo.quantite;
    // obj.tenantCode = this.currentTenant.code;
    // obj.securePassword = this.SelectedCultureInfo.securePassword;
    // obj.createdAt = null;
    Swal.fire({
      title: "Définir objectif",
      text: "Désirez-vous poursuivre cette action ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result: any) => {
      if (result.value) {
        this.definirObjectif(obj);
      } else {
        console.log("bnjr");
      }
    });
  }

  confirmSaveCrediterCarteItem(obj: any) {
    if (!this.SelectedCultureInfo) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les champs obligatoires."
      );
      return;
    }
    if (
      !this.SelectedCultureInfo.quantite 
      // ||!this.SelectedCultureInfo.montant
    ) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la culture et la quantité."
      );
      return;
    }
    obj.cultureId = this.SelectedCultureInfo.cultureId;
    obj.clientId = this.currentItemSeleced.dataClient.id;
    obj.montant = this.SelectedCultureInfo.montant;
    obj.quantite = this.SelectedCultureInfo.quantite;
    obj.tenantCode = this.currentTenant.code;
    obj.securePassword = this.SelectedCultureInfo.securePassword;
    obj.createdAt = null;
    Swal.fire({
      title: "Créditer",
      text: "Désirez-vous poursuivre cette action ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result: any) => {
      if (result.value) {
        this.crediterCarteItem(obj);
      } else {
        console.log("bnjr");
      }
    });
  }

  confirmSaveDebiterCarteItem(item: any) {
    const objToSave: any = { ...item };
    if (!objToSave) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les champs obligatoires."
      );
      return;
    }
    if (!item.moyenPaiementId) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le moyen de paiement."
      );
      return;
    }
    if (!item.montantDebit) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez vérifier le montant à débiter."
      );
      return;
    }
    objToSave.clientId = this.currentItemSeleced.dataClient.id;
    objToSave.siteId = 1;
    objToSave.montant = this.itemToSave.montantDebit;
    objToSave.typePret = this.itemToSave.typePret;
    objToSave.objetMarchand = "Débit de carte par la coopérative";
    objToSave.tenantCode = this.currentTenant.code;
    objToSave.isWord = false;
    console.log("objToSave", objToSave);
    Swal.fire({
      title: "Débiter",
      text: "Désirez-vous poursuivre cette action ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result: any) => {
      if (result.value) {
        this.debiterCarteItem(objToSave);
      }
    });
  }

  confirmSaveItem(obj: any) {
    console.log("l'enregistrement du client", obj);
    console.log("l'enregistrement de la carte", this.itemToSave);

    if (this.itemToSave.nbreEnfant < this.itemToSave.nbrEnfantMoin15) {
      this.notificationService.showMessageError(
        "Erreur",
        "Le nombre d'enfants de moins de 15 ne peut être supérieur au nombre d'enfants!"
      );
      return;
    }
    if (this.itemToSave.nbreEnfant < this.itemToSave.nbrEnfantScola) {
      this.notificationService.showMessageError(
        "Erreur",
        "Le nombre d'enfants scolarisés ne peut être supérieur au nombre d'enfants!"
      );
      return;
    }

    if (!obj) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les differents champs svp!"
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.annSouscriptionCoop) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la date de souscription coopérative svp!"
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.annSouscriptionCarte) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la date de souscription carte svp!"
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.sexe) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le sexe svp!"
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.nom) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le nom svp!"
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.prenoms) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le prenom svp!"
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.dateNais) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la date de naissance svp!"
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.telephone) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le contact 1 svp!"
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.statutMatrimoniale) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la situation matrimoniale svp!"
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.dialecteId) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Lettré svp!"
      );
      return;
    }
    if (!this.itemToSave || !(this.itemToSave.nbreEnfant >= "0")) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le nombre d'enfant svp!"
      );
      return;
    }
    if (
      !this.itemToSave ||
      !this.itemToSave.delaiId ||
      this.itemToSave.delaiId == "undefined"
    ) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Delai paiement svp!"
      );
      return;
    }
    if (
      !this.itemToSave ||
      !this.itemToSave.typePieceId ||
      this.itemToSave.typePieceId == "undefined"
    ) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le type de pièce svp!"
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.numPiece) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le numero de la pièce svp!"
      );
      return;
    }
    if (!this.datasCultures || this.datasCultures.length == 0) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les cultures svp!"
      );
      return;
    }

    this.itemToSave.tenantCode = this.currentTenant.code;
    Swal.fire({
      title: !obj.id ? "Enregistrement client" : "Modification client",
      text: "Désirez-vous poursuivre cette action ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result: any) => {
      if (result.value) {
        this.saveItem(obj);
      }
    });
  }

  confirmDeleteItem(obj) {
    Swal.fire({
      title: "Suppression",
      text: "Désirez-vous poursuivre cette action ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        this.deleteItem(obj);
      } else {
        console.log("bnjr");
      }
    });
  }

  checkCurrentItem(item: any) {
    if (item) {
      console.log("ellement coché", item);
      this.currentItemSeleced = item;
    }
  }

  cancelSave() {
    this.renderAnnSouscriptionCoop = false;
    this.renderAnnSouscriptionCarte = false;
    this.renderDateNais = false;

    this.currentSection = null;
    this.SelectedCultureInfo = {};
    this.newBanque = {};
    this.selectedItemsSections = [];
    this.listItemsSections = [];
    this.cultureSelected = {};
    this.cultureSuperficie = null;
    this.datasCultures = [];
    this.itemToSave = {};
    this.selectedItemsSections = [];
    this.selectedItemsCooperatives = [];
    this.selectedItemsCultures = [];
    this.currentItemImage = {};
    this.dataCarte = {};
    this.imageDisplay = null;
    this.itemToSave.typePieceId = null;
    // this.itemToSave.securePassword = 3706
  }

  handleImportFile(files: FileList) {
    const file = files.item(0) as File;
    this.importFile = file;
  }

  saveItemByImport(item: any) {
    console.log("datasCulture to save", this.datasCultures);
    item.datasCulture = this.datasCultures;
    item.datasCulture.map((idc: any) => {
      if (idc.hasFertilisant == "oui") {
        idc.hasFertilisant = true;
      }
      if (idc.hasFertilisant == "non") {
        idc.hasFertilisant = false;
      }
    });
    item.clientIdentifaint = this.itemToSave.code;
    item.tenancyId = this.currentTenant.id;
    const request = {
      user: this.user.id,
      datas: [item],
    };
    const formData = new FormData();
    formData.append("file", this.importFile, this.importFile.name);
    formData.append("userId", "1");
    formData.append("tenancyCode", this.currentTenant.code);
    this.busySave = this.api
      .post("client/uploadClient", formData, {
        headers: { formData: "true" },
        reportProgress: true,
        observe: "events",
      })
      .subscribe(
        (res: any) => {
          console.log("resul", res);
          this.documents = [];
          if (res["hasError"] === false) {
            this.notificationService.showMessageSucces(
              "Client",
              "Opération effectuée avec succès."
            );
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
            this.cultureSelected = {};
          } else {
            this.notificationService.showMessageError(
              "Erreur",
              res["status"]["message"]
            );
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Erreur",
            this.utilities.messageInterpretor(err)
          );
        }
      );
  }

  saveItemByIdentifiant(item: any) {
    console.log("datasCulture to save", this.datasCultures);
    item.datasCulture = this.datasCultures;
    item.datasCulture.map((idc: any) => {
      if (idc.hasFertilisant == "oui") {
        idc.hasFertilisant = true;
      }
      if (idc.hasFertilisant == "non") {
        idc.hasFertilisant = false;
      }
    });
    item.clientIdentifaint = this.itemToSave.code;
    item.tenancyId = this.currentTenant.id;
    const request = {
      user: this.user.id,
      datas: [item],
    };
    console.log("data sent to server: ", request);
    this.busySave = this.api.post("clientTenancy/create", request).subscribe(
      (res: any) => {
        console.log("resul", res);
        this.documents = [];
        if (res["hasError"] === false) {
          this.notificationService.showMessageSucces(
            "Client",
            "Opération effectuée avec succès."
          );
          this.currentPage = 1;
          this.hideModal();
          this.cancelSave();
          this.getData();
          this.cultureSelected = {};
        } else {
          this.notificationService.showMessageError(
            "Erreur",
            res["status"]["message"]
          );
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Erreur",
          this.utilities.messageInterpretor(err)
        );
      }
    );
  }

  saveItem(item: any) {
    console.log("datasCulture to save", this.datasCultures);
    item.seuilCarte =
      this.dataCarte && this.dataCarte.seuil ? this.dataCarte.seuil : null;
    item.datasDocuments = this.documents.length > 0 ? this.documents : null;
    item.datasCulture = this.datasCultures;
    item.datasCulture.map((idc: any) => {
      if (idc.hasFertilisant == "oui") {
        idc.hasFertilisant = true;
      }
      if (idc.hasFertilisant == "non") {
        idc.hasFertilisant = false;
      }
    });
    item.datasClientBanqueDto = this.clientBanques.map((p: any) => {
      return {
        annOuverture: p.annOuverture,
        rib: p.rib,
        id: p.id,
        banqueId: p.banqueId,
        isBorrow: p.isBorrow,
        tenantId: this.currentTenant.id,
      };
    });
    item.nbrEnfantScola = parseInt(item.nbrEnfantScola);
    item.nbrEnfantMoin15 = parseInt(item.nbrEnfantMoin15);
    item.id = item.id ? item.clientId : undefined;
    const request = {
      user: this.user.id,
      datas: [item],
    };
    this.busySave = this.api
      .post("client/" + (item.id ? "update" : "create"), request)
      .subscribe(
        (res: any) => {
          console.log("resul", res);
          this.documents = [];
          if (!res["hasError"]) {
            this.notificationService.showMessageSucces(
              "Client",
              "Opération effectuée avec succès."
            );
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
            this.getDataCarte();

            this.cultureSelected = {};
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.notificationService.showMessageError(
                "Erreur",
                res["status"]["message"]
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Erreur",
            this.utilities.messageInterpretor(err)
          );
        }
      );
  }

  definirObjectif(item: any) {
    if (
      !this.currentTenant ||
      (!this.currentTenant.code && !this.currentTenant.tenancyCode)
    ) {
      return;
    }
    const options = {
      headers: new HttpHeaders({
        // tslint:disable-next-line:comment-format
        tenantID:
          this.currentTenant &&
          (this.currentTenant.code || this.currentTenant.tenancyCode)
            ? this.currentTenant.code || this.currentTenant.tenancyCode
            : null, //'1907122100004',
        lang: "fr",
      }),
    };
    const request = {
      user: this.user.id,
      datas: [
        {
          annee: item.annee,
          estimation: item.estimation,
          clientId: this.currentItemSeleced.clientId,
          cultureId: item.cultureId,
        },
      ],
    };
    console.log("definirObjectif", request);
    this.busyGet = this.api
      .postOption("clientObjectif/create", request, options)
      .subscribe((res: any) => {
        console.log("resul", res);
        if (!res.hasError && res.items) {
          this.notificationService.showMessageSucces(
            "Créditer",
            "Opération effectuée avec succès."
          );
          this.currentSection = null;
          this.SelectedCultureInfo = {};
          this.currentPage = 1;
          this.cancelSave();
          // this.getData();
        } else {
          if (res.status && res.status.message) {
            this.notificationService.showMessageError(
              "Erreur",
              res.status.message
            );
          }
        }
      });
  }

  crediterCarteItem(item: any) {
    if (
      !this.currentTenant ||
      (!this.currentTenant.code && !this.currentTenant.tenancyCode)
    ) {
      return;
    }
    const options = {
      headers: new HttpHeaders({
        // tslint:disable-next-line:comment-format
        tenantID:
          this.currentTenant &&
          (this.currentTenant.code || this.currentTenant.tenancyCode)
            ? this.currentTenant.code || this.currentTenant.tenancyCode
            : null, //'1907122100004',
        lang: "fr",
      }),
    };
    const request = {
      user: this.user.id,
      datas: [
        {
          montant: item.montant,
          clientId: this.currentItemSeleced.clientId,
          tenantCode: this.currentTenant.code,
          sectionId: item.cultureSectionId,
          cultureSectionId: item.cultureSectionId,
          quantite: item.quantite,
          cultureId: item.cultureId,
          securePassword: item.securePassword,
          numeroBorderaux: item.numeroBorderaux,
          prix: item.prix,
          autreCooperativeId: item.autreCooperativeId,
        },
      ],
    };
    // console.log("crediterCarteItem", request);
    // console.log("crediterCarteItem", JSON.stringify(request));
    this.busyGet = this.api
      .postOption("carte/crediter", request, options)
      .subscribe((res: any) => {
        console.log("resul", res);
        if (!res.hasError && res.items) {
          this.crediterIdForBordereau = res.items[0].crediterId;
          this.saveBordereauImage();
          this.notificationService.showMessageSucces(
            "Créditer",
            "Opération effectuée avec succès."
          );
          this.currentSection = null;
          this.SelectedCultureInfo = {};
          this.currentPage = 1;
          this.cancelSave();
          this.getData();
        } else {
          if (res.status && res.status.message) {
            this.notificationService.showMessageError(
              "Erreur",
              res.status.message
            );
          }
        }
      });
  }

  debiterCarteItem(item: any) {
    const request = {
      user: this.user.id,
      datas: [
        {
          id: item.id,
          clientId: item.clientId,
          tenancyId: item.tenancyId,
          moyenPaiementId: item.moyenPaiementId,
          montantDebit: item.montantDebit,
          siteId: item.siteId,
          montant: item.montant,
          objetMarchand: item.objetMarchand,
          tenantCode: item.tenantCode,
          commentaireDebit: item.commentaireDebit,
          typePret: item.typePret,
        },
      ],
    };
    const methode = this.isAvance ? "avance" : "debiter";
    this.busyGet = this.api
      .post(`carte/${methode}`, request)
      .subscribe((res: any) => {
        console.log("resul", res);
        if (!res.hasError && res.items) {
          this.notificationService.showMessageSucces(
            "Débiter",
            "Opération effectuée avec succès."
          );
          this.currentPage = 1;
          this.cancelSave();
          this.getData();
        } else {
          if (res.status && res.status.message) {
            this.notificationService.showMessageError(
              "Erreur",
              res.status.message
            );
          }
        }
      });
  }

  deleteItem(obj) {
    console.log(obj);
    const request = {
      user: this.user.id,
      datas: [
        {
          id: obj.clientId,
          tenantCode: this.currentTenant.code,
        },
      ],
    };
    this.busyGet = this.api.post("client/delete", request).subscribe(
      (res) => {
        console.log(res);
        if (!res["hasError"]) {
          this.notificationService.showMessageSucces(
            "Succès",
            "Suppression effectuée avec succès"
          );
          this.currentPage = 1;
          this.getData();
        } else {
          if (res["status"] && res["status"]["message"]) {
            this.notificationService.showMessageError(
              "Erreur",
              res["status"]["message"]
            );
          }
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
      }
    );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = (page - 1) * 10 + (index + 1);
    }
    return num;
  }

  onSelectCulture(c: any) {
    console.log("onSelectCulture", c);
  }

  onSelectBanque(item: any) {
    console.log(item);
    this.clientBanques.forEach((p: any) => {
      p.isNew = false;
    });
    this.banqueSelected = item;
    this.banqueSelected.isNew = true;
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  onMapReady(map) {
    console.log("map", map);
    console.log("markers", map.markers); // to get all markers as an array
  }
  onIdle(event) {
    console.log("map", event.target);
  }
  onMarkerInit(marker) {
    console.log("marker", marker);
  }

  onMapClick(event) {
    this.itemToSave.isClickMap = false;
    console.log("on map click", event);
    console.log("latitude", event.latLng.lat);
    console.log("longitude", event.latLng.lng);
    this.positions = [];
    this.positions.push(event.latLng);
    event.target.panTo(event.latLng);

    console.log("la position push", this.positions[0]);

    this.itemToSave.positionMaker = this.positions[0];
    setTimeout(() => {
      const postionMaker = document.getElementById("positionMak")
        ? (<HTMLInputElement>document.getElementById("positionMak")).value
        : null;
      console.log("le position maker", postionMaker);
      if (postionMaker) {
        this.itemToSave.isClickMap = true;
        this.itemToSave.longitude =
          postionMaker && postionMaker.split(",")[0]
            ? postionMaker.split(",")[0].replace("(", "")
            : null;
        this.itemToSave.latitude =
          postionMaker && postionMaker.split(",")[1]
            ? postionMaker.split(",")[1].replace(")", "")
            : null;
      }
    }, 1000);
  }

  onMapClickDomicile(event: any) {
    this.itemToSave.isClickMapDomicile = false;
    console.log("on map click", event);
    this.positionsDomicile = [];
    this.positionsDomicile.push(event.latLng);
    event.target.panTo(event.latLng);
    console.log("la position push", this.positionsDomicile[0]);
    this.itemToSave.positionMakerDomicile = this.positionsDomicile[0];
    setTimeout(() => {
      const postionMakerDomicile = document.getElementById(
        "positionMakDomicile"
      )
        ? (<HTMLInputElement>document.getElementById("positionMakDomicile"))
            .value
        : null;
      console.log("le position maker", postionMakerDomicile);
      if (postionMakerDomicile) {
        this.itemToSave.isClickMapDomicile = true;
        this.itemToSave.longitudeDomicile =
          postionMakerDomicile && postionMakerDomicile.split(",")[0]
            ? postionMakerDomicile.split(",")[0].replace("(", "")
            : null;
        this.itemToSave.latitudeDomicile =
          postionMakerDomicile && postionMakerDomicile.split(",")[1]
            ? postionMakerDomicile.split(",")[1].replace(")", "")
            : null;
      }
    }, 1000);
  }

  gotoFicheProduction(item: any) {
    const clientId = item.id;
    const clientIdFormat =
      this.getRandomValueForCode() +
      "_" +
      clientId +
      "_" +
      this.getRandomValueForCode();
    this.router.navigate(["/fiche-production", clientIdFormat]);
  }

  getRandomValueForCode() {
    return Math.floor(Math.random() * 16777215).toString(16);
  }
  getDataFullTenant(idTenant: number) {
    if (!idTenant) return;
    const request = {
      user: this.user.id,
      data: {
        id: idTenant,
      },
    };
    this.busySave = this.api
      .post("tenancy/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getDataFullTenant", res);
        if (res && res.items) {
          this.fullTenancyInfo = res.items[0];
          this.ListeCultures = res.items[0].datasCulture || [];
          this.ListeCulturesCopy = [...this.ListeCultures];
          console.log("ListeCultures: ", this.ListeCultures);
        }
      });
  }

  onSetDate(event: any, key) {
    console.log("event: ", moment(event).format("DD/MM/YYYY"));
    console.log("key: ", event);
    const dateFormatted = moment(event).format("DD/MM/YYYY");
    if (key === "annSouscriptionCoop") {
      this.renderAnnSouscriptionCoop = true;
      this.itemToSave.annSouscriptionCoop = dateFormatted;
    } else if (key === "annSouscriptionCarte") {
      this.renderAnnSouscriptionCarte = true;
      this.itemToSave.annSouscriptionCarte = dateFormatted;
    } else if (key === "dateNais") {
      this.renderDateNais = true;
      this.itemToSave.dateNais = dateFormatted;
    }
  }

  onSelectCultureSections(forCredDeb?) {}
  updateCulture() {
    console.log("section culture: ", this.SelectedCultureInfo);

    if (JSON.stringify(this.SelectedCultureInfo) === JSON.stringify({})) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la culture svp!"
      );
      return;
    }
    if (!this.SelectedCultureInfo.annCreationCulture) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Année création svp!"
      );
      return;
    }
    if (!this.SelectedCultureInfo.certificatId) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Certification svp!"
      );
      return;
    }
    if (!this.currentSection) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la section svp!"
      );
      return;
    }
    if (
      this.SelectedCultureInfo.hasFertilisant === "oui" &&
      !this.SelectedCultureInfo.fertilisant
    ) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner description svp!"
      );
      return;
    }
    if (
      !(
        this.SelectedCultureInfo.surfaceCultivee &&
        this.SelectedCultureInfo.surfaceCultivee > 0
      )
    ) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la superficie de la culture svp!"
      );
      return;
    }

    console.log("SelectedCultureInfo: ", this.SelectedCultureInfo);
    const data = {
      id: this.SelectedCultureInfo.id,
      tenancyId: this.currentTenant.id,
      cultureId: this.SelectedCultureInfo.cultureId,
      cultureLibelle: this.SelectedCultureInfo.cultureLibelle,
      cultureSectionLibelle: this.SelectedCultureInfo.cultureSectionLibelle,
      cultureSectionId: this.SelectedCultureInfo.cultureSectionId,
      surfaceCultivee: this.SelectedCultureInfo.surfaceCultivee,
      longitude: this.SelectedCultureInfo.longitude,
      codePlantation: this.SelectedCultureInfo.codePlantation,
      latitude: this.SelectedCultureInfo.latitude,
      hasFertilisant: this.SelectedCultureInfo.hasFertilisant,
      fertilisant: this.SelectedCultureInfo.fertilisant,
      annCreationCulture: this.SelectedCultureInfo.annCreationCulture,
      certificatId: this.SelectedCultureInfo.certificatId,
    };
    const exists = _.find(this.datasCultures, {
      id: this.SelectedCultureInfo.id,
    });
    console.log("selected culture: ", this.cultureSelected);
    console.log("selected sections: ", this.selectedItemsSections);
    if (!exists) {
      this.datasCultures.push(data);
    } else {
      const index = _.findIndex(this.datasCultures, {
        id: this.SelectedCultureInfo.id,
      });
      this.datasCultures[index].certificatId = data.certificatId;
      this.datasCultures[index].annCreationCulture = data.annCreationCulture;
      this.datasCultures[index].surfaceCultivee = data.surfaceCultivee;
      this.datasCultures[index].codePlantation = data.codePlantation;
      this.datasCultures[index].fertilisant = data.fertilisant;
      this.datasCultures[index].hasFertilisant = data.hasFertilisant;
      this.datasCultures[index].latitude = data.latitude;

      this.datasCultures[index].longitude = data.longitude;

      this.datasCultures[index].cultureSectionId = data.cultureSectionId;
      this.datasCultures[index].cultureSectionLibelle =
        data.cultureSectionLibelle;
      this.datasCultures[index].cultureLibelle = data.cultureLibelle;

      this.datasCultures[index].cultureId = data.cultureId;
    }

    console.log("single data culture: ", data);
    console.log("All cultures to be saved: : ", this.datasCultures);
    this.SelectedCultureInfo = {};
    this.currentSection = "";
  }
  removeCulture(cul: any) {
    this.datasCultures.splice(cul.id, 1);
  }
  UncheckAll() {
    this.selectedItemsSections = [];
  }
  getCertificates() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.api.post("certificat/getByCriteria", request).subscribe(
      (res) => {
        console.log("getCertificates", res);
        if (!res["hasError"]) {
          if (res && res["items"]) {
            this.listCertificates = res["items"];
          }
        }
      },
      (err) => {}
    );
  }

  getAllInfoBanque() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.busySave = this.api
      .post("banque/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getAllInfoBanque", res);
        if (res && res.items) {
          this.banques = res.items || [];
        }
      });
  }

  getAutreCooperative() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.busySave = this.api
      .post("autreCooperative/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getAutreCooperative", res);
        if (res && res.items) {
          this.autreCoops = res.items || [];
        }
      });
  }

  controleYearCreate() {
    console.log("date changed");

    if (
      parseInt(this.SelectedCultureInfo.annCreationCulture) > this.currentYear
    ) {
      this.SelectedCultureInfo.annCreationCulture = this.currentYear;
    }
    if (parseInt(this.newBanque.annOuverture) > this.currentYear) {
      this.newBanque.annOuverture = this.currentYear;
    }
  }

  autoFillFields(cult) {
    console.log("cult: ", cult);
    this.rowSelected = cult.id;
    this.SelectedCultureInfo = { ...cult };
    this.currentSection = this.SelectedCultureInfo.cultureSectionLibelle;
    if (this.SelectedCultureInfo.hasFertilisant) {
      this.SelectedCultureInfo.hasFertilisant = "oui";
    }
  }

  getDataSearch() {
    this.currentPage = 1;
    this.getData();
  }

  phoneNumberSeparator(str) {
    if (str)
      return [
        str.slice(0, 2),
        " ",
        str.slice(2, 4),
        " ",
        str.slice(4, 6),
        " ",
        str.slice(6, 8),
        " ",
        str.slice(8, 10),
      ].join("");
  }

  downloadTemplate() {
    const options = {
      headers: new HttpHeaders({
        tenantID:
          this.currentTenant && this.currentTenant.code
            ? this.currentTenant.code
            : null, //'1907122100004',
        lang: "fr",
      }),
    };
    const request = {
      user: this.user.id,
      data: {
        tenancyId: this.currentTenant.id,
      },
    };
    this.busyGet = this.api
      .postOption("/clientCulture/downloadUploadeTemplate", request, options)
      .subscribe((res: any) => {
        console.log(res);
        if (res && res.filePathDoc) {
          console.log("res.filePathDoc: ", res.filePathDoc);

          window.location.href = res ? res.filePathDoc : window.location.href;
        } else {
          this.notificationService.showMessageError(
            "Information",
            res.status.message
          );
        }
      });
  }
  onSelectFile(event: any) {
    let af = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    this.selectedFile = event.target.files[0];
    console.log("selected file", this.selectedFile.type);
    if (!_.includes(af, this.selectedFile.type)) {
      this.notificationService.showMessageError(
        "Erreur",
        "Seuls les documents EXCEL sont autorisés !"
      );

      return;
    }

    Swal.fire({
      title: "Créditer en masse",
      text: "Désirez-vous poursuivre cette action ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result: any) => {
      if (result.value) {
        this.saveFile();
      } else {
        console.log("bnjr");
      }
    });
  }

  saveFile() {
    const fd = new FormData();
    fd.append("file", this.selectedFile);
    fd.append("userId", this.user.id);
    fd.append("tenancyId", this.currentTenant.id);
    this.busyGet = this.api
      .post("carte/crediterEnMasse", fd)
      .subscribe((response) => {
        if (!response["hasError"]) {
          this.notificationService.showMessageSucces(
            "Succès",
            response["status"]["message"]
          );
          this.getData();
        } else {
          this.notificationService.showMessageError(
            "Erreur",
            response["status"]["message"]
          );
        }
      });
  }

  onSelectTenancy(entr) {
    this.currentItemSeleced = entr;
  }


  uploadFileBordereau(event: any) {

    let reader = new FileReader();
    this.fileBordereau = event.target.files[0];
    console.log("data reader", this.fileBordereau);

    reader.onload = (readerEvent) => {
      console.log("data reader", readerEvent);
    };

    reader.readAsDataURL(event.target.files[0]);
  }

  saveBordereauImage() {
    var fd = new FormData();
    fd.append('userId', this.user.id + "");
    fd.append('file', this.fileBordereau);
    fd.append('crediterId', this.crediterIdForBordereau + "");
    //Appel du service
    this.api
      .post("carte/uploadBordereaux" , fd, {reportProgress: true, observe: "events"})
      .subscribe((res : any) => {
        // Swal.fire({
        //   title: "Succès",
        //   text: "Enregistrement réussi",
        //   icon: 'success',
        //   timer: 3000
        // })
        if (res.type === HttpEventType.Response) {
          this.crediterIdForBordereau = null;
          console.log("download progress", res);
        }
        else {
          console.log("download progress", res);
        }
        }
      );
  }

  ngOnInit() {
    console.log("SelectedCultureInfo", this.SelectedCultureInfo);
    this.userService.currentData.subscribe((currentData: any) => {
      console.log("currentUser", currentData);
      if (currentData) {
        this.cancelSave();
        this.currentTenant = currentData;
        console.log("current tenant id:", currentData.id);

        this.getDataFullTenant(currentData.id);
        this.getData();
        this.getDelaiPaiement();
        this.getAllInfoBanque();
        this.getCertificates();
        this.getAutreCooperative();
      } else {
        this.ListeClients = [];
      }
    });

    this.getDataCarte();
    this.getDialecte();
    this.getDataMoyenPaiement();
    this.getAllInfoTypePiece();

    this.dropdownCultureSettings = {
      singleSelection: false,
      idField: "id",
      textField: "cultureLibelle",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };
    this.dropdownCooperativesSettings = {
      singleSelection: false,
      idField: "id",
      textField: "tenancyRaisonSociale",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };
    this.dropdownSectionsSettings = {
      singleSelection: false,
      idField: "sectionId",
      textField: "libelle",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };
    window.scrollTo(0, 0);
  }
}
