import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
declare const Swal: any;
import { HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-main-reglement-smart-t',
  templateUrl: './main-reglement-smart-t.component.html',
  styleUrls: ['./main-reglement-smart-t.component.css']
})
export class MainReglementSmartTComponent implements OnInit {

  
  results: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;

  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination: number = 10;
  admin : any = {};
  currentTenant :  any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};


  config = {
      animated: true,
      keyboard: false,
      backdrop: 'static',
      ignoreBackdropClick: true
  };


  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService) {
      this.cancelSave();
      this.user = this.userService.getCurrentUser();
      this.itemsPerPage = 10;
      this.itemToSearch = {};
  }

  hideModal() {
      this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified?: any) {

      this.cancelSave();

      if (itemToModified) {
         
        //this.getDataFullMarchand(itemToModified.id) ;
        // itemToModified.typeUtilisateurSelected = _.find(this.ListeTypesUtilisateurs, { 'id': itemToModified.userTypeId });
        this.itemToSave = {...itemToModified};
      }
      this.modalRef = this.modalService.show(template, { class: 'gray modal-md' });
  }


  pageChanged(event: any): void {
      this.currentPage = event.page;
      this.getData();
  }


  getData() {

    if(!this.currentTenant || !this.currentTenant.code) return;

    const  options = {
      headers: new HttpHeaders({
          'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004', 
          lang: "fr"
      })
    }
  
    
      var request = {
          user: this.user.id,
          data: {
            tenantId : this.currentTenant.id
          },
          index: (this.currentPage - 1),
          size: this.itemsPerPage ? this.itemsPerPage : null
      }


      this.busyGet = this.api.postOption('reglementGlobal/getByCriteria', request,options)
          .subscribe(
              res => {
                  console.log(res);
                  if (res && res['items']) {
                      this.results = res['items'];
                      this.totalItems = res['count'] ? res['count'] : 0;
                  } else {
                      this.results = [];
                  }
              },
              err => {
                  console.log("Error occured", err);
                  this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                  // this.not
              }
          );

  }

  confirmSaveItem(obj) {

      if (!this.itemToSave) {
          this.notificationService.showMessageError("Erreur", "Veuillez renseigner les champs obligatoires.");
          return;
      }

      if(!this.itemToSave.montant)  
      {
        this.notificationService.showMessageError("Erreur", "Veuillez renseigner le montant.");
        return;
      }

      this.itemToSave.tenantCode = this.currentTenant.code;
  
      Swal.fire({
          title:'Réglement',
          text: 'Désirez-vous poursuivre cette action ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonColor: "#00A984",
          cancelButtonColor: "#333333",
      }).then((result) => {
          if (result.value) {
              this.saveItem(obj);
          } else {
              console.log("bnjr");
          }
      })

  }



  confirmDeleteItem(obj) {

      Swal.fire({
          title: 'Suppression',
          text: 'Désirez-vous poursuivre cette action ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonColor: "#ff7b0c",
          cancelButtonColor: "rgb(154, 154, 153)",
      }).then((result) => {
          if (result.value) {
              this.deleteItem(obj);
          } else {
              console.log("bnjr");
          }
      })

  }


  cancelSave() {
      this.itemToSave = {};
  }


  saveItem(item) {

      var request = {
          user: this.user.id,
          datas: [
              item
          ]
      }

      console.log("create return", request);

      this.busySave = this.api.post('reglementGlobal/createTenancy', request)
          .subscribe(
              res => {
                  console.log("resul", res);
                  if (!res['hasError'] && res['items']) {
                      this.notificationService.showMessageSucces("Réglement", 'Opération effectuée avec succès.');
                      this.currentPage = 1;
                      this.hideModal();
                      this.cancelSave();
                      this.getData();
                  } else {
                      if (res['status'] && res['status']['message']) {
                          this.notificationService.showMessageError("Erreur", res['status']['message']);
                      }
                  }
              },
              err => {
                  console.log("Error occured", err);
                  this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
              }
          );
  }


  deleteItem(obj) {

      var request = {
          user: this.user.id,
          datas: [
              obj
          ]
      }

      this.busyGet = this.api.post('domaine/delete', request)
          .subscribe(
              res => {
                  console.log(res);
                  if (!res['hasError']) {
                      this.notificationService.showMessageSucces("Domaine", 'Suppression effectuée avec succès');

                      this.currentPage = 1;
                      this.getData();
                  }
                  else {
                      if (res['status'] && res['status']['message']) {
                          this.notificationService.showMessageError("Erreur", res['status']['message']);
                      }
                  }

              },
              err => {
                  console.log("Error occured", err);
                  this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                  // this.not
              }
          );
  }


  getExactlyNumberRow(page, index) {
      let num = index + 1;
      if (page > 1) {
          num = ((page - 1) * 10) + (index + 1);
      }
      return num;
  }

  ngOnInit() {

      this.userService.currentData.subscribe(currentData => {
        this.currentTenant = currentData;
        this.getData();
      });
    
      window.scrollTo(0, 0);
  }
}
