<div class="project-context hidden-xs dropdown" dropdown>

  <span class="label">{{ 'Projects' }}:</span>
    <span class="project-selector dropdown-toggle" dropdownToggle>{{'Recent projects'}} <i ng-if="projects.length"
                                                                                                            class="fa fa-angle-down"></i></span>

  <ul *dropdownMenu class="dropdown-menu">
    <li *ngFor="let project of projects">
      <a href="{{project.href}}">{{project.title}}</a>
    </li>
    <li class="divider"></li>
    <li>
      <a (click)="clearProjects()"><i class="fa fa-power-off"></i> Clear</a>
    </li>
  </ul>

</div>
