<form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
        <div class="col-md-12" style="background: #fff;padding: 15px">
    
    
          <div class="form-group col-md-12">
              <h4 class="modal-title pull-left">Changement de mot de passe</h4>
              <hr>
          </div> 
        
          <div class="col-md-12" style="padding-left: 0px;padding-right: 0px">
    
                <div class="form-group col-md-12">
                      <label>Mot de passe actuel : <span class="required_champs">*</span></label>
                      <input type="password" id="passwordPop1" class="form-control custom_input" [(ngModel)]="itemToSave.oldPassword" name="old" placeholder="Mot de passe actuel " required>
                      <i class="fa fa-eye-slash eye-password" id="eyePop1" (click)="afficherPasswordPop1()"></i>
                </div> 
                <div class="form-group col-md-12">
                    <label>Nouveau mot de passe : <span class="required_champs">*</span></label>
                    <input type="password" id="passwordPop2" class="form-control custom_input" [(ngModel)]="itemToSave.password" name="new" placeholder="Nouveau mot de passe" required>
                    <i class="fa fa-eye-slash eye-password" id="eyePop2" (click)="afficherPasswordPop2()"></i>
                </div>
    
                <div class="form-group col-md-12">
                        <label>Confirmer nouveau mot de passe : <span class="required_champs">*</span></label>
                        <input type="password" id="passwordPop3" class="form-control custom_input" [(ngModel)]="itemToSave.ConfirmNewPassword" name="new" placeholder="Confirmer nouveau mot de passe" required>
                      <i class="fa fa-eye-slash eye-password" id="eyePop3" (click)="afficherPasswordPop3()"></i>
                </div>
    
                    <div class="form-group col-md-12" style="background-color: #f08484;padding: 10px;color: #fff" *ngIf="itemToSave.messageError"> 
                        <p>
                            {{ itemToSave.messageError }}
                        </p>
                    </div>
             </div>
        
             <div class="form-group col-md-12" style="margin-top: 15px">
              
                <button  type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel pull-right" (click)="bsModalRef.hide()">
                        Annuler
              </button>
    
              <button  type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 pull-right" (click)="saveItem(itemToSave)"  style="margin-right: 10px">
                    Valider
              </button>
            </div>
    
    
        </div>
        
    
    </form>
    