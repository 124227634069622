import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { SmartadminLayoutModule } from "./layout";
// import { I18nModule } from "./i18n/i18n.module";
import { SmartadminWidgetsModule } from "./widgets/smartadmin-widgets.module";
import { UtilsModule } from "./utils/utils.module";
import { SmartadminFormsLiteModule } from "./forms/smartadmin-forms-lite.module";
import { SmartProgressbarModule } from "./ui/smart-progressbar/smart-progressbar.module";

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,

    ModalModule,
    TabsModule,
    BsDropdownModule,

    SmartadminLayoutModule,

    // I18nModule,

    UtilsModule,

    SmartadminFormsLiteModule,

    SmartProgressbarModule,

    // InlineGraphsModule,

    SmartadminWidgetsModule,

    // StatsModule,
  ],
  providers: [],
})
export class SmartadminModule {}
