import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";
import {Api} from "../../../shared/utils/api/api";
import {BsModalService} from "ngx-bootstrap/modal";
import {NotificationService} from "../../../shared/utils/notification.service";
import {UtilitiesService} from "../../../shared/utils/utilities.service";
import {UserService} from "../../../shared/user";
import {HttpHeaders} from "@angular/common/http";
import * as _ from 'lodash';
import * as moment from 'moment';
import {NULL_EXPR} from "@angular/compiler/src/output/output_ast";
import { CommunicationService } from 'src/app/core/services/communication.service';
import { Subject } from 'rxjs';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
declare const Swal: any;

@Component({
  selector: 'app-main-beneficiaires-livrer-encaisser',
  templateUrl: './main-beneficiaires-livrer-encaisser.component.html',
  styleUrls: ['./main-beneficiaires-livrer-encaisser.component.css']
})
export class MainBeneficiairesLivrerEncaisserComponent implements OnInit {
  resultDelaiPaiements: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;

  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  currentTenant: any = {};
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination = 10;
  totalRepartie=0;
  admin: any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  valeurOperationRestante :any;
  options = [
    {
      key: 'noFilter',
      value: 'Tout',
      selected: false

    },
    {
      key: 'hommes',
      value: 'Hommes',
      selected: false

    },
    {
      key: 'femmes',
      value: 'Femmes',
      selected: false

    },
    // {
    //   key: 'certification',
    //   value: 'Certification',
    //   selected: false
    // }
  ];
  orders: any = [
    {
      key: 'superficie',
      value: 'Superficie',
      selected: true
    },
    {
      key: 'rendement',
      value: 'Rendement',
      selected: false
    },
    {
      key: 'solde',
      value: 'Solde',
      selected: false
    },
    {
      key: 'annee',
      value: 'Année souscription',
      selected: false
    }
  ];

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  listTypeDon= [];
  listBeneficiairesDon= [];
  listDonations= [];
  ListeClients = [];
  dropdownBeneficiariesSettings = {}
  listBeneficiaries = [];
  isForSeveral = true;
  listBeneficiairesTable: any;
  listClientsBrute: any;
  listRepartitions= [];
  valeurOperationRestanteBrute: number;
  isAll = false
  listToValidate= [];
  size: number;
  benefKey:any;
  onFullSearch = new Subject<string>();
  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, 
    private utilities: UtilitiesService, private userService: UserService,private communicationService:CommunicationService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.itemToSearch = {};
      this.communicationService.getData().subscribe(
        res=>{

          this.getOperations()
        }
      )
      this.onFullSearch.pipe(
        debounceTime(400),
        distinctUntilChanged())
        .subscribe(value => {
          this.listClientsFiltered = this.listClientsBrute
          console.log("current key: ",this.benefKey);
          this.benefKey = this.benefKey.toUpperCase()
          
          let matching = [];
          if(this.benefKey && this.benefKey.length){
            this.listClientsFiltered.map(
              lcf=>{
                let conc = lcf.clientNom + '' + lcf.clientPrenoms
                console.log("conc",conc.toUpperCase());
                
                console.log("index",conc.toUpperCase().indexOf(this.benefKey));
                
                  if(conc.toUpperCase().indexOf(this.benefKey)> -1){
                    matching.push(lcf)
                  }
              }
            );
            console.log("matching content: ",matching);
       
              this.listClientsFiltered = matching
          }
          else {
            this.listClientsFiltered = this.listClientsBrute
          }
        });

      
  }

  // MULTI SELECT
  listClientsFiltered: any[];
  selectedDonationId: any;
  selectectedDonation: any;
  quantiteToApply: any;
  valeurToApply: any;
  resteArepartir = 0;


  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified?: any) {
    this.benefKey = '';
    /*if (!(this.selectedDonationId && this.listBeneficiairesTable && this.listBeneficiairesTable.length)){
      this.notificationService.showMessageError('Erreur', 'Don non selectionné ou liste bénéficiaires vide');
      return
    }*/
    if (!this.selectedDonationId){
      this.notificationService.showMessageError('Erreur', 'Veuillez selectionner un crédit svp');
      return
    }
    this.getRemainingValue(this.selectedDonationId)

    this.listClientsFiltered.map(
        lbf=>{
          lbf.valeurRecut = null;
          lbf.quantiteRecut = null;
        }
    )
    this.listClientsFiltered = this.listClientsBrute
    this.valeurToApply = null
    this.quantiteToApply = null
    this.cancelSave();
    if (itemToModified) {
      console.log('itemToModified: ', itemToModified)
      this.itemToSave = { ...itemToModified };
      if (itemToModified.datasBeneficiaires && itemToModified.datasBeneficiaires.length){
        itemToModified.datasBeneficiaires.map(
            db => {
              db.libelle = db.clientNom + '' + db.clientPrenoms;
              db.id = db.clientId;
            }
        )
      }

    }
    this.modalRef = this.modalService.show(template, { class: 'gray modal-md' });
  }
  selectDonation(modal) {

    this.selectectedDonation = this.listDonations.filter(
        ld=>ld.id ===this.selectedDonationId
    )[0]
    this.listBeneficiairesTable = []
    if (this.selectedDonationId){
      this.getData();
    }

    console.log('selected donation id:',this.selectedDonationId)
    console.log('selected donation:',this.selectectedDonation)
    console.log('clients filter:',this.listClientsFiltered)
  }


  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }


  getData() {
    // if (!this.currentTenant || !this.currentTenant.code) {return};
    // const options = {
    //   headers: new HttpHeaders({
    //     'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004',
    //     lang: 'fr'
    //   })
    // }
    const request = {
      user: this.user.id,
      data: {
        tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        operBenefTenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        typeOperationCode: "CREDIT",
        operationId : this.selectedDonationId,
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }
    console.log('data sent to get beneficiaires don', JSON.stringify(request));

    this.busyGet = this.api.post('operationBeneficiaire/getByCriteria', request)
        .subscribe((res: any) => {
              if (res && res['items']) {
                console.log('data beneficiaires don return', res);
                this.listBeneficiairesDon = res['items'];
                this.totalItems = res['count'] ? res['count'] : 0;
                this.listBeneficiairesDon.map(
                    lb => {
                      lb.libelle = lb.clientNom + ' ' + lb.clientPrenoms;
                      lb.idOriginal = lb.id;
                      lb.id = lb.clientId;
                      lb.isChecked = false
                    }
                )

                if (!this.selectedDonationId){
                  this.listBeneficiairesTable = []
                }
                else {
                  /*
                  if (this.listBeneficiairesTable){
                      this.listBeneficiairesTable.map(
                          item=>{
                              if (item.isChecked){
                                  let id = item.id
                                  debugger
                                  this.listBeneficiairesDon.map(
                                      lb => {
                                          if (lb.id === id){
                                              lb.isChecked = true
                                          }
                                      }
                                  )
                              }

                          }
                      )
                  }*/
                  this.listBeneficiairesTable = this.listBeneficiairesDon
                }

                console.log('list beneficiaires don', this.listBeneficiairesDon);
                console.log('list listBeneficiairesTable don', this.listBeneficiairesTable);

              } else {
                this.listBeneficiairesDon = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }

  confirmSaveItem(obj: any) {
    this.itemToSave.datsListeBeneficiaires = this.listClientsFiltered
    this.itemToSave.operationId  = this.selectedDonationId
    this.itemToSave.valeurRepartition = this.valeurToApply

    console.log('item to save: ', obj)
    console.log('item to save: ', this.itemToSave)
    if (!obj) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner les champs obligatoires.');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.datsListeBeneficiaires || !this.itemToSave.datsListeBeneficiaires.length) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner le(s) bénéficiaire(s) svp!.');
      return;
    }
    else {
      console.log('beneficiaires to control',this.itemToSave.datsListeBeneficiaires)
      this.itemToSave.datsListeBeneficiaires = this.itemToSave.datsListeBeneficiaires.filter(
        lb=> lb.valeurRecut &&  lb.valeurRecut>0

    )
    if (!this.itemToSave.datsListeBeneficiaires.length){
        this.notificationService.showMessageError('Erreur', 'Veuillez renseigner des valeurs svp!');
        return;
    }
      else {
        let totalReparti= this.valeurOperationRestanteBrute ;
        this.itemToSave.datsListeBeneficiaires.map(
            db => {
              totalReparti -= db.valeurRecut

            }
        )
        if (totalReparti< 0){
          this.notificationService.showMessageError('Erreur', 'Le montant total à repartir est dépassé');
          return;
        }

      }
      let benefReduce = [];
      this.itemToSave.datsListeBeneficiaires.map(
          db => {
            let data ={'clientId':db.id,'valeurRecut':db.valeurRecut}
            benefReduce.push(data)
          }
      )
      this.itemToSave.datsListeBeneficiaires = benefReduce
    }
    this.itemToSave.valeurRepartition = this.itemToSave.datsListeBeneficiaires[0].valeurRecut
    if (this.isForSeveral){
      if (!this.itemToSave || !this.itemToSave.valeurRepartition) {
        this.notificationService.showMessageError('Erreur', 'Veuillez renseigner la valeur svp!.');
        return;
      }

    }
    else {
      this.itemToSave.valeurRepartition = null;
    }


    Swal.fire({
      title: !obj.id ? 'Enregistrement d\'une repartition de crédit' : 'Modification d\'une repartition de crédit',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.saveItem(obj);
      } else {
        console.log('bnjr');
      }
    })
  }

  cancelSave() {
    this.size = 0
    this.options.map(
        op => {
          op.selected = false
        }
    )

    this.orders.map(
        op => {
          op.selected = false
        }
    )

    this.itemToSave = {};
  }


  saveItem(item: any) {

    if (!this.currentTenant || !this.currentTenant.code) {return}
    const options = {
      headers: new HttpHeaders({
        'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004',
        lang: 'fr'
      })
    }
    item.isForSeveral = this.isForSeveral;
    const request = {
      user: this.user.id,
      datas: [{ ...item}]
    }
    console.log('data sent to server', JSON.stringify(request))
    this.busySave = this.api.post('repartitionOperation/' + (item.id ? 'update' : 'create'), request)
        .subscribe((res: any) => {
              console.log('resul', res);
              if (!res['hasError'] && res['items']) {
                this.notificationService.showMessageSucces('Succès', 'Opération effectuée avec succès.');
                this.currentPage = 1;
                this.hideModal();
                this.cancelSave();
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Erreur', this.utilities.messageInterpretor(err));
            });
  }

  confirmDeleteItem(obj: any) {
    Swal.fire({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.deleteItem(obj);
      } else {
        console.log('bnjr');
      }
    })

  }
  deleteItem(obj: any) {
    const request = {
      user: this.user.id,
      datas: [
        {
          id:obj.idOriginal
        }
        ]
    }
    console.log('data to delete',JSON.stringify(request))
    this.busyGet = this.api.post('operationBeneficiaire/delete', request)
        .subscribe(
            res => {
              console.log(res);
              if (!res['hasError']) {
                this.notificationService.showMessageSucces('Succès', 'Suppression effectuée avec succès');
                this.currentPage = 1;
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            }
        );
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }
  getClients() {
    if (!this.currentTenant || !this.currentTenant.code) { return }
    const request = {
      user: this.user.id,
      data: {
        tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        clientNom: this.itemToSearch.nom || null,
        clientPrenoms: this.itemToSearch.prenoms || null,
      },
    }
    console.log('list client in client don request', request);

    this.busyGet = this.api.post('clientTenancy/getByCriteria', request)
        .subscribe((res: any) => {
          console.log('list of client tenancy before filter', res.items);
          if (res && res['items']) {
            this.listClientsBrute = res['items'];
            this.listClientsBrute.map(
                lb => {
                  lb.libelle = lb.clientNom + ' ' + lb.clientPrenoms;
                  lb.id = lb.clientId;
                  lb.carteId = lb.dataClient.carteId;
                }
            )
            this.listClientsBrute = this.listClientsBrute.filter(
              lcb=> lcb.carteId
            )
            this.listClientsFiltered = this.listClientsBrute
          } else {
            this.ListeClients = [];
          }
          console.log('list of client tenancy after filter', this.listClientsFiltered);
        });
  }
  getRemainingValue(operationId) {
    const request = {
      user: this.user.id,
      data: {
        id: "",
        operationId: operationId
      },
    }
    console.log('data sent to get remaining', request);

    this.busyGet = this.api.post('repartitionOperation/getValeurRestanteByOpetation', request)
        .subscribe((res: any) => {
          console.log('remaing value return', res.items);
          if (res && res['items']) {
            this.resteArepartir = res['items'][0].sommeRestante || this.selectectedDonation.valeurOperation || 0
            this.valeurOperationRestanteBrute = this.resteArepartir

          } else {
            this.resteArepartir = 0;
            this.valeurOperationRestante = 0;
          }
        });
  }
  getOperations() {
    // if (!this.currentTenant
    // !this.currentTenant.code) {return};
    let data: any;
    data = {
      tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
      typeOperationCode : "CREDIT"
    };
    data.isValidated = true
    const request = {
      user: this.user.id,
      data
    }
    console.log('data sent to get operations', request)
    this.busyGet = this.api.post('operation/getByCriteria', request)
        .subscribe((res: any) => {
              console.log('listUnfiltered operations active', res);
              if (res && res['items']) {
                if (res['items'] && res['items'].length){
                  this.listDonations = res['items'];
                  console.log('listDonations', this.listDonations);
                  this.listDonations = this.listDonations.filter(
                    ld=>ld.tenancyId == this.currentTenant.id
                  )
                  console.log('listDonations don benef for current tenancy', this.listDonations);
                  if(this.selectedDonationId){
                    this.selectectedDonation = this.listDonations.filter(
                      ld=>ld.id ===this.selectedDonationId
                  )[0]
                  }
                  
                }
              } else {
                this.listDonations = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }
  onSelectOption(data,type,event){
    this.benefKey = '';
    console.log('event',event)
    this.itemToSave.datsListeBeneficiaires = []
    console.log(this.options);
    console.log(this.listClientsBrute);
    let selectedOptions = this.options.filter(
        op=>op.selected
    )

    console.log('data', data);
    console.log('data key', data.key);
    if (type === 'criteria'){
      if (data.key === 'noFilter' && event.target.checked){
        this.listClientsFiltered = this.listClientsBrute
        this.options.map(
            op => {
              if (op.key !=='noFilter')
                op.selected = true
            }
        )
        if (this.listClientsFiltered && this.listClientsFiltered.length){
          this.listClientsFiltered = this.listClientsFiltered.filter(
              lb => (lb.dataClient.sexe ==='F' || lb.dataClient.sexe ==='M') && lb.dataClient.isCertificate
          )
        }

        return
      }
      else {
        if (data.key === 'noFilter' && !event.target.checked){
          this.listClientsFiltered = this.listClientsBrute
          this.options.map(
              op => {
                if (op.key !=='noFilter')
                  op.selected = false
              }
          )

          return
        }

        this.options.map(
            op => {
              if (op.key ==='noFilter')
                op.selected = false
            }
        )

        if (
            !_.find(selectedOptions, { key: 'hommes'}) &&
            !_.find(selectedOptions, { key: 'femmes'}) &&
            !_.find(selectedOptions, { key: 'certification'})) {
          this.listClientsFiltered = this.listClientsBrute
        }
        if (
            _.find(selectedOptions, { key: 'hommes'}) &&
            _.find(selectedOptions, { key: 'femmes'}) &&
            _.find(selectedOptions, { key: 'certification'})) {
          this.listClientsFiltered = this.listClientsBrute
          if (this.listClientsFiltered && this.listClientsFiltered.length){
            this.listClientsFiltered = this.listClientsFiltered.filter(
                lb => (lb.dataClient.sexe ==='F' || lb.dataClient.sexe ==='M') && lb.dataClient.isCertificate
            )
          }


        }
        else {
          if (
              _.find(selectedOptions, { key: 'hommes'}) &&
              _.find(selectedOptions, { key: 'certification'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.sexe ==='M' && lb.dataClient.isCertificate
              )
            }

            return;
          }
          if (
              _.find(selectedOptions, { key: 'femmes'}) &&
              _.find(selectedOptions, { key: 'certification'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.sexe ==='F' && lb.dataClient.isCertificate
              )
            }

            return;
          }
          if (
              _.find(selectedOptions, { key: 'hommes'}) &&
              _.find(selectedOptions, { key: 'femmes'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.sexe ==='F' || lb.dataClient.sexe ==='M'
              )
            }

            return;
          }
          if (
              _.find(selectedOptions, { key: 'hommes'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.sexe ==='M'
              )
            }
            return;
          }
          if (
              _.find(selectedOptions, { key: 'femmes'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.sexe ==='F'
              )
            }
            return;
          }
          if (
              _.find(selectedOptions, { key: 'certification'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.isCertificate
              )
            }
            return;
          }

        }


      }
    }
    else {
      if (data.key === 'superficie') {
        if (this.listClientsFiltered && this.listClientsFiltered.length){
          this.listClientsFiltered = _.sortBy(this.listClientsFiltered, [function(lb) { return -lb.dataClient.sommeSurfaceCultive; }]);

        }
      }
      if (data.key === 'rendement') {
        if (this.listClientsFiltered && this.listClientsFiltered.length){
          this.listClientsFiltered = _.sortBy(this.listClientsFiltered, [function(lb) { return -lb.dataClient.rendement; }]);

        }
      }
      if (data.key === 'solde') {
        if (this.listClientsFiltered && this.listClientsFiltered.length){
          this.listClientsFiltered = _.sortBy(this.listClientsFiltered, [function(lb) { return -lb.dataClient.solde; }]);

        }
      }if (data.key === 'annee') {
        if (this.listClientsFiltered && this.listClientsFiltered.length){
          this.listClientsFiltered = _.sortBy(this.listClientsFiltered, [function(lb) { return moment(lb.dataClient.annSouscriptionCarte, 'DD/MM/YYYY'); }]);
        }
      }
    }
    console.log('beneficiaries filtered', this.listClientsFiltered);



  }


  applyValeur() {
    this.listClientsFiltered.map(
        lbf=>lbf.valeurRecut = null
    )
    if (!this.valeurToApply || this.valeurToApply == 0 ){
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner la valeur à répartir svp');
      this.resteArepartir = this.valeurOperationRestanteBrute
      return
    }
    console.log('valeur',this.valeurToApply)
    if (this.listClientsFiltered && this.listClientsFiltered.length){
      const valeurTotale = this.selectectedDonation.valeurOperation
      const valeurRepartie = this.valeurToApply*this.listClientsFiltered.length
      console.log('valeur totale opération',valeurTotale)
      console.log('valeur repartie',valeurRepartie)
      if (valeurRepartie <= this.resteArepartir){
        this.listClientsFiltered.map(
            lbf=>lbf.valeurRecut = this.valeurToApply
        )
        this.resteArepartir = this.valeurOperationRestanteBrute - valeurRepartie
        this.totalRepartie = valeurRepartie
      }
      else {
        this.notificationService.showMessageError('Erreur', 'Le montant total à repartir est dépassé');
        this.resteArepartir = this.valeurOperationRestanteBrute
        this.totalRepartie = 0

      }

    }
    console.log(this.listClientsFiltered)

  }
  onValidateOperation(entr?) {
    let items = []
    console.log('entr',entr)
    if (!entr){
      this.listBeneficiairesTable.map(
          lbt=>{
            if (lbt.isChecked)
            // lbt.id = lbt.idOriginal
              items.push(lbt)
          }
      )
    }
    else {
      // entr.id = entr.idOriginal
      items = [entr]
    }
    items.map(
      it=>it.id = it.idOriginal
    )
    Swal.fire({
      title:  'Suppression de bénéficiaires(s)',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.validateClient(items);
      } else {
        console.log('bnjr');
      }
    })
  }

  private validateClient(items: any) {
    const request = {
      user: this.user.id,
      datas:items
    }
    console.log('data sent to validate client', request)
    this.busyGet = this.api.post('operationBeneficiaire/update', request)
        .subscribe((res: any) => {
              console.log('client validate return', res);
              if (res && !res.hasError) {
                this.notificationService.showMessageSucces('Succès', res.status.message);
                this.cancelSave()
                this.getData();
              } else {
                this.notificationService.showMessageError('Erreur', res.status.message);
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }

  

  checkAll() {

    if (this.isAll){
      this.listBeneficiairesTable.map(
          lbt=>{
            lbt.isChecked = true
          }
      )
    }
    else {
      this.listBeneficiairesTable.map(
          lbt=>{
            lbt.isChecked = false
          }
      )
    }
    this.size=0;
    this.listBeneficiairesTable.map(
        lbt=>{
          if (lbt.isChecked){
            this.size ++;
          }
        }
    )

  }

  onCheckItem() {
    this.size=0;
    this.listBeneficiairesTable.map(
        lbt=>{
          if (lbt.isChecked){
            this.size ++;
          }
        }
    )
  }
  deleteRow(bnef: any) {
    console.log('row to delete',bnef)

    this.listClientsFiltered.map(
        (fc,index)=>{
            if (fc.clientId == bnef.clientId){
                console.log("to be deleted",fc)
                //this.listClientsFiltered.splice(index, 1);
                if(this.selectectedDonation.typeDonCode === 'ESPECE'){
                  if(fc.valeurRecut && fc.valeurRecut>0){
                    let valeur = parseInt(fc.valeurRecut)
                    this.resteArepartir += valeur
                    this.totalRepartie -= valeur
                    fc.valeurRecut = null

                  }
                
                }
                if(this.selectectedDonation.typeDonCode === 'NATURE'){
                  fc.quantiteRecut = null
                  }
                
            }
        }
    )

}
resetFilterredClients() {
    this.listClientsFiltered.map(
        lcf=>{
            lcf.valeurRecut = null;
            lcf.quantiteRecut = null
        }
    )
    this.resteArepartir = this.valeurOperationRestanteBrute 
    this.totalRepartie = 0

}
displayClientQuantiteValeur(rep: any) {
  // this.totalRepartie=0
  // this.listClientsFiltered.map(
  //   lcf=>{
  //     if(lcf && lcf.valeurRecut>0)
  //     this.totalRepartie += parseInt(lcf.valeurRecut)
  //     if(this.totalRepartie > this.resteArepartir){
  //       this.notificationService.showMessageError('Erreur', 'Le montant total à repartir est dépassé');
  //       this.totalRepartie -= parseInt(lcf.valeurRecut)
  //       lcf.valeurRecut =null
  //     }
  //   }
  // )
}
ngOnInit() {
    this.dropdownBeneficiariesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'libelle',
      selectAllText: 'Tout cocher',
      unSelectAllText: 'Tout décocher',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      searchPlaceholderText: 'Sélectionner',
      placeholder: 'Sélectionner',
    };

    this.userService.currentData.subscribe(currentData => {
      this.currentTenant = currentData;
      setTimeout(()=>{
        this.getData();
        this.getOperations();
        this.getClients();
      },2000)

    });
    window.scrollTo(0, 0);
  }
}
