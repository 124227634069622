import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Api} from '../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {NotificationService} from '../../shared/utils/notification.service';
import {UtilitiesService} from '../../shared/utils/utilities.service';
import {ModelService} from '../../shared/utils/model.service';
import {UserService} from "../../shared/user/user.service";
import * as _ from "lodash";
declare const Swal: any;

@Component({
  selector: 'app-main-update-profil-user-connected',
  templateUrl: './main-update-profil-user-connected.component.html',
  styleUrls: ['./main-update-profil-user-connected.component.css']
})
export class MainUpdateProfilUserConnectedComponent implements OnInit {

  
  
  results : Array<any> = [];
  ListeRoles : Array<any> = [];
  busyGet : Subscription;
  busySave : Subscription;
  itemToSave : any;
  totalItems = 0;
  currentPage = 1;
  currentWebApi = 'societe';
  user : any;
  itemsPerPage : any;
  currentItemChecked : any;
  statutCotisation : any;
  itemToSearch : any;
  modalRef: BsModalRef;
  currentItemImage : any;
  imageDisplay : any;


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

 
  constructor(private api:Api,private modalService: BsModalService,private notificationService : NotificationService,private utilities : UtilitiesService,private modelService : ModelService,private userService: UserService) {
        this.itemToSave = {};
        this.user = this.userService.getCurrentUser();
        this.itemsPerPage = 10;
        this.getData();
        this.currentItemChecked = {};
        //this.itemToSave = this.user;
       // this.statutCotisation = enumStatutCotisation;
   }


   hideModal()
   {
       this.modalRef.hide();
   }

    openFormModalModal(template,itemToModified) {

        this.cancelSave();

        if(itemToModified)
        {
            itemToModified.password = null;
            itemToModified.roleSelected =  _.find(this.ListeRoles, { 'id':itemToModified.roleId });

            this.itemToSave = Object.assign({}, itemToModified);
        }
        this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg modal-custom-preview' }));
    }
    


    
  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page ;
    this.getData();
  }

  changeItermPerPage()
  {
    this.currentPage = 1;
    //this.getData();
  }

  getData()
  {
      var request = {
          user : this.user.id,
          data :{
           id : this.user.id
          },
          index:(this.currentPage-1),
          size: this.itemsPerPage ? this.itemsPerPage : null
      }

      this.busyGet = this.api.post('utilisateur/getByCriteria', request)
          .subscribe(
              res => {
                  console.log(res); 
                  if(res && res['items'])
                  {
                      this.itemToSave = res['items'][0];
                      this.imageDisplay = this.itemToSave.documentPath ? this.itemToSave.documentPath : null;
                  } else
                  {
                    this.itemToSave = {};
                  }
              },
              err => {
                  console.log("Error occured",err);
                  //this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
              // this.not
              }
          );

  }

  getDataRole()
  {
      var request = {
          user : this.user.id,
          data :{
          }
      }

      this.api.post('role/getByCriteria', request)
          .subscribe(
              res => {
                  console.log('les roles',res); 
                  if(res && res['items'])
                  {
                      this.ListeRoles = res['items'];
                      //this.totalItems = res['count'] ? res['count'] : 0;
                  }
              },
              err => {
                  console.log("Error occured",err);
              //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
              // this.not
              }
          );
  }

        
  confirmDeleteItem(obj) {
      //this.lgModal.show();

      Swal.fire({
          title: 'Suppression',
          text: 'Désirez-vous poursuivre cette action ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonColor: "#ff7b0c",
          cancelButtonColor: "rgb(154, 154, 153)",
      }).then((result) => {
          if (result.value) {
              this.deleteItem(obj);
          } else {
              console.log("bnjr");
          }
      })

  }

  checkCurrentItem(item)
  {
    if(item)
    {
        console.log("ellement coché",item);
        this.currentItemChecked = item;
    }
  }

  cancelSave()
  {
      this.itemToSave = {};
      this.getData();
  }
      

  uploadFile(event : any,item,typeUpload)
  {
      let reader = new FileReader();

      let file = event.target.files[0];
      item.fileName = file.name.split('.')[0];
      let Tabextension = file.name.split('.');
      let extension = Tabextension[Tabextension.length-1];

        //verifier si l'extension est accepter
        const listeextensionImages = this.userService.getExtensionsImage();

        if( !_.find(listeextensionImages, { 'extension':extension.toLowerCase() }) )
        {
            this.notificationService.showMessageError("Upload","Veuillez vérifier l'extension du fichier.");
            event.target.value = null;
            return;
        }
        
      reader.onload = (readerEvent) => {

          let data = (readerEvent.target as any).result;
          this.imageDisplay = data;
          console.log(data);
        //  item.photoProfil = data;
          item.fileBase64 = data.split(',')[1];

          this.currentItemImage = {
              fichierBase64 : item.fileBase64,
              nomFichier : item.fileName,
              extension : extension
          }

          console.log("le item",this.currentItemImage);
      };

      reader.readAsDataURL(event.target.files[0]);
  }


    saveItem(item)
    {
        if(!item || !item.nom || !item.prenom || !item.login || !item.email  || !item.contact )
        {
            this.notificationService.showMessageInfo("Information","Veuillez renseigner les champs obligatoires.")
            return;
        }
    
        item.password = null;
       

        item.dataDocument = this.currentItemImage ? this.currentItemImage : null;
        var request = {
            user : this.user.id,
            datas : [
                item
            ]
        }
        
        console.log("create return",request);

        this.busySave = this.api.post('utilisateur/'+(item.id ? 'update':'create'), request)
            .subscribe(
                res => {
                console.log("resul",res);
                    if(!res['hasError'] && res['items'])
                    {
                        this.notificationService.showMessageSucces("Modification",'Les modifications effectuées seront prises en compte à la prochaine connexion.');
                        //this.currentPage = 1;
                        //this.hideModal();
                        //this.cancelSave();
                        this.getData();
                    }else
                    {
                    if(res['status'] && res['status']['message'])
                    {
                        this.notificationService.showMessageError("Erreur",res['status']['message']); 
                    }
                    }
                },
                err => {
                    console.log("Error occured",err);
                    this.notificationService.showMessageError("Erreur",this.utilities.messageInterpretor(err));
                }
        );
    }


    deleteItem(obj)
    {

        var request = {
            user : this.user.id,
            datas : [
                obj
            ]
        }

        this.busySave = this.api.post('typeActualite/delete', request)
            .subscribe(
                res => {
                    console.log(res); 
                    if(!res['hasError'])
                    {
                        this.notificationService.showMessageSucces("Suppression",'Suppression effectuée avec succès');
                    
                        this.currentPage = 1;
                        //this.getData();
                    }
                    else
                    {
                    if(res['status'] && res['status']['message'])
                    {
                        this.notificationService.showMessageError("Erreur",res['status']['message']); 
                    }
                    }
                
                },
                err => {
                    console.log("Error occured",err);
                    this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
                // this.not
                }
        );
    }


    getExactlyNumberRow(page,index)
    {
        let num = index +1;
        if(page>1)
        {
            num = ((page - 1) * 10) + (index+1);
        }
        return num;
    }

    
    ngOnInit() {
        //this.getData();
        this.getDataRole();
        window.scrollTo(0, 0);
    }

}
