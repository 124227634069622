import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";
import {Api} from "../../../shared/utils/api/api";
import {BsModalService} from "ngx-bootstrap/modal";
import {NotificationService} from "../../../shared/utils/notification.service";
import {UtilitiesService} from "../../../shared/utils/utilities.service";
import {UserService} from "../../../shared/user";
import {HttpHeaders} from "@angular/common/http";
import * as _ from 'lodash';
import * as moment from 'moment';
import { CommunicationService } from 'src/app/core/services/communication.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
declare const Swal: any;

@Component({
  selector: 'app-beneficiaires-promo',
  templateUrl: './beneficiaires-promo.component.html',
  styleUrls: ['./beneficiaires-promo.component.css']
})
export class BeneficiairesPromoComponent implements OnInit {
  resultDelaiPaiements: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;

  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  currentTenant: any = {};
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination = 10;
  admin: any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  selectedOptions: any = [];
  options = [
    {
      key: 'noFilter',
      value: 'Tout',
      selected: false

    },
    {
      key: 'hommes',
      value: 'Hommes',
      selected: false

    },
    {
      key: 'femmes',
      value: 'Femmes',
      selected: false

    }
  ];
  orders: any = [
    {
      key: 'superficie',
      value: 'Superficie',
      selected: true
    },
    {
      key: 'rendement',
      value: 'Rendement',
      selected: false
    },
    {
      key: 'solde',
      value: 'Solde',
      selected: false
    },
    {
      key: 'annee',
      value: 'Année souscription',
      selected: false
    }
  ];

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  listTypeDon= [];
  listDonations= [];
  ListeClients = [];
  dropdownBeneficiariesSettings = {}
  listBeneficiaries = [];
  isForSeveral = true;
  listBeneficiairesTable: any[];
  listPromos: any;
  listClientsBrute: any;
  listClientsFiltered: any;
  benefKey:any;
  onFullSearch = new Subject<string>();

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService,
     private utilities: UtilitiesService, private userService: UserService,private communicationService:CommunicationService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.itemToSearch = {};
    this.communicationService.getData().subscribe(
      res=>{

        this.getOperations()
      }
    )
    this.onFullSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.listClientsFiltered = this.listClientsBrute
        console.log("current key: ",this.benefKey);
        this.benefKey = this.benefKey.toUpperCase()
        
        let matching = [];
        if(this.benefKey && this.benefKey.length){
          this.listClientsFiltered.map(
            lcf=>{
              let conc = lcf.clientNom + '' + lcf.clientPrenoms
              console.log("conc",conc.toUpperCase());
              
              console.log("index",conc.toUpperCase().indexOf(this.benefKey));
              
                if(conc.toUpperCase().indexOf(this.benefKey)> -1){
                  matching.push(lcf)
                }
            }
          );
          console.log("matching content: ",matching);
     
            this.listClientsFiltered = matching
        }
        else {
          this.listClientsFiltered = this.listClientsBrute
        }
      });
  }

  // MULTI SELECT
  listBeneficiariesFiltered: any[];
  selectedPromo: any = {};
  quantiteToApply: any;


  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template: any, itemToModified?: any) {
    if (!this.selectedPromo.id){
      this.notificationService.showMessageError('Erreur', 'Veuillez selectionner une promo svp');
      return
    }
    this.listClientsFiltered.map(
        lbf=> lbf.quantiteRecut = null
    )
    this.listClientsFiltered = this.listClientsBrute
    this.quantiteToApply = null
    this.cancelSave();
    if (itemToModified) {
      console.log('itemToModified: ', itemToModified)
      this.itemToSave = { ...itemToModified };
      if (itemToModified.datasBeneficiaires && itemToModified.datasBeneficiaires.length){
        itemToModified.datasBeneficiaires.map(
            (db: any) => {
              db.libelle = db.clientNom + '' + db.clientPrenoms;
              db.id = db.clientId;
              return db
            }
        )
      }

    }
    this.modalRef = this.modalService.show(template, { class: 'gray modal-md' });
  }


  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }


  getData() {
    const request = {
      user: this.user.id,
      data: {
        // tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        // operBenefTenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        typeOperationCode: "PROMO",
        operationId : this.selectedPromo.operationId
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }
    this.listBeneficiairesTable = [];
    this.busyGet = this.api.post('operationBeneficiaire/getByCriteria', request)
        .subscribe((res: any) => {
              console.log('list beneficiaires promo not modif', res);
              if (res && res['items']) {
                this.listBeneficiairesTable = res['items'] || [];
                this.totalItems = res['count'] ? res['count'] : 0;
                this.listBeneficiairesTable.map((lb: any) => {
                      lb.libelle = lb.clientNom + ' ' + lb.clientPrenoms;
                      lb.idOriginal = lb.id;
                      lb.id = lb.clientId;
                      return lb
                    }
                )
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }

  confirmSaveItem(obj: any) {
    this.itemToSave.datsListeBeneficiaires = this.listClientsFiltered

    if (!obj) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner les champs obligatoires.');
      return;
    }
    if (!this.itemToSave || !this.itemToSave.datsListeBeneficiaires || !this.itemToSave.datsListeBeneficiaires.length) {
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner le(s) bénéficiaire(s) svp!.');
      return;
    } else {
      if (this.itemToSave.datsListeBeneficiaires && this.itemToSave.datsListeBeneficiaires.length) {
        let isValeur = true;
        console.log('beneficiaires to control',this.itemToSave.datsListeBeneficiaires)
        this.itemToSave.datsListeBeneficiaires = this.itemToSave.datsListeBeneficiaires.filter(
          lb=> lb.quantiteRecut
      )
      if (!this.itemToSave.datsListeBeneficiaires.length){
          this.notificationService.showMessageError('Erreur', 'Veuillez renseigner des quantités svp!');
          return;
      }
      }
      let benefReduce = [];
      this.itemToSave.datsListeBeneficiaires.map(
          db => {
            let data ={'clientId':db.id,'quantiteRecut':db.quantiteRecut}
            benefReduce.push(data)
          }
      )
      this.itemToSave.datsListeBeneficiaires = benefReduce
    }

    if (this.isForSeveral){
      this.itemToSave.quantiteRepartition = this.itemToSave.datsListeBeneficiaires[0].quantiteRecut
      if (!this.itemToSave || !this.itemToSave.quantiteRepartition) {
        this.notificationService.showMessageError('Erreur', 'Veuillez renseigner la quantité svp!.');
        return;
      }

    }
    else {
      this.itemToSave.quantiteRepartition = null

    }
    console.log('item to save: ', this.itemToSave)

    Swal.fire({
      title: !obj.id ? 'Enregistrement d\'une répartition de promo' : 'Modification d\'une répartition de promo',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.saveItem(obj);
      } else {
        console.log('bnjr');
      }
    })
  }



  confirmDeleteItem(obj: any) {
    Swal.fire({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.deleteItem(obj);
      } else {
        console.log('bnjr');
      }
    })

  }


  cancelSave() {
    this.options.map(
        op => {
          op.selected = false
        }
    )

    this.orders.map(
        op => {
          op.selected = false
        }
    )

    this.itemToSave = {};
  }


  saveItem(item: any) {
    if (!this.currentTenant || !this.currentTenant.code) {return};
    const options = {
      headers: new HttpHeaders({
        'tenantID': (this.currentTenant && this.currentTenant.code) ? this.currentTenant.code : null, //'1907122100004',
        lang: 'fr'
      })
    }
    item.isForSeveral = this.isForSeveral;
    const request = {
      user: this.user.id,
      datas: [{ ...item, tenancyId: this.currentTenant.id, operationId : this.selectedPromo.operationId }]
    }
    console.log('data sent to server', JSON.stringify(request))
    this.busySave = this.api.post('repartitionOperation/' + (item.id ? 'update' : 'create'), request)
        .subscribe((res: any) => {
              console.log('resul', res);
              if (!res['hasError'] && res['items']) {
                this.notificationService.showMessageSucces('Succès', 'Opération effectuée avec succès.');
                this.currentPage = 1;
                this.hideModal();
                this.cancelSave();
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Erreur', this.utilities.messageInterpretor(err));
            });
  }

  deleteItem(obj: any) {
    const request = {
      user: this.user.id,
      datas: [
        {
            id:obj.idOriginal
        }
    ]
    }
    console.log('data to delete',request)
    this.busyGet = this.api.post('operationBeneficiaire/delete', request)
        .subscribe(
            res => {
              console.log(res);
              if (!res['hasError']) {
                this.notificationService.showMessageSucces('Succès', 'Suppression effectuée avec succès');
                this.currentPage = 1;
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError('Erreur', res['status']['message']);
                }
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            }
        );
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }
  getClients() {
    if (!this.currentTenant || !this.currentTenant.code) { return }
    const request = {
      user: this.user.id,
      data: {
        tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        clientNom: this.itemToSearch.nom || null,
        clientPrenoms: this.itemToSearch.prenoms || null,
      },
    }
    console.log('list client in client don request', request);

    this.busyGet = this.api.post('clientTenancy/getByCriteria', request)
        .subscribe((res: any) => {
          console.log('list of client tenancy before filter', res.items);
          if (res && res['items']) {
            this.listClientsBrute = res['items'];
            this.listClientsBrute.map(
                lb => {
                  lb.libelle = lb.clientNom + ' ' + lb.clientPrenoms;
                  lb.id = lb.clientId;
                  lb.carteId = lb.dataClient.carteId;
                }
            )
            this.listClientsBrute = this.listClientsBrute.filter(
              lcb=> lcb.carteId
            )
            this.listClientsFiltered = this.listClientsBrute
          } else {
            this.ListeClients = [];
          }
          console.log('list of client tenancy after filter', this.listClientsFiltered);
        });
  }
  displayClientQuantiteValeur(rep: any) {
    console.log('certif: ', rep);
    console.log('all certficates: ', this.itemToSave.datsListeBeneficiaires)
  }
  getOperations() {
    let data: any;
    data = {
      tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
      typeOperationCode : "PROMO"
    };
    if (this.currentTenant && this.currentTenant.marchandId) {
      data = {
        marchandId: this.currentTenant.marchandId,
        typeOperationCode : "PROMO",
        tenancyId: this.currentTenant.id
      }
    }
    const request = {
      user: this.user.id,
      data
    }
    console.log('data sent to get donations list', request)
    this.busyGet = this.api.post('operations/getByCriteria', request)
        .subscribe((res: any) => {
              console.log('listUnfiltered operations', res);
              if (res && res['items']) {
                if (res['items'] && res['items'].length){
                  this.listPromos = res['items'];
                  console.log('listPromo benef for current tenancy', this.listPromos);
                }
              } else {
                this.listDonations = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }

  onSelectOption(data,type,event){
    this.benefKey = '';
    console.log('event',event)
    this.itemToSave.datsListeBeneficiaires = []
    console.log(this.options);
    console.log(this.listClientsBrute);
    let selectedOptions = this.options.filter(
        op=>op.selected
    )
    console.log('data', data);
    console.log('data key', data.key);
    if (type === 'criteria'){
      if (data.key === 'noFilter' && event.target.checked){
        this.listClientsFiltered = this.listClientsBrute
        this.options.map(
            op => {
              if (op.key !=='noFilter')
                op.selected = true
            }
        )
        if (this.listClientsFiltered && this.listClientsFiltered.length){
          this.listClientsFiltered = this.listClientsFiltered.filter(
              lb => (lb.dataClient.sexe ==='F' || lb.dataClient.sexe ==='M') && lb.dataClient.isCertificate
          )
        }

        return
      }
      else {
        if (data.key === 'noFilter' && !event.target.checked){
          this.listClientsFiltered = this.listClientsBrute
          this.options.map(
              op => {
                if (op.key !=='noFilter')
                  op.selected = false
              }
          )

          return
        }

        this.options.map(
            op => {
              if (op.key ==='noFilter')
                op.selected = false
            }
        )

        if (
            !_.find(selectedOptions, { key: 'hommes'}) &&
            !_.find(selectedOptions, { key: 'femmes'}) &&
            !_.find(selectedOptions, { key: 'certification'})) {
          this.listClientsFiltered = this.listClientsBrute
        }
        if (
            _.find(selectedOptions, { key: 'hommes'}) &&
            _.find(selectedOptions, { key: 'femmes'}) &&
            _.find(selectedOptions, { key: 'certification'})) {
          this.listClientsFiltered = this.listClientsBrute
          if (this.listClientsFiltered && this.listClientsFiltered.length){
            this.listClientsFiltered = this.listClientsFiltered.filter(
                lb => (lb.dataClient.sexe ==='F' || lb.dataClient.sexe ==='M') && lb.dataClient.isCertificate
            )
          }


        }
        else {
          if (
              _.find(selectedOptions, { key: 'hommes'}) &&
              _.find(selectedOptions, { key: 'certification'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.sexe ==='M' && lb.dataClient.isCertificate
              )
            }

            return;
          }
          if (
              _.find(selectedOptions, { key: 'femmes'}) &&
              _.find(selectedOptions, { key: 'certification'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.sexe ==='F' && lb.dataClient.isCertificate
              )
            }

            return;
          }
          if (
              _.find(selectedOptions, { key: 'hommes'}) &&
              _.find(selectedOptions, { key: 'femmes'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.sexe ==='F' || lb.dataClient.sexe ==='M'
              )
            }

            return;
          }
          if (
              _.find(selectedOptions, { key: 'hommes'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.sexe ==='M'
              )
            }
            return;
          }
          if (
              _.find(selectedOptions, { key: 'femmes'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.sexe ==='F'
              )
            }
            return;
          }
          if (
              _.find(selectedOptions, { key: 'certification'})) {
            this.listClientsFiltered = this.listClientsBrute
            if (this.listClientsFiltered && this.listClientsFiltered.length){
              this.listClientsFiltered = this.listClientsFiltered.filter(
                  lb => lb.dataClient.isCertificate
              )
            }
            return;
          }

        }


      }
    }
    else {
      if (data.key === 'superficie') {
        if (this.listClientsFiltered && this.listClientsFiltered.length){
          this.listClientsFiltered = _.sortBy(this.listClientsFiltered, [function(lb) { return -lb.dataClient.sommeSurfaceCultive; }]);

        }
      }
      if (data.key === 'rendement') {
        if (this.listClientsFiltered && this.listClientsFiltered.length){
          this.listClientsFiltered = _.sortBy(this.listClientsFiltered, [function(lb) { return -lb.dataClient.rendement; }]);

        }
      }
      if (data.key === 'solde') {
        if (this.listClientsFiltered && this.listClientsFiltered.length){
          this.listClientsFiltered = _.sortBy(this.listClientsFiltered, [function(lb) { return -lb.dataClient.solde; }]);

        }
      }if (data.key === 'annee') {
        if (this.listClientsFiltered && this.listClientsFiltered.length){
          this.listClientsFiltered = _.sortBy(this.listClientsFiltered, [function(lb) { return moment(lb.dataClient.annSouscriptionCarte, 'DD/MM/YYYY'); }]);
        }
      }
    }
    console.log('beneficiaries filtered', this.listClientsFiltered);



  }


  selectPromo() {
    this.getData();
  }

  ngOnInit() {
    this.dropdownBeneficiariesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'libelle',
      selectAllText: 'Tout cocher',
      unSelectAllText: 'Tout décocher',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      searchPlaceholderText: 'Sélectionner',
      placeholder: 'Sélectionner',
    };

    this.userService.currentData.subscribe(currentData => {
      this.currentTenant = currentData;
      // this.getData();
      this.getOperations();
      this.getClients();
      setTimeout(()=>{
        // this.getData();
        this.getOperations();
        this.getClients();
      },2000)
    });
    window.scrollTo(0, 0);
  }


  applyQuantite() {
    if (!this.quantiteToApply){
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner la quantité à répartir svp');
      if (this.listClientsFiltered && this.listClientsFiltered.length){
        this.listClientsFiltered.map(
            lbf=>lbf.quantiteRecut = ''
        )
      }
      return
    }
    console.log('quantite',this.quantiteToApply)
    if (this.listClientsFiltered && this.listClientsFiltered.length){
      this.listClientsFiltered.map(
          lbf=>lbf.quantiteRecut = this.quantiteToApply
      )
    }
    console.log(this.listClientsFiltered)
  }
  deleteRow(bnef: any) {
    console.log('row to delete',bnef)

    this.listClientsFiltered.map(
        (fc,index)=>{
            if (fc.clientId == bnef.clientId){
                console.log("to be deleted",fc)
                fc.quantiteRecut = null

                
            }
        }
    )

}
resetFilterredClients() {
    this.listClientsFiltered.map(
        lcf=>{
            lcf.valeurRecut = null;
            lcf.quantiteRecut = null
        }
    )

}
  }
