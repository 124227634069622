import { Component, OnInit, AfterViewInit } from "@angular/core";

import * as moment from "moment";
import { Subscription } from "rxjs";

import * as _ from "lodash";
import { Api } from "src/app/shared/utils/api/api";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { NotificationService } from "src/app/shared/utils/notification.service";
import { UtilitiesService } from "src/app/shared/utils/utilities.service";
import { ModelService } from "src/app/shared/utils/model.service";
import { UserService } from "src/app/shared/user/user.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { defineLocale } from "ngx-bootstrap/chronos";
import { frLocale } from "ngx-bootstrap/locale";
import { HttpHeaders } from "@angular/common/http";
import Highcharts from 'highcharts';
// const Highcharts = require("highcharts");
defineLocale("fr", frLocale);

@Component({
  selector: "app-main-dashboard",
  templateUrl: "./main-dashboard.component.html",
  styleUrls: ["./main-dashboard.component.css"],
})
export class MainDashboardComponent implements OnInit, AfterViewInit {
  [x: string]: any;
  tonnageRangeStart = "";
  tonnageRangeEnd = "";
  currentTenant: any;
  user: any;
  listSection: any;
  dateDebut = moment().startOf("year").toDate();
  dateFin = moment().endOf("year").toDate();
  busyGet: Subscription;
  tonnagesContent: any;
  locale = "fr";
  totalLivraisonAttenteReglement: any;
  totalAttente = 0;
  tonnageYesterdayCacao: any;
  tonnageYesterdayCafe: any;
  tonnageYesterdayHevea: any;
  tonnageYesterdayPalmier: any;
  tonnageLastWeekCacao: any;
  tonnageLastWeekCafe: any;
  tonnageLastWeekHevea: any;
  tonnageLastWeekPalmier: any;
  tonnageLastMonthCacao: any;
  tonnageLastMonthCafe: any;
  tonnageLastMonthHevea: any;
  tonnageLastMonthPalmier: any;
  tonnageMoyenParClient: any = [];
  tonnageMoyenParDelegue: any;
  tonnageMoyenParHectare: any;
  lastLigneRechargement: any = [];
  tonnageAnuelCooperative: any;
  listTonnageSectionLastWeek = [];
  listTonnageSectionLastMonth = [];
  isMarchandView: boolean;
  listSectionTonnageRange = [];
  listTonnageSectionYesterday = [];
  listClientTop10 = [];
  listDelegueTop10 = [];
  chartId: any;
  MontantAttente: any;
  currentPage: number = 1;
  maxSize = 5;
  // add point to chart serie

  //super admin data
  nombreTotalCooperativeInscripte = 0;
  nombreMembreFemme = 0;
  nombreMembreHomme = 0;
  nombreTotalMembre = 0;
  superfFemme = 0;
  superfHomme = 0;
  superfTotal = 0;
  listDataTopTenMbrCA: any = [];
  add() {
    this.chart.addPoint(Math.floor(Math.random() * 10));
  }

  constructor(
    private api: Api,
    private bsConfig: BsDatepickerConfig,
    public router: Router,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private utilities: UtilitiesService,
    private modelService: ModelService,
    private userService: UserService
  ) {
    this.bsConfig.dateInputFormat = "L"; // Or format like you want
    this.bsConfig.dateInputFormat = "DD/MM/YYYY";
    // this.localeService.use(this.locale);
  }

  cancelSave() {}

  getSections() {
    const request = {
      user: this.user.id,
      data: {
        tenancyIdx: this.currentTenant.id,
      },
    };
    this.api.post("section/getByCriteria", request).subscribe(
      (res) => {
        console.log("getSections", res);
        if (!res["hasError"]) {
          if (res && res["items"]) {
            this.listSection = res["items"];
          }
        }
      },
      (err) => {}
    );
  }

  getTonnageAnnuelCooperative() {
    if (moment(this.dateDebut).isAfter(moment(this.dateFin))) {
      this.notificationService.showMessageError(
        "Erreur",
        "La date de fin ne peut etre antérieure à la date de debut"
      );
      return;
    }
    const start = moment(this.dateDebut).format("DD/MM/YYYY");
    const end = moment(this.dateFin).format("DD/MM/YYYY");
    const request = {
      user: this.user.id,
      data: {
        tenantId: this.currentTenant.id,
        // createdAt: moment().format('DD/MM/YYYY'),
        createdAtParam: {
          operator: "[]",
          // start: start,
          // end: end
        },
      },
    };
    this.busyGet = this.api
      .getTonnageAnnuelCooperative(
        "mouvementClient/getTonnageAnnuelleByCooperative",
        request
      )
      .subscribe(
        (res: any) => {
          console.log("getTonnageAnnuelCooperative", res);
          console.log("getTonnageAnnuelCooperative", JSON.stringify(res));
          if (!res["hasError"]) {
            const toDraw = [];
            const categories = [];
            if (res && res["items"] && res["items"].length) {
              this.tonnageAnuelCooperative = (
                res.items[0] || {}
              ).totalTonnageAnnuel;

              res["items"].map((rs) => {
                categories.push(rs.periode);
              });

              let cultureData = [];
              let cultureLibelle = "";
              let filterCultures = res["items"][0].infoCulture.filter(
                (fcu) =>
                  fcu.cultureLibelle == "CACAO" ||
                  fcu.cultureLibelle == "CAFE" ||
                  fcu.cultureLibelle == "PALMIER A HUILE" ||
                  fcu.cultureLibelle == "HEVEA" ||
                  fcu.cultureLibelle == "CACAO AUTRE" ||
                  fcu.cultureLibelle == "CACAO BIO"
              );
              res["items"][0].infoCulture.map((rsc) => {
                cultureLibelle = rsc.cultureLibelle;
                cultureData = [];
                res["items"].map((rsi, index) => {
                  cultureData.push(
                    rsi.infoCulture.filter(
                      (inf) => inf.cultureLibelle == cultureLibelle
                    )[0].totalTonnageAnnuel
                  );
                });
                toDraw.push({
                  name: cultureLibelle,
                  data: cultureData,
                });
              });
              console.log("categories: ", categories);
              console.log("to draw: ", toDraw);
            }
            this.drawBar("bar-chart-year-tonnage", categories, toDraw);
          }
        },
        (err) => {}
      );
  }

  getTonnageMoyen() {
    const request = {
      user: this.user.id,
      data: {
        tenantId: this.currentTenant.id,
      },
    };
    this.api
      .getTonnageMoyen("mouvementClient/getTonnageMoyenByCooperative", request)
      .subscribe((res: any) => {
        console.log("return getTonnageMoyen", res);
        if (!res["hasError"]) {
          if (res && res["items"]) {
            this.tonnageMoyenParClient = res.items;
            // this.tonnageMoyenParClient = res['items'][0].moyennrTonnageByClient;
            // this.tonnageMoyenParDelegue = res['items'][0].moyennrTonnageByDelegue;
            // this.tonnageMoyenParHectare = res['items'][0].moyennrTonnageByHectare;
          }
        }
      });
  }

  getLastLigneRechargement() {
    const request = {
      user: this.user.id,
      index: 0,
      size: 10,
      data: {
        tenantId: this.currentTenant.id,
      },
    };
    this.api
      .getLastLigneRechargement(
        "mouvementClient/getLastLigneRechargement",
        request
      )
      .subscribe((res: any) => {
        console.log("return getLastLigneRechargement", res);
        if (!res["hasError"]) {
          if (res && res.items) {
            this.lastLigneRechargement = res.items || [];
          }
        }
      });
  }

  getTonnageYesterday() {
    const request = {
      user: this.user.id,
      data: {
        createdAt: moment().format("DD/MM/YYYY"),
        tenantId: this.currentTenant.id,
      },
    };
    this.api
      .getTonnageYesterday(
        "mouvementClient/getTonneNumbreByNowDateGroupByCulture",
        request
      )
      .subscribe(
        (res: any) => {
          console.log("getTonnageYesterday return", res);
          // debugger
          if (!res["hasError"]) {
            if (res && res["items"]) {
              console.log("after filter yesterday", res["items"]);
              let toDraw = res.items || [];
              toDraw = toDraw.filter(
                (p: any) => p.sommeTonneByDayByCulture > 0
              );
              let categories = [];
              toDraw.map((td) => {
                categories.push(td.culture);
              });
              toDraw = toDraw.map((it: any) => {
                return { name: it.culture, y: it.sommeTonneByDayByCulture };
              });
              console.log("to draw yesterday", toDraw);
              console.log("categors", categories);

              this.drawPie(
                "pie-tonnage-day",
                toDraw,
                "Tonnage (dernières 24h)",
                categories
              );
              // this.drawBar('pie-tonnage-day', categories, toDraw)
            }
          }
        },
        (err) => {}
      );
  }

  getTonnageLastWeek() {
    const request = {
      user: this.user.id,
      data: {
        createdAt: moment().format("DD/MM/YYYY"),
        tenantId: this.currentTenant.id,
      },
    };
    this.api
      .getTonnageLastWeek(
        "mouvementClient/getTonneNumbreByLastWeekGoupByCulture",
        request
      )
      .subscribe(
        (res: any) => {
          if (!res["hasError"]) {
            // if (res && res['items']) {
            //     console.log('after filter last week', res['items']);
            //     let toDraw = res.items || [];
            //     toDraw = toDraw.filter((p: any) => p.sommeTonneByWeekByCulture > 0);
            //     toDraw = toDraw.map((it: any) => ({ 'name': it.culture, 'y': it.sommeTonneByWeekByCulture }));
            //     this.drawPie('pie-tonnage-week', toDraw, 'Tonnage (dernière semaine)')
            // }
            console.log("getTonnageLastWeek", res);
            console.log("getTonnageLastWeek", JSON.stringify(res));
            if (res && res["items"] && res["items"].length) {
              this.tonnageAnuelCooperative = (
                res.items[0] || {}
              ).totalTonnageAnnuel;
              const toDraw = [];
              const categories = [];
              res["items"][0].datasDays.map((rs) => {
                categories.push(rs.key);
              });

              let cultureData = [];
              let cultureLibelle = "";

              res["items"].map((rsc) => {
                cultureLibelle = rsc.culture;
                cultureData = [];
                rsc.datasDays.map((rsi, index) => {
                  cultureData.push(rsi.value);
                });
                toDraw.push({
                  name: cultureLibelle,
                  data: cultureData,
                });
              });
              console.log("categories: ", categories);
              console.log("to draw: ", toDraw);
              this.drawBar("pie-tonnage-week", categories, toDraw);
            }
          }
        },
        (err) => {}
      );
  }

  getTonnageLastMonth() {
    const request = {
      user: this.user.id,
      data: {
        createdAt: moment().format("DD/MM/YYYY"),
        tenantId: this.currentTenant.id,
      },
    };
    this.api
      .getTonnageLastMonth(
        "mouvementClient/getTonneNumbreByLastMonthGoupByCulture",
        request
      )
      .subscribe((res: any) => {
        console.log("last month by culture: ", res);
        if (!res["hasError"]) {
          if (res && res["items"]) {
            console.log("after filter last month", res["items"]);
            let toDraw = res.items || [];
            toDraw = toDraw.filter(
              (p: any) => p.sommeTonneByMonthByCulture > 0
            );
            toDraw = toDraw.map((it: any) => {
              return { name: it.culture, y: it.sommeTonneByMonthByCulture };
            });
            // this.drawPie('pie-tonnage-month', toDraw, 'Tonnage (dernier mois)')
          }
        }
      });
  }

  getTonnageDateRange() {
    if (moment(this.dateDebut).isAfter(moment(this.dateFin))) {
      this.notificationService.showMessageError(
        "Erreur",
        "La date de fin ne peut etre antérieure à la date de debut"
      );
      return;
    }
    const start = moment(this.dateDebut).format("DD/MM/YYYY");
    const end = moment(this.dateFin).format("DD/MM/YYYY");
    const request = {
      user: this.user.id,
      isSimpleLoading: false,
      data: {
        createdAt: moment().format("DD/MM/YYYY"),
        tenantId:
          this.currentTenant && this.currentTenant.id
            ? this.currentTenant.id
            : null,
        createdAtParam: {
          operator: "[]",
          start: start,
          end: end,
        },
      },
    };
    this.busyGet = this.api
      .getTonnageDateRange(
        "mouvementClient/getTonneNumbreByDateGroupByCulture",
        request
      )
      .subscribe(
        (res: any) => {
          if (!res["hasError"]) {
            console.log("date range", res);
            this.tonnageRangeStart = start;
            this.tonnageRangeEnd = end;
            if (res && res["items"] && res["items"].length) {
              this.tonnageAnuelCooperative = (
                res.items[0] || {}
              ).totalTonnageAnnuel;
              const toDraw = [];
              const categories = [];
              res["items"][0].datasDays.map((rs) => {
                categories.push(rs.key);
              });

              let cultureData = [];
              let cultureLibelle = "";

              res["items"].map((rsc) => {
                cultureLibelle = rsc.culture;
                cultureData = [];
                rsc.datasDays.map((rsi, index) => {
                  cultureData.push(rsi.value);
                });
                toDraw.push({
                  name: cultureLibelle,
                  data: cultureData,
                });
              });
              console.log("categories: ", categories);
              console.log("to draw: ", toDraw);
              this.drawBar("pie-tonnage-date", categories, toDraw);
            }
          }
        },
        (err) => {}
      );
  }

  getTonnageSectionYesterday() {
    const request = {
      user: this.user.id,
      data: {
        createdAt: moment().format("DD/MM/YYYY"),
        tenantId: this.currentTenant.id,
      },
    };
    this.api
      .getTonnageSectionYesterday(
        "mouvementClient/getTonneNumbreByNowDateGoupBySection",
        request
      )
      .subscribe(
        (res) => {
          console.log("getTonnageSectionYesterday return", res);

          if (!res["hasError"]) {
            if (res && res["items"]) {
              this.listTonnageSectionYesterday = res["items"];
            }
          }
        },
        (err) => {}
      );
  }

  getTonnageSectionLastWeek() {
    const request = {
      user: this.user.id,
      data: {
        createdAt: moment().format("DD/MM/YYYY"),
        tenantId: this.currentTenant.id,
      },
    };
    this.api
      .getTonnageSectionLastWeek(
        "mouvementClient/getTonneNumbreByLastWeekGoupBySection",
        request
      )
      .subscribe(
        (res) => {
          console.log("getTonnageSectionLastWeek return", res);

          if (!res["hasError"]) {
            if (res && res["items"]) {
              this.listTonnageSectionLastWeek = res["items"];
            }
          }
        },
        (err) => {}
      );
  }

  getTonnageSectionLastMonth() {
    const request = {
      user: this.user.id,
      data: {
        createdAt: moment().format("DD/MM/YYYY"),
        tenantId: this.currentTenant.id,
      },
    };
    this.api
      .getTonnageSectionLastMonth(
        "mouvementClient/getTonneNumbreByLastMonthGoupBySection",
        request
      )
      .subscribe((res) => {
        console.log("getTonnageSectionLastMonth return", res);
        if (!res["hasError"]) {
          if (res && res["items"]) {
            this.listTonnageSectionLastMonth = res["items"];
          }
        }
      });
  }

  getTonnageSectionDateRange() {
    if (moment(this.dateDebut).isAfter(moment(this.dateFin))) {
      this.notificationService.showMessageError(
        "Erreur",
        "La date de fin ne peut etre antérieure à la date de debut"
      );
      return;
    }
    const start = moment(this.dateDebut).format("DD/MM/YYYY");
    const end = moment(this.dateFin).format("DD/MM/YYYY");
    const request = {
      user: this.user.id,
      isSimpleLoading: false,
      data: {
        createdAt: moment().format("DD/MM/YYYY"),
        tenantId:
          this.currentTenant && this.currentTenant.id
            ? this.currentTenant.id
            : null,
        createdAtParam: {
          operator: "[]",
          start: start,
          end: end,
        },
      },
    };
    this.busyGet = this.api
      .getTonnageSectionDateRange(
        "mouvementClient/getTonneNumbreByDateGoupBySection",
        request
      )
      .subscribe((res) => {
        if (!res["hasError"]) {
          console.log("getTonnageSectionDateRange", res);
          if (res && res["items"]) {
            this.listSectionTonnageRange = res["items"];
          }
        }
      });
  }

  getLivraisonAttente() {
    const options = {
      headers: new HttpHeaders({
        tenantID:
          this.currentTenant && this.currentTenant.code
            ? this.currentTenant.code
            : null, //'1907122100004',
        lang: "fr",
      }),
    };
    const request = {
      user: this.user.id,
      isSimpleLoading: false,
      data: {
        id: "",
      },
    };
    console.log("data sent to fetch livraison attente", request);
    console.log(
      "data sent to fetch livraison attente",
      JSON.stringify(request)
    );
    this.busyGet = this.api
      .getLivraisonAttente(
        "mouvementClient/getTotalLivraionEnAttente",
        request,
        options
      )
      .subscribe(
        (res) => {
          console.log("getLivraisonAttente", res);
          if (!res["hasError"]) {
            if (res && res["items"]) {
              this.totalAttente = res["items"][0].totaLivraisonEnAttente || 0;
              this.MontantAttente = res["items"][0].sommeLivraionEnAttente || 0;
              console.log(
                "totalLivraisonAttenteReglement: ",
                this.totalAttente
              );
            }
          }
        },
        (err) => {}
      );
  }
  getClientsTop10() {
    const start = moment(this.dateDebut).format("DD/MM/YYYY");
    const end = moment(this.dateFin).format("DD/MM/YYYY");
    const request = {
      user: this.user.id,
      isSimpleLoading: false,
      data: {
        createdAt: moment().format("DD/MM/YYYY"),
        tenantCode:
          this.currentTenant && this.currentTenant.code
            ? this.currentTenant.code
            : null,
        createdAtParam: {
          operator: "[]",
          start: start,
          end: end,
        },
      },
    };
    this.api
      .getClientsTop10("mouvementClient/getTop10client", request)
      .subscribe(
        (res) => {
          console.log("getClientsTop10", res);
          if (!res["hasError"]) {
            if (res && res["items"]) {
              this.listClientTop10 = res["items"] || [];
            } else {
            }
          }
        },
        (err) => {}
      );
  }

  getDelegueTop10() {
    const start = moment(this.dateDebut).format("DD/MM/YYYY");
    const end = moment(this.dateFin).format("DD/MM/YYYY");
    const request = {
      user: this.user.id,
      isSimpleLoading: false,
      data: {
        createdAt: moment().format("DD/MM/YYYY"),
        tenantCode:
          this.currentTenant && this.currentTenant.code
            ? this.currentTenant.code
            : null,
        createdAtParam: {
          operator: "[]",
          start: start,
          end: end,
        },
      },
    };
    this.api
      .getClientsTop10("mouvementClient/getTop10Deleguet", request)
      .subscribe(
        (res) => {
          console.log("getDelegueTop10", res);
          if (!res["hasError"]) {
            if (res && res["items"]) {
              this.listDelegueTop10 = res["items"] || [];
            } else {
            }
          }
        },
        (err) => {}
      );
  }

  formateDate(data) {
    return moment(data).format("DD/MM/YYYY");
  }

  searchData() {
    setTimeout(() => this.getTonnageDateRange(), 1 * 1000);
    setTimeout(() => this.getTonnageSectionDateRange(), 2 * 1000);
    setTimeout(() => this.getTonnageAnnuelCooperative(), 3 * 1000);
    setTimeout(() => this.getClientsTop10(), 4 * 1000);
    setTimeout(() => this.getDelegueTop10(), 5 * 1000);
  }

  refresh() {
    setTimeout(() => this.getTonnageYesterday(), 2 * 1000);
    setTimeout(() => this.getTonnageLastWeek(), 3 * 1000);
    setTimeout(() => this.getTonnageDateRange(), 1 * 1000);
    setTimeout(() => this.getTonnageLastMonth(), 4 * 1000);
    setTimeout(() => this.getTonnageMoyen(), 5 * 1000);
    setTimeout(() => this.getTonnageAnnuelCooperative(), 6 * 1000);
    setTimeout(() => this.getTonnageSectionYesterday(), 7 * 1000);
    setTimeout(() => this.getTonnageSectionLastWeek(), 8 * 1000);
    setTimeout(() => this.getTonnageSectionLastMonth(), 9 * 1000);
    setTimeout(() => this.getTonnageSectionDateRange(), 10 * 1000);
    setTimeout(() => this.getLivraisonAttente(), 11 * 1000);
    setTimeout(() => this.getLastLigneRechargement(), 12 * 1000);
    setTimeout(() => this.getClientsTop10(), 13 * 1000);
    setTimeout(() => this.getDelegueTop10(), 14 * 1000);
  }

  refreshSuperAdminData() {
    setTimeout(() => this.getNbrTotalHftCooperativesInscrites(), 1 * 1000);
    setTimeout(() => this.getSuperficieHft(), 2 * 1000);
    setTimeout(() => this.getTopTenMbrCA(), 3 * 1000);
    setTimeout(() => this.statByCulture(), 4 * 1000);
  }

  getNbrTotalHftCooperativesInscrites() {
    const request = {
      user: this.user.id,
      datas: [{}],
    };
    this.api
      .getTonnageMoyen("tenancy/nombreMembreHft", request)
      .subscribe((res: any) => {
        console.log("return getNbrTotalCooperativesInscrites", res);
        if (!res.hasError) {
          if (res && res.items) {
            this.nombreTotalCooperativeInscripte =
              res.items[0].nombreTotalCooperativeInscripte;
            this.nombreMembreFemme = res.items[0].nombreMembreFemme;
            this.nombreMembreHomme = res.items[0].nombreMembreHomme;
            this.nombreTotalMembre = res.items[0].nombreTotalMembre;
          }
        }
      });
  }

  getSuperficieHft() {
    const request = {
      user: this.user.id,
      datas: [{}],
    };
    this.api
      .getTonnageMoyen("tenancy/superficieMembreHft", request)
      .subscribe((res: any) => {
        console.log("return getSuperficieHft", res);
        if (!res.hasError) {
          if (res && res.items) {
            this.superfFemme = res.items[0].superfFemme;
            this.superfHomme = res.items[0].superfHomme;
            this.superfTotal = res.items[0].superfTotal;
            // this.nombreTotalMembre = res.items[0].nombreTotalMembre
          }
        }
      });
  }

  getTopTenMbrCA() {
    const request = {
      user: this.user.id,
      datas: [{}],
    };
    this.api
      .getTonnageMoyen("tenancy/topTenTotalMembreCoop", request)
      .subscribe((res: any) => {
        console.log("return getTopTenMbrCA", res);
        if (!res.hasError) {
          if (res && res.items) {
            this.listDataTopTenMbrCA = res.items;
            console.log("listDataTopTenMbrCA", this.listDataTopTenMbrCA);
          }
        }
      });
  }

  statByCulture() {
    const request = {
      user: this.user.id,
      datas: [{}],
    };
    this.api
      .getTonnageMoyen("culture/statByCulture", request)
      .subscribe((res: any) => {
        console.log("return statByCulture", res);
        if (!res.hasError) {
          if (res && res.items) {
            this.listDataStatByCulture = res.items;
            console.log("listDataTopTenMbrCA", this.listDataStatByCulture);
          }
        }
      });
  }

  round(num) {
    if (num) {
      // return Number(num).toFixed(1)
      return num;
    }
    return 0;
  }

  drawPie(id, toDraw, title, categories?) {
    let colors = ["#FF530D", "#E82C0C", "#FF0000", "#E80C7A", "#E80C7A"];
    Highcharts.chart(id, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
        y: 75,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Tonnage (Tonne)",
          align: "high",
        },
        labels: {
          overflow: "justify",
        },
      },
      xAxis: {
        categories: categories,
        title: {
          text: null,
        },
      },
      legend: {
        align: "right",
        width: 200,
      },
      tooltip: {
        // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: "allow",
          },
        },
      },
      // colors:colors,
      series: [
        {
          innerSize: "50%",
          name: "Valeur",
          colorByPoint: true,
          data: toDraw,
        },
      ],
    });
  }

  drawBar(id, categories?, toDraw?) {
    Highcharts.chart(id, {
      chart: {
        type: "column",
        height: 400,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
        y: 75,
      },
      xAxis: {
        categories: categories,
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Tonnage (Tonne)",
          align: "high",
        },
        labels: {
          overflow: "justify",
        },
      },
      tooltip: {
        valueSuffix: "Tonne",
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: "allow",
          },
        },
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
        shadow: true,
      },
      credits: {
        enabled: false,
      },
      series: toDraw,
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userService.currentData.subscribe((currentData: any) => {
        console.log("currentUser", currentData);
        if (currentData) {
          this.cancelSave();
          this.user = this.userService.getCurrentUser();
          this.currentTenant = currentData;
          console.log("current tenant id:", currentData.id);
          if (
            this.user.userTypeCode == "SA" ||
            this.user.userTypeCode == "ADMIN"
          ) {
            this.refreshSuperAdminData();
          }
          if (
            this.user.userTypeCode == "TENANCY_ADMIN" ||
            this.user.userTypeCode == "TENANCY_USER"
          ) {
            this.refresh();
          }
        }
      });
    }, 1 * 1000);
  }
}
