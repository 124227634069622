import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Api } from "../../../shared/utils/api/api";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NotificationService } from "../../../shared/utils/notification.service";
import { UtilitiesService } from "../../../shared/utils/utilities.service";
import { ModelService } from "../../../shared/utils/model.service";
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
declare const Swal: any;;
import { CommunicationService } from "src/app/core/services/communication.service";
import { HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-main-cooperative",
  templateUrl: "./main-cooperative.component.html",
  styleUrls: ["./main-cooperative.component.css"],
})
export class MainCooperativeComponent implements OnInit {
  selectedRow = null;
  ListeRegions: Array<any> = [];
  ListeMoyenPaiements: Array<any> = [];
  ListeDomaines: Array<any> = [];
  ListeCultures: Array<any> = [];
  ListeTailleCoperative: Array<any> = [];
  ListePartenaires: Array<any> = [];
  ListeTenants: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  currentWebApi = "societe";
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any = {};
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  imageDisplay: any;
  itemPerPagePagination = 10;
  admin: any = {};

  selectedItemsPartenaires = [];
  selectedItemsCultures = [];
  selectedItemsRegions = [];
  selectedItemsModePaiements = [];
  selectedItemsLigneBudgetaires = [];
  selectedCertificates = [];
  selectedItemsSections = [];
  selectedSection: any;
  selectedBanques = [];
  listSectionForCreate = [];

  dropdownPartenairesSettings = {};
  dropdownCulturesSettings = {};
  dropdownSectionsSettings = {};
  dropdownRegionsSettings = {};
  dropdownModePaiementSettings = {};
  dropdownLigneBudgetaireSettings = {};
  dropdownCertificateSettings = {};
  dropdownBanqueSettings = {};
  dropdownCultureSettings = {};
  enable = true;

  config = {
    animated: true,
    keyboard: false,
    backdrop: "static",
    ignoreBackdropClick: true,
  };
  isCertificate = false;
  listCertificates = [];
  listSection = [];
  currentTenant: any = {};
  datasCultures = [];
  selectedCulture: any;
  cultureCollection = [];
  listBanques = [];
  ListeRegionsBrute = [];
  listeDelais = [];
  nbrSection: number;
  supTotaPlantCoop: number;
  cultureSuperficie: number;
  selectedSuperficie: any;
  copyListSection: any[];
  currentYear = new Date().getFullYear();
  currentYearBank = new Date().getFullYear();
  isOpen: any;
  currentItemSeleced: any = {};
  selectedFile: any;

  constructor(
    private api: Api,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private utilities: UtilitiesService,
    private modelService: ModelService,
    private userService: UserService,
    private communicationService: CommunicationService
  ) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.itemToSearch = {};
    // this.statutCotisation = enumStatutCotisation;
  }

  //MULTI SELECT

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified?: any) {
    this.cancelSave();

    const autreValueDomaine = this.ListeDomaines.find(
      (element) => element.code === "AUTRES"
    );
    if (autreValueDomaine) {
      this.selectedItemsLigneBudgetaires.push(autreValueDomaine);
    }
    if (itemToModified) {
      console.log("to be modified", itemToModified);
      this.getDataFullTenant(itemToModified.code);
    }
    this.modalRef = this.modalService.show(template, {
      class: "gray modal-lg custom-modal-coop",
    });
    this.getDynamicValue();
  }

  pageChanged(event: any): void {
    console.log("Page changed to: " + event.page);
    console.log("Number items per page: " + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  getData() {
    const request = {
      user: this.user.id,
      data: {
        raisonSociale: this.itemToSearch.searchValue || null,
        code: this.currentTenant.code,
      },
      index: this.currentPage - 1,
      size: this.itemsPerPage ? this.itemsPerPage : null,
    };

    this.busyGet = this.api.post("tenancy/getByCriteria", request).subscribe(
      (res) => {
        console.log("liste coopératives: ", res);
        if (res && res["items"]) {
          this.ListeTenants = res["items"];
          this.currentItemSeleced = this.ListeTenants[0];

          this.totalItems = res["count"] ? res["count"] : 0;
        } else {
          this.ListeTenants = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
        // this.not
      }
    );
  }

  selectedMarchand(marchand) {
    console.log("");

    this.itemToSave = { ...marchand };
  }

  getPartenaires() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.api.post("marchand/getByCriteria", request).subscribe(
      (res) => {
        console.log("recuperation marchand");
        if (!res["hasError"]) {
          if (res && res["items"]) {
            this.ListePartenaires = Object.assign([], res["items"]);
          }
        }
      },
      (err) => {}
    );
  }

  getCertificates() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.api.post("certificat/getByCriteria", request).subscribe(
      (res) => {
        console.log("getCertificates", res);
        if (!res["hasError"]) {
          if (res && res["items"]) {
            this.listCertificates = res["items"];
          }
        }
      },
      (err) => {}
    );
  }

  getSections() {
    const request = {
      user: this.user.id,
      data: {
        tenancyIdx: this.currentTenant.id,
      },
    };
    this.api.post("section/getByCriteria", request).subscribe(
      (res) => {
        console.log("getSections", res);
        if (!res["hasError"]) {
          if (res && res["items"]) {
            this.listSection = res["items"];
            this.listSection.map((ls) => {
              ls.sectionId = ls.id;
            });
            this.copyListSection = this.listSection;
            // this.listSection = []
          }
        }
      },
      (err) => {}
    );
  }

  getDataLigneBudgetaire() {
    const request = {
      user: this.user.id,
      data: {},
    };

    this.api.post("domaine/getByCriteria", request).subscribe(
      (res) => {
        console.log("getDataLigneBudgetaire", res);
        if (res && res["items"]) {
          res["items"].forEach(function (value) {
            value.libelle = value.libelle.toUpperCase();
            value.domaineLibelle = value.libelle;
            value.id = value.id;
          });

          this.ListeDomaines = res["items"];
        }
      },
      (err) => {
        console.log("Error occured", err);
      }
    );
  }

  //Get culture
  getDataCulture() {
    const request = {
      user: this.user.id,
      data: {},
    };

    this.api.post("culture/getByCriteria", request).subscribe(
      (res: any) => {
        console.log("getDataCulture", res);
        if (res && res["items"]) {
          this.ListeCultures = (res["items"] || []).map((value) => {
            value.libelle = value.libelle.toUpperCase();
            value.cultureLibelle = value.libelle;
            return value
          });
          console.log('this.ListeCultures', this.ListeCultures)
        }
      },
      (err) => {
        console.log("Error occured", err);
      }
    );
  }

  getDataRegion() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.api.post("region/getByCriteria", request).subscribe(
      (res) => {
        console.log("getDataRegion", res);
        if (res && res["items"]) {
          res["items"].forEach(function (value) {
            value.libelle = value.libelle.toUpperCase();
            value.regionLibelle = value.libelle;
            value.id = value.id;
          });

          this.ListeRegions = res["items"];
          this.ListeRegionsBrute = res["items"];
        }
      },
      (err) => {
        console.log("Error occured", err);
      }
    );
  }

  getDataFullTenant(code) {
    const request = {
      user: this.user.id,
      data: {
        code: code,
      },
    };

    this.busySave = this.api.post("tenancy/getByCriteria", request).subscribe(
      (res) => {
        console.log("full tenancy info", res);
        if (res && res["items"]) {
          this.itemToSave = { ...res["items"][0] };
          console.log("itemToSave", this.itemToSave);
          console.log("itemToSave", JSON.stringify(this.itemToSave));
          if (
            this.itemToSave.datasMoyenPaiement &&
            this.itemToSave.datasMoyenPaiement.length
          ) {
            this.itemToSave.datasMoyenPaiement.forEach((o) => {
              o.id = o.moyenPaiementId;
              o.libelle = o.moyenPaiementLibelle;
            });
          }
          if (
            this.itemToSave.datasDomaine &&
            this.itemToSave.datasDomaine.length
          ) {
            this.itemToSave.datasDomaine.forEach((o) => {
              o.id = o.domaineId;
              o.libelle = o.domaineLibelle;
            });
          }

          if (
            this.itemToSave.datasRegion &&
            this.itemToSave.datasRegion.length
          ) {
            this.itemToSave.datasRegion.forEach((o) => {
              o.id = o.regionId;
              o.libelle = o.regionLibelle;
            });
          }

          if (
            this.itemToSave.datasTenancy &&
            this.itemToSave.datasTenancy.length
          ) {
            this.itemToSave.datasTenancy.forEach((o) => {
              o.id = o.marchandId;
              o.raisonSociale = o.tenancyRaisonSociale;
            });
          }
          if (
            this.itemToSave.datasBanque &&
            this.itemToSave.datasBanque.length
          ) {
            this.itemToSave.datasBanque.forEach((o) => {
              o.id = o.banqueId;
              o.libelle = o.banqueLibelle;
            });
          }

          if (
            this.itemToSave.datasCertificat &&
            this.itemToSave.datasCertificat.length
          ) {
            this.itemToSave.datasCertificat.forEach((o) => {
              o.id = o.certificatId;
              o.libelle = o.certificatLibelle;
            });
          }

          if (
            this.itemToSave.datasCulture &&
            this.itemToSave.datasCulture.length
          ) {
            this.itemToSave.datasCulture.forEach((o) => {
              o.id = o.cultureId;
              o.libelle = o.cultureLibelle;
              o.datasTenancySection = o.datasTenancySection;
            });

            // this.supTotaPlantCoop = 0;
            // this.itemToSave.datasCulture.map(
            //     dc=>{
            //         this.supTotaPlantCoop += parseInt(dc.cultureSuperficie)
            //     }
            // )
          }
          this.datasCultures = this.itemToSave.datasCulture || [];
          this.selectedCertificates = this.itemToSave.datasCertificat || [];
          this.selectedBanques = this.itemToSave.datasBanque || [];
          this.selectedItemsPartenaires = this.itemToSave.datasTenancy || [];
          this.selectedItemsRegions = this.itemToSave.datasRegion || [];
          this.selectedItemsLigneBudgetaires =
            this.itemToSave.datasDomaine || [];
          this.selectedItemsModePaiements =
            this.itemToSave.datasMoyenPaiement || [];

          console.log("data cultures", this.datasCultures);
          console.log(
            "delai moyen reglement",
            this.itemToSave.delaiMoyenreglement
          );

          if (this.itemToSave.datasUser && this.itemToSave.datasUser[0]) {
            const userAdmin = this.itemToSave.datasUser[0];
            this.admin = {
              //referenceIdentite : ,
              lastName: userAdmin.userLastName,
              firstName: userAdmin.userFirstName,
              email: userAdmin.userEmail,
              telephone: userAdmin.userTelephone,
              login: userAdmin.userLogin,
              id: userAdmin.userId,
            };
          }
        }
      },
      (err) => {
        console.log("Error occured", err);
        //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
        // this.not
      }
    );
  }

  getDataMoyenPaiement() {
    const request = {
      user: this.user.id,
      data: {},
    };

    this.api.post("moyenPaiement/getByCriteria", request).subscribe(
      (res) => {
        console.log("les moyens de paiement", res);
        if (res && res["items"]) {
          this.ListeMoyenPaiements = res["items"];
        }
      },
      (err) => {
        console.log("Error occured", err);
        //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
        // this.not
      }
    );
  }

  getDataTailleCoperative() {
    const request = {
      user: this.user.id,
      data: {},
    };

    this.api.post("tailleCoop/getByCriteria", request).subscribe(
      (res) => {
        console.log("getDataTailleCoperative", res);
        if (res && res["items"]) {
          res["items"].forEach(function (value) {
            value.libelle = value.libelle.toUpperCase();
          });

          this.ListeTailleCoperative = res["items"];
        }
      },
      (err) => {
        console.log("Error occured", err);
        //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
        // this.not
      }
    );
  }

  confirmSaveItem(obj: any) {
    console.log("le item to save", obj);
    console.log(
      "itemToSave.isOtherSoftware: ",
      this.itemToSave.isOtherSoftware
    );
    console.log(
      "itemToSave.isTracabiltySoftware: ",
      this.itemToSave.isTracabiltySoftware
    );
    console.log(
      "itemToSave.isComptaSoftware: ",
      this.itemToSave.isComptaSoftware
    );

    if (!obj) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les champs obligatoires."
      );
      return;
    }
    if (!this.itemToSave.nomPrenomPca) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner nom et prenom du PCA svp!"
      );
      return;
    }
    if (!this.itemToSave.contactPca) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le contact du PCA svp!"
      );
      return;
    }
    if (!this.itemToSave.nomPrenomDir) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner nom et prenom du directeur svp!"
      );
      return;
    }
    if (!this.itemToSave.contactDir) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le contact du directeur svp!"
      );
      return;
    }
    if (!this.itemToSave.nomRepresentantCarte) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner nom du responsable carte agricole svp!"
      );
      return;
    }
    if (!this.itemToSave.contactRepresentantCarte) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le contact du responsable carte agricole svp!"
      );
      return;
    }
    if (!this.itemToSave.code) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner l'identifiant svp!"
      );
      return;
    }
    if (!this.itemToSave.raisonSociale) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la raison sociale svp!"
      );
      return;
    }
    if (!this.itemToSave.localisationSiege) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la localisation du siège social svp!"
      );
      return;
    }
    if (!this.itemToSave.numeroAgrement) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le numero d'agrément svp!"
      );
      return;
    }
    if (!this.itemToSave.localisationMembre) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la localisation des membres principaux svp!"
      );
      return;
    }

    if (!this.itemToSave.annCreationCoop) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner année création coopérative svp!"
      );
      return;
    }

    if (!this.itemToSave.annInscCarte) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner année inscription carte agricole svp!"
      );
      return;
    }

    if (!(this.itemToSave.nbrMembHom >= "0")) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner nombre membres hommes svp!"
      );
      return;
    }
    if (!(this.itemToSave.nbrMembFem >= "0")) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner nombre membres femmes svp!"
      );
      return;
    }
    if (!this.itemToSave.nbrMembOffi) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner nombre membres officiels svp!"
      );
      return;
    }

    if (this.selectedCulture && this.selectedCulture.length > 0) {
      this.itemToSave.datasCulture = this.selectedCulture.map((r: any) => {
        return { cultureId: r.id, cultureSectionId: r.cultureSectionId }
      })
    }

    if (!this.itemToSave.nbrDelegue) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le nombre de délégués svp!"
      );
      return;
    }
    if (
      this.itemToSave.isCertificat == "true" ||
      this.itemToSave.isCertificat == !!"true"
    ) {
      let isAnne = true;
      if (this.selectedCertificates && this.selectedCertificates.length) {
        this.selectedCertificates.map((crt) => {
          if (!crt.annCertificat) {
            isAnne = false;
          }
        });
        if (!isAnne) {
          this.notificationService.showMessageError(
            "Erreur",
            "Veuillez renseigner année de certification svp!"
          );
          return;
        }
      } else {
        this.notificationService.showMessageError(
          "Erreur",
          "Veuillez renseigner le/les certifications svp!"
        );
        return;
      }
    }
    if (
      this.itemToSave.isCertificate == "false" ||
      this.itemToSave.isCertificate == !!"false" ||
      this.itemToSave.isCertificate == "null"
    ) {
      if (this.selectedCertificates && this.selectedCertificates.length) {
        this.itemToSave.isCertificate == false;
        this.selectedCertificates = [];
      }
    }

    if (this.itemToSave.donIntraAgricole == "null") {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Dons d’intrants agricoles/12 mois svp!"
      );
      return;
    }
    if (this.itemToSave.remisDonSociaux == "null") {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Remise de dons sociaux/12 mois svp!"
      );
      return;
    }
    if (!this.itemToSave.nbrAnnExpePca) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Expérience PCA svp!"
      );
      return;
    }
    if (!this.itemToSave.annDirectionPca) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez Année de nomination PCA svp!"
      );
      return;
    }
    if (!this.itemToSave.supPlantPca) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Plantation PCA (ha) svp!"
      );
      return;
    }
    if (this.itemToSave.nbrModifPca === "") {
      console.log("number of modif pca: ", this.itemToSave.nbrModifPca);
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Modifications PCA/5 ans svp!"
      );
      return;
    }

    if (this.itemToSave.nbrAnnExpeDir === "") {
      console.log("number of exp dir: ", this.itemToSave.nbrAnnExpeDir);

      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Expérience Directeur svp!"
      );
      return;
    }
    if (!this.itemToSave.annDirectionDir) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Année de nomination Directeur svp!"
      );
      return;
    }
    if (!this.itemToSave.supPlantDir) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Plantation Directeur (ha) svp!"
      );
      return;
    }

    if (this.itemToSave.nbrModifDir === "") {
      console.log("number of modifs: ", this.itemToSave.nbrModifDir);

      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner Modifications Directeur/5 ans svp!"
      );
      return;
    }
    if (this.selectedBanques && this.selectedBanques.length) {
      let isAnne = true;
      this.selectedBanques.map((bk) => {
        if (!bk.annOuverute) {
          isAnne = false;
        }
      });
      if (!isAnne) {
        this.notificationService.showMessageError(
          "Erreur",
          "Veuillez renseigner la/les années d'ouverture svp!"
        );
        return;
      }
    }
    if (this.itemToSave.creditRegul === "null") {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez indiquer si il y a crédit régulier aux membres svp!"
      );
      return;
    }
    if (!this.itemToSave.delaiPaiementId) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner delai moyen de reglement svp!"
      );
      return;
    }
    if (this.itemToSave.isComptaSoftware === "null") {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez indiquer si il exite un logiciel comptable svp!"
      );
      return;
    }

    if (
      (this.itemToSave.isComptaSoftware == "true" ||
        this.itemToSave.isComptaSoftware == !!"true") &&
      !this.itemToSave.nomLogicielCompta
    ) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le logiciel de comptabilité svp!."
      );
      return;
    }
    if (
      this.itemToSave.isComptaSoftware == "false" ||
      this.itemToSave.isComptaSoftware == !!"false"
    ) {
      if (this.itemToSave.nomLogicielCompta) {
      }
    }
    if (this.itemToSave.isTracabiltySoftware === "null") {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez indiquer si il exite un logiciel de traçabilité svp!"
      );
      return;
    }
    if (
      (this.itemToSave.isTracabiltySoftware == "true" ||
        this.itemToSave.isTracabiltySoftware == !!"true") &&
      !this.itemToSave.nomLogicielTracabilite
    ) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le logiciel de traçabilité svp!."
      );
      return;
    }
    if (
      this.itemToSave.isTracabiltySoftware == "false" ||
      this.itemToSave.isTracabiltySoftware == !!"false"
    ) {
      if (this.itemToSave.nomLogicielTracabilite) {
      }
    }

    if (this.itemToSave.isOtherSoftware === "null") {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez indiquer si il exite d'autres logiciels svp!"
      );
      return;
    }
    if (
      (this.itemToSave.isOtherSoftware == "true" ||
        this.itemToSave.isOtherSoftware == !!"true") &&
      !this.itemToSave.nomAutreLogiciel
    ) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner autre logiciel svp svp!."
      );
      return;
    }
    if (
      this.itemToSave.isOtherSoftware == "false" ||
      this.itemToSave.isOtherSoftware == !!"false"
    ) {
      if (this.itemToSave.nomAutreLogiciel) {
      }
    }
    if (!this.admin.firstName) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le nom de l'administrateur  svp!"
      );
      return;
    }
    if (!this.admin.lastName) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le prenom de l'administrateur svp!"
      );
      return;
    }
    if (!this.admin.telephone) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le telephone de l'administrateur svp!"
      );
      return;
    }

    if (!this.admin.email) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le mail de l'administrateur svp!"
      );
      return;
    }
    if (!this.admin.login) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner le login de l'administrateur svp!"
      );
      return;
    }
    if (this.selectedBanques && this.selectedBanques.length) {
      this.selectedBanques.map((bk) => {
        bk.banqueId = bk.id;
      });
    }

    if (this.selectedCertificates && this.selectedCertificates.length) {
      this.selectedCertificates.map((cert) => {
        cert.certificatId = cert.id;
      });
    }

    this.itemToSave.dataAdmin = this.admin;
    // this.itemToSave.datasCulture = this.datasCultures;
    this.itemToSave.datasCertificat = this.selectedCertificates;
    this.itemToSave.datasBanque = this.selectedBanques;
    this.itemToSave.dataAdmin = this.admin;
    this.itemToSave.nbrSection = this.nbrSection;
    console.log("this.itemToSave.dataAdmin", this.itemToSave.dataAdmin);

    Swal.fire({
      title: !obj.id
        ? "Enregistrement coopérative"
        : "Modification coopérative",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        this.saveItem(obj);
      } else {
        console.log("bnjr");
      }
    });
  }

  confirmDeleteItem(obj) {
    Swal.fire({
      title: "Suppression",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    })
      .then(
        (result) => {
          if (result.value) {
            this.deleteItem(obj);
          } else {
            console.log("bnjr");
          }
        },
        (dismiss) => {
          if (dismiss == "cancel") {
            //Swal.fire({..}); //un-comment this line to add another sweet alert popup on cancel
            console.log("cancelled successfully");
          }
        }
      )
  }

  checkCurrentItem(item) {
    if (item) {
      console.log("ellement coché", item);
      this.currentItemChecked = item;
    }
  }

  cancelSave() {
    this.selectedItemsSections = [];
    this.datasCultures = [];
    this.supTotaPlantCoop = null;
    this.itemToSave = {};
    this.selectedItemsPartenaires = [];
    this.selectedItemsCultures = [];
    this.selectedItemsRegions = [];
    this.selectedCertificates = [];
    this.selectedBanques = [];
    this.selectedItemsModePaiements = [];
    this.selectedItemsLigneBudgetaires = [];
    this.selectedItemsSections = [];
    this.admin = {};
  }

  saveItem(item: any) {
    const request = {
      user: this.user.id,
      datas: [item],
    };
    console.log("data sent to server for tenancy create", request);
    this.busySave = this.api
      .post("tenancy/" + (item.id ? "update" : "create"), request)
      .subscribe(
        (res) => {
          console.log("resul", res);
          if (!res["hasError"]) {
            this.notificationService.showMessageSucces(
              "Enregistrement",
              "Opération effectuée avec succès."
            );
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
            this.communicationService.sendData("Refresh list");
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.notificationService.showMessageError(
                "Erreur",
                res["status"]["message"]
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Erreur",
            this.utilities.messageInterpretor(err)
          );
        }
      );
  }

  deleteItem(obj) {
    const request = {
      user: this.user.id,
      datas: [obj],
    };

    this.busyGet = this.api.post("users/delete", request).subscribe(
      (res) => {
        console.log(res);
        if (!res["hasError"]) {
          this.notificationService.showMessageSucces(
            "utilisateur",
            "Suppression effectuée avec succès"
          );

          this.currentPage = 1;
          this.getData();
        } else {
          if (res["status"] && res["status"]["message"]) {
            this.notificationService.showMessageError(
              "Erreur",
              res["status"]["message"]
            );
          }
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
        // this.not
      }
    );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = (page - 1) * 10 + (index + 1);
    }
    return num;
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  onSelectTenancy(entr) {
    console.log("hello");

    this.currentItemSeleced = entr;
    console.log("selected tenancy", this.currentItemSeleced);
  }

  onSelectFile(event: any) {
    let af = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    this.selectedFile = event.target.files[0];
    console.log("selected file", this.selectedFile.type);
    if (!_.includes(af, this.selectedFile.type)) {
      this.notificationService.showMessageError(
        "Erreur",
        "Seuls les documents EXCEL sont autorisés !"
      );

      return;
    }

    Swal.fire({
      title: "Créditer en masse",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result: any) => {
      if (result.value) {
        this.saveFile();
      } else {
        console.log("bnjr");
      }
    });
  }

  saveFile() {
    const fd = new FormData();
    fd.append("file", this.selectedFile);
    fd.append("userId", this.user.id);
    fd.append("tenancyId", this.currentItemSeleced.id);
    console.log("data sent to credit masse", fd);

    this.busyGet = this.api
      .post("carte/crediterEnMasse", fd)
      .subscribe((response) => {
        if (!response["hasError"]) {
          this.notificationService.showMessageSucces(
            "Succès",
            response["status"]["message"]
          );
          this.getData();
        } else {
          this.notificationService.showMessageError(
            "Erreur",
            response["status"]["message"]
          );
        }
      });
  }

  ngOnInit() {
    this.getSections();
    this.getData();
    this.getDataLigneBudgetaire();
    this.getDataMoyenPaiement();
    this.getDataTailleCoperative();
    this.getDataRegion();
    this.getDataCulture();
    this.getPartenaires();
    this.getCertificates();

    this.getAllInfoBanque();
    this.getDelaiPaiement();
    // this.userService.currentData.subscribe(currentData => {
    //     console.log('currentUser', currentData);
    //     if (currentData) {
    //         this.currentTenant = currentData;
    //         this.getSections();
    //     } else {
    //     }
    // });

    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    this.dropdownModePaiementSettings = {
      singleSelection: false,
      idField: "id",
      textField: "libelle",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };

    this.dropdownCertificateSettings = {
      singleSelection: false,
      idField: "id",
      textField: "libelle",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };
    this.dropdownBanqueSettings = {
      singleSelection: false,
      idField: "id",
      textField: "libelle",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };
    this.dropdownCultureSettings = {
      singleSelection: false,
      idField: "id",
      textField: "libelle",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      itemsShowLimit: 5,
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };

    this.dropdownLigneBudgetaireSettings = {
      singleSelection: false,
      idField: "id",
      textField: "libelle",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };

    this.dropdownSectionsSettings = {
      singleSelection: false,
      idField: "sectionId",
      textField: "libelle",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };

    this.dropdownRegionsSettings = {
      singleSelection: false,
      idField: "id",
      textField: "libelle",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      //itemsShowLimit: 30,
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };

    this.dropdownPartenairesSettings = {
      singleSelection: false,
      idField: "id",
      textField: "raisonSociale",
      selectAllText: "Tout cocher",
      unSelectAllText: "Tout décocher",
      //itemsShowLimit: 30,
      allowSearchFilter: true,
      searchPlaceholderText: "Sélectionner",
      placeholder: "Sélectionner",
    };

    window.scrollTo(0, 0);
  }
  displayLigne(lin: any) {
    console.log("ligne bud: ", lin);
    console.log("all lignes: ", this.selectedItemsLigneBudgetaires);
  }
  displayCertifs(cert: any) {
    console.log("certif: ", cert);
    console.log("all certficates: ", this.selectedCertificates);
  }
  displayCulture(cult: any) {
    console.log("cult: ", cult);
    console.log("all cultures: ", this.selectedItemsCultures);
  }
  updateCulture() {
    console.log("currentTenant.id", this.itemToSave.id);

    // if (JSON.stringify(this.selectedCulture) === JSON.stringify({})){
    //     this.notificationService.showMessageError('Erreur', 'Veuillez selectionner une culture svp!');
    //     return
    // }
    if (!this.selectedCulture) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez selectionner une culture svp!"
      );
      return;
    }
    if (!this.selectedSection) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner la section de la culture svp!"
      );
      return;
    }

    const existsCulture = _.find(this.datasCultures, {
      cultureId: (this.selectedCulture as any).id,
    });
    const existsSection = _.find(this.datasCultures, {
      cultureSectionId: (this.selectedCulture as any).cultureSectionId,
    });
    console.log("selected culture: ", this.selectedCulture);
    console.log("selected sections: ", this.selectedItemsSections);
    const data = {
      tenancyId: this.itemToSave.id,
      cultureId: (this.selectedCulture as any).id,
      cultureLibelle: (this.selectedCulture as any).libelle,
      cultureSectionId: this.selectedSection.sectionId,
      cultureSectionLibelle: this.selectedSection.libelle,
    };
    console.log("single data culture: ", data);
    this.datasCultures.push(data);
    console.log("current culture: : ", this.datasCultures);
    this.selectedCulture = {};
  }
  removeCulture(cult: any) {
    console.log("removing row", cult);
    console.log("datasCultures", this.datasCultures);
    let index = _.findIndex(this.datasCultures, function (o) {
      return o.cultureId == cult.cultureId;
    });

    this.datasCultures.splice(index, 1);
    // this.supTotaPlantCoop = 0;
    // this.datasCultures.map(
    //     dc=>{
    //         this.supTotaPlantCoop += parseInt(dc.cultureSuperficie)
    //     }
    // )
  }
  getDynamicValue() {
    this.api.get("tenancy/getDynamiqueValue").subscribe((res: any) => {
      console.log("dynamic value: ", res);
      // this.itemToSave.nbrMembOffi = res.items[0].nbrMembOffi
      this.itemToSave.nbrMembCarte = res.items[0].nbrMembCarte;
      this.itemToSave.supTotalPlantCarte = res.items[0].supTotalPlantCarte;
      this.itemToSave.supTotaPlantCoop = res.items[0].supTotaPlantCoop;
      console.log("itemToSave.nbrMembOffi", this.itemToSave.nbrMembOffi);
      console.log(
        "itemToSave.supTotalPlantCarte",
        this.itemToSave.supTotalPlantCarte
      );
      console.log(
        "itemToSave.supTotaPlantCoop",
        this.itemToSave.supTotaPlantCoop
      );
    });
  }
  setNombreHommeFemme() {
    let totalMembres = 0;
    totalMembres =
      parseInt(this.itemToSave.nbrMembHom || 0) +
      parseInt(this.itemToSave.nbrMembFem || 0);

    if (totalMembres > 0) this.itemToSave.nbrMembOffi = totalMembres;
    else this.itemToSave.nbrMembOffi = "";
  }
  addCulture(p?: any) {
    console.log("list of selected cultures: ", this.selectedItemsCultures);
    console.log("last selected culture: ", this.selectedCulture);
  }
  selectCultureChange() {
    if (!this.selectedCulture.id) {
      this.listSection = [];
      return;
    }
    this.listSection = this.copyListSection;

    console.log("selected culture: ", this.selectedCulture);
  }
  onCultureDetails(cult) {
    this.selectedCulture = cult;
    console.log("cuture detail", cult);
    console.log("cuture detail", cult.cultureId);
    const index = _.findIndex(this.datasCultures, {
      cultureId: cult.cultureId,
    });
    this.selectedItemsSections = this.datasCultures[index].datasTenancySection;
    this.selectedItemsSections.map((sis) => {
      sis.libelle = sis.sectionLibelle;
    });
    console.log("selectedItemsSections", this.selectedItemsSections);
  }
  UncheckAll() {
    //    this.selectedItemsSections = [];
  }

  getAllInfoBanque() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.busySave = this.api
      .post("banque/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getAllInfoBanque", res);
        if (res && res.items) {
          this.listBanques = res.items || [];
        }
      });
  }
  getDelaiPaiement() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.busySave = this.api
      .post("delaiPaiement/getByCriteria", request)
      .subscribe((res: any) => {
        console.log("getDelaiPaiement", res);
        if (res && res.items) {
          this.listeDelais = res.items || [];
        }
      });
  }
  controleYear(item: any) {
    console.log("date changed");

    if (parseInt(item.annCertificat) > this.currentYear) {
      item.annCertificat = this.currentYear;
    }
  }

  controleYearBank(item: any) {
    console.log("date changed");

    if (parseInt(item.annOuverute) > this.currentYearBank) {
      item.annOuverute = this.currentYear;
    }
  }
  controleAnneeCreationCoop(item: any) {
    console.log("date changed");

    if (parseInt(item.annCreationCoop) > this.currentYear) {
      item.annCreationCoop = this.currentYear;
    }
  }
  controleAnneeInscriptionCarteAgricole(item: any) {
    console.log("date changed");

    if (parseInt(item.annInscCarte) > this.currentYear) {
      item.annInscCarte = this.currentYear;
    }
  }

  controleAnneeNominationPCA(item: any) {
    console.log("date changed");

    if (parseInt(item.annDirectionPca) > this.currentYear) {
      item.annDirectionPca = this.currentYear;
    }
  }
  controleAnneeNominationDirecteur(item: any) {
    console.log("date changed");

    if (parseInt(item.annDirectionDir) > this.currentYear) {
      item.annDirectionDir = this.currentYear;
    }
  }

  onChangeLock(event, item) {
    console.log("event", event);
    console.log("item", item);
    Swal.fire({
      title: "Confirmation",
      text: item.isLocked
        ? "Voulez-vous activer la coopérative ?"
        : "Voulez vous désactivation de la coopérative",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
      allowOutsideClick: false,
    })
      .then(
        (result) => {
          if (result.value) {
            this.lockCoop(item);
          } else {
            console.log("cancelled");
          }
        },
        (dismiss) => {
          if (dismiss == "cancel") {
            console.log("cancelled successfully");
            item.isValidated = !event;
          } else {
            throw dismiss;
          }
        }
      )
  }

  onChangeStatus(event, item) {
    console.log("event", event);
    console.log("item", item);
    Swal.fire({
      title: event
        ? "Validation de coopérative"
        : "Invalidation de coopérative",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
      allowOutsideClick: false,
    })
      .then(
        (result) => {
          if (result.value) {
            this.validerCoop(item);
          } else {
            console.log("cancelled");
          }
        },
        (dismiss) => {
          if (dismiss == "cancel") {
            //Swal.fire({..}); //un-comment this line to add another sweet alert popup on cancel
            console.log("cancelled successfully");
            item.isValidated = !event;
          } else {
            throw dismiss;
          }
        }
      )
  }

  lockCoop(coop: any) {
    const request = {
      user: this.user.id,
      datas: [coop],
    };
    const method = coop.isLocked === true ? "lockedTenacy" : "unLockedTenacy";
    console.log("data sent to lock coop", request);
    this.busyGet = this.api.post(`tenancy/${method}`, request).subscribe(
      (res: any) => {
        console.log("resul", res);
        if (res.hasError === false) {
          this.notificationService.showMessageSucces(
            "Enregistrement",
            "Opération effectuée avec succès."
          );
          this.currentPage = 1;
          this.cancelSave();
          // this.getData();
        } else {
          // this.getData();
          if (res.status && res.status.message) {
            this.notificationService.showMessageError(
              "Erreur",
              res.status.message
            );
          }
        }
        this.getData();
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Erreur",
          this.utilities.messageInterpretor(err)
        );
      }
    );
  }

  validerCoop(coop) {
    const request = {
      user: this.user.id,
      datas: [
        {
          id: coop.id,
        },
      ],
    };
    console.log("data sent to validate coop", request);
    this.busyGet = this.api
      .post("tenancy/validateCooperative", request)
      .subscribe(
        (res) => {
          console.log("resul", res);
          if (!res["hasError"] && res["items"]) {
            this.notificationService.showMessageSucces(
              "Enregistrement",
              "Opération effectuée avec succès."
            );
            this.currentPage = 1;
            this.cancelSave();
            this.getData();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.notificationService.showMessageError(
                "Erreur",
                res["status"]["message"]
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Erreur",
            this.utilities.messageInterpretor(err)
          );
        }
      );
  }

  exportData() {
    // if (!this.currentTenant || !this.currentTenant.code) { return };
    const request = {
      user: this.user.id,
      data: {
        tenancyId:
          this.currentItemSeleced && this.currentItemSeleced.id
            ? this.currentItemSeleced.id
            : null,
      },
      // index: (this.currentPage - 1),
      // size: this.itemsPerPage ? this.itemsPerPage : null
    };
    this.busyGet = this.api
      .post("clientTenancy/exportClient", request)
      .subscribe((res: any) => {
        console.log(res);
        if (res && res.filePathDoc) {
          console.log("res.filePathDoc: ", res.filePathDoc);

          window.location.href = res ? res.filePathDoc : window.location.href;
        } else {
          this.notificationService.showMessageError(
            "Information",
            res.status.message
          );
        }
      });
  }
  doSomethingOnToggle() {
    this.isOpen = !this.isOpen;
  }
  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  downloadTemplate() {
    const options = {
      headers: new HttpHeaders({
        // tslint:disable-next-line:comment-format
        tenantID:
          this.currentItemSeleced && this.currentItemSeleced.code
            ? this.currentItemSeleced.code
            : null, //'1907122100004',
        lang: "fr",
      }),
    };

    const request = {
      user: this.user.id,
      data: {
        tenancyId: this.currentItemSeleced.id,
      },
    };
    console.log("data sent to download", request);

    this.busyGet = this.api
      .postOption("/clientCulture/downloadUploadeTemplate", request, options)
      .subscribe((res: any) => {
        console.log(res);
        if (res && res.filePathDoc) {
          console.log("res.filePathDoc: ", res.filePathDoc);

          window.location.href = res ? res.filePathDoc : window.location.href;
        } else {
          this.notificationService.showMessageError(
            "Information",
            res.status.message
          );
        }
      });
  }
}
