import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {UserService} from "../../shared/user/user.service";
import {Api} from '../../shared/utils/api/api';
import {NotificationService} from '../../shared/utils/notification.service';
import { Subscription } from 'rxjs';
import {Router} from "@angular/router";
import { UtilitiesService } from '../../shared/utils/utilities.service';

@Component({
  selector: 'app-form-reinit-password',
  templateUrl: './form-reinit-password.component.html',
  styleUrls: ['./form-reinit-password.component.css']
})
export class FormReinitPasswordComponent implements OnInit {

  eyePassword  = false ;
  itemToSave : any;
  user : any;
  busySave : Subscription;
  currentUser : any;

  constructor(public bsModalRef: BsModalRef,private api:Api,private notificationService : NotificationService,private userService: UserService,private router: Router,private utilities : UtilitiesService) {
    this.itemToSave = {};
    //this.user = this.userService.getCurrentUser();

    setTimeout(() => {
      this.itemToSave  = Object.assign({},this.currentUser);
      this.itemToSave.password = null;
      console.log("le item to save ", this.itemToSave);
    }, 2000);

   }


saveItem(item)
{
    this.itemToSave.messageError = null;
    if(!item || !item.password || !item.oldPassword)
    {
        this.notificationService.showMessageInfo("Information","Veuillez renseigner l'ancien et le nouveau mot de passe.")
        return;
    }

    if(item.ConfirmNewPassword != item.password)
    {
      this.notificationService.showMessageInfo("Information","Veuillez confirmer votre mot de passe.")
      return;
    }

    item.id = this.itemToSave.id;
    //item.oldPassword = item.password;
   
    var request = {
      user : this.itemToSave.id,
      "data" : item
    }

    this.busySave = this.api.post('user/changerMotDePasse', request)
        .subscribe(
            res => {
              console.log("resul",res);
                if(!res['hasError'])
                {
                    this.notificationService.showMessageSucces("Mot de passe",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                    // on le deconnecte
                    this.bsModalRef.hide();
                    this.router.navigate(['/auth/login']);
                    
                    // localStorage.removeItem("yz-backoffice-app-smart-carte");
                    // var currentUser = res['items'][0];
                    // //localStorage.setItem("yz-backoffice-app-boutique-shell",JSON.stringify(currentUser));

                    // // if(currentUser && currentUser.fonctionnalites && currentUser.fonctionnalites.length > 0)
                    // // {
                    // //   currentUser.fonctionnalites.push({code : "DASH",libelle : 'DASHBORD'});
                    // // }
                    
                    // localStorage.setItem("yz-backoffice-app-smart-carte",JSON.stringify(currentUser));
                    
                    // if(this.currentUser.isFromLogin)
                    // {
                    //   this.router.navigate(['/home']);
                    // }
                    // else
                    // {
                    //   //this.router.navigate(['/auth/login']);
                    // }
                 

                }else
                {
                  if(res['status'] && res['status']['message'])
                  {
                    this.itemToSave.messageError = this.utilities.mainSelfFormatMsgServeur(res['status']['message']);
                    this.notificationService.showMessageError("Erreur",this.utilities.mainSelfFormatMsgServeur(res['status']['message'])); 
                  }
                }
            },
            err => {
                console.log("Error occured",err);
                //this.notificationService.showMessageError("Erreur",this.utilities.messageInterpretor(err));
            }
        );
}

  ngOnInit() {
  }

  //gérer l'affichage du password
  afficherPasswordPop1(){
    if(this.eyePassword && document.getElementById("passwordPop1")){
        document.getElementById("passwordPop1").setAttribute("type","password");
        document.getElementById("eyePop1").setAttribute("class", "fa fa-eye-slash");
        // document.getElementById("eye").style.color='gray';
        this.eyePassword = false;
    }
    else{
      document.getElementById("passwordPop1").setAttribute("type","text");
      document.getElementById("eyePop1").setAttribute("class", "fa fa-eye");
      // document.getElementById("eye").style.color='gray'; 
      this.eyePassword=true;
        }
    }

    afficherPasswordPop2(){
  if(this.eyePassword && document.getElementById("passwordPop2")){
      document.getElementById("passwordPop2").setAttribute("type","password");
      document.getElementById("eyePop2").setAttribute("class", "fa fa-eye-slash");
      // document.getElementById("eye").style.color='gray';
      this.eyePassword = false;
  }
  else{
    document.getElementById("passwordPop2").setAttribute("type","text");
    document.getElementById("eyePop2").setAttribute("class", "fa fa-eye");
    // document.getElementById("eye").style.color='gray'; 
    this.eyePassword=true;
      }
  }

afficherPasswordPop3(){
  if(this.eyePassword && document.getElementById("passwordPop3")){
      document.getElementById("passwordPop3").setAttribute("type","password");
      document.getElementById("eyePop3").setAttribute("class", "fa fa-eye-slash");
      // document.getElementById("eye").style.color='gray';
      this.eyePassword = false;
  }
  else{
    document.getElementById("passwordPop3").setAttribute("type","text");
    document.getElementById("eyePop3").setAttribute("class", "fa fa-eye");
    // document.getElementById("eye").style.color='gray'; 
    this.eyePassword=true;
      }
  }

}
