import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
// import { MatStepperModule } from "@angular/material/stepper";
// import { MatListModule } from "@angular/material/list";

import { HeaderModule } from "./header/header.module";
import { FooterComponent } from "./footer/footer.component";
import { NavigationModule } from "./navigation/navigation.module";
import { RibbonComponent } from "./ribbon/ribbon.component";
import { ShortcutComponent } from "./shortcut/shortcut.component";
import { LayoutSwitcherComponent } from "./layout-switcher.component";
import { MainLayoutComponent } from "./app-layouts/main-layout.component";
import { EmptyLayoutComponent } from "./app-layouts/empty-layout.component";
import { RouterModule } from "@angular/router";
import { AuthLayoutComponent } from "./app-layouts/auth-layout.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { RouteBreadcrumbsComponent } from "./ribbon/route-breadcrumbs.component";
import { UtilsModule } from "../utils/utils.module";
import {NgBusyModule} from 'ng-busy';
import {
  Preview,
  FormatMiller,
  SafeUrlPipe,
  JsonDate,
} from "../utils/pipe_volontarait";
import { Api } from "../utils/api/api";
import { UtilitiesService } from "../utils/utilities.service";
import { ModelService } from "../utils/model.service";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { OrderModule } from "ngx-order-pipe";
import { NgxMaskModule } from "ngx-mask";
import { ChartModule } from "angular-highcharts";
import { MatTabsModule } from "@angular/material/tabs";
import { ImageCropperModule } from "ngx-image-cropper";
import { SmartadminInputModule } from "../forms/input/smartadmin-input.module";
import { NguiMapModule } from "@ngui/map";
import { MainProfilComponent } from "../../+components/main-profil/main-profil.component";
import { FormReinitPasswordComponent } from "../../+components/form-reinit-password/form-reinit-password.component";
import { MainUpdateProfilUserConnectedComponent } from "../../+components/main-update-profil-user-connected/main-update-profil-user-connected.component";
import { FormPreviewPrintComponent } from "../../+components/component-app/form-preview-print/form-preview-print.component";
import { MainMouvementComponent } from "../../+components/component-app/main-mouvement/main-mouvement.component";
import { MainMarchandsComponent } from "../../+components/component-app/main-marchands/main-marchands.component";
import { MainCooperativeComponent } from "../../+components/component-app/main-cooperative/main-cooperative.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MainParametrageRedionsComponent } from "../../+components/component-app/main-parametrage-redions/main-parametrage-redions.component";
import { MainParametrageDomainesComponent } from "../../+components/component-app/main-parametrage-domaines/main-parametrage-domaines.component";
import { MainParametrageCultureComponent } from "../../+components/component-app/main-parametrage-culture/main-parametrage-culture.component";
import { MainUtilisateurComponent } from "../../+components/component-app/main-utilisateur/main-utilisateur.component";
import { MainParametrageLigneBudgetaireComponent } from "../../+components/component-app/main-parametrage-ligne-budgetaire/main-parametrage-ligne-budgetaire.component";
import { MainClientsComponent } from "../../+components/component-app/main-clients/main-clients.component";
import { MainReglementSmartTComponent } from "../../+components/component-app/main-reglement-smart-t/main-reglement-smart-t.component";
import { MainParametrageCarteComponent } from "../../+components/component-app/main-parametrage-carte/main-parametrage-carte.component";
import { MainParametrageSiteComponent } from "../../+components/component-app/main-parametrage-site/main-parametrage-site.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FicheProductionClientComponent } from "../../+components/component-app/fiche-production-client/fiche-production-client.component";
import { MainDelaiPaiementComponent } from "../../+components/component-app/main-delai-paiement/main-delai-paiement.component";
import { MainDonationComponent } from "../../+components/component-app/main-donation/main-donation.component";
import { MainDonatorComponent } from "../../+components/component-app/main-donator/main-donator.component";
import { MainTypeDonComponent } from "../../+components/component-app/main-type-don/main-type-don.component";
import { ClientDonComponent } from "../../+components/component-app/main-beneficiaires-don/client-don.component";
import { BeneficiairesPromoComponent } from "../../+components/component-app/main-beneficiaires-promo/beneficiaires-promo.component";
import { MainPromoComponent } from "../../+components/component-app/main-promo/main-promo.component";
import { MoneyFormatPipe } from "../../core/pipes/money-format";
import { TruncatePipe } from "../../core/pipes/truncate";
import { MainLivrerEncaisserComponent } from "../../+components/component-app/main-livrer-encaisser/main-livrer-encaisser.component";
import { MainBeneficiairesLivrerEncaisserComponent } from "../../+components/component-app/main-beneficiaires-livrer-encaisser/main-beneficiaires-livrer-encaisser.component";
import { MainGestionnairesComponent } from "src/app/+components/component-app/main-gestionnaires/main-gestionnaires.component";
import { MainValiderPrefinancementComponent } from "src/app/+components/component-app/main-valider-prefinancement/main-valider-prefinancement.component";
import { DelegueComponent } from "src/app/+components/component-app/delegue/delegue.component";
import { MainDashboardComponent } from "src/app/+components/component-app/main-dashboard/main-dashboard.component";
import { ManagePrefinancementComponent } from "src/app/+components/manage-prefinancement/manage-prefinancement.component";
import { AccordionModule } from "ngx-accordion";
import { GestionStockComponent } from "src/app/+components/component-app/gestion-stock/gestion-stock.component";
import { MainMappingComponent } from "src/app/+components/component-app/main-mapping/main-mapping.component";
import { MainPrimeComponent } from "src/app/+components/component-app/main-prime/main-prime.component";
import { MainBeneficiairesPrimeComponent } from "src/app/+components/component-app/main-beneficiaires-prime/main-beneficiaires-prime.component";

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    NavigationModule,
    FormsModule,
    RouterModule,
    UtilsModule,
    NgBusyModule,
    BsDropdownModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    OrderModule,
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ChartModule,
    ImageCropperModule,
    SmartadminInputModule,
    TypeaheadModule,
    NgSelectModule,
    AccordionModule,
    // MatStepperModule,
    // MatListModule,
    NguiMapModule.forRoot({
      apiUrl:
        "https://maps.google.com/maps/api/js?key=AIzaSyA67CzCCzdTGz8LifE6DvD1JC5VMhCMH2s",
    }),
  ],
  declarations: [
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,
    MainLayoutComponent,
    EmptyLayoutComponent,
    AuthLayoutComponent,
    RouteBreadcrumbsComponent,
    Preview,
    FormatMiller,
    SafeUrlPipe,
    JsonDate,

    /*SMART HOME */
    MainProfilComponent,
    FormReinitPasswordComponent,
    MainUpdateProfilUserConnectedComponent,
    FormPreviewPrintComponent,

    // SMART T
    MainMouvementComponent,
    MainMarchandsComponent,
    MainCooperativeComponent,
    MainParametrageRedionsComponent,
    MainParametrageDomainesComponent,
    MainParametrageCultureComponent,
    MainUtilisateurComponent,
    MainParametrageLigneBudgetaireComponent,
    MainClientsComponent,
    MainReglementSmartTComponent,
    MainParametrageCarteComponent,
    MainParametrageSiteComponent,
    FicheProductionClientComponent,
    MainDelaiPaiementComponent,
    MainDonationComponent,
    MainDonatorComponent,
    MainTypeDonComponent,
    ClientDonComponent,
    BeneficiairesPromoComponent,
    MainPromoComponent,
    TruncatePipe,
    MoneyFormatPipe,
    MainLivrerEncaisserComponent,
    MainBeneficiairesLivrerEncaisserComponent,
    MainGestionnairesComponent,
    MainValiderPrefinancementComponent,
    DelegueComponent,
    MainDashboardComponent,
    ManagePrefinancementComponent,
    GestionStockComponent,
    MainMappingComponent,
    MainPrimeComponent,
    MainBeneficiairesPrimeComponent,
  ],

  exports: [
    HeaderModule,
    NavigationModule,
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,

    /* SMART HOME */
    MainProfilComponent,
    FormReinitPasswordComponent,
    MainUpdateProfilUserConnectedComponent,
    FormPreviewPrintComponent,

    // SMART CARTE
    MainMouvementComponent,
    MainMarchandsComponent,
    MainCooperativeComponent,
    MainParametrageRedionsComponent,
    MainParametrageDomainesComponent,
    MainParametrageCultureComponent,
    MainUtilisateurComponent,
    MainParametrageLigneBudgetaireComponent,
    MainClientsComponent,
    MainReglementSmartTComponent,
    MainParametrageCarteComponent,
    MainParametrageSiteComponent,
    FicheProductionClientComponent,
    MainDelaiPaiementComponent,
    MainDonationComponent,
    MainDonatorComponent,
    MainTypeDonComponent,
    ClientDonComponent,
    BeneficiairesPromoComponent,
    MainPromoComponent,
    TruncatePipe,
    MoneyFormatPipe,
    MainLivrerEncaisserComponent,
    MainBeneficiairesLivrerEncaisserComponent,
    MainPrimeComponent,
    MainBeneficiairesPrimeComponent,
    MainGestionnairesComponent,
    MainValiderPrefinancementComponent,
    DelegueComponent,
    MainDashboardComponent,
    ManagePrefinancementComponent,
    GestionStockComponent,
    AccordionModule,
    FormatMiller,
    // MatStepperModule,
    PaginationModule,
    MainMappingComponent,
    NguiMapModule,
  ],
  providers: [Api, UtilitiesService, ModelService],
  bootstrap: [FormReinitPasswordComponent, FormPreviewPrintComponent],
})
export class SmartadminLayoutModule {}
