import { Component, OnInit,  ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { Api } from 'src/app/shared/utils/api/api';
import { UserService } from 'src/app/shared/user/user.service';
// import * as L from 'leaflet';
import * as _ from 'lodash';

declare const L: any;

@Component({
  selector: 'app-main-mapping',
  templateUrl: './main-mapping2.component.html',
  styleUrls: ['./main-mapping.component.css']
})
export class MainMappingComponent implements OnInit {
  public maps: any; // L.Map;
  tileLayer: any // L.TileLayer;
  @ViewChild('map') map
  busyGet: Subscription;
  busySave: Subscription;
  currentClient:any = { }
  currentTenant: any;
  ListeClients: any=[];
  user: any;
  busyGetPointContour: Subscription;
  ListePointContours: any=[];


  constructor(private api: Api, private userService: UserService,) { 
    this.user = this.userService.getCurrentUser();
  }
  
  onSelectClient(data:any){
    this.currentClient.clientId = data.clientId
    this.getClientPointContour(data.clientId)
  }
  getClientPointContour(clientId){

    let request={
      user: this.user.id,
      isSimpleLoading: false,
      data: {
        tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        clientId: clientId
     
      }
  }
  console.log('data sent to server',JSON.stringify(request));
  
  let listePointContours = []
  this.ListePointContours = []
  this.busyGetPointContour = this.api.post('culture/getCulturePointContour', request)
  .subscribe((res: any) => {
      console.log('getData', res.items);
      if (res && res.items) {
        
          // this.ListePointContours = res.items;

          res.items.forEach(element => {
            if(element.datasCultures && element.datasCultures.length){
              
              element.datasCultures.map(
                idc=>{
                  if(idc.dataPointContours && idc.dataPointContours.length){
                    idc.dataPointContours.map(
                      (idcdpc,index)=>{
                        let inverted = {lat:idcdpc.lat,lng:idcdpc.lng}
                        // console.log('inverted value',inverted);
                        idc.dataPointContours[index] = inverted
                      }
                    )
                    listePointContours.push(idc.dataPointContours)
                  }
                  
                }
                  
              )
              
  
  
            }

            this.ListePointContours = listePointContours
            

          });
          
          
      } else {
          this.ListePointContours = [];
      }
  });
  }

  getClientList(){
        if (!this.currentTenant || !this.currentTenant.id) { return };
        const request = {
            user: this.user.id,
            data: {
                tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
                // clientNom: this.itemToSearch.nom || null,
                // clientPrenoms: this.itemToSearch.prenoms || null,
                // clientCode: this.itemToSearch.code || null,
            },
            // index: (this.currentPage - 1),
            // size: this.itemsPerPage ? this.itemsPerPage : null
        }
        this.busyGet = this.api.post('clientTenancy/getByCriteria', request)
            .subscribe((res: any) => {
                console.log('getData', res.items);
                if (res && res.items) {
                    this.ListeClients = res.items;
                    
                } else {
                    this.ListeClients = [];
                }
            });
    }

    onMapReady(event){

    }

    onMapClick(event){

    }

    onIdle(event){

    }
    getRandomColor() {

    //   var randomArray = [
    //     '#182627','#dc122a', '#e4002b','#a90000','#fec5e5','#34a853','#ff027d','#65ff01','#090909','#7fe5f0'
    //  ];
     
    //  return _.sample(randomArray);
  
      return '#'+Math.floor(Math.random()*16777215).toString(16);
    }

    initMap(){
      this.maps = L.map(this.map).setView([5.3940944, -3.973805], 11);
      this.tileLayer = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.maps)
        
    }
    ngOnInit() {
      // this.initMap()
      this.userService.currentData.subscribe((currentData:any) => {
        console.log('currentUser', currentData);
        if (currentData) {
            // this.cancelSave();
            this.currentTenant = currentData;
            this.getClientList()
           
        } else {
            this.ListeClients = [];
        }
    });
  
    const map = L.map('map').setView([5.3881394, -4.0157707], 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);



    }
}
