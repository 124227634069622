<div class="login-info">
  <span *ngIf="user"><a>
	   <!--img [src]="user.picture" alt="me"
          class="online"/--><!--span>{{user.username }}</span--> <!--i class="fa fa-angle-down"></i-->
          <span>{{user.Nom +' '+user.Prenoms}} <br>
            <a (click)="logout()">Déconnecter</a>

            <a (click)="logout()">Modifier mot de passe</a>
          </span> 
    </a>
   </span>
   
      
</div>
