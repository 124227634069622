<sa-header></sa-header>


<sa-navigation></sa-navigation>


<div id="main" role="main">
  <sa-layout-switcher></sa-layout-switcher>

  <sa-ribbon></sa-ribbon>

  <router-outlet></router-outlet>
</div>

<!--sa-footer></sa-footer-->

<sa-shortcut></sa-shortcut>
