export enum EtatMissionId {
    BROUILLON = 1,
    ENTRANT=2,
    VALIDE=3,
    REJETE=4
}

export enum StatutCandidature {
    PRESELECTIONNER = 1,
    ENTRETIEN_PASSE=2,
    SELECTIONNE=3,
    CONTRAT_SIGNE=4,
    ELIMINE = 5
}

export enum EtatTemoignage {
    BROUILLON = 1,
    ENTRANT=2,
    VALIDE=3,
    REJETE=4
}

export enum EnumCodeParametreBase {
    HeureNormaleTravail = "HrNor",
    CoefficientPrixPanier="CoPrP",
    ReferenceNumber="NumRefSaisi",
}


export enum enumEtatId {
    BROUILLON = 1,
    ENTRANT=2,
    VALIDE=3,
    REJETE=4
}

export enum enumEtatCode {
    BROUILLON = "BROUILLON",
    ENTRANT="ENTRANT",
    VALIDE="VALIDE",
    REJETE="REJETE"
}

export enum enumEtatUserFo {
    Actif = "Actif",
    Suspendu="Suspendu",
    Fermer="Fermer",
    REJETE="REJETE"
}

export enum enumEtatAnnonce {
    Annule = "Annulé",
}


export enum enumLibelleEtats {
    BROUILLON = "BROUILLON",
    ENTRANT="ENTRANT",
    VALIDE="VALIDE",
    REJETE="REJETE"
}

export enum enumCodeMenu {
    PUBLICATION = "PUBLICATION",
    ACCUEILPROPOS ="ACC-APROPOS",
    PRESENTATION_PNV ="PRST-PNV",
    PRESENTATION_COMPRENDRE ="PRST-CMPRD",
    PRESENTATION_ORGANISATION ="PRST-ORG",
    PRESENTATION_MISSION ="PRST-MISSION-STATIC"
}

export enum enumStatutId {
    Brouillon = 1,
    EnLigne = 2,
    Termine = 3,
    Archiver = 4,
    Reactiver = 1000  //Utiliser pr des controles mais en realité cela equivaut au statut brouillon (cad avant l'enregistrement)
}

export enum enumParametreGeneraux {
    Bulletin_Alerte = "DELAI_BULLETIN_ALERTE",
    Bulletin_Innondation = "DELAI_BULLETIN_INONDATION"
}

export enum enumLibelleStatutDesktop {
    TERMINER = "TERMINER",
    EN_COURS="EN COURS"
}


export enum enumStatutRedevanceMarchePublicId {
    PAYER = 1,
    SOLDE_PARTIELLEMENT =2,
    NON_SOLDE =3,
    SOLDE =4
}

export enum enumIdModePaiement {
    CHEQUE = 1,
    MOBILE_MONEY =2,
    ESPECE =4,
    CARTE_BANCAIRE =3,
    VIREMENT =6
}

export enum enumTVAMarchePublicId {
    TAUX_TVA = 18
}


export enum enumIdStatutCheque {
    RECEPTIONNE = 1,
    MISE_ENCAISSEMENT =2,
    COMPENSE = 3,
    REJETE =4
}

export enum enumCodeTypeUtilisateur {
    ADMIN = "ADMIN",
    MARCHAND_USER ="MARCHAND_USER",
    TENANCY_USER ="TENANCY_USER",
    MARCHAND_ADMIN ="MARCHAND_ADMIN",
    TENANCY_ADMIN ="TENANCY_ADMIN",
    MARCHAND ="MARCHAND",
    DELEGUE ="DELEGUE",
    SA ="SA"
}

export enum enumLibelleRole {
    ADMIN = "ADMIN",
    DELEGUE = "DELEGUE",
    COOP = "COOPERATIVE",
    MARCHAND ="MARCHAND",
    SUPER_ADMIN ="SUPER ADMIN",
    ADMIN_MARCHAND = "ADMIN MARCHAND",
    ADMIN_TENANCY = "ADMIN TENANCY"
}

