import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
declare const Swal: any;
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'app-main-parametrage-carte',
  templateUrl: './main-parametrage-carte.component.html',
  styleUrls: ['./main-parametrage-carte.component.css']
})
export class MainParametrageCarteComponent implements OnInit {


  results: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination: number = 10;
  admin: any = {};
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};


  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  searchKey:any;
  onFullSearch = new Subject<string>();
  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private modelService: ModelService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.itemToSearch = {};

    this.onFullSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.getData();
      
      });
  }

  //MULTI SELECT

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified?: any) {

    this.cancelSave();

    if (itemToModified) {
      // itemToModified.typeUtilisateurSelected = _.find(this.ListeTypesUtilisateurs, { 'id': itemToModified.userTypeId });
      this.itemToSave = { ...itemToModified };
    }
    this.modalRef = this.modalService.show(template, { class: 'gray modal-md' });
  }


  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }


  getData() {
    var request = {
      user: this.user.id,
      data: {
        code:this.searchKey
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }


    this.busyGet = this.api.post('carte/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
          } else {
            this.results = [];
          }
        },
        err => {
        }
      );

  }

  confirmSaveItem(obj) {

    if (!obj) {
      this.notificationService.showMessageError("Erreur", "Veuillez renseigner les champs obligatoires.");
      return;
    }

    if (!this.itemToSave || !this.itemToSave.code || !this.itemToSave.seuil) {
      this.notificationService.showMessageError("Erreur", "Veuillez renseigner les champs obligatoires.");
      return;
    }

    Swal.fire({
      title: !obj.id ? 'Enregistrement carte' : 'Modification carte',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        this.saveItem(obj);
      } else {
        console.log("bnjr");
      }
    })

  }



  confirmDeleteItem(obj) {

    Swal.fire({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.deleteItem(obj);
      } else {
        console.log("bnjr");
      }
    })

  }


  cancelSave() {
    this.itemToSave = {};
  }


  saveItem(item) {

    var request = {
      user: this.user.id,
      datas: [
        item
      ]
    }

    console.log("create return", request);

    this.busySave = this.api.post('carte/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Carte", 'Opération effectuée avec succès.');
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  deleteItem(obj) {

    var request = {
      user: this.user.id,
      datas: [
        obj
      ]
    }

    this.busyGet = this.api.post('carte/delete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Carte", 'Suppression effectuée avec succès');

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    this.getData();
    window.scrollTo(0, 0);
  }


}
