import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RestService {

  baseUrl = 'https://questionnement-web-api.dev05.syt.smile.ci/questionnement/';
  constructor(private http: HttpClient) {}

  post(uri: string, body: any): Observable<any> {
    return this.http.post(this.baseUrl + uri, body);
  }

}
