import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';
import {NULL_EXPR} from "@angular/compiler/src/output/output_ast";
import { CommunicationService } from 'src/app/core/services/communication.service';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Api } from 'src/app/shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from 'src/app/shared/utils/notification.service';
import { UtilitiesService } from 'src/app/shared/utils/utilities.service';
import { UserService } from 'src/app/shared/user';
import { HttpHeaders } from '@angular/common/http';
declare const Swal: any;
@Component({
  selector: 'app-main-valider-prefinancement',
  templateUrl: './main-valider-prefinancement.component.html',
  styleUrls: ['./main-valider-prefinancement.component.css']
})
export class MainValiderPrefinancementComponent implements OnInit {
  resultDelaiPaiements: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;

  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  currentTenant: any = {};
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination = 10;
  totalRepartie=0;
  admin: any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  valeurOperationRestante :any;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  private listTypeDon= [];
  listBeneficiairesDon= [];
  listDonations= [];
  ListeClients = [];
  dropdownBeneficiariesSettings = {}
  listBeneficiaries = [];
  isForSeveral = true;
  listBeneficiairesTable: any;
  listClientsBrute: any;
  listRepartitions= [];
  valeurOperationRestanteBrute: number;
  isAll = false
  listToValidate= [];
  size: number;
  selectedClientId: any;
  codeClient:any

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, 
    private utilities: UtilitiesService, private userService: UserService,private communicationService:CommunicationService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.itemToSearch = {};
      this.communicationService.getData().subscribe(
        res=>{

          debugger
          this.getOperations()
        }
      )
  }

  // MULTI SELECT
  listClientsFiltered: any[];
  selectedDonationId: any;
  selectectedDonation: any;
  quantiteToApply: any;
  valeurToApply: any;
  resteArepartir = 0;



  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }


  getData() {
    if(!this.codeClient){
      this.notificationService.showMessageError('Erreur', 'Veuillez renseigner Numéro carte svp. ');
      return
    }
    const request = {
      user: this.user.id,
      data: {
        tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        operBenefTenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        typeOperationCode: "CREDIT",
         clientCarteCode: this.codeClient,
        //  isActived:true
        // operationId : this.selectedDonationId,
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }
    console.log("code client: ", this.codeClient);
    
    if(!this.codeClient || this.codeClient==''){
      return
    }
    console.log('data sent to get beneficiaires don', JSON.stringify(request));

    this.busyGet = this.api.post('operationBeneficiaire/getByCriteria', request)
        .subscribe((res: any) => {
              if (res && res['items']) {
                console.log('data beneficiaires don return', res);
                this.listBeneficiairesDon = res['items'];
                this.totalItems = res['count'] ? res['count'] : 0;
                this.listBeneficiairesDon.map(
                    lb => {
                      lb.libelle = lb.clientNom + ' ' + lb.clientPrenoms;
                      lb.idOriginal = lb.id;
                      lb.id = lb.clientId;
                      lb.isChecked = false
                    }
                )
                // this.listBeneficiairesDon = this.listBeneficiairesDon.filter(
                //   lb=> !lb.isActived
                // )
                this.listBeneficiairesTable = this.listBeneficiairesDon

                console.log('list listBeneficiairesTable credit', this.listBeneficiairesTable);

              } else {
                this.listBeneficiairesTable = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }

  cancelSave() {
    this.size = 0

    this.itemToSave = {};
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }
  getClients() {
    if (!this.currentTenant || !this.currentTenant.code) { return }
    const request = {
      user: this.user.id,
      data: {
        tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
        clientNom: this.itemToSearch.nom || null,
        clientPrenoms: this.itemToSearch.prenoms || null,
      },
    }
    console.log('list client in client don request', request);

    this.busyGet = this.api.post('clientTenancy/getByCriteria', request)
        .subscribe((res: any) => {
          console.log('list of client tenancy before filter', res.items);
          if (res && res['items']) {
            this.listClientsBrute = res['items'];
            this.listClientsBrute.map(
                lb => {
                  lb.libelle = lb.clientNom + ' ' + lb.clientPrenoms;
                  lb.id = lb.clientId;
                  lb.carteId = lb.dataClient.carteId;
                }
            )
            this.listClientsBrute = this.listClientsBrute.filter(
              lcb=> lcb.carteId
            )
            this.listClientsFiltered = this.listClientsBrute
          } else {
            this.ListeClients = [];
          }
          console.log('list of client tenancy after filter', this.listClientsFiltered);
        });
  }

  getOperations() {
    // if (!this.currentTenant
    // !this.currentTenant.code) {return};
    let data: any;
    data = {
      tenancyId: (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
      typeOperationCode : "CREDIT"
    };
    const request = {
      user: this.user.id,
      data
    }
    console.log('data sent to get operations', request)
    this.busyGet = this.api.post('operation/getByCriteria', request)
        .subscribe((res: any) => {
              console.log('listUnfiltered operations', res);
              if (res && res['items']) {
                if (res['items'] && res['items'].length){
                  this.listDonations = res['items'];
                  console.log('listDonations', this.listDonations);
                }
              } else {
                this.listDonations = [];
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }
  
  onValidateOperation(entr?) {
    let items = []
    console.log('entr',entr)
    if (!entr){
      this.listBeneficiairesTable.map(
          lbt=>{
            if (lbt.isChecked)
            lbt.tenancyId = (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
              items.push(lbt)
          }
      )
    }
    else {
      entr.tenancyId = (this.currentTenant && this.currentTenant.id) ? this.currentTenant.id : null,
      items = [entr]
    }
    Swal.fire({
      title:  'Validation de client(s)',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#00A984',
      cancelButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.validateClient(items);
      } else {
        console.log('bnjr');
      }
    })
  }

  private validateClient(items: any) {
    const request = {
      user: this.user.id,
      datas:items
    }
    console.log('data sent to validate client', JSON.stringify(request))
    this.busyGet = this.api.post('operationBeneficiaire/validatePrefinancement', request)
        .subscribe((res: any) => {
              console.log('client validate return', res);
              if (res && !res.hasError) {
                this.notificationService.showMessageSucces('Succès', res.status.message);
                this.cancelSave()
                this.getData();
              } else {
                this.notificationService.showMessageError('Erreur', res.status.message);
              }
            },
            err => {
              console.log('Error occured', err);
              this.notificationService.showMessageError('Connexion', this.notificationService.defaultMessage());
            });
  }
  onCheckItem() {
    this.size=0;
  this.listBeneficiairesTable.map(
      lbt=>{
          if (lbt.isChecked){
              this.size ++;
          }
      }
  )
  }

  ngOnInit() {

    this.userService.currentData.subscribe(currentData => {
      this.currentTenant = currentData;
      setTimeout(()=>{
        this.getOperations();
        this.getClients();
      },2000)

    });
    window.scrollTo(0, 0);
  }


}
