import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Subscription } from "rxjs";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Api } from "../../../shared/utils/api/api";
import { BsModalService } from "ngx-bootstrap/modal";
import { NotificationService } from "../../../shared/utils/notification.service";
import { UtilitiesService } from "../../../shared/utils/utilities.service";
import { UserService } from "../../../shared/user";
import * as moment from "moment";
declare const Swal: any;;
import { ChangeDetectorRef } from "@angular/core";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { defineLocale } from "ngx-bootstrap/chronos";
import { frLocale } from "ngx-bootstrap/locale";
import { CommunicationService } from "src/app/core/services/communication.service";
defineLocale("fr", frLocale);

@Component({
  selector: "app-main-promo",
  templateUrl: "./main-promo.component.html",
  styleUrls: ["./main-promo.component.css"],
})
export class MainPromoComponent implements OnInit {
  @ViewChild("libelle") libelle: ElementRef;
  locale = "fr";
  resultDelaiPaiements: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;

  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  currentTenant: any = {};
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination = 10;
  admin: any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: "static",
    ignoreBackdropClick: true,
  };
  listPromos = [];
  listTypeDon = [];
  ListeTenants = [];
  listDonateurs = [];
  listMarchands = [];
  dateDebut = new Date();
  dateFin = new Date();

  regions: any[] = [];
  cultures: any[] = [];
  selectedCultures: any[] = [];
  selectedRegions: any[] = [];
  selectedTenants: any[] = [];
  isMoreOptionCollapsed: boolean = false;

  constructor(
    private api: Api,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private utilities: UtilitiesService,
    private userService: UserService,
    private cdref: ChangeDetectorRef,
    private bsConfig: BsDatepickerConfig,
    private communicationService: CommunicationService
  ) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.itemToSearch = {};

    //this.bsConfig.containerClass = 'theme-blue';
    this.bsConfig.dateInputFormat = "L"; // Or format like you want
    this.bsConfig.dateInputFormat = "DD/MM/YYYY";
  }

  // MULTI SELECT

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified?: any) {
    this.cancelSave();
    this.dateDebut = moment().toDate();
    this.dateFin = moment().toDate();
    if (itemToModified) {
      this.itemToSave = { ...itemToModified };
      this.dateDebut = moment(this.itemToSave.dateDebut, "DD/MM/YYYY").toDate();
      this.dateFin = moment(this.itemToSave.dateFin, "DD/MM/YYYY").toDate();
    }
    this.itemToSave.nature = 2;
    this.modalRef = this.modalService.show(
      template,
      { class: "gray modal-md" }
    );
  }

  pageChanged(event: any): void {
    console.log("Page changed to: " + event.page);
    console.log("Number items per page: " + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }

  getData() {
    // if (!this.currentTenant || !this.currentTenant.code) {return};
    let data: any;
    data = {
      tenancyId:
        this.currentTenant && this.currentTenant.id
          ? this.currentTenant.id
          : null,
      typeOperationCode: "PROMO",
    };
    const request = {
      user: this.user.id,
      index: this.currentPage - 1,
      size: this.itemsPerPage ? this.itemsPerPage : null,
      data,
    };
    console.log("data sent to get donations list", request);
    this.busyGet = this.api.post("operations/getByCriteria", request).subscribe(
      (res: any) => {
        console.log("listUnfiltered operations", res);
        if (res && res["items"]) {
          if (res["items"] && res["items"].length) {
            this.listPromos = res["items"];
            // this.listPromos.map(
            //   lp=>{
            //     lp.dateDebut = moment(lp.dateDebut).format("DD/MM/YYYY");
            //     lp.dateFin = moment(lp.dateFin).format("DD/MM/YYYY");
            //   }
            // )
            console.log("listPromos", this.listPromos);
            this.totalItems = this.listPromos.length;
          }
        } else {
          this.listPromos = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
      }
    );
  }

  confirmSaveItem(obj: any) {
    this.itemToSave.dateDebut = moment(this.dateDebut).format("DD/MM/YYYY");
    this.itemToSave.dateFin = moment(this.dateFin).format("DD/MM/YYYY");
    if (!obj) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les champs obligatoires."
      );
      return;
    }
    if (!this.dateDebut || !moment(this.dateDebut, "DD-MM-YYYY").isValid) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner date debut svp!."
      );
      return;
    }
    if (!this.dateFin || !moment(this.dateFin, "DD-MM-YYYY").isValid) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner date fin svp!."
      );
      return;
    }
    if (moment(this.dateDebut).isAfter(this.dateFin)) {
      this.notificationService.showMessageError(
        "Erreur",
        "Date fin antérieure à date debut."
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.nature) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner type promo svp!."
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.dateDebut) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner date debut svp!."
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.dateFin) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner date fin svp!."
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.marchandId) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner marchand svp!."
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.libelle) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner libellé svp!."
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.description) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner description svp!."
      );
      return;
    }
    if (!this.itemToSave || !this.itemToSave.quantiteOperation) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner quantité svp!."
      );
      return;
    }
    Swal.fire({
      title: !obj.id ? "Enregistrement promo" : "Modification promo",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        this.saveItem(obj);
      } else {
        console.log("bnjr");
      }
    });
  }

  confirmDeleteItem(obj: any) {
    Swal.fire({
      title: "Suppression",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        this.deleteItem(obj);
      } else {
        console.log("bnjr");
      }
    });
  }

  cancelSave() {
    this.itemToSave = {};
  }

  saveItem(item: any) {
    // if (!this.currentTenant || !this.currentTenant.code) {return};
    item.tenancyId =
      this.currentTenant && this.currentTenant.id
        ? this.currentTenant.id
        : null;
    const request = {
      user: this.user.id,
      isForDon: false,
      isForPromo: true,
      isForCredited: false,
      datas: [
        {
          ...item,
          datasCultures: this.selectedCultures,
          datasRegions: this.selectedRegions,
          datasTenancy: this.selectedTenants,
        },
      ],
    };
    console.log("data sent to create promo", JSON.stringify(request));
    this.busySave = this.api
      .post("operation/" + (item.id ? "update" : "create"), request)
      .subscribe(
        (res: any) => {
          console.log("resul", res);
          if (!res["hasError"] && res["items"]) {
            this.notificationService.showMessageSucces(
              "Succès",
              "Opération effectuée avec succès."
            );
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
            this.communicationService.sendData("Refresh list");
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.notificationService.showMessageError(
                "Erreur",
                res["status"]["message"]
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Erreur",
            this.utilities.messageInterpretor(err)
          );
        }
      );
  }

  deleteItem(obj: any) {
    const request = {
      user: this.user.id,
      datas: [obj],
    };
    this.busyGet = this.api.post("operations/delete", request).subscribe(
      (res) => {
        console.log(res);
        if (!res["hasError"]) {
          this.notificationService.showMessageSucces(
            "Succès",
            "Suppression effectuée avec succès"
          );
          this.currentPage = 1;
          this.getData();
          this.communicationService.sendData("Refresh list");
        } else {
          if (res["status"] && res["status"]["message"]) {
            this.notificationService.showMessageError(
              "Erreur",
              res["status"]["message"]
            );
          }
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
      }
    );
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = (page - 1) * 10 + (index + 1);
    }
    return num;
  }
  getTypeDon() {
    const request = {
      user: this.user.id,
      data: {},
      index: this.currentPage - 1,
      size: this.itemsPerPage ? this.itemsPerPage : null,
    };
    this.busyGet = this.api.post("typeDon/getByCriteria", request).subscribe(
      (res: any) => {
        console.log("listTypeDon", res);
        // debugger
        if (res && res["items"]) {
          this.listTypeDon = res["items"];
          console.log("this.listTypeDon", this.listTypeDon);
        } else {
          this.listTypeDon = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
      }
    );
  }
  getTenancyMarchand() {
    const request = {
      user: this.user.id,
      data: {
        // tenancyId:
        //   this.currentTenant && this.currentTenant.id
        //     ? this.currentTenant.id
        //     : null,
        id: "",
        marchandId: "",
      },
      index: this.currentPage - 1,
      size: this.itemsPerPage ? this.itemsPerPage : null,
    };
    this.busyGet = this.api
      .post("marchandTenancy/getByCriteria", request)
      .subscribe(
        (res: any) => {
          console.log("getTenancyMarchand", res);
          // debugger
          if (res && res["items"]) {
            this.listMarchands = res["items"];
            console.log("List of marchands", this.listMarchands);
          } else {
            this.listTypeDon = [];
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Connexion",
            this.notificationService.defaultMessage()
          );
        }
      );
  }
  getTenancy() {
    const request = {
      user: this.user.id,
      data: {
        raisonSociale: this.itemToSearch.searchValue || null,
      },
      index: 0, // this.currentPage - 1,
      size: 10 * 1000 // this.itemsPerPage ? this.itemsPerPage : null,
    };

    this.busyGet = this.api.post("tenancy/getByCriteria", request).subscribe(
      (res) => {
        console.log("liste coopératives: ", res);
        if (res && res["items"]) {
          this.ListeTenants = res["items"];
          console.log("ListeTenants", this.ListeTenants);
        } else {
          this.ListeTenants = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
        // this.not
      }
    );
  }
  getDonator() {
    const request = {
      user: this.user.id,
      data: {},
      index: this.currentPage - 1,
      size: this.itemsPerPage ? this.itemsPerPage : null,
    };
    this.busyGet = this.api.post("donateur/getByCriteria", request).subscribe(
      (res: any) => {
        console.log("listDonateurs", res);
        if (res && res["items"]) {
          this.listDonateurs = res["items"];
        } else {
          this.listDonateurs = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
      }
    );
  }

  getRegions() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.api.post("region/getByCriteria", request).subscribe(
      (res: any) => {
        console.log("listRegions", res);
        if (res && res.items) {
          this.regions = res.items;
        } else {
          this.regions = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
      }
    );
  }

  getCultures() {
    const request = {
      user: this.user.id,
      data: {},
    };
    this.api.post("culture/getByCriteria", request).subscribe(
      (res: any) => {
        console.log("listCult", res);
        if (res && res.items) {
          this.cultures = res.items;
        } else {
          this.cultures = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
      }
    );
  }

  ngOnInit() {
    this.getTypeDon();
    this.getTenancy();
    this.getDonator();
    this.getRegions();
    this.getCultures();
    this.userService.currentData.subscribe((currentData) => {
      this.currentTenant = currentData;
      console.log("current tenant: ", this.currentTenant);

      setTimeout(() => {
        this.getData();
        this.getTenancyMarchand();
      }, 2000);
    });
    window.scrollTo(0, 0);
  }
}
