import { Component, OnInit } from "@angular/core";
import { Subscription } from 'rxjs';
import { Api } from "../../../shared/utils/api/api";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NotificationService } from "../../../shared/utils/notification.service";
import { UtilitiesService } from "../../../shared/utils/utilities.service";
import { ModelService } from "../../../shared/utils/model.service";
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
// import { FormProfilComponent } from '../form-profil/form-profil.component';
declare const Swal: any;;
import * as moment from "moment";
import { HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-main-parametrage-culture",
  templateUrl: "./main-parametrage-culture.component.html",
  styleUrls: ["./main-parametrage-culture.component.css"],
})
export class MainParametrageCultureComponent implements OnInit {
  currentSection = "";
  currentSectionId: any;
  results: Array<any> = [];
  ListeCoutsCultures: Array<any> = [];
  ListeCulturesTenant: Array<any> = [];
  totalItemsCoutCulture: number = 10;
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  currentPageCoutCulture = 1;
  user: any;
  itemsPerPage: any;
  itemsPerPageCoutCulture: any;
  itemToSearch: any;
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  currentItemImage: any;
  itemPerPagePagination: number = 10;
  admin: any = {};
  currentTenant: any = {};
  minDate: any = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: "static",
    ignoreBackdropClick: true,
  };
  searchKey: any;
  onFullSearch = new Subject<string>();

  constructor(
    private api: Api,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private utilities: UtilitiesService,
    private modelService: ModelService,
    private userService: UserService
  ) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 10;
    this.itemsPerPageCoutCulture = 10;
    this.itemToSearch = {};
    this.minDate = new Date();

    this.onFullSearch
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        this.getData();
      });
  }

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified?: any) {
    this.currentSection = "";
    this.cancelSave();

    if (itemToModified) {
      this.itemToSave = { ...itemToModified };
    }
    this.modalRef = this.modalService.show(template, {
      class: "gray modal-md",
    });
  }

  pageChanged(event: any): void {
    console.log("Page changed to: " + event.page);
    console.log("Number items per page: " + event.itemsPerPage);
    this.currentPage = event.page;
    this.getData();
  }

  pageChangedCoutCulture(event: any): void {
    this.currentPageCoutCulture = event.page;
    this.getDataCoutCulture(this.currentTenant);
  }

  getData() {
    var request = {
      user: this.user.id,
      data: {
        libelle: this.searchKey,
      },
      index: this.currentPage - 1,
      size: this.itemsPerPage ? this.itemsPerPage : null,
    };

    this.busyGet = this.api.post("culture/getByCriteria", request).subscribe(
      (res) => {
        console.log("getData culture", res);
        if (res && res["items"]) {
          this.results = res["items"];
          this.totalItems = res["count"] ? res["count"] : 0;
        } else {
          this.results = [];
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
        // this.not
      }
    );
  }

  getDataCoutCulture(tenant) {
    const options = {
      headers: new HttpHeaders({
        tenantID: tenant && tenant.code ? tenant.code : null, 
        lang: "fr",
      }),
    };

    var request = {
      user: this.user.id,
      data: {},
      index: this.currentPageCoutCulture - 1,
      size: this.itemsPerPageCoutCulture ? this.itemsPerPageCoutCulture : null,
    };

    this.busyGet = this.api
      .postOption("coursCulture/getByCriteria", request, options)
      .subscribe(
        (res) => {
          console.log("ListeCoutsCultures", res);
          if (res && res["items"]) {
            this.ListeCoutsCultures = res["items"];
            this.totalItemsCoutCulture = res["count"] ? res["count"] : 0;
          } else {
            this.ListeCoutsCultures = [];
          }
        },
        (err) => {
          console.log("Error occured", err);
          //this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
          // this.not
        }
      );
  }

  confirmSaveItem(obj: any) {
    if (!obj) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les champs obligatoires."
      );
      return;
    }

    if (!this.itemToSave || !this.itemToSave.libelle) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les champs obligatoires."
      );
      return;
    }

    Swal.fire({
      title: !obj.id ? "Enregistrement culture" : "Modification culture",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        this.saveItem(obj);
      } else {
        console.log("bnjr");
      }
    });
  }

  confirmSaveItemCoutCulture(obj: any) {
    if (!obj) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les champs obligatoires."
      );
      return;
    }

    if (
      !this.itemToSave ||
      !this.itemToSave.cultureSelected ||
      !this.itemToSave.prix ||
      !this.itemToSave.dateApplication
    ) {
      this.notificationService.showMessageError(
        "Erreur",
        "Veuillez renseigner les champs obligatoires."
      );
      return;
    }

    this.itemToSave.cultureId = this.itemToSave.cultureSelected.cultureId;
    this.itemToSave.dateApplication = moment(
      this.itemToSave.dateApplication
    ).format("DD/MM/YYYY");

    Swal.fire({
      title: !obj.id
        ? "Enregistrement coût culture"
        : "Modification  coût culture",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        this.saveItemCoutCulture(obj);
      } else {
        console.log("bnjr");
      }
    });
  }

  confirmDeleteItem(obj?, isCourCulture?) {
    Swal.fire({
      title: "Suppression",
      text: "Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#00A984",
      cancelButtonColor: "#333333",
    }).then((result) => {
      if (result.value) {
        if (!isCourCulture) {
          this.deleteItem(obj);
        } else {
          this.deleteItemCourCulture(obj);
        }
      } else {
        console.log("bnjr");
      }
    });
  }

  cancelSave() {
    this.itemToSave = {};
  }

  saveItemCoutCulture(item) {
    const options = {
      headers: new HttpHeaders({
        tenantID:
          this.currentTenant && this.currentTenant.code
            ? this.currentTenant.code
            : null, //'1907122100004',
        lang: "fr",
      }),
    };
    item.cultureSectionId = this.currentSectionId;
    delete item.cultureSelected;

    var request = {
      user: this.user.id,
      datas: [item],
    };

    console.log("create return", JSON.stringify(request));

    this.busySave = this.api
      .postOption(
        "coursCulture/" + (item.id ? "update" : "create"),
        request,
        options
      )
      .subscribe(
        (res) => {
          console.log("resul", res);
          if (!res["hasError"] && res["items"]) {
            this.notificationService.showMessageSucces(
              "Succès",
              "Opération effectuée avec succès."
            );
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getDataCoutCulture(this.currentTenant);
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.notificationService.showMessageError(
                "Erreur",
                res["status"]["message"]
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Erreur",
            this.utilities.messageInterpretor(err)
          );
        }
      );
  }

  saveItem(item: any) {
    var request = {
      user: this.user.id,
      datas: [item],
    };
    console.log("create", JSON.stringify(request));
    this.busySave = this.api
      .post("culture/" + (item.id ? "update" : "create"), request)
      .subscribe(
        (res) => {
          console.log("resul", res);
          if (!res["hasError"] && res["items"]) {
            this.notificationService.showMessageSucces(
              "Succès",
              "Opération effectuée avec succès."
            );
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.notificationService.showMessageError(
                "Erreur",
                res["status"]["message"]
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Erreur",
            this.utilities.messageInterpretor(err)
          );
        }
      );
  }

  deleteItemCourCulture(obj: any) {
    const options = {
      headers: new HttpHeaders({
        tenantID:
          this.currentTenant && this.currentTenant.code
            ? this.currentTenant.code
            : null, 
        lang: "fr",
      }),
    };

    var request = {
      user: this.user.id,
      datas: [obj],
    };

    this.busyGet = this.api
      .postOption("coursCulture/delete", request, options)
      .subscribe(
        (res) => {
          console.log(res);
          if (!res["hasError"]) {
            this.notificationService.showMessageSucces(
              "Succès",
              "Suppression effectuée avec succès"
            );

            this.currentPage = 1;
            this.getDataCoutCulture(this.currentTenant);
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.notificationService.showMessageError(
                "Erreur",
                res["status"]["message"]
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.notificationService.showMessageError(
            "Connexion",
            this.notificationService.defaultMessage()
          );
          // this.not
        }
      );
  }

  deleteItem(obj) {
    var request = {
      user: this.user.id,
      datas: [obj],
    };

    this.busyGet = this.api.post("culture/delete", request).subscribe(
      (res) => {
        console.log(res);
        if (!res["hasError"]) {
          this.notificationService.showMessageSucces(
            "Succès",
            "Suppression effectuée avec succès"
          );

          this.currentPage = 1;
          this.getData();
        } else {
          if (res["status"] && res["status"]["message"]) {
            this.notificationService.showMessageError(
              "Erreur",
              res["status"]["message"]
            );
          }
        }
      },
      (err) => {
        console.log("Error occured", err);
        this.notificationService.showMessageError(
          "Connexion",
          this.notificationService.defaultMessage()
        );
        // this.not
      }
    );
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = (page - 1) * 10 + (index + 1);
    }
    return num;
  }

  getDataCultureTenant() {
    if (!this.currentTenant || !this.currentTenant.code) return;

    const options = {
      headers: new HttpHeaders({
        tenantID:
          this.currentTenant && this.currentTenant.code
            ? this.currentTenant.code
            : null, //'1907122100004',
        lang: "fr",
      }),
    };

    var request = {
      user: this.user.id,
      data: {
        tenancyId: this.currentTenant.id,
      },
    };

    this.api
      .postOption("tenancyCulture/getByCriteria", request, options)
      .subscribe(
        (res) => {
          console.log("les tancy culture", res);
          if (res && res["items"]) {
            res["items"].forEach(function (value) {
              value.cultureLibelle = value.cultureLibelle.toUpperCase();
              value.libelle = value.cultureLibelle;
            });

            this.ListeCulturesTenant = res["items"];
          }
        },
        (err) => {
          console.log("Error occured", err);
        }
      );
  }

  ngOnInit() {
    this.getData();
    this.userService.currentData.subscribe((currentData) => {
      this.currentTenant = currentData;
      this.getDataCoutCulture(this.currentTenant);
      this.getDataCultureTenant();
    });
    window.scrollTo(0, 0);
  }
  
}
