
<div class="modal-header custom-modal-header">
    <h4 class="modal-title pull-left">Prévisualisation d'état</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModale()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
     <div class="row">
      
  
      <div class="form-group col-md-12">
          
        <!--div id="fancybox-content" style="border-width: 10px; width: auto; height: 341px;">
          <div style="width:auto;height:341px;overflow: hidden;position:relative;">
            <object data="http://localhost:82/PointagePro/wepApi/Report/ReportsPPM/RecapitulatifPaieParZone.php?date_debut=2018-03-01&date_fin=2018-03-04&libellesociete=ANSUT&libellecommune=FERKESSEDOUGOU&libellesecteur=RP&numero_semaine=9#toolbar=1&amp;navpanes=0&amp;nameddest=self&amp;page=1&amp;view=FitH,0&amp;zoom=80,0,0" type="application/pdf" height="100%" width="100%" internalinstanceid="6">
              <param name="src" value="http://localhost:82/PointagePro/wepApi/Report/ReportsPPM/RecapitulatifPaieParZone.php?date_debut=2018-03-01&date_fin=2018-03-04&libellesociete=ANSUT&libellecommune=FERKESSEDOUGOU&libellesecteur=RP&numero_semaine=9#toolbar=1&amp;navpanes=0&amp;nameddest=self&amp;page=1&amp;view=FitH,0&amp;zoom=80,0,0">
               <embed src="http://localhost:82/PointagePro/wepApi/Report/ReportsPPM/RecapitulatifPaieParZone.php?date_debut=2018-03-01&date_fin=2018-03-04&libellesociete=ANSUT&libellecommune=FERKESSEDOUGOU&libellesecteur=RP&numero_semaine=9#toolbar=1&amp;navpanes=0&amp;nameddest=self&amp;page=1&amp;view=FitH,0&amp;zoom=80,0,0" type="application/pdf" height="100%" width="100%">
                <a href="http://localhost:82/PointagePro/wepApi/Report/ReportsPPM/PaiementElectronicPeriodeOuReference.php" style="display:block;font-size:18px;height:20px;position:absolute;top:50%;margin:-10px auto 0 auto">
                  <span style="color:#e9eff7;padding:4px 13px 5px;font-size:11px;height:11px;line-height:11px;border-top:1px solid #92b0d5;border-radius:3px;text-shadow:-1px -1px 0 #20579a;-moz-border-radius:3px;-moz-text-shadow:-1px -1px 0 #20579a;-webkit-border-radius:3px;-webkit-text-shadow:-1px -1px 0 #20579a;">Afficher le report</span>
                </a>
              </object>
              </div>
          </div-->
  
          <!--div id="fancybox-content" style="border-width: 10px; width: auto; height: 100%;">
            <div style="width:auto;height:100%;overflow: hidden;position:relative;">
  
              <object data="http://localhost:82/PointagePro/wepApi/Report/ReportsPPM/PaiementElectronicPeriodeOuReference.php?date_debut=2018-03-01&date_fin=2018-03-04&libellereport=BORDEREAU&libellesociete=ANSUT#toolbar=1&amp;zoom=80,0,0&amp;page=1&amp;view=FitH" type="application/pdf" style="height:388px;width:100%">
                <embed src="http://localhost:82/PointagePro/wepApi/Report/ReportsPPM/PaiementElectronicPeriodeOuReference.php?date_debut=2018-03-01&date_fin=2018-03-04&libellereport=BORDEREAU&libellesociete=ANSUT#toolbar=1&amp;zoom=80,0,0&amp;page=1&amp;view=FitH" type="application/pdf" style="height:388px;width:100%" />
                
              </object>
  
            </div>
          </div-->
  
          <div id="fancybox-content" style="border-width: 10px; width: auto; height: 100%;">
              <div style="width:auto;height:100%;overflow: hidden;position:relative;">
    
                <object [data]='itemToSave.fileUrlFinale'  type="application/pdf" style="height:388px;width:100%" id="objPdf" internalinstanceid=''>
                  <embed [src]='itemToSave.fileUrlFinale'  type="application/pdf" style="height:388px;width:100%" id="embedPdf" /> 
                </object>
    
              </div>
            </div>
  
  
      </div>
   
     </div>
  </div>
  <div class="modal-footer">
     <!-- <button  type="submit" (click)="getUrlReport(itemToSave)" class="btn btn-md btn-primary btn-bg-new custom_border_radius" >
          Imprimer
    </button> -->
  
    <button type="button" class="btn btn-default custom_border_radius" (click)="hideModale()">Fermer</button>
  </div>