import { Injectable } from '@angular/core';

@Injectable()
export class UtilitiesService {

  //TypeUser : Object;

  constructor() { }

    setReportParametre(parametreReport:any)
    {
        localStorage.setItem("xyz-report-token",parametreReport);
    }

    getReportParametre()
    {
        let item = localStorage.getItem("xyz-report-token");
        return item;
    }

    deleteHtmlCaracter(item : any,nombre:number)
    {
        let regex = /(&nbsp;|<([^>]+)>)/ig;

        //RETIRER LES CARACTERES HTML
        item=item.replace(regex, "");

        if(item.length>nombre)
        {
            item = item.substring(0, nombre).trim()+""+" ...";
        }

        //this.htmlEntities(item);

        return item;
        //return this.escapeCaractereSpeciaux(item); //this.htmlEntities(item);
    }

    splitHourMinutesDate(date)
    {
        if(!date)
        {
           return " ";
        }

        date=date.replace("00:00", "");
        return date.trim()
    }

    mainSelfFormatMsgServeur(msg) {
        console.log("le message",msg);
        var  messageError=msg;
        if (msg){

        if(msg.trim() == "Operation effectuee avec succes:") 
        {
            messageError=messageError.replace("Operation effectuee avec succes:", "Operation effectuée avec succès.");
        }
        else{
            messageError=messageError.replace("Operation effectuee avec succes:", "");
        }
        
         messageError=messageError.replace("Operation effectuee avec succes:", "Operation effectuée avec succès");
         messageError=messageError.replace("Impossible de se connecter a la base de donnees:", "");
         messageError=messageError.replace("La base de donnees est indisponible:","");
         messageError=messageError.replace("Permission refusee par la base de donnees:","");
         messageError=messageError.replace("Le serveur de base de donnees a refuse la requete:","");
         messageError=messageError.replace("Authentification echouee:","");
         messageError=messageError.replace("Donnee existante:","");
         messageError=messageError.replace("Liste vide : il n'y a pas de donnees respectant ce critere:","");
         messageError=messageError.replace("il n'y a pas de donnees respectant ce critere","");
         messageError=messageError.replace("Champ non renseigne:","");
         messageError=messageError.replace("Utilisateur deja connecte:","");
         messageError=messageError.replace("la requete attendue n'est pas celle fournie:","");
         messageError=messageError.replace("Le type est incorrect:","");
         messageError=messageError.replace("Le format de la date est incorrect:","");
         messageError=messageError.replace("le serveur a signale un format invalide:","");
         messageError=messageError.replace("le code de la langue n'est pas valide:","");
         messageError=messageError.replace("La periode de date n'est pas valide","");
         messageError=messageError.replace("une erreur est survenue lors de l'enregistrement:","");
         messageError=messageError.replace("le name de l'entite n'est pas valide:","");
         messageError=messageError.replace("Veuillez renseigner une seule valeur pour cette donnee:","");
         messageError=messageError.replace("La somme des pourcentages ne doit exceder 100:","");
         messageError=messageError.replace("Erreur de generation de fichier:","");
         messageError=messageError.replace("login et/ou mot de passe incorrect(s):","");
         messageError=messageError.replace("Operation interdite/refusee:","");
         messageError=messageError.replace("Ccette donnees ne peut etre supprimee car elle est utilisee:","");
         messageError=messageError.replace("cette donnees est trop superieure:","");
         messageError=messageError.replace("Vous n'etes pas autoriser a effectuer cette operation.","");
         messageError=messageError.replace("Donnee inexistante:","");
         messageError=messageError.replace("Erreur interne:","");
         messageError=messageError.replace("Le serveur de base de donnees a refuse la requete:","");
         messageError=messageError.replace("cette donnees ne peut etre supprimee car elle est utilisee:","");
         messageError=messageError.replace("Vous n'etes pas autoriser a effectuer cette operation.","");
         

        }
        return  messageError;
    };

    

    messageInterpretor(err)
    {
        let messageError = '';
        let messageContains ='';
        console.log("interpreteur");

        let error = err;
        if(error && error.error && error.error.text)
        {
            let defError =  error.error.text;
            messageContains = "Integrity constraint violation";
            if(defError.indexOf(messageContains))
            {
                let currentColonne = '';
                let tabsColonne = defError.split('for key');
                if(tabsColonne && tabsColonne.length>1)
                {
                    let newTab = tabsColonne[1].split('in');

                    if(newTab && newTab.length>0)
                    {
                        currentColonne = newTab[0];
                    }
                    
                }
                messageError = "Tentative de duplication de donnée ("+currentColonne+")";
            }
            else
            {
                    messageContains = "Unknown column";
                    if(defError.indexOf(messageContains))
                    {
                        let currentColonne = '';
                        let tabsColonne = defError.split('Unknown column');
                        if(tabsColonne && tabsColonne.length>1)
                        {
                            let newTab = tabsColonne[1].split('in');

                            if(newTab && newTab.length>0)
                            {
                                currentColonne = newTab[0];
                            }
                            
                        }
                        messageError = "Champs non renseigné ("+currentColonne+")";
                    }
               
            }

        }
        else
        {
            messageError = "Connexion momentanément interrompue";
        }
        
        

        return messageError;
    }

    serviceDto()
    {
        let data = {
            Libelle : '',
            IsDeleted : false,
            CreatedBy : 1,
            ModifiedBy : 1,
            DeletedBy : 0
        }
        return data;
    }

    escapeCaractereSpeciaux(item)
    {

        var result = null;
        if(item && item.length>0)
        {
            result = item;

            result = result.replace('&nbsp;',' ');
            result = result.replace('&quot;','"');
            result = result.replace('&lt;','<');
            result = result.replace('&gt;','>');
            result = result.replace('&laquo;','«');
            result = result.replace('&raquo;','»');
            result = result.replace('&amp;','&');
            result = result.replace('&euro;','€');
            result = result.replace('&yen;','¥');
            result = result.replace('&copy;','©');
            result = result.replace('&agrave;','à');
            result = result.replace('&reg;','®');

            result = result.replace('&acirc;','â');
            result = result.replace('&eacute;','é');
            result = result.replace('&egrave;','è');
            result = result.replace('&ecirc;','ê');
            result = result.replace('&icirc;','î');
            result = result.replace('&iuml;','ï');
            result = result.replace('&oelig;','œ');
            result = result.replace('&ugrave;','ù');
            result = result.replace('&ucirc;','û');
            result = result.replace('&ccedil;','ç');


            result = result.replace('&Agrave;','À');
            result = result.replace('&Acirc;','Â');
            result = result.replace('&Eacute;','É');
            result = result.replace('&Egrave;','È');
            result = result.replace('&Ecirc;','Ê');
            result = result.replace('&Icirc;','Î');
            result = result.replace('&Iuml;','Ï');
            result = result.replace('&OElig;','Œ');
            result = result.replace('&Ugrave;','Ù');
            result = result.replace('&Ucirc;','Û');
            result = result.replace('&Ccedil;','Ç');


            result = result.replace('&acute;','\'');
            result = result.replace('&Acirc;','Â');
            result = result.replace('&Eacute;','É');
            result = result.replace('&Egrave;','È');
            result = result.replace('&Ecirc;','Ê');
            result = result.replace('&Icirc;','Î');
            result = result.replace('&Iuml;','Ï');
            result = result.replace('&OElig;','Œ');
            result = result.replace('&Ugrave;','Ù');
            result = result.replace('&Ucirc;','Û');
            result = result.replace('&Ccedil;','Ç');

        }

        console.log("retour item esacape",result);
        return result;
    }

    getStatusIds()
    {
        var listeStatus = [
            {
                libelle : 'Brouillon',
                statutId : 1
            },
            {
                libelle : 'En ligne',
                statutId : 2
            },
            {
                libelle : 'Terminé',
                statutId : 3
            },
            {
                libelle : 'Archivé',
                statutId : 4
            },
            {
                libelle : 'En attente',
                statutId : 5
            }
        ];

        return listeStatus;
    }


    removeLocalStorageBulletinMeteo()
    {
        localStorage.removeItem("refreshTemperatureMeteo");
    
        localStorage.removeItem("refreshTempsMeteo");

        localStorage.removeItem("DatasToSaveTemperatureLocalStorage");
        localStorage.removeItem("DatasToSaveTempsLocalStorage");

        localStorage.removeItem("DatasToSaveVentLocalStorage");
        localStorage.removeItem("refreshVentMeteo");

        localStorage.removeItem("refreshHumiditeMeteo");
        localStorage.removeItem("DatasToSaveHumiditeLocalStorage");

        localStorage.removeItem("refreshDescriptionMeteo");
        localStorage.removeItem("DatasToSaveDescriptionLocalStorage"); 

        localStorage.removeItem("refreshPrecipitationMeteo");
        localStorage.removeItem("DatasToSavePrecipitationLocalStorage"); 

        localStorage.removeItem("idCurrentBulletin");

        localStorage.removeItem("DatasToSaveHeureLeveLocalStorage");
        localStorage.removeItem("refreshHeureLeveMeteo");
        
        return true;
    }

    removeLocalStorageBulletinMaritime()
    {
        localStorage.removeItem("refreshDescriptionMaritime");
    
        localStorage.removeItem("refreshPeriodeMaritime");

        localStorage.removeItem("DatasToSaveBulletinMaritimeLocalStorage");
        localStorage.removeItem("DatasToSaveBulletinMaritimeDescriptionLocalStorage");
        
        return true;
    }

    
 /*   htmlEntities(string) {

        String.fromHtmlEntities = function(string) {

            var result =(string+"").replace(/&#\d+;/gm,function(s) {
                return String.fromCharCode(s.match(/\d+/gm)[0]);
            })
            return result;

            console.log("resuk",result);
        };
    }*/

 /* public getBaseUrl(){
      return location.protocol + '//' + location.hostname + (location.port ? ':'+location.port : '') + '/'
  }*/
}
