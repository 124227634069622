<!-- MAIN CONTENT -->
<div id="content" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">

  <!-- fil d actualite -->
  <div class="row">
    <div class="form-group col-md-12">
      <div class="col-md-12">

        <button (click)="openFormModalModal(templateCreate)" class="btn btn-md custom_border_radius btn-bg-new padding-30" type="button">
          Ajouter </button>
        <br/>

        <div class="col-md-12 no-padding" style="margin-top: 10px">
          <table class="table table-condensed table-striped">
            <thead>
            <tr class="custom-table-thead">
              <th style="width: 45px">
                <a (click)="getData()"> <i
                  class="fa fa-refresh"></i> </a></th>
              <th>Statut</th>
              <th>Date creation </th>
              <th>Libellé</th> 
              <th>Micro-finance </th>
              <!-- <th>Coopérative</th> -->
              <th class="text-right">Valeur</th>
              <th class="text-right">Restant</th>
              <!-- <th>Réference </th> -->
              <th style="text-align: center;"> Action </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let entr of listDonations; let i = index" >
              <td class="vertical-align-uppercase">
                {{i + 1}}
              </td>
              <td>
                <i *ngIf="!entr.isValidated" class="fa fa-check-circle fa-2x" aria-hidden="true"
                style="color: #e8091b" 
                tooltip="Non validé"
                 placement="right"
                ></i>

                <i *ngIf="entr.isValidated"  class="fa fa-check-circle fa-2x" aria-hidden="true"
                style="color: #0aa66e" 
                tooltip="Validé"
                 placement="right"
                ></i>
              </td>
              <td class="vertical-align-uppercase">{{entr.createdAt}}</td> 
              <td class="vertical-align-uppercase">{{entr.libelle}}</td>
              <td class="vertical-align-uppercase">{{entr.donateurNom}}</td>
              <!-- <td class="vertical-align-uppercase">{{entr.tenancyRaisonSociale}}</td> -->
              <td class="vertical-align-uppercase text-right" style="font-weight: 700">{{entr.valeurOperation | moneyFormat}}</td>
              <td class="vertical-align-uppercase text-right"  style="font-weight: 700">{{entr.valeurOperationRestante | moneyFormat}}</td>
            
              <!-- <td class="vertical-align-uppercase">{{entr.referenceDevice}}</td> -->
              <td style="text-align: center">
                <a title="Modifier" class="positionEdit" (click)="openFormModalModal(templateCreate,entr)">
                  <i class="fa fa-edit" style="color: green"> </i>
                </a>
                <a title="Supprimer" (click)="confirmDeleteItem(entr)">
                  <i class="fa fa-trash-o" style="color: red"> </i>
                </a>
              </td>
              
            </tr>

            <tr *ngIf="listDonations && listDonations.length > 0">
              <td colspan="10">
                <div class="col-xs-12 col-12" style="text-align: left;padding: 0px;margin-top: 15px">
                  <pagination [boundaryLinks]="true" [totalItems]="totalItems" [maxSize]='itemPerPagePagination' [itemsPerPage]="itemsPerPage"
                              [(ngModel)]="currentPage" class="pagination-sm" previousText="&laquo;" nextText=" &raquo;"
                              firstText="&laquo;" lastText="&raquo;" name="pagination" (pageChanged)="pageChanged($event)">
                  </pagination>
                </div>
              </td>
            </tr>
            </tbody>
            <tfoot *ngIf="!listDonations || listDonations.length == 0">
            <tr>
              <td colspan="10" style="text-align:center; font-weight:bold">Aucune donnée disponible</td>
            </tr>

            </tfoot>

          </table>
        </div>

      </div>


    </div>
  </div>
  <!-- fin file d actualite -->



  <ng-template #templateCreate>
    <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">
          CREDIT
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-lg-12">

            <div class="row">
              <div class="col-md-12">

                <div class="form-group col-md-12">
                  <div class="row">
                    <label style="margin-left: 14px;">Micro-finance :
                      <span class="required_champs">*</span>
                    </label>
                    <br>
                    <div class="col-md-11">

                      <select class="form-control custom_input" id="donateurId" [(ngModel)]="itemToSave.donateurId" #ctrl="ngModel"
                              name="donateurId" >
                        <option></option>
                        <option *ngFor="let donateur of listDonateurs " [ngValue]="donateur.id">
                          {{ donateur.nom }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-1">
                      <button (click)="quickAddDonator(templateCreateDonator)" style="margin-top: 5px"
                              class="btn btn-xs btn-primary pull-right margin-top-1">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div> 
                <div class="form-group col-md-12">
                  <label>Libellé({{itemToSave.libelle?.length || 0}}/50)  :
                    <span class="required_champs">*</span>
                  </label>
                  <input maxlength="50" class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.libelle" name="libelle" required
                         placeholder="libelle" type="text">
                </div>
                <div class="form-group col-md-12">
                  <label>Description :
                    <span class="required_champs">*</span>
                  </label>
                  <textarea name="description" id="" style="width: 100%" [(ngModel)]="itemToSave.description" rows="3"></textarea>
                </div>
                <div class="form-group col-md-12">
                  <label>Valeur :
                    <span class="required_champs">*</span>
                  </label>
                  <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.valeurOperation" name="valeur" required
                         placeholder="valeur" type="text" mask="separator">
                </div>
              </div>
            </div>

          </div>

        </div>


      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left">(
          <span class="required_champs">*</span>) Champs requis</b>

        <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal()">
          Annuler
        </button>

        <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItem(itemToSave) ">
          Valider
        </button>

      </div>
    </form>
  </ng-template>

  <ng-template #templateCreateDonator>
    <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">
          DON
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left">(
          <span class="required_champs">*</span>) Champs requis</b>

        <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal()">
          Annuler
        </button>

        <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItem(itemToSave) ">
          Valider
        </button>

      </div>
    </form>
  </ng-template>

  <ng-template #templateCreateDonator>
    <form name="frmSave" [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
      <div class="modal-header custom-modal-header">
        <h4 class="modal-title pull-left">
          MICRO-FINANCE
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal2()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="form-group col-lg-12">

            <div class="row">
              <div class="col-md-12">

                <div class="form-group col-md-12">
                  <label>Nom :
                    <span class="required_champs">*</span>
                  </label>
                  <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.nom" name="nom" required
                         placeholder="Nom" type="text">
                </div>


                <div class="form-group col-md-12">
                  <label>Contact :
                    <span class="required_champs">*</span>
                  </label>
                  <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.contact" name="contact" required
                         placeholder="Contact" type="text" mask="00-00-00-00">
                </div>
                <div class="form-group col-md-12">
                  <label>Email :
                    <span class="required_champs">*</span>
                  </label>
                  <input class="form-control custom_input" autocomplete="off" [(ngModel)]="itemToSave.email" name="email" required
                         placeholder="Email" type="text">
                </div>
              </div>
            </div>

          </div>

        </div>


      </div>
      <div class="modal-footer modal-footer-custom">
        <b class="pull-left">(
          <span class="required_champs">*</span>) Champs requis</b>

        <button type="button" class="btn btn-md custom_border_radius btn-default padding-30  btn-bg-cancel" (click)="hideModal2()">
          Annuler
        </button>

        <button type="submit" class="btn btn-md custom_border_radius btn-bg-new padding-30 " (click)="confirmSaveItemDonator(itemToSave) ">
          Valider
        </button>

      </div>
    </form>
  </ng-template>



</div>
